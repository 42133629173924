import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { fetchAssetCategories } from 'store/thunks/fetchAssetCategories';
import { ReadonlyData } from '@clearalpha/common';
import { FormikSelect } from 'components/formik/fields';
import { FieldLabel } from 'components/StrategyDetails/blocks/styled';
import {
  assetCategoriesSelector,
  isGlobalStrategyEditModeSelector,
} from 'store/slices/strategies';
import { GlobalStrategyDetailsFormFields } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.type';
import { StrategyFormFields } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.constants';
import { convertValuesToOptions } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.helper';

const StrategyCategory = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<GlobalStrategyDetailsFormFields>();
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);
  const assetCategories = useSelector(assetCategoriesSelector);

  useEffect(() => {
    if (!assetCategories.length) {
      dispatch(fetchAssetCategories());
    }
  }, [dispatch, assetCategories.length]);

  const options = useMemo(
    () => convertValuesToOptions(assetCategories),
    [assetCategories]
  );

  return (
    <>
      <FieldLabel>{t('strategyCategory')}</FieldLabel>
      {isEditMode ? (
        <FormikSelect
          name={StrategyFormFields.DESCRIPTION_SHORT}
          options={options}
        />
      ) : (
        <ReadonlyData>
          {
            assetCategories.find(
              (assetCategory) => assetCategory.id === values.description_short
            )?.name
          }
        </ReadonlyData>
      )}
    </>
  );
};

export default StrategyCategory;
