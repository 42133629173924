import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AbstractLayout, GridElement, Loader } from '@clearalpha/common';
import DashboardWrapper from '@clearalpha/common/dist/components/DashboardWrapper/DashboardWrapper';
import { SelectChangeEvent } from '@mui/material';
import { fetchOptimization, saveWorkingPortfolio } from 'store/thunks';
import { useAppDispatch } from 'store';
import { createWidgetsWithData } from 'components/widgets/Widgets.helper';
import { VersionListDialog } from 'components/Dialogs/VersionListDialog';
import { CorrelationsDialog } from 'components/Dialogs/CorrelationsDialog';
import { WorkspaceHeader } from 'components/WorkspaceHeader';
import {
  setIsOpenConfirmationDialog,
  productionPortfolioPerformanceSelector,
  workingPortfolioSelector,
  workingPortfolioPerformanceSelector,
  isLoadingPortfolioSelector,
  isOptimizationLoadingSelector,
  isConfirmationDialogActiveSelector,
  isReviewModePortfolioSelector,
  sourcePortfolioSelector,
  productionPortfolioSelector,
} from 'store/slices/portfolios';
import { selectedChangeRequestSelector } from 'store/slices/changeRequests';
import { SuccessDialog } from 'components/Dialogs/SuccessDialog/SuccessDialog';
import ParametersDialog from 'components/Dialogs/ParametersDialog/ParametersDialog';
import ResetToProductionDialog from 'components/Dialogs/ResetToProductionDialog/ResetToProductionDialog';
import { ApprovalHeader } from 'components/ChangeRequests/ApprovalHeader/ApprovalHeader';
import {
  ContentContainer,
  DashboardContainer,
} from './StrategyRiskAllocator.styled';
import {
  useCalculateTabWidth,
  useCalculateDashboardHeight,
} from './StrategyRiskAllocator.helper';
import {
  SaraAbstractConfig,
  SaraWrapperProps,
} from './StrategyRiskAllocator.type';

const StrategyRiskAllocator = ({
  dashboardContainerHeight,
}: SaraWrapperProps) => {
  const dispatch = useAppDispatch();

  const [widgetsConfig, setWidgetsConfig] =
    useState<AbstractLayout[]>(SaraAbstractConfig);
  const [widgetGridElements, setWidgetGridElements] = useState<GridElement[]>(
    []
  );
  const [selectedFund, setSelectedFund] = useState<string>('Niche Plus Fund');
  const [isViewHistoryOpen, setIsViewHistoryOpen] = useState<boolean>(false);
  const [isResetToProductionOpen, setIsResetToProductionOpen] =
    useState<boolean>(false);
  const [isParametersOpen, setIsParametersOpen] = useState<boolean>(false);
  const [isCorrelationsOpen, setIsCorrelationsOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const productionPortfolioPerformance = useSelector(
    productionPortfolioPerformanceSelector
  );
  const workingPortfolioPerformance = useSelector(
    workingPortfolioPerformanceSelector
  );
  const isLoadingPortfolio = useSelector(isLoadingPortfolioSelector);
  const isOptimizationLoading = useSelector(isOptimizationLoadingSelector);
  const isConfirmationDialogActive = useSelector(
    isConfirmationDialogActiveSelector
  );
  const minTabWidth = useCalculateTabWidth();
  const dashboardHeight = useCalculateDashboardHeight(dashboardContainerHeight);
  const isReviewPortfolioMode = useSelector(isReviewModePortfolioSelector);
  const selectedChangeRequest = useSelector(selectedChangeRequestSelector);
  const workingPortfolio = useSelector(workingPortfolioSelector);
  const sourcePortfolio = useSelector(sourcePortfolioSelector);
  const { correlationSets } = useSelector(productionPortfolioSelector);

  const handleViewHistoryClose = () => setIsViewHistoryOpen(false);
  const handleViewHistoryOpen = () => setIsViewHistoryOpen(true);

  const handleResetToProductionClose = () => setIsResetToProductionOpen(false);
  const handleResetToProductionOpen = () => setIsResetToProductionOpen(true);

  const handleParametersClose = () => setIsParametersOpen(false);
  const handleParametersOpen = () => setIsParametersOpen(true);

  const handleCorrelationsClose = () => setIsCorrelationsOpen(false);
  const handleCorrelationsOpen = () => setIsCorrelationsOpen(true);

  const updateWidgetGridElements = async () => {
    // TODO: REMOVE REDUCE AND SOLVE ISSUE WITH TS
    const widgetsWithData = createWidgetsWithData(widgetsConfig).reduce(
      (acc: GridElement[], { key, component }) => {
        if (key) {
          acc.push({
            key,
            component,
          });
        }

        return acc;
      },
      []
    );
    setWidgetGridElements(widgetsWithData);
  };

  const handleSavePortfolio = async () => {
    const { payload: newPortfolio } = await dispatch(saveWorkingPortfolio());

    if (newPortfolio) {
      dispatch(setIsOpenConfirmationDialog(true));
    }
  };

  // effect is called on update of either 'productionPortfolioPerformance' or 'workingPortfolioPerformance'
  // TODO: call effect once both states are set
  useEffect(() => {
    updateWidgetGridElements();
  }, [productionPortfolioPerformance, workingPortfolioPerformance]);

  const handleRunOptimization = () => {
    dispatch(fetchOptimization());
  };

  const confirmationCloseHandler = () => {
    dispatch(setIsOpenConfirmationDialog(false));
  };

  const handleFundNameChange = (event: SelectChangeEvent<any>) => {
    // TODO: Remove local state, change fundName in Redux
    setSelectedFund(event.target.value);
  };

  return (
    <>
      <ContentContainer>
        <WorkspaceHeader
          fundName={selectedFund}
          tenantName='' // TODO: CHANGE TENANT NAME (UX-670)
          totalAUM={
            isReviewPortfolioMode
              ? sourcePortfolio.assetsUnderManagement || 0
              : workingPortfolio.assetsUnderManagement || 0
          }
          onSave={handleSavePortfolio}
          onOpenViewHistory={handleViewHistoryOpen}
          onOpenResetToProduction={handleResetToProductionOpen}
          onRunOptimization={handleRunOptimization}
          onChangeFundName={handleFundNameChange}
          onOpenParameters={handleParametersOpen}
          onOpenCorrelations={handleCorrelationsOpen}
          fundOptions={[selectedFund]} // TODO: CHANGE LOGIC AFTER DEMO
        />
        {isReviewPortfolioMode && (
          <ApprovalHeader changeRequest={selectedChangeRequest} />
        )}
        <DashboardContainer
          style={{
            height: dashboardHeight,
          }}
        >
          <DashboardWrapper
            areWidgetsResizable={false}
            widgetGridElements={widgetGridElements}
            widgetsConfig={widgetsConfig}
            setWidgetsConfig={setWidgetsConfig}
            minWidthToDisplayTab={1240}
            minColumnsToDisplayTab={3}
            isTabAlwaysVisible
            areColumnsEven
            minTabWidth={minTabWidth}
          />
        </DashboardContainer>
      </ContentContainer>
      <VersionListDialog
        handleClose={handleViewHistoryClose}
        isOpen={isViewHistoryOpen}
      />
      <ResetToProductionDialog
        isOpen={isResetToProductionOpen}
        handleClose={handleResetToProductionClose}
      />
      <ParametersDialog
        handleClose={handleParametersClose}
        isOpen={isParametersOpen}
      />
      {!!correlationSets.length && (
        <CorrelationsDialog
          handleClose={handleCorrelationsClose}
          isOpen={isCorrelationsOpen}
          correlationSets={correlationSets}
        />
      )}
      <SuccessDialog
        isOpen={isConfirmationDialogActive}
        handleClose={confirmationCloseHandler}
        title={t('success.versionSaved')}
        message={t('success.versionDescription')}
      />
      <Loader loading={isLoadingPortfolio || isOptimizationLoading} />
    </>
  );
};

export default StrategyRiskAllocator;
