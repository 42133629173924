import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { NumericTextCurrency } from '@clearalpha/common';

const FormButtonsContainer = styled(Box)`
  display: flex;
  gap: 20px;
  margin-bottom: 40px;

  button {
    width: 142px;
  }
`;

const Wrapper = styled.div`
  width: 1250px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-bottom: 32px;
`;

const HighlightedDetailsValue = styled(NumericTextCurrency)`
  background: ${({ theme: { colors } }) => colors.highlightColor};
  width: fit-content;
`;

const StyledForm = styled.form`
  overflow: auto;
`;

export {
  FormButtonsContainer,
  Wrapper,
  Header,
  SectionGrid,
  HighlightedDetailsValue,
  StyledForm,
};
