import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { InputAdornment } from '@mui/material';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import {
  MixWrapper,
  MixTitle,
  Slider,
  SliderWrapper,
  MixLabel,
  PercentFieldSmall,
} from './CorrelationsDialog.styled';

const CorrelationMix = () => {
  const { t } = useTranslation();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);

  const [leftValue, setLeftValue] = useState<number | ''>(100);
  const [rightValue, setRightValue] = useState<number | ''>(0);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setLeftValue(100 - newValue);
      setRightValue(newValue);
    }
  };

  return (
    <MixWrapper>
      <MixTitle id='input-slider' gutterBottom>
        {t('dialogs.correlationMatrix.mix')}
      </MixTitle>
      <SliderWrapper>
        <div>
          <PercentFieldSmall
            variant='outlined'
            value={leftValue ?? ''}
            InputProps={{
              readOnly: true,
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
          />
          <MixLabel>{t('dialogs.correlationMatrix.regular')}</MixLabel>
        </div>

        <Slider
          value={typeof rightValue === 'number' ? rightValue : 0}
          onChange={handleSliderChange}
          aria-labelledby='input-slider'
          step={100}
          disabled={isPortfolioReviewMode}
        />

        <div>
          <PercentFieldSmall
            variant='outlined'
            value={rightValue ?? ''}
            InputProps={{
              readOnly: true,
              endAdornment: <InputAdornment position='end'>%</InputAdornment>,
            }}
          />
          <MixLabel>{t('dialogs.correlationMatrix.tail')}</MixLabel>
        </div>
      </SliderWrapper>
    </MixWrapper>
  );
};

export default CorrelationMix;
