import React from 'react';
import { useSelector } from 'react-redux';
import { prepareStrategiesData } from 'components/StrategyList/StrategyList.helpers';
import {
  productionPortfolioSelector,
  sourcePortfolioSelector,
  targetPortfolioSelector,
  isReviewModePortfolioSelector,
  workingPortfolioOptimizationsSelector,
  workingPortfolioSelector,
} from 'store/slices/portfolios';
import {
  saraProductionStrategiesSelector,
  sourceStrategiesSelector,
  strategiesSelector,
  targetStrategiesSelector,
} from 'store/slices/strategies/strategies.selectors';
import List from 'components/StrategyList/StrategyList';
import { getStrategyToView } from 'components/StrategyDetails/StrategyDetails.helper';
import { getStrategiesAllocationsDiff } from '../WorkspaceHeader/PromotePortfolio/PromotePortfolio.helpers';

const StrategyList = () => {
  const { strategyAllocations: productionStrategyAllocations } = useSelector(
    productionPortfolioSelector
  );
  const { strategyAllocations: workingStrategyAllocations } = useSelector(
    workingPortfolioSelector
  );
  const { strategyAllocations: sourceStrategyAllocations } = useSelector(
    sourcePortfolioSelector
  );
  const { strategyAllocations: targetStrategyAllocations } = useSelector(
    targetPortfolioSelector
  );
  const strategies = useSelector(strategiesSelector);
  const strategiesProd = useSelector(saraProductionStrategiesSelector);
  const sourceStrategies = useSelector(sourceStrategiesSelector);
  const targetStrategies = useSelector(targetStrategiesSelector);

  const { strategyAllocation: optimizations } = useSelector(
    workingPortfolioOptimizationsSelector
  );

  const isReviewMode = useSelector(isReviewModePortfolioSelector);

  const currentStrategyAllocations = isReviewMode
    ? sourceStrategyAllocations
    : workingStrategyAllocations;
  const currentProductionStrategyAllocations = isReviewMode
    ? targetStrategyAllocations
    : productionStrategyAllocations;
  const currentStrategies = isReviewMode ? sourceStrategies : strategies;
  const currentStrategiesProduction = isReviewMode
    ? targetStrategies
    : strategiesProd;
  const strategiesToDisplay = prepareStrategiesData(
    currentProductionStrategyAllocations,
    currentStrategyAllocations,
    currentStrategies,
    currentStrategiesProduction,
    optimizations
  );

  const getStrategy = (strategyId: string) => {
    return getStrategyToView({
      strategyId,
      strategies: strategiesToDisplay,
    });
  };

  const { strategyAllocationsADiff, strategyAllocationsBDiff } =
    getStrategiesAllocationsDiff(
      currentProductionStrategyAllocations,
      currentStrategyAllocations
    );

  const strategiesDiffs = strategyAllocationsADiff.concat(
    strategyAllocationsBDiff
  );

  return (
    <List
      strategiesData={strategiesToDisplay}
      getStrategy={getStrategy}
      strategiesDiffs={isReviewMode ? strategiesDiffs : []}
    />
  );
};

export default StrategyList;
