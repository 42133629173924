import React, { useEffect, useState } from 'react';
import { TableRow, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  currencyFormatter,
  currencyFormatterShorthand,
  FieldValue,
  FieldValueWarning,
  NonDraggableContainer,
  THEME_COLORS,
} from '@clearalpha/common';
import {
  TitleCell,
  ValueCell,
} from '@clearalpha/common/dist/components/Dashboard/Widgets/TableWidgetBackbone';
import {
  Fund,
  FundWarnings,
} from 'services/fundCalculator/fundCalculator.types';
import UpdateAumDialog from 'components/Dialogs/UpdateAumDialog/UpdateAumDialog';
import { useAppDispatch } from 'store';
import {
  changeAssetsUnderManagementAndRunCalculation,
  isReviewModePortfolioSelector,
} from 'store/slices/portfolios';
import { StatRowFundOverview } from './FundOverviewWidget.type';
import { Button, ButtonWrap } from './FundOverviewWidget.styled';

const AUMRow = ({
  aumRow,
  production,
  scenario,
  scenarioWarnings,
}: {
  aumRow: StatRowFundOverview;
  production: Fund;
  scenario: Fund;
  scenarioWarnings: FundWarnings;
}) => {
  const dispatch = useAppDispatch();
  const [scenarioAum, setScenarioAum] = useState(scenario.aum);
  const [isUpdateAumDialogOpen, setIsUpdateAumDialogOpen] = useState(false);

  const handleOpenUpdateAumDialog = () => {
    setIsUpdateAumDialogOpen(true);
  };

  const handleCloseUpdateAumDialog = () => {
    setIsUpdateAumDialogOpen(false);
  };

  const handleUpdateAum = (value: number) => {
    setScenarioAum(value);
    dispatch(changeAssetsUnderManagementAndRunCalculation(value));
  };

  const buttonText = currencyFormatterShorthand(scenarioAum);
  const isReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted = isReviewMode && scenarioAum !== production.aum;

  useEffect(() => {
    setScenarioAum(scenario.aum);
  }, [scenario.aum]);

  return (
    <TableRow>
      <TitleCell>{aumRow.fieldName}</TitleCell>
      <ValueCell align='right'>
        <FieldValue
          row={{
            ...aumRow,
            type: aumRow.type.left,
          }}
          value={production.aum}
        />
      </ValueCell>
      <ValueCell
        align='right'
        style={
          isValueHighlighted
            ? { backgroundColor: THEME_COLORS.highlightColor }
            : {}
        }
      >
        {!isReviewMode ? (
          <NonDraggableContainer>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              title={currencyFormatter(scenarioAum)}
              placement='top'
              arrow
            >
              <ButtonWrap>
                <Button onClick={handleOpenUpdateAumDialog}>
                  {buttonText}
                </Button>
              </ButtonWrap>
            </Tooltip>
            <UpdateAumDialog
              isOpen={isUpdateAumDialogOpen}
              handleClose={handleCloseUpdateAumDialog}
              onUpdate={handleUpdateAum}
              updateAumDialogData={{
                production_aum: production.aum,
                total_aum: scenarioAum,
              }}
            />
            {scenarioWarnings[aumRow.variableName] && (
              <FieldValueWarning sx={{ top: '44px!important' }}>
                {scenarioWarnings[aumRow.variableName]?.join(' ')}
              </FieldValueWarning>
            )}
          </NonDraggableContainer>
        ) : (
          <div>
            <FieldValue
              row={{
                ...aumRow,
                type: aumRow.type.right,
              }}
              value={scenario.aum}
            />
          </div>
        )}
      </ValueCell>
    </TableRow>
  );
};

export default AUMRow;
