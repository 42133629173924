import { InfinityIcon, PenPaperIcon } from '@clearalpha/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnHeader, ColumnTitle } from './styled';

const ColumnsHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <div />
      <ColumnHeader>
        <ColumnTitle>{t('production')}</ColumnTitle>
        <InfinityIcon />
      </ColumnHeader>
      <ColumnHeader>
        <ColumnTitle>{t('scenario')}</ColumnTitle>
        <PenPaperIcon />
      </ColumnHeader>
    </>
  );
};

export default ColumnsHeader;
