import { TableRow, Tooltip } from '@mui/material';
import {
  CheckboxOutlineBlankIcon,
  CheckboxIcon,
  IndeterminateCheckboxIcon,
} from '@clearalpha/common';
import {
  LOCALS,
  HeadTooltipProps,
  StrategyListTableHeaderProps,
} from './StrategyListTableHeader.type';
import {
  ColumnText,
  DefaultCell,
  CheckBoxIconButtonToggle,
  TableHead,
} from './styled';

const CellTooltip = ({ children, sx }: HeadTooltipProps) => (
  <ColumnText noWrap sx={sx}>
    <Tooltip title={children} placement='top' arrow>
      <span>{children}</span>
    </Tooltip>
  </ColumnText>
);

const StrategyListTableHeader = ({
  handleSelectAll,
  strategiesCount,
  selectedStrategiesCount,
}: StrategyListTableHeaderProps) => {
  const isSomeStrategiesSelected =
    selectedStrategiesCount > 0 && selectedStrategiesCount < strategiesCount;
  const isAllStrategiesSelected =
    strategiesCount > 0 && selectedStrategiesCount === strategiesCount;

  return (
    <TableHead>
      <TableRow>
        <DefaultCell>
          <CheckBoxIconButtonToggle
            inputProps={{
              'aria-label': 'select all strategies',
            }}
            icon={<CheckboxOutlineBlankIcon width={24} height={24} />}
            checkedIcon={<CheckboxIcon width={24} height={24} />}
            indeterminateIcon={
              <IndeterminateCheckboxIcon width={24} height={24} />
            }
            onChange={handleSelectAll}
            indeterminate={isSomeStrategiesSelected}
            checked={isAllStrategiesSelected}
          />
        </DefaultCell>
        <DefaultCell>
          <CellTooltip>{LOCALS.STRATEGY_COLUMN}</CellTooltip>
        </DefaultCell>
        <DefaultCell>
          <CellTooltip>{LOCALS.ASSET_CATEGORY}</CellTooltip>
        </DefaultCell>
        <DefaultCell>
          <CellTooltip>{LOCALS.MIN_ALLOCATION}</CellTooltip>
        </DefaultCell>
        <DefaultCell>
          <CellTooltip>{LOCALS.CAPACITY}</CellTooltip>
        </DefaultCell>
        <DefaultCell>
          <CellTooltip>{LOCALS.CAPACITY_PRODUCTION}</CellTooltip>
        </DefaultCell>
        <DefaultCell>
          <CellTooltip>{LOCALS.CAPACITY_SCENARIO}</CellTooltip>
        </DefaultCell>
        <DefaultCell />
        <DefaultCell />
      </TableRow>
    </TableHead>
  );
};

export default StrategyListTableHeader;
