import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SensitiveDataBlock } from '@clearalpha/common';
import { FormikText, FormikValue } from 'components/formik/fields';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { isTeamEditModeSelector } from 'store/slices/teams';
import { TeamDetailsFormFieldName } from 'components/Teams/TeamDetails/TeamDetails.constants';
import { FieldWrapper, Title } from './TeamName.styled';

const TeamName = () => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isTeamEditModeSelector);
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);

  if (isEditMode) {
    return (
      <FieldWrapper>
        <FormikText
          name={TeamDetailsFormFieldName.NAME}
          label={t('teamName')}
          placeholder={t('teamName')}
          fullWidth
        />
      </FieldWrapper>
    );
  }

  return (
    <Title>
      <SensitiveDataBlock isVisible={isVisibleSensitiveData} minHeight='56px'>
        <FormikValue name={TeamDetailsFormFieldName.NAME} />
      </SensitiveDataBlock>
    </Title>
  );
};

export default TeamName;
