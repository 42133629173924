import { useState } from 'react';
import { TableRow, Tooltip } from '@mui/material';
import {
  CheckboxIcon,
  CheckboxOutlineBlankIcon,
  LockIcon,
  UnlockIcon,
  DeleteIcon,
  currencyFormatter,
  currencyFormatterShorthand,
  THEME_COLORS,
} from '@clearalpha/common';
import { useSelector } from 'react-redux';
import {
  isReviewModePortfolioSelector,
  changeStrategiesAllocationsAndRunCalculation,
  toggleLockStrategiesAllocations,
} from 'store/slices/portfolios';
import {
  updateStrategiesAllocation,
  updateStrategiesLock,
} from 'store/slices/strategies';
import { useAppDispatch } from 'store';
import { StrategyListItemProps } from './StrategyListItem.type';
import AllocationDialog from '../AllocationDialog/AllocationDialog';
import {
  CheckBoxCell,
  CheckBoxIconButtonToggle,
  Span,
  Button,
  DefaultTableCell,
  IconCell,
  IconButtonToggle,
  IconButton,
  CurrencySpan,
  WorkingSpan,
} from './styled';

const StrategyListItem = ({
  strategy,
  handleSelect,
  isSelected,
  isHighlighted,
  labelId,
  handleOpenStrategyDrawer,
  handleOpenDeleteDialog,
}: StrategyListItemProps) => {
  const dispatch = useAppDispatch();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const [isAllocationDialogOpen, setIsAllocationDialogOpen] = useState(false);

  const {
    id,
    productionNotionalAccountValue,
    workingNotionalAccountValue,
    locked,
    globalStrategyId,
    strategy: { assetCategory, name: strategyName, minAllocation, capacity },
  } = strategy;

  const handleOpenStrategy = () => {
    if (workingNotionalAccountValue === null) return;

    handleOpenStrategyDrawer(strategy.id);
  };

  const handleOpenAllocationDialog = () => {
    setIsAllocationDialogOpen(true);
  };

  const handleCloseAllocationDialog = () => {
    setIsAllocationDialogOpen(false);
  };

  const handleUpdateAllocation = (notionalAccountValue: number) => {
    dispatch(
      updateStrategiesAllocation([{ strategyId: id, notionalAccountValue }])
    );

    dispatch(
      changeStrategiesAllocationsAndRunCalculation([
        { strategyId: id, notionalAccountValue },
      ])
    );
  };

  const handleLockToggle = () => {
    dispatch(
      toggleLockStrategiesAllocations([
        {
          strategyId: id,
          isLocked: !locked,
        },
      ])
    );
    dispatch(
      updateStrategiesLock([
        {
          strategyId: id,
          isLocked: !locked,
        },
      ])
    );
  };

  return (
    <TableRow
      key={id}
      aria-checked={isSelected}
      selected={isSelected}
      style={
        isHighlighted ? { backgroundColor: THEME_COLORS.highlightColor } : {}
      }
    >
      <CheckBoxCell padding='checkbox'>
        <CheckBoxIconButtonToggle
          checked={isSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          icon={<CheckboxOutlineBlankIcon width={24} height={24} />}
          checkedIcon={<CheckboxIcon width={24} height={24} />}
          onChange={() => handleSelect(id)}
        />
      </CheckBoxCell>

      <DefaultTableCell id={labelId} component='th' scope='row' padding='none'>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          title={strategyName}
          placement='top'
          arrow
        >
          <div>
            <Button onClick={handleOpenStrategy}>
              <Span>{strategyName}</Span>
            </Button>
          </div>
        </Tooltip>
      </DefaultTableCell>

      <DefaultTableCell>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          title={assetCategory?.name}
          placement='top'
          arrow
        >
          <Span>{assetCategory?.name}</Span>
        </Tooltip>
      </DefaultTableCell>

      <DefaultTableCell align='right'>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          title={currencyFormatter(minAllocation)}
          placement='top'
          arrow
        >
          <CurrencySpan>
            {currencyFormatterShorthand(minAllocation)}
          </CurrencySpan>
        </Tooltip>
      </DefaultTableCell>
      <DefaultTableCell align='right'>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          title={currencyFormatter(capacity)}
          placement='top'
          arrow
        >
          <CurrencySpan>{currencyFormatterShorthand(capacity)}</CurrencySpan>
        </Tooltip>
      </DefaultTableCell>
      <DefaultTableCell align='right'>
        {productionNotionalAccountValue === null ? null : (
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            title={currencyFormatter(productionNotionalAccountValue)}
            placement='top'
            arrow
          >
            <CurrencySpan>
              {currencyFormatterShorthand(productionNotionalAccountValue)}
            </CurrencySpan>
          </Tooltip>
        )}
      </DefaultTableCell>
      <DefaultTableCell align='right'>
        {workingNotionalAccountValue === null ? null : (
          <>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              title={currencyFormatter(workingNotionalAccountValue)}
              placement='top'
              arrow
            >
              <div>
                <Button onClick={handleOpenAllocationDialog}>
                  <WorkingSpan>
                    {currencyFormatterShorthand(workingNotionalAccountValue)}
                  </WorkingSpan>
                </Button>
              </div>
            </Tooltip>
            <AllocationDialog
              isOpen={isAllocationDialogOpen}
              handleClose={handleCloseAllocationDialog}
              onUpdate={handleUpdateAllocation}
              strategy={strategy}
            />
          </>
        )}
      </DefaultTableCell>
      <IconCell>
        <IconButtonToggle
          checked={locked}
          disabled={isPortfolioReviewMode}
          icon={<UnlockIcon />}
          checkedIcon={<LockIcon />}
          onClick={handleLockToggle}
        />
      </IconCell>
      <IconCell>
        <IconButton
          disabled={
            strategy.workingNotionalAccountValue === null ||
            isPortfolioReviewMode
          }
          onClick={() =>
            handleOpenDeleteDialog(
              globalStrategyId || '',
              strategy.strategyGroup?.id || ''
            )
          }
        >
          <DeleteIcon />
        </IconButton>
      </IconCell>
    </TableRow>
  );
};

export default StrategyListItem;
