import React from 'react';
import { TableRow } from '@mui/material';
import { FieldValue } from '@clearalpha/common';
import {
  TitleCell,
  ValueCell,
} from '@clearalpha/common/dist/components/Dashboard/Widgets/TableWidgetBackbone';
import { ChangeReportDialogTableProps } from './ChangeReportDialogTable.type';

const ChangeReportDialogTableStrategies = ({
  config,
  name,
}: ChangeReportDialogTableProps) => (
  <TableRow>
    {!!name.length && <TitleCell>{name}</TitleCell>}
    <ValueCell align='right'>
      <FieldValue
        row={{
          type: config.type,
          fieldName: config.fieldName,
          variableName: config.variableName,
        }}
        value={config.targetValue}
      />
    </ValueCell>
    <ValueCell align='right'>
      <FieldValue
        row={{
          type: config.type,
          fieldName: config.fieldName,
          variableName: config.variableName,
        }}
        value={config.sourceValue}
      />
    </ValueCell>
  </TableRow>
);

export { ChangeReportDialogTableStrategies };
