import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { FormikValue } from 'components/formik/fields';
import { StrategyFormFields } from '../../StrategyDetails.constants';
import { Title } from './Name.styled';
import { StrategyDetailsFormFields } from '../../StrategyDetails.type';

const Name = () => {
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode && values.productionValues.name !== values.name;

  return (
    <Title className={isValueHighlighted ? 'highlighted' : ''}>
      <FormikValue name={StrategyFormFields.NAME} />
    </Title>
  );
};

export default Name;
