import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NumericTextPercent, TextFieldWrapper } from '@clearalpha/common';
import { isGlobalStrategyEditModeSelector } from 'store/slices/strategies';
import FormikPercentNumbers from 'components/formik/fields/FormikPercentNumbers';
import { FieldLabel } from 'components/StrategyDetails/blocks/styled';
import { GlobalStrategyDetailsFormFields } from '../../../GlobalStrategyDetails.type';
import { StrategyFormFields } from '../../../GlobalStrategyDetails.constants';

const Engineer = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<GlobalStrategyDetailsFormFields>();
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);

  return (
    <>
      <FieldLabel>{t('alphaArchetype.engineer')}</FieldLabel>
      {isEditMode ? (
        <FormikPercentNumbers
          name={StrategyFormFields.ENGINEER}
          minLimit={0}
          maxLimit={100}
          customInput={TextFieldWrapper}
        />
      ) : (
        <NumericTextPercent value={values.engineer} />
      )}
    </>
  );
};

export default Engineer;
