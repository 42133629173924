import styled from '@emotion/styled';
import { Button as BaseButton } from '@clearalpha/common';

const Button = styled(BaseButton)`
  & .MuiTypography-root {
    color: ${({ theme: { colors } }) => colors.onSurface};
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`;

export { Button };
