import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isGlobalStrategyEditModeSelector } from 'store/slices/strategies';
import { FormikText, FormikValue } from 'components/formik/fields';
import { Block, BlockTitle } from 'components/StrategyDetails/blocks/styled';
import { Description as StyledDescription } from './StrategyEdge.styled';
import { StrategyFormFields } from '../../GlobalStrategyDetails.constants';

const StrategyEdge = () => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);

  return (
    <Block>
      <BlockTitle>{t('strategyEdge')}</BlockTitle>

      {isEditMode ? (
        <FormikText
          name={StrategyFormFields.STRATEGY_EDGE}
          placeholder={t('strategyEdge')}
          fullWidth
          multiline
        />
      ) : (
        <StyledDescription>
          <FormikValue name={StrategyFormFields.STRATEGY_EDGE} />
        </StyledDescription>
      )}
    </Block>
  );
};

export default StrategyEdge;
