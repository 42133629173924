import styled from '@emotion/styled';
import {
  TableCell,
  TableHead as BaseTableHead,
  Typography,
} from '@mui/material';
import { IconButtonToggle } from '@clearalpha/common';

const ColumnText = styled(Typography)`
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: ${({ theme: { colors } }) => colors.onSurface};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DefaultCell = styled(TableCell)`
  border-bottom: none;
  background: ${({ theme: { colors } }) => colors.systemSurfaceAtPlusThree};
`;

const CheckBoxIconButtonToggle = styled(IconButtonToggle)`
  & path {
    fill: ${({ theme: { colors } }) => colors.outline};
  }
`;

const TableHead = styled(BaseTableHead)`
  background: ${({ theme: { colors } }) => colors.systemSurfaceAtPlusThree};
  & th:nth-of-type(1) {
    width: 90px;
    padding: 16px 24px;
  }

  & th:nth-of-type(2) {
    width: 20%;
    padding: 0 18px;
  }

  & th:nth-of-type(3) {
    width: 17%;
    padding: 0 18px;
  }

  & th:nth-of-type(4) {
    width: 20%;
  }
  & th:nth-of-type(5) {
    width: 13%;
  }
  & th:nth-of-type(6) {
    width: 16%;
  }
  & th:nth-of-type(7) {
    width: 14%;
  }

  & th:nth-of-type(4),
  & th:nth-of-type(5),
  & th:nth-of-type(6),
  & th:nth-of-type(7) {
    padding: 5px 16px;
    text-align: right;
  }

  & th:nth-of-type(8),
  & th:nth-of-type(9) {
    width: 70px;
  }
`;

export { ColumnText, DefaultCell, CheckBoxIconButtonToggle, TableHead };
