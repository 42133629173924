import { TableCell } from '@mui/material';
import styled from '@emotion/styled';
import {
  Button as BaseButton,
  IconButtonToggle as BaseIconButtonToggle,
  IconButton as BaseIconButton,
} from '@clearalpha/common';

const DefaultTableCell = styled(TableCell)`
  border-bottom-color: ${({ theme: { colors } }) => colors.surface};
  height: 72px;
  padding: 5px 16px;
  overflow: hidden;
`;

const CheckBoxCell = styled(DefaultTableCell)`
  fill: ${({ theme: { colors } }) => colors.outline};
  padding: 16px 24px;
`;

const CheckBoxIconButtonToggle = styled(BaseIconButtonToggle)`
  & path {
    fill: ${({ theme: { colors } }) => colors.outline};
  }
`;

const Span = styled.span`
  font-family: Work Sans;
  font-weight: 400;
  line-height: 26px;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: ${({ theme: { colors } }) => colors.onSurface};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  &:hover {
    color: ${({ theme: { colors } }) => colors.white};
  }
`;

const Button = styled(BaseButton)`
  padding: 5px;
  min-width: min-content;
  max-height: 100%;
  & > span {
    text-align: left;
  }
`;

const CurrencySpan = styled(Span)`
  letter-spacing: 1px;
  line-height: 21px;
  text-overflow: unset;
`;

const WorkingSpan = styled(CurrencySpan)`
  color: ${({ theme: { colors } }) => colors.dataFour};
  text-overflow: unset;
`;

const IconCell = styled(DefaultTableCell)`
  fill: ${({ theme: { colors } }) => colors.onSurface};
  padding: 16px 24px 16px 0;
`;

const IconButtonToggle = styled(BaseIconButtonToggle)`
  & path {
    fill: ${({ theme: { colors } }) => colors.onSurface};
  }
`;

const IconButton = styled(BaseIconButton)`
  & path {
    fill: ${({ theme: { colors } }) => colors.onSurface};
  }
`;

export {
  DefaultTableCell,
  CheckBoxCell,
  CheckBoxIconButtonToggle,
  Span,
  Button,
  CurrencySpan,
  WorkingSpan,
  IconCell,
  IconButtonToggle,
  IconButton,
};
