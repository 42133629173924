import { useWindowSize } from '@clearalpha/common/dist/hooks/useWindowSize';
import { useSelector } from 'react-redux';
import { getChangeRequestDiffs } from 'store/thunks/changeRequest';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { useAppDispatch } from 'store';
import { setSelectedRequest } from 'store/slices/changeRequests/changeRequests.slice';
import {
  ChangeRequestResponse,
  FullPortfolioStrategy,
  PortfolioFeeTypes,
  StrategyGroup,
} from 'services/portfolioCore/portfolioCore.types';

import {
  GetPortfolioResponse,
  SavePortfolioRequest,
  TabWidth,
  WorkspaceHeaderBreakpoints,
  WorkspaceHeaderHeight,
} from './StrategyRiskAllocator.type';

export const useCalculateDashboardHeight = (propHeight: string | number) => {
  const isReviewMode = useSelector(isReviewModePortfolioSelector);
  const approvalHeaderHeight = isReviewMode ? 100 : 0;
  const { width } = useWindowSize();

  let workSpaceHeaderHeight = 0;

  if (width >= WorkspaceHeaderBreakpoints.BigScreen) {
    workSpaceHeaderHeight = WorkspaceHeaderHeight.SMALL;
  }

  if (
    width > WorkspaceHeaderBreakpoints.MediumDisplay &&
    width < WorkspaceHeaderBreakpoints.BigScreen
  ) {
    workSpaceHeaderHeight = WorkspaceHeaderHeight.MEDIUM;
  }

  if (
    width <= WorkspaceHeaderBreakpoints.MediumDisplay &&
    width > WorkspaceHeaderBreakpoints.Laptop
  ) {
    workSpaceHeaderHeight = WorkspaceHeaderHeight.MEDIUM;
  }

  if (
    width <= WorkspaceHeaderBreakpoints.Laptop &&
    width > WorkspaceHeaderBreakpoints.Tablet
  ) {
    workSpaceHeaderHeight = WorkspaceHeaderHeight.ULTRA;
  }

  if (width <= WorkspaceHeaderBreakpoints.Tablet) {
    workSpaceHeaderHeight = WorkspaceHeaderHeight.HUGE;
  }

  return `calc(${propHeight} - ${workSpaceHeaderHeight}px - ${approvalHeaderHeight}px)`;
};

export const useCalculateTabWidth = () => {
  const { width } = useWindowSize();

  if (width <= TabWidth.BIG_SCREEN) {
    return TabWidth.DEFAULT_TAB;
  }

  const countOfBigColumns = Math.trunc(width / TabWidth.BIG_COLUMN);
  const availableTabWidth =
    width - TabWidth.GAP - (countOfBigColumns - 2) * TabWidth.BIG_COLUMN;
  return availableTabWidth;
};

export const usePortfolioDifferences = () => {
  const dispatch = useAppDispatch();

  const showReview = async (selectedRequest: ChangeRequestResponse) => {
    if (!selectedRequest) return;

    dispatch(setSelectedRequest(selectedRequest));
    await dispatch(
      getChangeRequestDiffs({
        sourceId: selectedRequest.sourceId,
        targetId: selectedRequest.targetId,
        sourceVersion: selectedRequest.sourceVersion,
      })
    );
  };

  return { showReview };
};

export const transformToPortfolioRequest = (
  portfolio: GetPortfolioResponse,
  strategies: FullPortfolioStrategy[],
  portfolioFeeTypes: PortfolioFeeTypes[],
  strategyGroups: StrategyGroup[]
): SavePortfolioRequest => {
  const portfolioFees = portfolio.fees.reduce(
    (acc: Record<string, number>, { fee, value }) => {
      acc[
        portfolioFeeTypes.find(
          (portfolioFeeType) => fee.name === portfolioFeeType.name
        )!.id
      ] = value;
      return acc;
    },
    {}
  );

  const portfolioStrategies = strategies.map(
    ({ strategyGroup, strategyType, ...rest }) => ({
      ...rest,
      portfolioId: portfolio.id,
      strategyGroupId: strategyGroup.id,
      strategyTypeId: strategyType?.id,
    })
  );
  const strategyGroupsRequest = strategyGroups.map(({ fees, ...rest }) => ({
    ...rest,
    portfolioId: portfolio.id,
    fees: fees.reduce((acc: Record<string, number>, { fee, value }) => {
      acc[fee.id] = value;
      return acc;
    }, {}),
  }));

  return {
    arcesiumId: portfolio.arcesiumId,
    assetsUnderManagement: portfolio.assetsUnderManagement,
    averageCorrelation: portfolio.averageCorrelation,
    axiomaId: portfolio.axiomaId,
    constraints: portfolio.constraints,
    crossCapitalAtRiskBenefit: portfolio.crossCapitalAtRiskBenefit,
    crossMarginBenefit: portfolio.crossMarginBenefit,
    fees: portfolioFees,
    freeCashFromVolMultiplier: portfolio.freeCashFromVolMultiplier,
    minFreeCashFromAum: portfolio.minFreeCashFromAum,
    name: portfolio.name,
    portfolioOriginId: portfolio.portfolioOriginId,
    portfolioStrategies,
    strategyGroups: strategyGroupsRequest,
  };
};
