import styled from '@emotion/styled';
import { Button } from '@clearalpha/common';

export const ApprovalHeaderWrapper = styled.div`
  font-family: Work Sans, sans-serif;
  background: ${({ theme: { colors } }) => colors.violetOpacity2};
  color: ${({ theme: { colors } }) => colors.white};
  padding: 20px 50px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  left: calc(50% - 40px);

  button {
    margin-right: 10px;
  }
`;

export const FilledButton = styled(Button)`
  background: ${({ theme: { colors } }) => colors.defaultPurple};
`;

export const TextButton = styled(Button)`
  color: ${({ theme: { colors } }) => colors.defaultPurple};
`;

export const InfoText = styled.p`
  white-space: pre-line;
`;
