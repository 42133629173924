import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { NumericTextDecimal } from '@clearalpha/common';
import { FieldLabel, FieldWrapper } from '../../styled';
import { StrategyDetailsFormFields } from '../../../StrategyDetails.type';

const HistoricalSharpe = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.productionValues.historicSharpeRatio !== values.historicSharpeRatio;

  return (
    <>
      <FieldLabel>{t('historicalSharpeRatio')}</FieldLabel>
      <NumericTextDecimal value={values.productionValues.historicSharpeRatio} />
      <FieldWrapper className={isValueHighlighted ? 'highlighted' : ''}>
        <NumericTextDecimal value={values.historicSharpeRatio} />
      </FieldWrapper>
    </>
  );
};

export default HistoricalSharpe;
