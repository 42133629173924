const COOKIE_SEPARATOR = '; ';

const getCookie = (key: string | RegExp): string | undefined =>
  document.cookie
    .split(COOKIE_SEPARATOR)
    .filter((row) => row.match(key))
    .map((row) => row.split('=')[1])[0];

const removeCookies = (key: string | RegExp) => {
  const cookiesUpdate = document.cookie
    .split(COOKIE_SEPARATOR)
    .filter((row) => !row.match(key))
    .join(COOKIE_SEPARATOR);

  document.cookie = cookiesUpdate;
};

export { getCookie, removeCookies };
