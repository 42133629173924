import {
  UserState,
  setUser as setReduxUser,
  USER_SLICE_NAME,
  USER_SLICE_INITIAL_STATE,
} from 'store/slices/user';
import { setUserRoles as setReduxUserRoles } from 'store/slices/permissions';
import { store } from 'store';
import { config } from 'config';
import { getTimestampSeconds } from 'helpers/datetime';
import { extractRolesFromJwt } from 'helpers';
import {
  getUserStored,
  clearUserStored,
  getSession as getCognitoSession,
  signIn,
  signOut,
} from './awsCognito.service';

const setUserState = (user: UserState) => {
  store.dispatch(setReduxUser(user));

  const { idToken } = user;

  if (idToken) {
    const roles = extractRolesFromJwt(idToken);

    store.dispatch(setReduxUserRoles(roles));
  }
};

const getUser = (): UserState => {
  const userRedux: UserState = { ...store.getState()[USER_SLICE_NAME] };

  if (userRedux.idToken) return userRedux;

  const { idToken, isRedirected } = getUserStored();

  if (!idToken && !isRedirected) return userRedux;

  userRedux.isSignInInitiated = true;
  setUserState(userRedux);

  return userRedux;
};

const signInInit = async () => signIn();

const signOutInit = async () => {
  const user = getUser();

  user.isSignOutInitiated = true;

  setUserState(user);

  return signOut();
};

const signOutComplete = () => {
  store.dispatch(setReduxUser(USER_SLICE_INITIAL_STATE));
  clearUserStored();
};

// TODO UX-1514: remove isUserAction argument
const getSession = async (
  isUserAction: boolean
): Promise<UserState | undefined> => {
  const user = getUser();
  const { isSignedIn, isSignOutInitiated, isSignInInitiated } = user;

  if (isSignOutInitiated) {
    signOutComplete();

    return;
  }

  if (!isSignedIn && !isSignInInitiated) return;

  const nowSeconds = getTimestampSeconds();
  const { idTokenRefreshAt, refreshToken, refreshTokenExpiresAt, signOutAt } =
    user;
  const isRefreshRequired = !idTokenRefreshAt || idTokenRefreshAt <= nowSeconds;

  if (!isRefreshRequired) return user;

  let userUpdate: UserState;

  try {
    const session = await getCognitoSession();

    const idTokenSession = session.getIdToken().getJwtToken();
    const idTokenExpiresAt = session.getIdToken().getExpiration();
    const accessTokenSession = session.getAccessToken().getJwtToken();
    const refreshTokenSession = session.getRefreshToken().getToken();
    const isRefreshTokenUpdated = refreshToken !== refreshTokenSession;
    const signOutAtUpdated = nowSeconds + config.auth.idle_limit_seconds;

    userUpdate = {
      idToken: idTokenSession,
      accessToken: accessTokenSession,
      refreshToken: refreshTokenSession,
      idTokenRefreshAt:
        idTokenExpiresAt - config.auth.id_token_ttl_threshold_seconds,
      refreshTokenExpiresAt: isRefreshTokenUpdated
        ? nowSeconds + config.auth.refresh_token_ttl_seconds
        : refreshTokenExpiresAt,
      signOutAt: isUserAction ? signOutAtUpdated : signOutAt,
      isSignedIn: true,
      isSignInInitiated: false,
      isSignOutInitiated: false,
      redirectTo: '',
    };
  } catch (e) {
    userUpdate = {
      ...user,
      isSignInInitiated: false,
    };
  }

  setUserState(userUpdate);

  return userUpdate;
};

// TODO UX-1514: remove isUserAction argument
const refreshSession = async (
  isUserAction = true
): Promise<UserState | undefined> => {
  const user = await getSession(isUserAction);

  if (!user) return;

  const { isSignedIn, signOutAt, refreshTokenExpiresAt } = user;

  if (!isSignedIn) return;

  const nowSeconds = getTimestampSeconds();
  const isIdle = signOutAt <= nowSeconds;
  const isRefreshTokenExpired = refreshTokenExpiresAt <= nowSeconds;

  if (!isIdle && !isRefreshTokenExpired) return user;

  await signOutInit();
};

export { signInInit, signOutInit, refreshSession };
