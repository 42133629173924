import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ListContainer = styled(Box)`
  height: calc(100vh - 224px);

  .MuiDataGrid-virtualScroller::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const ClickableContainer = styled(Box)`
  cursor: pointer;
`;
