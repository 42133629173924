import { createSlice } from '@reduxjs/toolkit';
import { ROLES } from 'constants/roles';
import {
  PERMISSIONS_SLICE_NAME,
  PERMISSIONS_SLICE_INITIAL_STATE,
} from './permissions.constants';
import { PermissionsState } from './permissions.types';

const permissions = createSlice({
  name: PERMISSIONS_SLICE_NAME,
  initialState: PERMISSIONS_SLICE_INITIAL_STATE,
  reducers: {
    setUserRoles: (state: PermissionsState, action: { payload: ROLES[] }) => {
      state.arePermissionsDefined = true;
      state.roles = action.payload;
      state.isStrategyLibraryPermission =
        state.roles.indexOf(ROLES.STRATEGY_LIBRARY_FULL_ACCESS) !== -1;
      state.isSaraUserPermission =
        state.roles.indexOf(ROLES.SARA_USER_ACCESS) !== -1;
      state.isSaraFullPermission =
        state.roles.indexOf(ROLES.SARA_FULL_ACCESS) !== -1;
    },
  },
});

export const { setUserRoles } = permissions.actions;

export default permissions.reducer;
