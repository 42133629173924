import { object, number } from 'yup';
import i18n from 'translations/i18n';
import { ZERO, TRILLION } from './validationNumbers';

export const updateAumValidationSchema = object().shape({
  total_aum: number()
    .moreThan(
      ZERO,
      i18n.t('errors.notLessOrEqualTo', {
        name: i18n.t('totalAUM'),
        value: '$0',
      })
    )
    .max(
      TRILLION,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('totalAUM'),
        value: '$1T',
      })
    )
    .required(i18n.t('errors.required', { name: i18n.t('totalAUM') })),
});
