import { BaseAttribute } from './FormikSelectEntityAttributes.types';

export const entityToOptions = <T extends BaseAttribute>(entities: T[]) => {
  if (!Array.isArray(entities)) {
    return {};
  }

  return entities.reduce((acc, current) => {
    return { ...acc, [current.id]: current.name };
  }, {} as Record<string, string>);
};
