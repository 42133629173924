import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { setIsTeamLoading } from 'store/slices/teams';
import { getTeams } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import { setTeams } from 'store/slices/teams/teams.slice';

export const fetchTeams = createAsyncThunk<void, void, { state: RootState }>(
  'fetchTeams',
  async (_, { dispatch }) => {
    dispatch(setIsTeamLoading(true));
    try {
      const teams = await getTeams();

      dispatch(setTeams(teams));
    } finally {
      dispatch(setIsTeamLoading(false));
    }
  }
);
