const {
  REACT_APP_PORTFOLIO_CORE_URL,
  REACT_APP_PORTFOLIO_MANAGER_CORE_URL,
  REACT_APP_STRATEGY_CATALOG_URL,
  REACT_APP_FUND_CALCULATOR_URL,
  REACT_APP_URL,
  REACT_APP_AWS_COGNITO_REGION,
  REACT_APP_AWS_COGNITO_POOL_ID,
  REACT_APP_AWS_COGNITO_POOL_CLIENT_ID,
  REACT_APP_AWS_COGNITO_OAUTH_DOMAIN,
} = process.env;

const config = {
  api: {
    portfolioCore: REACT_APP_PORTFOLIO_CORE_URL || '',
    portfolioManagerCore: REACT_APP_PORTFOLIO_MANAGER_CORE_URL || '',
    strategyCatalog: REACT_APP_STRATEGY_CATALOG_URL || '',
    fundCalculator: REACT_APP_FUND_CALCULATOR_URL || '',
  },
  url: REACT_APP_URL || '',
  auth: {
    id_token_ttl_threshold_seconds: 60,
    // TODO UX-1514: refresh_token_ttl_seconds and cookie_ttl_days are the same value, unite
    refresh_token_ttl_seconds: 86400,
    cookie_ttl_days: 1,
    idle_limit_seconds: 1800,
    cognito: {
      region: REACT_APP_AWS_COGNITO_REGION || '',
      poolId: REACT_APP_AWS_COGNITO_POOL_ID || '',
      poolClientId: REACT_APP_AWS_COGNITO_POOL_CLIENT_ID || '',
      oauthDomain: REACT_APP_AWS_COGNITO_OAUTH_DOMAIN || '',
    },
  },
};

export { config };
