import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { convertToPerformancePayload } from 'services/fundCalculator/fundCalculator.helper';
import { mapStateToResponse } from 'components/StrategyList/StrategyList.helpers';
import { getCustomPortfolioOptimization } from 'services/fundCalculator/fundCalculator.service';
import {
  STRATEGIES_SLICE_NAME,
  updateStrategiesOptimizedData,
} from 'store/slices/strategies';
import i18n from 'translations/i18n';
import {
  PORTFOLIOS_SLICE_NAME,
  setWorkingPortfolioOptimizations,
  setIsLoadingOptimization,
  setOptimizedAllocationsAndRunCalculation,
} from 'store/slices/portfolios';
import { Toasters } from '@clearalpha/common';

export const fetchOptimization = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchOptimization', async (_, { getState, dispatch }) => {
  const {
    [STRATEGIES_SLICE_NAME]: { strategies },
    [PORTFOLIOS_SLICE_NAME]: { workingPortfolio },
  } = getState();

  const payload = {
    portfolio: convertToPerformancePayload(workingPortfolio),
    strategies: strategies.map(mapStateToResponse),
  };

  dispatch(setIsLoadingOptimization(true));

  try {
    const optimizations = await getCustomPortfolioOptimization(payload);

    dispatch(setWorkingPortfolioOptimizations(optimizations));
    dispatch(setOptimizedAllocationsAndRunCalculation());
    dispatch(updateStrategiesOptimizedData(optimizations));
  } catch (e) {
    Toasters.error(i18n.t('errors.notFeasible'));
  } finally {
    dispatch(setIsLoadingOptimization(false));
  }
});
