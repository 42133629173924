import i18n from 'translations/i18n';
import { FieldValueEnum } from '@clearalpha/common';

export const ZERO_ALLOCATIONS_VALUE = 0;

export const PARAMETERS_CONFIG = [
  {
    variableName: 'crossMarginBenefit',
    fieldName: i18n.t('dialogs.parameters.crossMarginBenefit'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  {
    variableName: 'targetFreeCashFromAum',
    fieldName: i18n.t('dialogs.parameters.minFreeCashAum'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  {
    variableName: 'targetNetExcessReturn',
    fieldName: i18n.t('dialogs.parameters.minNetExcess'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  {
    variableName: 'targetMaxLiquidityCR',
    fieldName: i18n.t('dialogs.parameters.maxLiquidityCR'),
    type: FieldValueEnum.NUMERIC_TEXT_DECIMAL,
  },
  {
    variableName: 'targetMaxPLRiskCR',
    fieldName: i18n.t('dialogs.parameters.maxPLRiskCR'),
    type: FieldValueEnum.NUMERIC_TEXT_DECIMAL,
  },
  {
    variableName: 'targetMinNicheRisk',
    fieldName: i18n.t('dialogs.parameters.minNicheRisk'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  {
    variableName: 'targetMaxCapitalAtRisk',
    fieldName: i18n.t('dialogs.parameters.maxCapitalAtRisk'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  {
    variableName: 'targetMaxEquityLeverage',
    fieldName: i18n.t('dialogs.parameters.maxEquityLeverage'),
    type: FieldValueEnum.NUMERIC_TEXT_DECIMAL,
  },
  {
    variableName: 'minProbReturnThreshold',
    fieldName: i18n.t('dialogs.parameters.minProbReturnThreshold'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  {
    variableName: 'platformPerformanceFeePct',
    fieldName: i18n.t('dialogs.parameters.performanceFee'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  {
    variableName: 'platformMgmtFeePct',
    fieldName: i18n.t('dialogs.parameters.mgmtFee'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  {
    variableName: 'platformFixedExpenses',
    fieldName: i18n.t('dialogs.parameters.fixedExpenses'),
    type: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
  },
  {
    variableName: 'crossCapitalAtRiskBenefit',
    fieldName: i18n.t('dialogs.parameters.crossCapitalAtRiskBenefit'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  {
    variableName: 'targetMaxGrossExposure',
    fieldName: i18n.t('dialogs.parameters.maxCapitalAtRisk'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
];

export const STRATEGY_LEVEL_CONFIG_BASE = {
  notionalAccountValue: {
    name: i18n.t('notionalAccountValueShort'),
    type: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
  },
  scientist: {
    name: i18n.t('alphaArchetype.scientist'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  engineer: {
    name: i18n.t('alphaArchetype.engineer'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  actuary: {
    name: i18n.t('alphaArchetype.actuary'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  assetCategory: {
    name: i18n.t('strategyCategory'),
    type: FieldValueEnum.READONLY,
  },
  averageHoldingPeriod: {
    name: i18n.t('averageHoldPeriod'),
    type: FieldValueEnum.READONLY,
  },
  capacity: {
    name: i18n.t('aumCapacity'),
    type: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
  },
  grossSharpe: {
    name: i18n.t('grossSharpeRatio'),
    type: FieldValueEnum.NUMERIC_TEXT_DECIMAL,
  },
  groupCharacteristic: {
    name: i18n.t('alphaCategory'),
    type: FieldValueEnum.READONLY,
  },
  historicSharpeRatio: {
    name: i18n.t('historicalSharpeRatio'),
    type: FieldValueEnum.NUMERIC_TEXT_DECIMAL,
  },
  marginType: {
    name: i18n.t('marginType'),
    type: FieldValueEnum.READONLY,
  },
  minAllocation: {
    name: i18n.t('minAllocation'),
    type: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
  },
  name: {
    name: i18n.t('name'),
    type: FieldValueEnum.READONLY,
  },
  volatilityTarget: {
    name: i18n.t('volatilityTarget'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  volatilityCapacity: {
    name: i18n.t('volatilityCapacity'),
    type: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
  },
  remainingCapacity: {
    name: i18n.t('remainingCapacity'),
    type: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
  },
  strategyGroup: {
    name: i18n.t('strategyMoved'),
    type: FieldValueEnum.READONLY,
  },
};

export const GROUP_LEVEL_CONFIG_BASE = {
  name: {
    name: i18n.t('name'),
    type: FieldValueEnum.READONLY,
  },
  performanceFee: {
    name: i18n.t('performanceFee'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  managementFee: {
    name: i18n.t('managementFee'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
  },
  fixedExpenses: {
    name: i18n.t('groupFixedExpenses'),
    type: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
  },
};
