import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Block,
  BlockTitle,
  Fields,
} from 'components/StrategyDetails/blocks/styled';
import { GrossSharpe, HistoricalSharpe } from './fields';

const Performance = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <BlockTitle>{t('performance')}</BlockTitle>
      <Fields>
        <GrossSharpe />
        <HistoricalSharpe />
      </Fields>
    </Block>
  );
};

export default Performance;
