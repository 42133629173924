/* eslint-disable react-hooks/rules-of-hooks */
import {
  LayoutWrapper,
  Title,
  TitleAndContent,
} from 'layouts/ManageGroups/ManageGroups.styled';
import { useEffect, useState } from 'react';
import SaraBreadcrumbs from 'components/SaraBreadcrumbs/SaraBreadcrumbs';
import i18n from 'translations/i18n';
import isEqual from 'lodash.isequal';
import {
  Back,
  BreadcrumbsText,
  StyledLink,
} from 'components/SaraBreadcrumbs/SaraBreadcrumbs.styled';
import Card from 'components/Card/Card';
import CustomList from 'components/List/CustomList';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'constants/routes';
import { useSelector } from 'react-redux';
import { teamsSelector } from 'store/slices/teams';
import { globalStrategiesSelector } from 'store/slices/strategies';
import { useAppDispatch } from 'store';
import { fetchGlobalStrategies, fetchTeams } from 'store/thunks';
import { NoDataContainer } from 'components/Dialogs/CorrelationsDialog/CorrelationsDialog.styled';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { Form, FormikProvider, useFormik } from 'formik';
import { StrategyGroupsResponse } from 'services/portfolioCore/portfolioCore.types';
import {
  deleteStrategyGroup,
  sourceStrategyGroupsSelector,
  strategyGroupsSelector,
  updateManageGroupDetails,
} from 'store/slices/portfolios';
import FormButtons from 'components/StrategyLibrary/Actions/FormButtons';
import { manageGroupDetailsValidation } from 'validation/manageGroupFormValidation';
import UnsavedChangesDialog from 'components/Dialogs/UnsavedChangesDialog/UnsavedChangesDialog';
import DeleteConfirmationDialog from 'components/Dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import ErrorDialog from 'components/Dialogs/ErrorDialog/ErrorDialog';
import { updateGroupDataInWorkingStrategy } from 'store/slices/strategies/strategies.slice';
import { ContentBlock, HeaderWrapper } from './ManageGroupDetails.styled';
import {
  createInitialValues,
  useManageGroupsDetails,
} from './ManageGroupDetails.helper';
import {
  GroupDetailsFields,
  initialFormState,
} from './ManageGroupDetails.type';

const ManageGroupDetails = () => {
  const { t } = i18n;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const teams = useSelector(teamsSelector);
  const portfolioStrategyGroups: StrategyGroupsResponse[] = useSelector(
    strategyGroupsSelector
  );
  const sourceStrategyGroups = useSelector(sourceStrategyGroupsSelector);
  const strategyGroups = sourceStrategyGroups.length
    ? sourceStrategyGroups
    : portfolioStrategyGroups;
  if (!id) return null;

  const group: StrategyGroupsResponse | undefined = strategyGroups.find(
    (current) => current.id === id
  );
  const globalStrategies = useSelector(globalStrategiesSelector);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState<boolean>(false);
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] =
    useState<boolean>(false);
  const [isErrorDialogOpened, setIsErrorDialogOpened] =
    useState<boolean>(false);
  const [initialValues, setInitialValues] =
    useState<GroupDetailsFields>(initialFormState);
  const isSensitiveInfoVisible = useSelector(sensitiveDataVisibilitySelector);
  const formik = useFormik<GroupDetailsFields>({
    initialValues: initialFormState,
    onSubmit: (values) => {
      setIsEditModeEnabled(false);
      if (group) {
        dispatch(
          updateManageGroupDetails({
            newValues: values,
            groupIdToUpdate: group.id,
          })
        );
        dispatch(
          updateGroupDataInWorkingStrategy({
            newValues: values,
            groupIdToUpdate: group.id,
          })
        );
      }
    },
    validationSchema: manageGroupDetailsValidation,
  });

  const { setValues, dirty, values } = formik;

  const { groupListData, strategiesListData, groupName } =
    useManageGroupsDetails(id, isSensitiveInfoVisible, isEditModeEnabled);
  const breadcrumbsItems = [
    <StyledLink key='home' color='inherit' to={`/${routes.MONITOR.path}`}>
      <Back>
        <BreadcrumbsText>{t('sara')}</BreadcrumbsText>
      </Back>
    </StyledLink>,
    <StyledLink
      key='manageStrategyGroups'
      color='inherit'
      to={`/${routes.MONITOR.path}/${routes.MANAGE_STRATEGY_GROUPS.path}`}
    >
      <BreadcrumbsText>{t('manageStrategyGroups')}</BreadcrumbsText>
    </StyledLink>,
  ];

  const handleDeleteAction = () => {
    if (group && !group.strategies.length) {
      setIsDeleteDialogOpened(true);
    } else {
      setIsErrorDialogOpened(true);
    }
  };

  const detailsActionOptions = [
    {
      label: t('actions.edit'),
      isVisible: !isEditModeEnabled,
      action: () => setIsEditModeEnabled(true),
    },
    {
      label: t('actions.delete'),
      isVisible: true,
      action: handleDeleteAction,
    },
  ];

  const handleCancel = () => {
    const isAnythingChanged = !isEqual(initialValues, values);
    if (isAnythingChanged) {
      setIsDialogOpened(true);
    } else {
      setIsEditModeEnabled(false);
    }
  };

  const handleCancelAction = () => {
    setIsDialogOpened(false);
  };
  const handleConfirmAction = () => {
    setIsEditModeEnabled(false);
    setValues(initialValues);
    setIsDialogOpened(false);
  };

  const handleDelete = () => {
    dispatch(deleteStrategyGroup(group?.id || ''));
    setIsDeleteDialogOpened(false);

    navigate(`/${routes.MONITOR.path}/${routes.MANAGE_STRATEGY_GROUPS.path}`);
  };

  const handleCloseDeleteDialog = () => setIsDeleteDialogOpened(false);
  const handleCloseErrorDialog = () => setIsErrorDialogOpened(false);

  useEffect(() => {
    if (!globalStrategies.length) {
      dispatch(fetchGlobalStrategies());
    }
  }, [globalStrategies.length, dispatch]);

  useEffect(() => {
    if (!teams.length) {
      dispatch(fetchTeams());
    }
  }, [teams.length, dispatch]);

  useEffect(() => {
    if (group) {
      const valuesBeforeEdit = createInitialValues(group);
      setValues(valuesBeforeEdit);
      setInitialValues(valuesBeforeEdit);
    }
  }, [group, setValues]);

  return (
    <>
      <LayoutWrapper>
        <FormikProvider value={formik}>
          <Form>
            <TitleAndContent>
              <SaraBreadcrumbs
                backButtonUrl={`/${routes.MANAGE_STRATEGY_GROUPS.path}`}
                items={breadcrumbsItems}
              />
              <HeaderWrapper>
                <Title variant='h2'>{groupName}</Title>
                {isEditModeEnabled && (
                  <FormButtons
                    handleCancel={handleCancel}
                    isDirty={dirty}
                    isEditMode={isEditModeEnabled}
                  />
                )}
              </HeaderWrapper>
            </TitleAndContent>
            <ContentBlock>
              {groupListData.length ? (
                <>
                  <Card
                    width='40%'
                    title={t('groupDetails')}
                    menuItems={detailsActionOptions}
                  >
                    <CustomList listItems={groupListData} />
                  </Card>
                  <Card width='60%' title={t('strategies')} menuItems={[]}>
                    <CustomList listItems={strategiesListData} />
                  </Card>
                </>
              ) : (
                <NoDataContainer>{t('noDataToDisplay')}</NoDataContainer>
              )}
            </ContentBlock>
          </Form>
        </FormikProvider>
      </LayoutWrapper>
      <UnsavedChangesDialog
        isOpen={isDialogOpened}
        handleClose={handleCancelAction}
        confirmAction={handleConfirmAction}
        cancelAction={handleCancelAction}
      />
      <DeleteConfirmationDialog
        isOpen={isDeleteDialogOpened}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handleDelete}
        title={t('dialogs.deleteStrategyGroup.title')}
        description={t('dialogs.deleteStrategyGroup.message')}
      />
      <ErrorDialog
        isOpen={isErrorDialogOpened}
        handleClose={handleCloseErrorDialog}
        title={t('dialogs.deleteStrategyGroup.error.title')}
        description={t('dialogs.deleteStrategyGroup.error.message')}
      />
    </>
  );
};

export default ManageGroupDetails;
