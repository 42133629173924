import React from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, View, Text } from '@react-pdf/renderer';
import { PdfReportProps } from './PdfReport.type';
import { PdfReportTable } from './PdfReportTable/PdfReportTable';
import { Logo } from './Logo/Logo';
import { styles } from './PdfReport.styled';
import { PdfReportStrategiesTable } from './PdfReportStrategiesTable/PdfReportStrategiesTable';

const PdfReport = ({
  fundLevelConfig,
  strategyLevelConfig,
  groupLevelConfig,
  addedStrategiesConfig,
  deletedStrategiesConfig,
  editedStrategiesConfig,
}: PdfReportProps) => {
  const { t } = useTranslation();

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.column}>
          <View style={styles.row}>
            <Logo />
            <Text style={styles.header}>
              {t('dialogs.changeReportPdf.title')}
            </Text>
          </View>
          {!!fundLevelConfig.length && (
            <View>
              <Text style={styles.title}>{t('fundLevelChanges')}</Text>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderEmpty} />
                <Text style={styles.tableHeaderData}>{t('scenario')}</Text>
                <Text style={styles.tableHeaderData}>{t('production')}</Text>
              </View>
              <PdfReportTable items={fundLevelConfig} />

              {!!addedStrategiesConfig.length && (
                <View style={styles.rowNotCentered}>
                  <Text style={styles.subheader}>{t('strategyAdded')}</Text>
                </View>
              )}
              {addedStrategiesConfig.map((item) => (
                <View key={item.id}>
                  <PdfReportStrategiesTable
                    name={item.name}
                    config={item.config}
                  />
                </View>
              ))}

              {!!deletedStrategiesConfig.length && (
                <View style={styles.rowNotCentered}>
                  <Text style={styles.subheader}>{t('strategyDeleted')}</Text>
                </View>
              )}
              {deletedStrategiesConfig.map((item) => (
                <View key={item.id}>
                  <PdfReportStrategiesTable
                    name={item.name}
                    config={item.config}
                  />
                </View>
              ))}

              {!!editedStrategiesConfig.length && (
                <View style={styles.rowNotCentered}>
                  <Text style={styles.subheader}>{t('strategyChanged')}</Text>
                </View>
              )}
              {editedStrategiesConfig.map((item) => (
                <View key={item.id}>
                  <PdfReportStrategiesTable
                    name={item.name}
                    config={item.config}
                  />
                </View>
              ))}
              <View style={styles.separator} />
            </View>
          )}
          {!!strategyLevelConfig.length && (
            <View>
              <Text style={styles.title}>{t('strategyLevelChanges')}</Text>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderEmpty} />
                <Text style={styles.tableHeaderData}>{t('scenario')}</Text>
                <Text style={styles.tableHeaderData}>{t('production')}</Text>
              </View>
              {strategyLevelConfig.map((strategy) => (
                <View key={strategy.name}>
                  <Text style={styles.subtitle}>{strategy.name}</Text>
                  <PdfReportTable items={strategy.config} />
                </View>
              ))}
            </View>
          )}
          {!!groupLevelConfig.length && (
            <View>
              <Text style={styles.title}>{t('strategyGroups')}</Text>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderEmpty} />
                <Text style={styles.tableHeaderData}>{t('scenario')}</Text>
                <Text style={styles.tableHeaderData}>{t('production')}</Text>
              </View>
              {groupLevelConfig.map((group) => (
                <View key={group.name}>
                  <Text style={styles.subtitle}>{group.name}</Text>
                  <PdfReportTable items={group.config} />
                </View>
              ))}
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export { PdfReport };
