import { useEffect, useState, useMemo } from 'react';
import { FormikProvider } from 'formik';
import { Drawer, Loader, Toasters } from '@clearalpha/common';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import UnsavedChangesDialog from 'components/Dialogs/UnsavedChangesDialog/UnsavedChangesDialog';
import {
  isPMEditModeSelector,
  setIsPMEditMode,
} from 'store/slices/portfolioManagers';
import { useAppDispatch } from 'store';
import DeleteConfirmationDialog from 'components/Dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { deletePM } from 'store/thunks/deletePortfolioManager';
import { ActionMenuItems } from 'constants/locales';
import { isLoadingPortfolioSelector } from 'store/slices/portfolios';
import { StyledForm } from 'components/StrategyDetails/StrategyDetails.styled';
import { Wrapper } from './PortfolioManagerDetails.styled';
import { Description, Experience, Overview, Team } from './sections';
import Actions from '../StrategyLibrary/Actions/Actions';
import { usePortfolioManagerDetailsForm } from './PortfolioManagerDetails.helpers';
import { PortfolioManagerDetailsProps } from './PortfolioManagerDetails.type';
// import CrmSystem from './sections/CrmSystem/CrmSystem'; // TODO: TBD

const PortfolioManagerDetails = ({
  isOpen,
  portfolioManager,
  handleCloseDrawer,
  updateManager,
  handleOpenTeam,
}: PortfolioManagerDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] = useState(false);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);

  const isCreateForm = !portfolioManager;
  const isEditMode = useSelector(isPMEditModeSelector);
  const isLoading = useSelector(isLoadingPortfolioSelector);

  const { formik, initialValues } = usePortfolioManagerDetailsForm({
    isCreateForm,
    portfolioManager,
    updateManager,
  });

  const { dirty, resetForm, handleSubmit } = formik;

  const handleCancel = () => {
    setIsCancelClicked(true);

    if (dirty) {
      return setIsOpenUnsavedChanges(true);
    }

    if (isCreateForm) {
      return handleCloseDrawer();
    }

    dispatch(setIsPMEditMode(false));
  };

  const handleOutsideClick = () => {
    setIsCancelClicked(false);
    if (dirty) {
      return setIsOpenUnsavedChanges(true);
    }

    handleCloseDrawer();
  };

  const handleCancelUnsavedChanges = () => {
    setIsOpenUnsavedChanges(false);
  };

  const handleConfirmUnsavedChanges = () => {
    handleCancelUnsavedChanges();
    resetForm({ values: initialValues });

    if (isCancelClicked && !isCreateForm) {
      dispatch(setIsPMEditMode(false));
    } else {
      handleCloseDrawer();
    }
  };

  const handleDeleteAction = () => {
    setIsDeleteDialogOpened(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpened(false);
  };

  const handleDeletePM = async () => {
    const { payload: error } = await dispatch(
      deletePM({ uuid: portfolioManager?.id || '' })
    );

    if (!error) {
      Toasters.success(t('success.managerDeleted'));
      handleCloseDeleteDialog();
      return handleCloseDrawer();
    }

    if (error instanceof AxiosError) {
      Toasters.error(t('errors.deleteManager'));
    } else {
      Toasters.error(t('errors.somethingWentWrong'));
    }

    handleCloseDeleteDialog();
  };

  useEffect(() => {
    if (!isOpen) return;

    setIsDeleteDialogOpened(false);
    dispatch(setIsPMEditMode(isCreateForm));
    resetForm({ values: initialValues });
  }, [dispatch, resetForm, isOpen, initialValues, isCreateForm]);

  const menuItems: ActionMenuItems[] = useMemo(
    () => [
      {
        label: t('actions.edit'),
        isVisible: !isEditMode,
        action: () => dispatch(setIsPMEditMode(true)),
      },
      {
        label: t('actions.delete'),
        action: handleDeleteAction,
      },
    ],
    [t, isEditMode, dispatch]
  );

  return (
    <Drawer
      data-testid='pmDetailsDrawer'
      anchor='right'
      open={isOpen}
      onClose={handleOutsideClick}
    >
      <FormikProvider value={formik}>
        <StyledForm onSubmit={handleSubmit}>
          <Actions
            isDirty={dirty}
            isCreateForm={isCreateForm}
            handleCancel={handleCancel}
            data-testid='pmActions'
            handleCloseDrawer={handleCloseDrawer}
            isEditModeSelector={isPMEditModeSelector}
            menuItems={menuItems}
          />
          <Wrapper>
            <Overview
              portfolioManager={portfolioManager}
              handleOpenTeam={handleOpenTeam}
            />
            {/* <CrmSystem /> TODO: TBD */}
            <Description />
            <Experience portfolioManager={portfolioManager} />
            <Team portfolioManager={portfolioManager} />
          </Wrapper>
        </StyledForm>
      </FormikProvider>
      <UnsavedChangesDialog
        isOpen={isOpenUnsavedChanges}
        handleClose={handleCancelUnsavedChanges}
        confirmAction={handleConfirmUnsavedChanges}
        cancelAction={handleCancelUnsavedChanges}
      />
      <DeleteConfirmationDialog
        handleClose={handleCloseDeleteDialog}
        isOpen={isDeleteDialogOpened}
        handleDelete={handleDeletePM}
        title={t('dialogs.deleteManager.title')}
        description={t('dialogs.deleteManager.message')}
      />
      <Loader loading={isLoading} />
    </Drawer>
  );
};

export default PortfolioManagerDetails;
