import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAssetCategories } from 'services/strategyCatalog/strategyCatalog.service';
import { RootState } from 'store';
import {
  setAssetCategories,
  setLoadingAssetCategories,
} from 'store/slices/strategies';

export const fetchAssetCategories = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchAssetCategories', async (_, { dispatch }) => {
  dispatch(setLoadingAssetCategories(true));

  try {
    const assetCategories = await getAssetCategories();

    dispatch(setAssetCategories(assetCategories));
  } finally {
    dispatch(setLoadingAssetCategories(false));
  }
});
