import styled from '@emotion/styled';

export const SectionContainer = styled.section`
  margin-bottom: 48px;
  max-width: 696px;
  width: 100%;
`;

export const SectionTitle = styled.h2`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 16px;
  word-break: break-word;
`;

export const SectionContent = styled.div`
  background-color: ${({ theme: { colors } }) =>
    colors.stateLayersDarkPrimaryOpacity};
  border-radius: 8px;
  padding: 24px;
  width: 100%;
`;

export const StyledSectionRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 10px;
  width: 100%;

  span {
    margin: 0;
    word-break: break-word;
  }
`;

export const SectionRowName = styled.p`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  opacity: 0.87;
  margin: 0;
`;

export const SectionRowContent = styled.div`
  display: grid;
  height: 100%;
`;
