import React, { useEffect, useState } from 'react';
import { FormikSelect } from 'components/formik/fields';
import { entityToOptions } from './FormikSelectEntityAttributes.helpers';
import {
  BaseAttribute,
  FormikSelectEntityAttributesProps,
} from './FormikSelectEntityAttributes.types';

const FormikSelectEntityAttributes = <T extends BaseAttribute>({
  name,
  label,
  isDisabled = false,
  isMultiple = false,
  getData,
}: FormikSelectEntityAttributesProps<T>) => {
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<Record<string, string>>({});

  useEffect(() => {
    let mounted = true;

    const getOptions = async () => {
      setIsLoading(true);
      try {
        const statuses = await getData();

        if (mounted) {
          setOptions(entityToOptions(statuses));
        }
      } catch (e) {
        if (mounted) {
          setOptions({});
        }
      } finally {
        setIsLoading(false);
      }
    };

    getOptions();

    return () => {
      mounted = false;
    };
  }, [getData]);

  return (
    <FormikSelect
      name={name}
      label={label}
      options={options}
      disabled={isLoading || isDisabled}
      multiple={isMultiple}
    />
  );
};

export default FormikSelectEntityAttributes;
