import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { fetchPerformanceWork } from 'store/thunks';
import { portfolioParametersValidationSchema } from 'validation/portfolioParametersValidationSchema';
import {
  changeWorkingPortfolio,
  isReviewModePortfolioSelector,
  sourcePortfolioSelector,
  workingPortfolioSelector,
} from 'store/slices/portfolios';
import { GetPortfolioResponse } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { useAppDispatch } from 'store';
import { getFeeValue } from 'services/portfolioCore/portfolioCore.helper';
import {
  PortfolioParameters,
  PortfolioParametersFields,
} from './ParametersDialog.type';

const parametersToFormFields = (portfolio: GetPortfolioResponse) => {
  const { fees } = portfolio;
  return {
    [PortfolioParametersFields.ARCESIUM_ID]: portfolio.arcesiumId,
    [PortfolioParametersFields.MIN_FREE_CASH_FROM_AUM]:
      portfolio.constraints.targetFreeCashFromAum,
    [PortfolioParametersFields.MAX_CAPITAL_AT_RISK]:
      portfolio.constraints.targetMaxCapitalAtRisk,
    [PortfolioParametersFields.MAX_LIQUIDITY_CONCENTRATION_RATIO]:
      portfolio.constraints.targetMaxLiquidityCR,
    [PortfolioParametersFields.MAX_PL_RISK_CONCENTRATION_RATIO]:
      portfolio.constraints.targetMaxPLRiskCR,
    [PortfolioParametersFields.MIN_NICHE_RISK]:
      portfolio.constraints.targetMinNicheRisk,
    [PortfolioParametersFields.MAX_EQUITY_LEVERAGE]:
      portfolio.constraints.targetMaxEquityLeverage,
    [PortfolioParametersFields.MAX_GROSS_EXPOSURE]:
      portfolio.constraints.targetMaxGrossExposure,
    [PortfolioParametersFields.MIN_PROBABILITY_RETURN_THRESHOLD]:
      portfolio.constraints.minProbReturnThreshold,
    [PortfolioParametersFields.MIN_NET_EXCESS_RETURN]:
      portfolio.constraints.targetNetExcessReturn,
    [PortfolioParametersFields.PLATFORM_FIXED_EXPENSES]: getFeeValue(
      'Platform Fixed Expenses',
      fees
    ) as number,
    [PortfolioParametersFields.PLATFORM_MGMT_FEE]: getFeeValue(
      'Management fee',
      fees
    ) as number,
    [PortfolioParametersFields.PLATFORM_PERFORMANCE_FEE]: getFeeValue(
      'Performance Fee',
      fees
    ) as number,
    [PortfolioParametersFields.CROSS_CAPITAL_AT_RISK_BENEFIT]:
      portfolio.crossCapitalAtRiskBenefit,
    [PortfolioParametersFields.CROSS_MARGIN_BENEFIT]:
      portfolio.crossMarginBenefit,
  };
};

export const useParametersForm = (isOpen: boolean, handleClose: () => void) => {
  const dispatch = useAppDispatch();
  const workingPortfolio = useSelector(workingPortfolioSelector);
  const sourcePortfolio = useSelector(sourcePortfolioSelector);
  const isReviewMode = useSelector(isReviewModePortfolioSelector);
  const currentPortfolio = isReviewMode ? sourcePortfolio : workingPortfolio;

  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] = useState(false);

  const saveParameters = async (values: PortfolioParameters) => {
    const {
      arcesiumId,
      targetMaxCapitalAtRisk,
      targetMaxLiquidityCR,
      targetMaxPLRiskCR,
      targetMinNicheRisk,
      targetMaxEquityLeverage,
      targetMaxGrossExposure,
      minProbReturnThreshold,
      targetFreeCashFromAum,
      targetNetExcessReturn,
      platformFixedExpenses,
      platformMgmtFeePct,
      platformPerformanceFee,
      crossCapitalAtRiskBenefit,
      crossMarginBenefit,
    } = values;

    const newConstraints = {
      targetMaxCapitalAtRisk,
      targetMaxLiquidityCR,
      targetMaxPLRiskCR,
      targetMinNicheRisk,
      targetMaxEquityLeverage,
      targetMaxGrossExposure,
      minProbReturnThreshold,
      targetFreeCashFromAum,
      targetNetExcessReturn,
    };

    const { fees } = workingPortfolio;

    const feesMapper = [
      { name: 'Platform Fixed Expenses', value: platformFixedExpenses },
      { name: 'Management fee', value: platformMgmtFeePct },
      { name: 'Performance Fee', value: platformPerformanceFee },
    ];

    const newFees = fees.length
      ? fees.map((fee) => {
          const value = feesMapper.find(
            (feeMapper) => fee.fee.name === feeMapper.name
          )?.value;
          return {
            ...fee,
            value: value || 0,
          };
        })
      : feesMapper.map((fee) => ({
          fee: { id: fee.name, name: fee.name, deletedAt: '' },
          value: fee.value || 0,
        }));

    dispatch(
      changeWorkingPortfolio({
        ...workingPortfolio,
        constraints: {
          ...workingPortfolio.constraints,
          ...newConstraints,
        },
        arcesiumId,
        crossCapitalAtRiskBenefit,
        crossMarginBenefit,
        fees: newFees,
      })
    );

    await dispatch(fetchPerformanceWork());
    handleClose();
  };

  const initialValues: PortfolioParameters = useMemo(
    () => parametersToFormFields(currentPortfolio),
    [currentPortfolio]
  );

  const formik = useFormik<PortfolioParameters>({
    initialValues,
    onSubmit: saveParameters,
    validateOnBlur: false,
    validationSchema: portfolioParametersValidationSchema,
  });

  const { dirty, resetForm } = formik;

  const handleCancelUnsavedChanges = () => {
    setIsOpenUnsavedChanges(false);
  };

  const handleConfirmUnsavedChanges = () => {
    handleCancelUnsavedChanges();
    resetForm({ values: initialValues });
    handleClose();
  };

  const handleCancel = () => {
    if (dirty) {
      return setIsOpenUnsavedChanges(true);
    }
    handleClose();
  };

  useEffect(() => {
    if (!isOpen) return;

    resetForm({ values: initialValues });
  }, [isOpen]);

  return {
    formik,
    handleCancel,
    isOpenUnsavedChanges,
    handleConfirmUnsavedChanges,
    handleCancelUnsavedChanges,
  };
};
