import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog, InfoIcon } from '@clearalpha/common';
import { DeleteDialogProps } from './DeleteConfiramtionDialog.type';

const DeleteConfirmationDialog = ({
  handleClose,
  isOpen,
  handleDelete,
  title,
  description,
}: DeleteDialogProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      data-testid='deleteDialog'
      title={title}
      handleClose={handleClose}
      isOpen={isOpen}
      message={description}
      cancelButton={{
        label: t('actions.cancel'),
        action: handleClose,
      }}
      confirmButton={{
        label: t('actions.delete'),
        action: handleDelete,
      }}
      icon={<InfoIcon />}
    />
  );
};

export default DeleteConfirmationDialog;
