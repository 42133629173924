import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { setIsLoading } from 'store/slices/portfolios';
import { ChangeRequestBody } from 'services/portfolioCore/portfolioCore.types';
import * as service from 'services/portfolioCore/portfolioCore.service';
import { AxiosError } from 'axios';

export const requestApproval = createAsyncThunk<
  null | AxiosError,
  ChangeRequestBody,
  { state: RootState }
>('requestApproval', async (data, { dispatch }): Promise<null | AxiosError> => {
  dispatch(setIsLoading(true));

  try {
    await service.requestApproval(data);

    return null;
  } catch (e) {
    return e as AxiosError;
  } finally {
    dispatch(setIsLoading(false));
  }
});
