import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import {
  Button,
  Menu,
  MenuItem,
  ArrowDropDownIcon,
  ArrowDropUpIcon,
} from '@clearalpha/common';
import { useTranslation } from 'react-i18next';
import { StrategyListBulkActionMenuProps } from './StrategyListBulkActionMenu.type';

const MenuButton = styled(Button)`
  padding: 22px 16px;
  height: 56px;
  max-height: 56px;
  width: 312px;
  justify-content: space-between;

  &:before {
    content: none;
  }

  & .MuiTypography-button {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  & .MuiButton-endIcon {
    margin-right: -8px;
  }
`;

const BulkItem = styled(MenuItem)`
  font-size: 16px;
  width: 312px;
`;

const StrategyListBulkActionMenu = ({
  bulkActions,
}: StrategyListBulkActionMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const onOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const isOpen = !!anchorEl;
  const arrowDropIcon = isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
  const isButtonDisabled = useSelector(isReviewModePortfolioSelector);

  return (
    <>
      <MenuButton
        variant={isButtonDisabled ? 'elevated' : 'outlined'}
        onClick={onOpen}
        endIcon={arrowDropIcon}
        disabled={isButtonDisabled}
      >
        {t('actions.bulkAction')}
      </MenuButton>

      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onClose}
        onClick={onClose}
      >
        <BulkItem onClick={bulkActions.handleLockClick}>
          {t('actions.lockAllSelected')}
        </BulkItem>
        <BulkItem onClick={bulkActions.handleUnlockClick}>
          {t('actions.unlockAllSelected')}
        </BulkItem>
        <BulkItem onClick={bulkActions.handleZeroAllocationClick}>
          {t('actions.zeroAllocationToAllSelected')}
        </BulkItem>
        <BulkItem onClick={bulkActions.handleRevertAllocationClick}>
          {t('actions.revertToProductionAllocation')}
        </BulkItem>
      </Menu>
    </>
  );
};

export default StrategyListBulkActionMenu;
