import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { SensitiveDataButton } from '@clearalpha/common';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { teamsSelector } from 'store/slices/teams';
import { PmTeamProps } from './PmTeam.type';
import { Button } from '../styled';

const PmTeam = ({
  pmTeamId,
  handleOpenTeam,
  disabled = false,
}: PmTeamProps) => {
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);
  const teams = useSelector(teamsSelector);
  const team = teams.find(({ id }) => id === pmTeamId);

  if (!team) return null;

  return (
    <Box mb='20px'>
      <SensitiveDataButton
        isVisible={isVisibleSensitiveData}
        onClick={handleOpenTeam}
        alignItems='flex-start'
        disabled={disabled}
      >
        <Button disabled={disabled} variant='text' onClick={handleOpenTeam}>
          {team.name}
        </Button>
      </SensitiveDataButton>
    </Box>
  );
};

export default PmTeam;
