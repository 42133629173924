import {
  Select as Base,
  Button as ButtonBase,
  SelectHelperText,
  TextFieldWrapper,
} from '@clearalpha/common';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 95%;
  height: 100%;
  min-height: 350px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  position: relative;
`;
export const Title = styled.div`
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.15px;
  padding-top: 24px;
  padding-left: 24px;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  margin-bottom: 50px;
`;

export const Select = styled(Base)`
  max-width: 100%;
  width: 550px;
`;

export const ErrorMessage = styled(SelectHelperText)`
  position: unset;
  text-align: left;
  margin-left: 15px;
  margin-top: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  justify-self: flex-end;
  gap: 16px;
  width: 100%;
  position: absolute;
  bottom: 2px;
  left: 20px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin-bottom: 20px;
`;

export const GroupDescription = styled.p`
  margin: 0;
  padding-left: 24px;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: ${({ theme }) => theme.colors.whiteOpacity70};
`;

export const ButtonCreate = styled(ButtonBase)`
  width: 225px;
`;

export const CreateGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin-bottom: 30px;
`;

export const NumericWrapper = styled(TextFieldWrapper)`
  width: 100%;
  .MuiInputBase-root {
    height: 56px;
    min-height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    .MuiOutlinedInput-input {
      text-align: left;
    }
  }
`;
