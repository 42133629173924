import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NumericTextCurrency, TextFieldWrapper } from '@clearalpha/common';
import FormikCurrencyNumbers from 'components/formik/fields/FormikCurrencyNumbers';
import { isGlobalStrategyEditModeSelector } from 'store/slices/strategies';
import { FieldLabel } from 'components/StrategyDetails/blocks/styled';
import { GlobalStrategyDetailsFormFields } from '../../../GlobalStrategyDetails.type';
import { StrategyFormFields } from '../../../GlobalStrategyDetails.constants';

const AUMCapacity = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<GlobalStrategyDetailsFormFields>();
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);

  return (
    <>
      <FieldLabel>{t('aumCapacity')}</FieldLabel>
      {isEditMode ? (
        <FormikCurrencyNumbers
          name={StrategyFormFields.ALLOCATION_TERMS}
          customInput={TextFieldWrapper}
        />
      ) : (
        <NumericTextCurrency value={values.allocation_terms} />
      )}
    </>
  );
};

export default AUMCapacity;
