import React, { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@clearalpha/common';
import {
  isLoadingPMsSelector,
  setPortfolioManagers,
} from 'store/slices/portfolioManagers';
import { isTeamsLoadingSelector, setTeams } from 'store/slices/teams';
import { useAppDispatch } from 'store';
import {
  isLoadingStrategiesSelector,
  setGlobalStrategies,
} from 'store/slices/strategies';
import { fetchStrategyLibrary } from 'store/thunks/fetchStrategyLibrary';

const StrategyLibraryContent = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();

  const isTeamsLoading = useSelector(isTeamsLoadingSelector);
  const isManagersLoading = useSelector(isLoadingPMsSelector);
  const isStrategiesLoading = useSelector(isLoadingStrategiesSelector);

  useEffect(() => {
    dispatch(fetchStrategyLibrary());

    return () => {
      dispatch(setPortfolioManagers([]));
      dispatch(setGlobalStrategies([]));
      dispatch(setTeams([]));
    };
  }, [dispatch]);

  const isLoading = isTeamsLoading || isManagersLoading || isStrategiesLoading;

  return (
    <div>
      {children}
      <Loader loading={isLoading} />
    </div>
  );
};

export default StrategyLibraryContent;
