import styled from '@emotion/styled';

export const Container = styled.div`
  margin-bottom: 56px;
`;

export const FormButtonsWrapper = styled.div`
  margin-bottom: 40px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 64px;
  max-width: 1536px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`;

export const ActionMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;

export const TeamTypeContainer = styled.div`
  max-width: 200px;
`;
