import { UserState } from './user.types';

const USER_SLICE_NAME = 'user';

const USER_SLICE_INITIAL_STATE: UserState = {
  accessToken: '',
  refreshToken: '',
  idToken: '',
  idTokenRefreshAt: 0,
  refreshTokenExpiresAt: 0,
  signOutAt: 0,
  isSignedIn: false,
  isSignInInitiated: false,
  isSignOutInitiated: false,
  redirectTo: '',
};

export { USER_SLICE_NAME, USER_SLICE_INITIAL_STATE };
