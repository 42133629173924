import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { updateGlobalStrategy } from 'services/strategyCatalog/strategyCatalog.service';
import { fetchGlobalStrategies } from 'store/thunks';
import {
  GlobalStrategiesRequest,
  GlobalStrategiesResponse,
} from 'services/strategyCatalog/strategyCatalog.type';
import {
  setIsGlobalStrategiesLoading,
  updateSingleGlobalStrategy,
} from '../slices/strategies';

export const updateGlobalStrategiesDetails = createAsyncThunk<
  GlobalStrategiesResponse | any,
  {
    uuid: string;
    updatedStrategy: GlobalStrategiesRequest;
  },
  { state: RootState }
>(
  'updateGlobalStrategiesDetails',
  async ({ uuid, updatedStrategy }, { dispatch }) => {
    dispatch(setIsGlobalStrategiesLoading(true));

    try {
      const updatedGlobalStrategy = await updateGlobalStrategy(
        uuid,
        updatedStrategy
      );

      dispatch(updateSingleGlobalStrategy(updatedGlobalStrategy));

      dispatch(fetchGlobalStrategies());

      return updatedGlobalStrategy;
    } catch (error) {
      return error;
    } finally {
      dispatch(setIsGlobalStrategiesLoading(false));
    }
  }
);
