import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, FormikProvider } from 'formik';
import { useSelector } from 'react-redux';
import { DialogContent } from '@mui/material';
import { Button, CloseIcon } from '@clearalpha/common';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { saraProductionStrategiesSelector } from 'store/slices/strategies/strategies.selectors';
import { CorrelationsDialogFormFields } from './CorrelationsDialog.constants';
import { CorrelationsDialogProps } from './CorrelationsDialog.type';
import CorrelationsTable from './CorrelationsTable';
import {
  Dialog,
  DialogTitle,
  Description,
  DialogActions,
  Tab,
  Tabs,
  Form,
  IconButton,
} from './CorrelationsDialog.styled';
import CorrelationMix from './CorrelationMix';
import UnsavedChangesDialog from '../UnsavedChangesDialog/UnsavedChangesDialog';
import { useCorrelationsForm } from './CorrelationsDialog.helper';

const CorrelationsDialog = ({
  isOpen,
  handleClose,
  correlationSets,
}: CorrelationsDialogProps) => {
  const { t } = useTranslation();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const strategies = useSelector(saraProductionStrategiesSelector);
  const [activeTab, setActiveTab] = useState<'regular' | 'tail'>('regular');
  const {
    formikRegular,
    formikTail,
    handleConfirmUnsavedChanges,
    handleCancel,
    handleCancelUnsavedChanges,
    isOpenUnsavedChanges,
    initialValuesRegular,
    initialValuesTail,
  } = useCorrelationsForm(isOpen, handleClose, correlationSets);

  const {
    dirty: dirtyRegular,
    resetForm: resetFormRegular,
    handleSubmit: handleSubmitRegular,
    errors: errorsRegular,
  } = formikRegular;

  const {
    dirty: dirtyTail,
    resetForm: resetFormTail,
    handleSubmit: handleSubmitTail,
    errors: errorsTail,
  } = formikTail;

  const handleChange = (
    event: SyntheticEvent,
    newValue: 'regular' | 'tail'
  ) => {
    setActiveTab(newValue);
  };

  const handleResetRegular = () => {
    resetFormRegular();
  };
  const handleResetTail = () => {
    resetFormTail();
  };

  useEffect(() => {
    if (!isOpen) return;

    resetFormRegular({ values: initialValuesRegular });
    resetFormTail({ values: initialValuesTail });
  }, [isOpen]);

  if (
    !initialValuesRegular.correlations.length ||
    !initialValuesTail.correlations.length
  )
    return null;

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>
        {t('dialogs.correlationMatrix.title')}
        <Description>{t('dialogs.correlationMatrix.desc')}</Description>
      </DialogTitle>
      <IconButton aria-label='close' onClick={handleCancel}>
        <CloseIcon />
      </IconButton>
      <CorrelationMix />
      <Tabs value={activeTab} onChange={handleChange}>
        <Tab
          value='regular'
          label={t('dialogs.correlationMatrix.regularMatrix')}
        />
        <Tab value='tail' label={t('dialogs.correlationMatrix.tailMatrix')} />
      </Tabs>
      {activeTab === 'regular' && (
        <FormikProvider value={formikRegular}>
          <Form onSubmit={handleSubmitRegular}>
            <DialogActions>
              <Button variant='outlined' disabled={isPortfolioReviewMode}>
                {t('actions.setOverride')}
              </Button>
              <Button
                variant='outlined'
                disabled={!dirtyRegular}
                onClick={handleResetRegular}
              >
                {t('actions.cancel')}
              </Button>
              <Button
                type='submit'
                disabled={!dirtyRegular || !!errorsRegular.correlations}
                variant='filled'
              >
                {t('actions.done')}
              </Button>
            </DialogActions>
            <DialogContent>
              <FieldArray
                name={CorrelationsDialogFormFields.CORRELATIONS}
                render={(props) => (
                  <CorrelationsTable {...props} strategies={strategies} />
                )}
              />
            </DialogContent>
          </Form>
        </FormikProvider>
      )}
      {activeTab === 'tail' && (
        <FormikProvider value={formikTail}>
          <Form onSubmit={handleSubmitTail}>
            <DialogActions>
              <Button variant='outlined' disabled={isPortfolioReviewMode}>
                {t('actions.setOverride')}
              </Button>
              <Button
                variant='outlined'
                disabled={!dirtyTail}
                onClick={handleResetTail}
              >
                {t('actions.cancel')}
              </Button>
              <Button
                type='submit'
                disabled={!dirtyTail || !!errorsTail.correlations}
                variant='filled'
              >
                {t('actions.done')}
              </Button>
            </DialogActions>
            <DialogContent>
              <FieldArray
                name={CorrelationsDialogFormFields.CORRELATIONS}
                render={(props) => (
                  <CorrelationsTable {...props} strategies={strategies} />
                )}
              />
            </DialogContent>
          </Form>
        </FormikProvider>
      )}
      <UnsavedChangesDialog
        isOpen={isOpenUnsavedChanges}
        handleClose={handleCancelUnsavedChanges}
        confirmAction={handleConfirmUnsavedChanges}
        cancelAction={handleCancelUnsavedChanges}
      />
    </Dialog>
  );
};

export default CorrelationsDialog;
