import { Theme } from '@emotion/react';
import { DialogProps } from '@clearalpha/common';
import i18n from 'translations/i18n';

export type UpdateAumDialogDataType = {
  production_aum: number;
  total_aum: number;
};

export type UpdateAumDialogProps = DialogProps & {
  updateAumDialogData: UpdateAumDialogDataType;
  onUpdate: (value: number) => void;
};

type ValidationValues = {
  production_aum: number;
  total_aum: number;
  inflow: number;
  outflow: number;
};

export type UpdateAumDialogRowType = {
  fieldName: string;
  note?: string;
  variableName: keyof ValidationValues;
};

type UpdateAumDialogRowsType = {
  productionAumRow: UpdateAumDialogRowType;
  totalAumRow: UpdateAumDialogRowType;
  flowRows: UpdateAumDialogRowType[];
};

export const updateAumDialogRows: UpdateAumDialogRowsType = {
  productionAumRow: {
    fieldName: i18n.t('dialogs.aum.productionAUM'),
    variableName: 'production_aum',
  },
  totalAumRow: {
    fieldName: i18n.t('dialogs.aum.projectedAUM'),
    variableName: 'total_aum',
  },
  flowRows: [
    {
      fieldName: i18n.t('dialogs.aum.inflow'),
      note: i18n.t('dialogs.aum.addToAUM'),
      variableName: 'inflow',
    },
    {
      fieldName: i18n.t('dialogs.aum.outflow'),
      note: i18n.t('dialogs.aum.removeFromAUM'),
      variableName: 'outflow',
    },
  ],
};

export type TotalAumRowProps = {
  theme?: Theme;
  isValid: boolean;
};
