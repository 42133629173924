import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  GetTeam,
  TeamEntityRequest,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { updateTeam } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import {
  updateSingleTeam,
  setIsTeamEditMode,
  setIsTeamLoading,
} from 'store/slices/teams';
import { fetchTeams } from './fetchTeams';
import { fetchPortfolioManagers } from './fetchPortfolioManagers';

export const editTeam = createAsyncThunk<
  GetTeam | null,
  { uuid: string; team: TeamEntityRequest },
  { state: RootState }
>('editTeam', async ({ uuid, team }, { dispatch }) => {
  dispatch(setIsTeamLoading(true));

  try {
    const updatedTeam = await updateTeam(uuid, team);

    dispatch(updateSingleTeam(updatedTeam));
    dispatch(setIsTeamEditMode(false));

    dispatch(fetchTeams());
    dispatch(fetchPortfolioManagers());

    return updatedTeam;
  } catch {
    return null;
  } finally {
    dispatch(setIsTeamLoading(false));
  }
});
