import React from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../Section/Section';
import TeamMemberForm from './TeamMemberForm/TeamMemberForm';
import TeamMemberList from './TeamMemberList/TeamMemberList';
import { TeamMembersProps } from './TeamMembers.types';

// TODO: Update team members section while replacing PM endpoints
const TeamMembers = ({ handleOpenTeamMember }: TeamMembersProps) => {
  const { t } = useTranslation();

  return (
    <Section title={t('teamMembers')} withBackground>
      <TeamMemberForm />

      <TeamMemberList handleOpenTeamMember={handleOpenTeamMember} />
    </Section>
  );
};

export default TeamMembers;
