import React from 'react';
import { NameField, TeamField, StatusField, LocationField } from './fields';
import {
  Overview as StyledOverview,
  OverviewStats,
  PortfolioPicture,
} from './styled';
import { OverviewProps } from './Overview.types';

const Overview = ({ portfolioManager, handleOpenTeam }: OverviewProps) => {
  return (
    <>
      <NameField />
      <StyledOverview>
        <PortfolioPicture />
        <OverviewStats>
          <TeamField
            portfolioManager={portfolioManager}
            handleOpenTeam={handleOpenTeam}
          />
          <StatusField status={portfolioManager?.status} />
          <LocationField />
        </OverviewStats>
      </StyledOverview>
    </>
  );
};

export default Overview;
