import { useTranslation } from 'react-i18next';
import {
  InfoIcon,
  ConfirmationDialog as ConfirmationDialogWrapper,
} from '@clearalpha/common';
import React from 'react';
import { ConfirmationDialogProps } from './ConfirmationDialog.type';

const ConfirmationDialog = ({
  title,
  message,
  testId,
  isOpen,
  handleConfirm,
  handleClose,
  confirmButtonText,
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialogWrapper
      data-testid={testId}
      title={title}
      handleClose={handleClose}
      isOpen={isOpen}
      message={message}
      cancelButton={{
        label: t('actions.cancel'),
        action: handleClose,
      }}
      confirmButton={{
        label: confirmButtonText,
        action: handleConfirm,
      }}
      icon={<InfoIcon />}
    />
  );
};

export { ConfirmationDialog };
