import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { globalStrategiesSelector } from 'store/slices/strategies';
import { useAppDispatch } from 'store';
import { FormikProvider, useFormik } from 'formik';
import { fetchGlobalStrategies } from 'store/thunks';
import {
  addStrategyValidationSchema,
  addStrategyValidationSchemaWithForm,
} from 'validation/addStrategyValidationSchema';
import {
  strategyGroupFeeTypesSelector,
  strategyGroupsSelector,
  workingPortfolioSelector,
} from 'store/slices/portfolios';
import { createStrategyBase } from 'store/thunks/createStrategyBase';
import { GlobalStrategiesResponse } from 'services/strategyCatalog/strategyCatalog.type';
import { Strategy } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import {
  addNewStrategyAndGroupToPortfolio,
  addNewStrategyGroup,
  addNewStrategyToPortfolio,
  addNewStrategyToStrategyGroup,
} from 'store/slices/portfolios/portfolios.slice';
import SelectStrategy from './Steps/SelectStrategy/SelectStrategy';
import {
  AddNewStrategyDialogProps,
  FormState,
  Steps,
  initialFormState,
} from './AddNewStrategyDialog.type';
import { Dialog } from './AddNewStrategyDialog.styled';
import {
  createGroupsList,
  createOptionsList,
  createEntitiesToSave,
  prepareBaseStategyPayload,
  createStrategyEntity,
} from './AddNewStrategyDialog.helpers';
import SelectGroup from './Steps/SelectGroup/SelectGroup';

const AddNewStrategyDialog = ({
  isOpen,
  handleClose,
}: AddNewStrategyDialogProps) => {
  const [currentStep, setCurrentStep] = useState<number>(Steps.STEP_ONE);
  const [selectedStrategy, setSelectedStrategy] =
    useState<GlobalStrategiesResponse | null>(null);
  const [isCreateFormVisible, setIsCreateFormVisible] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const allGlobalStrategies = useSelector(globalStrategiesSelector);
  const { strategyAllocations, id } = useSelector(workingPortfolioSelector);
  const strategyGroups = useSelector(strategyGroupsSelector);
  const strategyGroupFeeTypes = useSelector(strategyGroupFeeTypesSelector);

  const strategiesMenuItems = createOptionsList(
    strategyAllocations,
    allGlobalStrategies
  );
  const saveStrategy = async (values: FormState) => {
    if (!selectedStrategy) return;
    const reqPayload = prepareBaseStategyPayload(selectedStrategy);
    const { payload } = await dispatch(
      createStrategyBase({ strategyData: reqPayload })
    );
    const baseStrategy = payload as Strategy;

    if (!values.strategyGroup && baseStrategy) {
      const {
        reduxStrategyGroupEntity,
        strategyAllocation,
        strategyGroupBasic,
      } = createEntitiesToSave(
        selectedStrategy,
        id,
        values,
        baseStrategy.id,
        strategyGroupFeeTypes
      );
      dispatch(addNewStrategyGroup(reduxStrategyGroupEntity));
      dispatch(
        addNewStrategyAndGroupToPortfolio({
          newStrategy: strategyAllocation,
          strategyGroup: strategyGroupBasic,
        })
      );
    } else if (values.strategyGroup && baseStrategy) {
      const strategyGroup = strategyGroups.find(
        (group) => group.id === values.strategyGroup
      );
      const newStrategyEntity = createStrategyEntity(
        baseStrategy.id,
        selectedStrategy,
        id
      );

      const newStrategy = {
        ...newStrategyEntity,
        strategyGroupId: strategyGroup?.id || '',
      };

      dispatch(
        addNewStrategyToStrategyGroup({
          newStrategy,
          strategyGroupToUpdateId: strategyGroup?.id || '',
        })
      );

      const { strategyAllocation } = createEntitiesToSave(
        selectedStrategy,
        id,
        values,
        baseStrategy.id,
        strategyGroupFeeTypes,
        strategyGroup
      );
      dispatch(addNewStrategyToPortfolio({ strategy: strategyAllocation }));
    }
    setSelectedStrategy(null);
  };

  const submitValues = async (values: FormState) => {
    if (currentStep === Steps.STEP_TWO) {
      saveStrategy(values);
    }
    handleClose();
    setCurrentStep(Steps.STEP_ONE);
  };

  const formik = useFormik<FormState>({
    initialValues: initialFormState,
    onSubmit: (values, { resetForm }) => {
      submitValues(values);
      resetForm({ values: initialFormState });
    },
    validateOnBlur: false,
    validationSchema: isCreateFormVisible
      ? addStrategyValidationSchemaWithForm
      : addStrategyValidationSchema,
  });

  const { handleSubmit, resetForm, setFieldValue, values } = formik;

  const groupsMenuList = createGroupsList(
    strategyGroups,
    allGlobalStrategies,
    values.strategy || ''
  );

  const handleCancel = () => {
    handleClose();
    resetForm({ values: initialFormState });
    setCurrentStep(Steps.STEP_ONE);
  };

  const moveToTheNextStep = () => {
    setCurrentStep((prev) => ++prev);
  };

  const steps: { [key: number]: JSX.Element } = {
    [Steps.STEP_ONE]: (() => (
      <SelectStrategy
        handleCancel={handleCancel}
        moveToTheNextStep={moveToTheNextStep}
        options={strategiesMenuItems}
        formState={values}
        setSelectedStrategy={setSelectedStrategy}
      />
    ))(),
    [Steps.STEP_TWO]: (() => (
      <SelectGroup
        handleCancel={handleCancel}
        setFieldValue={setFieldValue}
        isCreateFormVisible={isCreateFormVisible}
        options={groupsMenuList}
        setIsCreateFormVisible={setIsCreateFormVisible}
      />
    ))(),
  };

  const onClose = () => {
    handleClose();
    resetForm({ values: initialFormState });
    setCurrentStep(Steps.STEP_ONE);
  };

  useEffect(() => {
    dispatch(fetchGlobalStrategies());
    resetForm({ values: initialFormState });
  }, []);

  return (
    <Dialog data-testid='strategyDialog' onClose={onClose} open={isOpen}>
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit}>{steps[currentStep]}</form>
      </FormikProvider>
    </Dialog>
  );
};

export default AddNewStrategyDialog;
