import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { CloseIcon, DialogProps, Loader } from '@clearalpha/common';
import {
  HistoryWrapper,
  Header,
  VersionsContainer,
  Label,
  ColumnName,
  VersionDialog,
  NoData,
} from './VersionListDialog.styled';
import VersionListItem from './VersionListItem';
import { useVersionListDialog } from './VersionList.helper';

const VersionListDialog = ({ isOpen, handleClose }: DialogProps) => {
  const { t } = useTranslation();

  const { isLoading, currentVersion, savedVersions, handleRestoreVersion } =
    useVersionListDialog({
      isOpen,
      handleClose,
    });

  return (
    <VersionDialog open={isOpen} onClose={handleClose}>
      <HistoryWrapper>
        <Header>
          <Label>{t('dialogs.versionHistory.title')}</Label>
          <IconButton data-testid='close-dialog-button' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Header>

        {!currentVersion && !savedVersions?.length && (
          <NoData>{t('noDataToDisplay')}</NoData>
        )}

        {currentVersion && (
          <VersionsContainer>
            <VersionListItem
              id={currentVersion.id}
              asOf={currentVersion.asOf}
              version={currentVersion.version}
              handleRestoreVersion={handleRestoreVersion}
              isCurrent
            />
          </VersionsContainer>
        )}

        {!!savedVersions?.length && (
          <>
            <ColumnName>{t('dialogs.versionHistory.lastSaved')}</ColumnName>
            <VersionsContainer>
              {savedVersions?.map(({ id, asOf, version }) => (
                <VersionListItem
                  key={`${id}-${asOf}-${version}`}
                  id={id}
                  asOf={asOf}
                  version={version}
                  handleRestoreVersion={handleRestoreVersion}
                />
              ))}
            </VersionsContainer>
          </>
        )}
      </HistoryWrapper>

      <Loader loading={isLoading} />
    </VersionDialog>
  );
};

export default VersionListDialog;
