import {
  GetPortfolioStrategies,
  PortfolioFeeTypes,
  StrategyGroup,
  StrategyGroupsResponse,
} from 'services/portfolioCore/portfolioCore.types';
import { GlobalStrategiesResponse } from 'services/strategyCatalog/strategyCatalog.type';
import { v4 as uuidv4 } from 'uuid';
import { StrategyParams } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { FormState, OptionsEntity } from './AddNewStrategyDialog.type';

export const createOptionsList = (
  portfolioStrategies: GetPortfolioStrategies[],
  globalStrategies: GlobalStrategiesResponse[]
) => {
  const strategiesHashMap = portfolioStrategies.reduce(
    (acc: { [key: string]: GetPortfolioStrategies }, current) => {
      const globalStrategyId = current.globalStrategyId || '';

      if (globalStrategyId) {
        acc[globalStrategyId] = current;
      }
      return acc;
    },
    {}
  );

  const strategiesWithoutPortfolio = globalStrategies.filter(
    ({ id }) => !strategiesHashMap[id]
  );
  const options = strategiesWithoutPortfolio.reduce(
    (acc: OptionsEntity, current) => {
      acc[current.id] = current.name;
      return acc;
    },
    {}
  );
  return Object.entries(options)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .reduce((obj: OptionsEntity, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
};

export const createGroupsList = (
  strategyGroups: StrategyGroupsResponse[],
  allGlobalStrategies: GlobalStrategiesResponse[],
  strategyId: string
) => {
  const pmTeamId = allGlobalStrategies.find(
    (current) => current.id === strategyId
  )?.portfolioManagerTeamId;

  const filteredGroups = strategyGroups
    .filter((current) => current.portfolioManagerTeamId === pmTeamId)
    .reduce((acc: OptionsEntity, current) => {
      acc[current.id] = current.name;
      return acc;
    }, {});

  return Object.entries(filteredGroups)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .reduce((obj: OptionsEntity, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
};

export const preparePayload = ({
  strategyGroupName,
  strategyGroupMgmtFee,
  strategyGroupFixedExpenses,
  strategyGroupPerformanceFee,
}: FormState) => ({
  name: strategyGroupName,
  fixedExpenses: Number(strategyGroupFixedExpenses) || 0,
  mgmtFee: Number(strategyGroupMgmtFee) || 0,
  performanceFee: Number(strategyGroupPerformanceFee) || 0,
});

export const prepareBaseStategyPayload = (
  globalStrategy: GlobalStrategiesResponse
) => {
  const [firstAlphaCategory, secondAlphaCategory, thirdAlphaCategory] =
    globalStrategy.alphaCategories;
  return {
    name: globalStrategy.name,
    description: globalStrategy.description || '', // HARDCODED VALUE
    assetCategoryId: globalStrategy.assetCategory.id,
    marginTypeId: globalStrategy.marginType.id,
    averageHoldingPeriodId: globalStrategy.averageHoldingPeriod.id,
    groupCharacteristicId: globalStrategy.groupCharacteristic.id,
    alphaCategories: {
      SCIENTIST: firstAlphaCategory.value,
      ENGINEER: secondAlphaCategory.value,
      ACTUARY: thirdAlphaCategory.value,
    },
    historicSharpeRatio: globalStrategy.historicSharpeRatio,
    grossSharpe: globalStrategy.grossSharpe,
    capacity: globalStrategy.capacity,
    minAllocation: globalStrategy.minAllocation,
    volatilityTarget: globalStrategy.volatilityTarget,
    proportionSystematic: globalStrategy.proportionSystematic,
    strategyEdge: globalStrategy.strategyEdge,
    capitalAtRiskWindowBdays: globalStrategy.capitalAtRiskWindowBdays,
  };
};

export const prepareBaseStategyFromDetailsPayload = (
  strategy: StrategyParams
) => {
  return {
    name: strategy.name,
    description: strategy.description || '', // HARDCODED VALUE
    assetCategoryId: strategy.assetCategory,
    marginTypeId: strategy.marginType,
    averageHoldingPeriodId: strategy.averageHoldingPeriod,
    groupCharacteristicId: strategy.groupCharacteristic,
    alphaCategories: strategy.alphaCategoriesIds,
    historicSharpeRatio: strategy.historicSharpeRatio,
    grossSharpe: strategy.grossSharpe,
    capacity: strategy.capacity,
    minAllocation: strategy.minAllocation,
    volatilityTarget: strategy.volatilityTarget,
    proportionSystematic: strategy.proportionSystematic,
    strategyEdge: strategy.strategyEdge,
    capitalAtRiskWindowBdays: strategy.capitalAtRiskWindowBdays,
  };
};

export const createStrategyEntity = (
  baseStrategyId: string,
  strategy: GlobalStrategiesResponse,
  portfolioId: string
) => ({
  id: uuidv4(),
  status: {
    // TODO: HARDCODED VALUE
    id: 'NOT_APPROVED',
    name: 'not approved',
    deletedAt: '',
  },
  globalStrategyId: strategy.id,
  globalStrategyVersion: strategy.version,
  strategyId: baseStrategyId,
  strategyType: {
    id: 'uuid',
    name: 'scenario',
    deletedAt: '', // ???
  },
  axiomaId: '',
  arcesiumId: '',
  ownerId: 'STRING', // TODO: GET FROM AUTHORIZED USER
  portfolioId,
  notionalAccountValue: 0,
  isLocked: false,
  version: strategy.version,
  deletedAt: '', // ???
});

export const createEntitiesToSave = (
  strategy: GlobalStrategiesResponse,
  portfolioId: string,
  values: FormState,
  baseStrategyId: string,
  strategyGroupFeeTypes: PortfolioFeeTypes[],
  existingStrategyGroup?: StrategyGroup
) => {
  const getFeeTypeId = (name: string) =>
    strategyGroupFeeTypes.find((feeType) => feeType.name === name)!.id;
  const newGroupId = uuidv4();
  const strategyGroupBasic = {
    id: newGroupId,
    name: values.strategyGroupName || '',
    description: '', // TODO: HARDCODED VALUE
    portfolioId,
    portfolioManagerTeamId: strategy.portfolioManagerTeamId,
    strategyGroupOriginId: newGroupId,
    parentGroupId: '', // TODO: HARDCODED VALUE
    version: 1, // TODO: HARDCODED VALUE
    deletedAt: null,
    fees: [
      {
        fee: {
          id: getFeeTypeId('Fixed expense budget'),
          name: 'Fixed expense budget',
          deletedAt: null, // ???
        },
        value: Number(values.strategyGroupFixedExpenses) || 0,
      },
      {
        fee: {
          id: getFeeTypeId('Management fee'),
          name: 'Management fee',
          deletedAt: null, // ???
        },
        value: Number(values.strategyGroupMgmtFee) || 0,
      },
      {
        fee: {
          id: getFeeTypeId('Performance fee'),
          name: 'Performance fee',
          deletedAt: null, // ???
        },
        value: Number(values.strategyGroupPerformanceFee) || 0,
      },
    ],
  };

  const strategyToAdd = createStrategyEntity(
    baseStrategyId,
    strategy,
    portfolioId
  );

  const strategyAllocation = {
    ...strategyToAdd,
    strategyGroup: existingStrategyGroup || strategyGroupBasic,
    strategyGroupId: (existingStrategyGroup || strategyGroupBasic).id,
  };

  const reduxStrategyGroupEntity = {
    ...strategyGroupBasic,
    strategies: [strategyToAdd],
  };

  return {
    reduxStrategyGroupEntity,
    strategyAllocation,
    strategyGroupBasic: existingStrategyGroup || strategyGroupBasic,
  };
};
