import { createSlice } from '@reduxjs/toolkit';
import {
  GetPortfolioManager,
  GetPortfolioManagerRole,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import {
  PORTFOLIO_MANAGERS_SLICE_NAME,
  PORTFOLIO_MANAGERS_SLICE_INITIAL_STATE,
} from './portfolioManagers.constants';
import { PortfolioManagersState } from './portfolioManagers.types';

const portfolioManagers = createSlice({
  name: PORTFOLIO_MANAGERS_SLICE_NAME,
  initialState: PORTFOLIO_MANAGERS_SLICE_INITIAL_STATE,
  reducers: {
    setIsPMEditMode: (
      state: PortfolioManagersState,
      action: { payload: boolean }
    ) => {
      state.isPMEditMode = action.payload;
    },
    setIsPortfolioMangersLoading: (
      state: PortfolioManagersState,
      action: { payload: boolean }
    ) => {
      state.isLoadingPMs = action.payload;
    },
    setPortfolioManagers: (
      state: PortfolioManagersState,
      action: { payload: GetPortfolioManager[] }
    ) => {
      state.portfolioManagers = action.payload;
    },
    addNewPortfolioManager: (
      state: PortfolioManagersState,
      action: { payload: GetPortfolioManager }
    ) => {
      state.portfolioManagers.push(action.payload);
    },
    updateSinglePortfolioManager: (
      state: PortfolioManagersState,
      action: { payload: GetPortfolioManager }
    ) => {
      state.portfolioManagers = state.portfolioManagers.map((manager) => {
        if (manager.id !== action.payload.id) return manager;

        return action.payload;
      });
    },
    setIsRolesLoading: (
      state: PortfolioManagersState,
      action: { payload: boolean }
    ) => {
      state.isLoadingRoles = action.payload;
    },
    setRoles: (
      state: PortfolioManagersState,
      action: { payload: GetPortfolioManagerRole[] }
    ) => {
      state.roles = action.payload;
    },
  },
});

export const {
  setIsPMEditMode,
  setPortfolioManagers,
  setIsPortfolioMangersLoading,
  updateSinglePortfolioManager,
  addNewPortfolioManager,
  setIsRolesLoading,
  setRoles,
} = portfolioManagers.actions;

export default portfolioManagers.reducer;
