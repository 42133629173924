import React, { Fragment } from 'react';
import { Divider } from 'components/Card/Card.styled';
import { StyledList } from './CustomList.styled';
import { CustomListProps } from './CustomList.type';

const CustomList = ({ listItems }: CustomListProps) => {
  const itemsToRender = listItems.map(({ item, key }) => (
    <Fragment key={key}>
      {item}
      <Divider />
    </Fragment>
  ));
  return <StyledList>{itemsToRender}</StyledList>;
};

export default CustomList;
