import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, BlockTitle, FieldsWithProduction } from '../styled';
import { GrossSharpe, HistoricalSharpe } from './fields';
import ColumnsHeader from '../ColumnsHeader/ColumnsHeader';

const Performance = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <BlockTitle>{t('performance')}</BlockTitle>
      <FieldsWithProduction>
        <ColumnsHeader />
        <GrossSharpe />
        <HistoricalSharpe />
      </FieldsWithProduction>
    </Block>
  );
};

export default Performance;
