import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ConfirmationDialog,
  formatToCommonDate,
  InfoIcon,
  localizeDatetime,
  Toasters,
} from '@clearalpha/common';
import { useBoolean } from 'helpers/useBoolean';
import { useAppDispatch } from 'store';
import { requestApproval } from 'store/thunks/requestApproval';
import { changeRequestsSelector } from 'store/slices/changeRequests';
import {
  initialWorkingPortfolioSelector,
  isReviewModePortfolioSelector,
  productionPortfolioPerformanceSelector,
  productionPortfolioSelector,
  productionStrategyGroupsSelector,
  strategyGroupsSelector,
  workingPortfolioPerformanceSelector,
  workingPortfolioSelector,
} from 'store/slices/portfolios';
import {
  initialStrategiesSelector,
  saraProductionStrategiesSelector,
  strategiesSelector,
} from 'store/slices/strategies';
import { saveWorkingPortfolio } from 'store/thunks';
import { ChangeRequestBody } from 'services/portfolioCore/portfolioCore.types';
import { GetPortfolioResponse } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { isEqualPortfoliosParams } from './PromotePortfolio.helpers';
import { Button } from '../styled';
import { ChangeReportDialog } from '../../Dialogs/ChangeReportDialog/ChangeReportDialog';

const PromotePortfolio = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    value: isOpen,
    setTrue: handleOpen,
    setFalse: handleClose,
  } = useBoolean(false);
  const isReviewMode = useSelector(isReviewModePortfolioSelector);

  const productionPortfolio = useSelector(productionPortfolioSelector);
  const workingPortfolio = useSelector(workingPortfolioSelector);
  const scenarioGroups = useSelector(strategyGroupsSelector);
  const productionGroups = useSelector(productionStrategyGroupsSelector);
  const initialWorkingPortfolio = useSelector(initialWorkingPortfolioSelector);
  const saraProductionStrategies = useSelector(
    saraProductionStrategiesSelector
  );
  const workingPortfolioPerformance = useSelector(
    workingPortfolioPerformanceSelector
  );
  const productionPortfolioPerformance = useSelector(
    productionPortfolioPerformanceSelector
  );

  const saraWorkingStrategies = useSelector(strategiesSelector);
  const saraInitialStrategies = useSelector(initialStrategiesSelector);
  const changeRequests = useSelector(changeRequestsSelector);
  const [isChangeReportDialogOpened, setChangeReportDialogOpened] =
    useState(false);
  const changeRequestExists = !!changeRequests.length;
  const changeRequestForTheVersionExists = !!changeRequests.filter(
    (changeRequest) => changeRequest.sourceVersion === workingPortfolio.version
  ).length;

  const today = new Date();
  const dateTimeLocalized = localizeDatetime(today.toISOString());
  const { time, date } = formatToCommonDate(dateTimeLocalized);

  const handleOpenChangeReportDialog = () => {
    setChangeReportDialogOpened(true);
  };

  const handleCloseChangeReportDialog = () => {
    setChangeReportDialogOpened(false);
  };

  const hasUnsavedChanges = useMemo(
    () =>
      !isEqualPortfoliosParams({
        portfolioA: initialWorkingPortfolio,
        portfolioB: workingPortfolio,
        portfolioAStrategies: saraInitialStrategies,
        portfolioBStrategies: saraWorkingStrategies,
        scenarioGroups,
        productionGroups,
      }),
    [
      initialWorkingPortfolio,
      workingPortfolio,
      saraInitialStrategies,
      saraWorkingStrategies,
    ]
  );

  const isWorkingEqualToProduction = useMemo(
    () =>
      isEqualPortfoliosParams({
        portfolioA: productionPortfolio,
        portfolioB: workingPortfolio,
        portfolioAStrategies: saraProductionStrategies,
        portfolioBStrategies: saraWorkingStrategies,
        scenarioGroups,
        productionGroups,
      }),
    [
      productionPortfolio,
      workingPortfolio,
      saraProductionStrategies,
      saraWorkingStrategies,
    ]
  );

  const isPromoteButtonDisabled =
    isReviewMode ||
    changeRequestExists ||
    changeRequestForTheVersionExists ||
    isWorkingEqualToProduction ||
    !workingPortfolio.strategyAllocations.length;

  const changeRequestBody: ChangeRequestBody = {
    changeRequestStatus: '',
    description: '',
    reviewerId: '',
    sourceId: workingPortfolio?.id,
    sourceVersion: workingPortfolio?.version,
    targetId: productionPortfolio?.id,
    targetVersion: productionPortfolio?.version,
  };

  const handleRequest = async (body: ChangeRequestBody) => {
    const { payload: error } = await dispatch(requestApproval(body));

    if (!error) {
      Toasters.success(t('success.requestSent'));
      setChangeReportDialogOpened(false);
      handleClose();
    } else {
      Toasters.error(t('errors.changeRequestAlreadyExists'));
    }
  };

  const handleSaveAndPromote = async () => {
    // TODO: UX-1579 Save correlations in portfolio
    const { payload: newPortfolio } = await dispatch(saveWorkingPortfolio());

    if (!newPortfolio) return;

    await handleRequest({
      ...changeRequestBody,
      sourceId: (newPortfolio as GetPortfolioResponse).id,
      sourceVersion: (newPortfolio as GetPortfolioResponse).version,
    });
  };

  const handleRequestApprovalClick = async () => {
    if (workingPortfolio.strategyAllocations?.length) {
      if (hasUnsavedChanges) {
        handleOpen();
      } else {
        await handleRequest(changeRequestBody);
      }
    }
  };

  return (
    <>
      <Button
        variant='outlined'
        onClick={handleOpenChangeReportDialog}
        disabled={isPromoteButtonDisabled}
        data-testid={t('actions.promote')}
      >
        {t('actions.promote')}
      </Button>

      <ConfirmationDialog
        isOpen={isOpen}
        handleClose={handleClose}
        title={t('warning')}
        message={t('warning.requestApproval')}
        icon={<InfoIcon />}
        cancelButton={{
          label: t('actions.cancel'),
          action: handleClose,
        }}
        confirmButton={{
          label: t('actions.saveAndPromote'),
          action: handleSaveAndPromote,
        }}
      />

      <ChangeReportDialog
        isOpen={isChangeReportDialogOpened}
        handleClose={handleCloseChangeReportDialog}
        sourcePortfolio={workingPortfolio}
        targetPortfolio={productionPortfolio}
        sourceStrategies={saraWorkingStrategies}
        targetStrategies={saraProductionStrategies}
        sourcePerformance={workingPortfolioPerformance}
        targetPerformance={productionPortfolioPerformance}
        submitText={t('actions.promote')}
        changeReportText={t('dialogs.changeReport.messageToPromote')}
        changeReportInfo={t('dialogs.changeReport.info', {
          date,
          time,
        })}
        handleSubmit={handleRequestApprovalClick}
      />
    </>
  );
};

export default PromotePortfolio;
