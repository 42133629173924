import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 1000px;
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-bottom: 32px;
`;

export { Wrapper, SectionGrid };
