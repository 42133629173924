import React from 'react';
import { useField } from 'formik';
import { AutocompleteOption, AutocompleteField } from '@clearalpha/common';

type FormikAutocompleteProps<Option> = {
  name: string;
  label: string;
  testId?: string;
  disabled?: boolean;
  variant?: 'outlined';
  options: Option[];
};

const FormikAutocomplete = <Option extends AutocompleteOption>({
  name,
  label,
  testId,
  options,
  variant = 'outlined',
  disabled = false,
}: FormikAutocompleteProps<Option>) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] =
    useField(name);

  const handleClear = () => {
    setTouched(true);
    setValue(null);
  };

  const handleChange = (newValue: Option | null) => {
    setTouched(true);
    setValue(newValue);
  };

  const hasError = Boolean(error && touched);
  const errorMessage = hasError ? error : '';

  return (
    <AutocompleteField<Option>
      options={options}
      onChange={handleChange}
      onClear={handleClear}
      label={label}
      value={value}
      name={name}
      variant={variant}
      data-testid={testId || name}
      error={hasError}
      helperText={errorMessage}
      disabled={disabled}
    />
  );
};

export default FormikAutocomplete;
