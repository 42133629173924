import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ActionMenu } from '@clearalpha/common';
import { useAppDispatch } from 'store';
import { isTeamEditModeSelector, setIsTeamEditMode } from 'store/slices/teams';
import { routes } from 'constants/routes';
import TeamName from 'components/Teams/TeamDetails/TeamName/TeamName';
import { FormikSelectEntityAttributes } from 'components/formik/fields';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { TeamDetailsFormFieldName } from 'components/Teams/TeamDetails/TeamDetails.constants';
import FormButtons from 'components/StrategyLibrary/Actions/FormButtons';
import { getTeamStatuses } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import {
  ActionMenuContainer,
  Container,
  FormButtonsWrapper,
  Grid,
  TeamTypeContainer,
} from './TeamHeader.styled';
import { TeamHeaderProps } from './TeamHeader.types';

const TeamHeader = ({
  isCreateForm,
  isDirty,
  handleCancel,
  handleCloseDrawer,
  handleOpenDeleteDialog,
}: TeamHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isEditMode = useSelector(isTeamEditModeSelector);
  const { pathname } = useLocation();
  const isSara = pathname.includes(routes.MONITOR.path);
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isShouldShowActionMenu =
    !isCreateForm && !isSara && !isPortfolioReviewMode;

  const menuItems = [
    {
      label: t('actions.edit'),
      isVisible: !isEditMode,
      action: () => dispatch(setIsTeamEditMode(true)),
    },
    {
      label: t('actions.delete'),
      action: handleOpenDeleteDialog,
    },
  ];

  return (
    <Container>
      <FormButtonsWrapper>
        <FormButtons
          isDirty={isDirty}
          isEditMode={isEditMode}
          handleCancel={handleCancel}
          handleCloseDrawer={handleCloseDrawer}
          submitButtonLabel={t('actions.save')}
        />
      </FormButtonsWrapper>

      <Grid>
        <TeamName />

        <div>
          <ActionMenuContainer>
            <TeamTypeContainer>
              <FormikSelectEntityAttributes
                name={TeamDetailsFormFieldName.STATUS}
                label={t('status')}
                isDisabled={!isEditMode}
                getData={getTeamStatuses}
              />
            </TeamTypeContainer>

            {isShouldShowActionMenu && (
              <ActionMenu label={t('actions.action')} menuItems={menuItems} />
            )}
          </ActionMenuContainer>
        </div>
      </Grid>
    </Container>
  );
};

export default TeamHeader;
