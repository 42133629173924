import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  setIsPortfolioMangersLoading,
  setPortfolioManagers,
} from 'store/slices/portfolioManagers';
import { getGlobalStrategies } from 'services/strategyCatalog/strategyCatalog.service';
import { setIsTeamLoading, setTeams } from 'store/slices/teams';
import {
  getPortfolioManagers,
  getTeams,
} from 'services/portfolioManagerCore/portfolioManagerCore.service';
import {
  setGlobalStrategies,
  setIsGlobalStrategiesLoading,
} from '../slices/strategies';

export const fetchStrategyLibrary = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchStrategyLibrary', async (_, { dispatch }) => {
  dispatch(setIsGlobalStrategiesLoading(true));
  dispatch(setIsPortfolioMangersLoading(true));
  dispatch(setIsTeamLoading(true));

  try {
    const [globalStrategies, managers, teams] = await Promise.all([
      getGlobalStrategies(),
      getPortfolioManagers(),
      getTeams(),
    ]);

    dispatch(setGlobalStrategies(globalStrategies));
    dispatch(setPortfolioManagers(managers));
    dispatch(setTeams(teams));
  } finally {
    dispatch(setIsGlobalStrategiesLoading(false));
    dispatch(setIsPortfolioMangersLoading(false));
    dispatch(setIsTeamLoading(false));
  }
});
