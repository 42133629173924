import React, { useCallback } from 'react';
import { List } from '@clearalpha/common';
import { useOutletContext } from 'react-router-dom';
import { usePortfolioManagerListConfig } from './PortfolioManagerList.helpers';
import { ListContainer } from '../StrategyLibrary.styled';
import { StrategyLibraryOutletContextParams } from '../StrategyLibrary.types';
import StrategyLibraryContent from '../StrategyLibraryContent';

const PortfolioManagerList = () => {
  const { handleOpenPMDrawer } =
    useOutletContext<StrategyLibraryOutletContextParams>();

  const handleOpenManagerDetails = useCallback(
    (id: string) => handleOpenPMDrawer(id),
    [handleOpenPMDrawer]
  );

  const { rows, columns } = usePortfolioManagerListConfig(
    handleOpenManagerDetails
  );

  return (
    <StrategyLibraryContent>
      <ListContainer>
        <List
          columns={columns}
          rows={rows}
          checkboxSelection={false}
          columnBuffer={columns.length}
        />
      </ListContainer>
    </StrategyLibraryContent>
  );
};

export default PortfolioManagerList;
