import Qs from 'qs';
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { Toasters } from '@clearalpha/common';
import i18n from '../translations/i18n';
import { addAuthorizationHeader } from './http.listeners';

type Error = {
  errorCode: string;
  errorMessage: string;
};

type DataError = {
  errors: Error[];
  message: string;
  statusCode: number;
};

export enum Errors {
  VALIDATION_FAILED = 'Validation failed',
}

/* eslint-disable @typescript-eslint/no-explicit-any */

const instance = axios.create({
  headers: {
    Accept: 'application/json',
  },
  paramsSerializer: {
    serialize: (params) =>
      Qs.stringify(params, { arrayFormat: 'repeat', encode: false }),
  },
});

const logError = (error: AxiosError) => {
  if (error.response?.status === 500) {
    // TODO: ADD POST REQUEST TO REMOTE ERROR LOGGER
    Toasters.error(i18n.t('errors.somethingWentWrong'));
    return;
  }

  if (error.response?.status === 400) {
    const { data }: AxiosResponse<any, DataError> = error.response || {};
    if (data.message === Errors.VALIDATION_FAILED) {
      return;
    }
  }

  if (error.response?.status === 422) return;

  if (error.response?.status === 409) return;

  Toasters.error(error.message);
};

const put = async <T>(
  url: string,
  data?: T,
  config?: any,
  errorHandler?: (e: Error) => any
) => {
  try {
    const response = await instance.put<T>(url, data, config);
    return response.data;
  } catch (e: any) {
    logError(e);
    if (errorHandler) {
      return errorHandler(e);
    }

    throw e;
  }
};

const post = async <T>(
  url: string,
  data?: T,
  config?: any,
  errorHandler?: (e: Error) => any
) => {
  try {
    const response = await instance.post<T>(url, data, config);
    return response.data;
  } catch (e: any) {
    logError(e);
    if (errorHandler) {
      return errorHandler(e);
    }

    throw e;
  }
};

const patch = async <T>(
  url: string,
  data: T,
  errorHandler?: (e: Error) => any
) => {
  try {
    const response = await instance.patch<T>(url, data);
    return response.data;
  } catch (e: any) {
    logError(e);
    if (errorHandler) {
      return errorHandler(e);
    }

    throw e;
  }
};

const remove = async (url: string, errorHandler?: (e: Error) => any) => {
  try {
    const response = await instance.delete(url);
    return response.data;
  } catch (e: any) {
    logError(e);
    if (errorHandler) {
      return errorHandler(e);
    }

    throw e;
  }
};

const get = async <T>(
  url: string,
  errorHandler?: (e: Error) => any,
  params?: AxiosRequestConfig<T>
) => {
  try {
    const response = await instance.get<T>(url, params);
    return response.data;
  } catch (e: any) {
    logError(e);
    if (errorHandler) {
      return errorHandler(e);
    }

    throw e;
  }
};

instance.interceptors.request.use(addAuthorizationHeader);

export { get, put, post, remove, patch, instance };
