import { DialogProps, FieldValueEnum, RowType } from '@clearalpha/common';
import { StrategyListEntity } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import i18n from 'translations/i18n';

export type AllocationDialogProps = DialogProps & {
  onUpdate: (value: number) => void;
  strategy: StrategyListEntity;
};

export const allocationRow: RowType<'workingNotionalAccountValue'> = {
  fieldName: i18n.t('notionalAccountValue'),
  type: FieldValueEnum.NUMERIC_INPUT_CURRENCY,
  variableName: 'workingNotionalAccountValue',
};
