import React from 'react';
import { useSelector } from 'react-redux';
import { SensitiveDataBlock } from '@clearalpha/common';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { isTeamEditModeSelector } from 'store/slices/teams';
import { FormikText, FormikValue } from 'components/formik/fields';
import { Description as StyledDescription } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.styled';
import { ARCESIUM_ID } from 'constants/fields';
import { TeamDetailsFormFieldName } from '../TeamDetails.constants';
import Section from '../Section/Section';

const TeamArcesiumId = () => {
  const isEditMode = useSelector(isTeamEditModeSelector);
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);

  return (
    <Section title={ARCESIUM_ID}>
      {isEditMode ? (
        <FormikText
          name={TeamDetailsFormFieldName.ARCESIUM_ID}
          placeholder={ARCESIUM_ID}
          fullWidth
        />
      ) : (
        <SensitiveDataBlock isVisible={isVisibleSensitiveData} minHeight='80px'>
          <StyledDescription>
            <FormikValue name={TeamDetailsFormFieldName.ARCESIUM_ID} />
          </StyledDescription>
        </SensitiveDataBlock>
      )}
    </Section>
  );
};

export default TeamArcesiumId;
