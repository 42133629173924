import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { FormikText, FormikValue } from 'components/formik/fields';
import { isGlobalStrategyEditModeSelector } from 'store/slices/strategies';
import { StrategyFormFields } from '../../GlobalStrategyDetails.constants';
import { Title } from './Name.styled';

const Name = () => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);

  if (isEditMode) {
    return (
      <Box mb='16px'>
        <FormikText
          name={StrategyFormFields.NAME}
          label={t('name')}
          placeholder={t('name')}
          fullWidth
        />
      </Box>
    );
  }

  return (
    <Title>
      <FormikValue name={StrategyFormFields.NAME} />
    </Title>
  );
};

export default Name;
