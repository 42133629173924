import {
  Block,
  Fields,
  BlockTitle,
} from 'components/StrategyDetails/blocks/styled';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Scientist, Engineer, Actuary } from './fields';

const AlphaArchetypes = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <BlockTitle>{t('alphaArchetypes')}</BlockTitle>
      <Fields>
        <Scientist />
        <Engineer />
        <Actuary />
      </Fields>
    </Block>
  );
};

export default AlphaArchetypes;
