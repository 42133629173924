import { number, object } from 'yup';
import i18n from 'translations/i18n';
import { StrategyFieldNames } from 'components/StrategyList/AddNewStrategyDialog/AddNewStrategyDialog.type';
import { requiredText } from './defaultValidationRules';
import { ONE_BILLION, ONE_HUNDRED } from './validationNumbers';

export const addStrategyValidationSchema = object().shape({
  [StrategyFieldNames.STRATEGY]: requiredText({
    fieldName: i18n.t('strategy'),
  }),
  [StrategyFieldNames.STRATEGY_GROUP]: requiredText({
    fieldName: i18n.t('strategyGroup'),
  }),
});

export const addStrategyValidationSchemaWithForm = object().shape({
  [StrategyFieldNames.STRATEGY]: requiredText({
    fieldName: i18n.t('strategy'),
  }),
  [StrategyFieldNames.STRATEGY_GROUP_NAME]: requiredText({
    fieldName: i18n.t('createGroupFields.name'),
  }),
  [StrategyFieldNames.STRATEGY_GROUP_MGMT_FEE]: number().max(
    ONE_HUNDRED,
    i18n.t('errors.notMoreThan', {
      name: i18n.t('managementFee'),
      value: '100%',
    })
  ),
  [StrategyFieldNames.STRATEGY_GROUP_PERFORMANCE_FEE]: number().max(
    ONE_HUNDRED,
    i18n.t('errors.notMoreThan', {
      name: i18n.t('performanceFee'),
      value: '100%',
    })
  ),
  [StrategyFieldNames.STRATEGY_GROUP_FIXED_EXPENSES]: number().max(
    ONE_BILLION,
    i18n.t('errors.notMoreThan', {
      name: i18n.t('groupFixedExpenses'),
      value: '1B',
    })
  ),
});
