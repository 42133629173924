import styled from '@emotion/styled';

export const NoAccessContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${({ theme: { colors } }) => colors.surface};
  position: relative;
`;

export const NoAccessMessage = styled.p`
  color: ${({ theme: { colors } }) => colors.surfaceVariant2};
  font-family: Work Sans, sans-serif;
  font-size: 32px;
  text-align: center;
  position: absolute;
  top: calc(50% - 120px);
  width: 100%;
`;
