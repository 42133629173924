import { useEffect, useState, useMemo } from 'react';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { Drawer, Button } from '@clearalpha/common';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  sourceStrategyGroupsSelector,
  strategyGroupsSelector,
} from 'store/slices/portfolios';
import { fetchGlobalStrategies, fetchTeams } from 'store/thunks';
import { routes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { isUserSignedInSelector } from 'store/slices/user/user.selectors';
import { hasStrategyLibraryPermissionSelector } from 'store/slices/permissions';
import { StrategyDetailsProps, EditModeState } from './StrategyDetails.type';
import {
  FormButtonsContainer,
  Wrapper,
  SectionGrid,
  Header,
  StyledForm,
} from './StrategyDetails.styled';
import {
  useStrategyDetailsForm,
  createStrategyGroupOptions,
} from './StrategyDetails.helper';
import UnsavedChangesDialog from '../Dialogs/UnsavedChangesDialog/UnsavedChangesDialog';
import {
  PmTeam,
  Name,
  StrategyGroup,
  StrategyId,
  Status,
  Description,
  StrategyEdge,
  AlphaArchetypes,
  Performance,
  Descriptive,
  Capacity,
} from './blocks';

const StrategyDetails = ({
  isOpen,
  handleCloseDrawer,
  handleOpenTeam,
  strategy,
}: StrategyDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] = useState(false);
  const [isClickFromGroupLink, setIsClickFromGroupLink] = useState(false);
  const [isClickFromPmTeam, setIsClickFromPmTeam] = useState(false);
  const [isEditMode, setIsEditMode] = useState<EditModeState>({
    status: false,
    strategyId: false,
    strategyGroup: false,
  });
  const isSignedIn = useSelector(isUserSignedInSelector);
  const hasStrategyLibraryPermission =
    useSelector(hasStrategyLibraryPermissionSelector) && isSignedIn;
  const sourceStrategyGroups = useSelector(sourceStrategyGroupsSelector);
  const portfolioStrategyGroups = useSelector(strategyGroupsSelector);
  const strategyGroups = sourceStrategyGroups.length
    ? sourceStrategyGroups
    : portfolioStrategyGroups;
  const strategyGroupOptions = useMemo(
    () =>
      createStrategyGroupOptions(
        strategyGroups,
        strategy?.strategyGroup.portfolioManagerTeamId || ''
      ),
    [strategyGroups, strategy]
  );

  const { formik, initialValues } = useStrategyDetailsForm({
    strategy,
    handleCloseDrawer,
    strategyGroupOptions,
  });

  const { dirty, resetForm, handleSubmit, values } = formik;

  const handleCancel = () => {
    if (dirty) {
      return setIsOpenUnsavedChanges(true);
    }

    handleCloseDrawer();
  };

  const handleOutsideClick = () => {
    if (dirty) {
      return setIsOpenUnsavedChanges(true);
    }

    handleCloseDrawer();
  };

  const handleCancelUnsavedChanges = () => {
    setIsOpenUnsavedChanges(false);
    setIsClickFromGroupLink(false);
    setIsClickFromPmTeam(false);
  };

  const handleConfirmUnsavedChanges = () => {
    handleCancelUnsavedChanges();
    resetForm({ values: initialValues });
    handleCloseDrawer();

    if (isClickFromGroupLink) {
      return navigate(
        `/${routes.MONITOR.path}/${routes.MANAGE_STRATEGY_GROUPS.path}/${
          values.strategyGroup?.id || ''
        }`
      );
    }

    if (isClickFromPmTeam) {
      return handleOpenTeam(
        strategy?.strategyGroup?.portfolioManagerTeamId || ''
      );
    }
  };

  const handleStrategyGroupEditModeClick = () => {
    setIsEditMode({ ...isEditMode, strategyGroup: true });
  };

  const handleStrategyIdEditModeClick = () => {
    setIsEditMode({ ...isEditMode, strategyId: true });
  };

  const handleStatusEditModeClick = () => {
    setIsEditMode({ ...isEditMode, status: true });
  };

  const handleStrategyGroupRedirectClick = () => {
    if (dirty) {
      setIsClickFromGroupLink(true);
      return setIsOpenUnsavedChanges(true);
    }
    return navigate(
      `/${routes.MONITOR.path}/${routes.MANAGE_STRATEGY_GROUPS.path}/${
        values.strategyGroup?.id || ''
      }`
    );
  };

  const handlePmTeamOpen = () => {
    if (dirty) {
      setIsClickFromPmTeam(true);
      return setIsOpenUnsavedChanges(true);
    }
    handleOpenTeam(strategy?.strategyGroup?.portfolioManagerTeamId || '');
  };

  useEffect(() => {
    if (!isOpen) return;
    setIsEditMode({
      status: false,
      strategyId: false,
      strategyGroup: false,
    });

    resetForm({ values: initialValues });

    return () => {
      setIsClickFromGroupLink(false);
      setIsClickFromPmTeam(false);
    };
  }, [isOpen, resetForm, initialValues]);

  useEffect(() => {
    if (!isOpen) return;

    dispatch(fetchGlobalStrategies());
    dispatch(fetchTeams());
  }, [isOpen, dispatch]);

  return (
    <Drawer
      data-testid='strategyDetailsDrawer'
      anchor='right'
      open={isOpen}
      onClose={handleOutsideClick}
    >
      <FormikProvider value={formik}>
        <StyledForm onSubmit={handleSubmit}>
          <FormButtonsContainer>
            <Button
              data-testid='doneBtn'
              variant='filled'
              type='submit'
              disabled={!dirty}
            >
              {t('actions.done')}
            </Button>
            <Button variant='outlined' onClick={handleCancel}>
              {t('actions.cancel')}
            </Button>
          </FormButtonsContainer>
          <Wrapper data-testid='strategyDetailsWrapper'>
            <Header>
              <PmTeam
                disabled={!hasStrategyLibraryPermission}
                pmTeamId={strategy?.strategyGroup?.portfolioManagerTeamId}
                handleOpenTeam={handlePmTeamOpen}
              />
              <Status
                isStatusEditMode={isEditMode.status}
                handleStatusEditModeClick={handleStatusEditModeClick}
              />
            </Header>
            <Name />
            <StrategyId
              isStrategyIdEditMode={isEditMode.strategyId}
              handleStrategyIdEditModeClick={handleStrategyIdEditModeClick}
            />
            <StrategyGroup
              strategyGroupOptions={strategyGroupOptions}
              isStrategyGroupEditMode={isEditMode.strategyGroup}
              handleStrategyGroupEditModeClick={
                handleStrategyGroupEditModeClick
              }
              handleStrategyGroupRedirectClick={
                handleStrategyGroupRedirectClick
              }
            />
            <SectionGrid>
              <Description />
              <StrategyEdge />
              <AlphaArchetypes />
              <Performance />
              <Descriptive />
              <Capacity />
            </SectionGrid>
          </Wrapper>
        </StyledForm>
      </FormikProvider>
      <UnsavedChangesDialog
        isOpen={isOpenUnsavedChanges}
        handleClose={handleCancelUnsavedChanges}
        confirmAction={handleConfirmUnsavedChanges}
        cancelAction={handleCancelUnsavedChanges}
      />
    </Drawer>
  );
};

export default StrategyDetails;
