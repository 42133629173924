import { RootState } from 'store';
import {
  GlobalStrategiesResponse,
  MarginType,
  GroupCharacteristic,
  AverageHoldPeriod,
  AssetCategory,
} from 'services/strategyCatalog/strategyCatalog.type';
import { FullPortfolioStrategy } from 'services/portfolioCore/portfolioCore.types';
import { STRATEGIES_SLICE_NAME } from './strategies.constants';

export const strategiesSelector = (state: RootState): FullPortfolioStrategy[] =>
  state[STRATEGIES_SLICE_NAME].strategies;

export const initialStrategiesSelector = (state: RootState) =>
  state[STRATEGIES_SLICE_NAME].initialStrategies;

export const saraProductionStrategiesSelector = (
  state: RootState
): FullPortfolioStrategy[] =>
  state[STRATEGIES_SLICE_NAME].saraProductionStrategies;

export const sourceStrategiesSelector = (
  state: RootState
): FullPortfolioStrategy[] => state[STRATEGIES_SLICE_NAME].sourceStrategies;

export const targetStrategiesSelector = (
  state: RootState
): FullPortfolioStrategy[] => state[STRATEGIES_SLICE_NAME].targetStrategies;

export const isLoadingProductionStrategiesSelector = (
  state: RootState
): boolean => state[STRATEGIES_SLICE_NAME].isLoadingStrategies;

export const isGlobalStrategyEditModeSelector = (state: RootState): boolean =>
  state[STRATEGIES_SLICE_NAME].isGlobalStrategyEditMode;

export const globalStrategiesSelector = (
  state: RootState
): GlobalStrategiesResponse[] => state[STRATEGIES_SLICE_NAME].globalStrategies;

export const isLoadingStrategiesSelector = (state: RootState): boolean =>
  state[STRATEGIES_SLICE_NAME].isLoadingStrategies;

export const isLoadingMarginTypesSelector = (state: RootState): boolean =>
  state[STRATEGIES_SLICE_NAME].isLoadingMarginTypes;

export const marginTypesSelector = (state: RootState): MarginType[] =>
  state[STRATEGIES_SLICE_NAME].marginTypes;

export const isLoadingGroupCharacteristicSelector = (
  state: RootState
): boolean => state[STRATEGIES_SLICE_NAME].isLoadingGroupCharacteristics;

export const groupCharacteristicSelector = (
  state: RootState
): GroupCharacteristic[] => state[STRATEGIES_SLICE_NAME].groupCharacteristics;

export const isLoadingAverageHoldPeriodsSelector = (
  state: RootState
): boolean => state[STRATEGIES_SLICE_NAME].isLoadingAverageHoldPeriods;

export const averageHoldPeriodsSelector = (
  state: RootState
): AverageHoldPeriod[] => state[STRATEGIES_SLICE_NAME].averageHoldPeriods;

export const assetCategoriesSelector = (state: RootState): AssetCategory[] =>
  state[STRATEGIES_SLICE_NAME].assetCategories;

export const isLoadingAssetCategoriesSelector = (state: RootState): boolean =>
  state[STRATEGIES_SLICE_NAME].isLoadingAssetCategories;

export const strategiesColorsSelector = (
  state: RootState
): Record<string, string> => state[STRATEGIES_SLICE_NAME].strategiesColors;
