import { GetPortfolioResponse } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { getFeeValue } from 'services/portfolioCore/portfolioCore.helper';
import {
  PortfolioPerformanceRequest,
  PortfolioPerformanceResponse,
} from './fundCalculator.types';

export const addAlphaCategoriesMock = (data: PortfolioPerformanceResponse) => {
  if (data.portfolio.alphaCategoriesPct.length) return data;

  return {
    ...data,
    portfolio: {
      ...data.portfolio,
      alphaCategoriesPct: [
        {
          groupName: 'engineer',
          alphaCategoriesPct: 0.5,
        },
        {
          groupName: 'scientist',
          alphaCategoriesPct: 0.3,
        },
        {
          groupName: 'actuary',
          alphaCategoriesPct: 0.2,
        },
      ],
    },
  };
};

export const convertToPerformancePayload = (
  portfolio: GetPortfolioResponse
): PortfolioPerformanceRequest => {
  const {
    assetsUnderManagement: aum,
    crossCapitalAtRiskBenefit,
    averageCorrelation,
    constraints,
    tbillYield: { value: tbillYieldValue },
    crossMarginBenefit,
    strategyAllocations,
    fees,
  } = portfolio;
  return {
    aum,
    averageCorrelation,
    crossCapitalAtRiskBenefitPct: crossCapitalAtRiskBenefit,
    constraints: {
      targetNetExcessReturn: constraints.targetNetExcessReturn,
      minProbReturnThreshold: constraints.minProbReturnThreshold,
      targetMaxGrossExposure: constraints.targetMaxGrossExposure,
      targetMaxCapitalAtRiskPct: constraints.targetMaxCapitalAtRisk,
      targetMaxLiquidityCR: constraints.targetMaxLiquidityCR,
      targetFreeCashFromAum: constraints.targetFreeCashFromAum,
      targetMaxEquityLeverage: constraints.targetMaxEquityLeverage,
      targetMinNicheRiskPct: constraints.targetMinNicheRisk,
      targetMaxPAndLRiskCR: constraints.targetMaxPLRiskCR,
    },
    tBillYieldPct: tbillYieldValue,
    crossMarginBenefitPct: crossMarginBenefit,
    platformPerformanceFeePct: getFeeValue('Performance Fee', fees) as number,

    platformMgmtFeePct: getFeeValue('Management fee', fees) as number,

    platformFixedExpenses: getFeeValue(
      'Platform Fixed Expenses',
      fees
    ) as number,
    strategyAllocation: strategyAllocations?.map(
      ({ isLocked, ...allocation }) => ({
        strategyId: allocation.id,
        version: allocation.version,
        notionalAccountValue: allocation.notionalAccountValue,
        isLockedNotionalAccountValue: isLocked,
      })
    ),
  };
};
