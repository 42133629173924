import styled from '@emotion/styled';
import { List, ListItemText } from '@mui/material';

export const StyledList = styled(List)`
  width: 100%;
`;

export const StyledlistItem = styled(ListItemText)`
  display: flex;
  justify-content: space-between;
`;

export const TextItem = styled.p`
  font-family: Public Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
`;
