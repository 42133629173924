import styled from '@emotion/styled';
import { Dialog as BaseDialog } from '@clearalpha/common';

const Dialog = styled(BaseDialog)`
  background-color: ${({ theme: { colors } }) => colors.blackOverlay25};

  .MuiPaper-root {
    background: ${({ theme: { colors } }) => colors.surfacePlusFive};
    border-radius: 8px;
    color: ${({ theme: { colors } }) => colors.onSurface};
    font-family: Work Sans, sans-serif;
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export { Dialog };
