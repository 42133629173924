import { View, Text } from '@react-pdf/renderer';
import { styles } from './PdfReportTable.styled';
import { formatSourceAndTargetValues } from './PdfReportTable.helpers';

const PdfReportTable = ({ items }: { items: any[] }) => (
  <View style={styles.tableContainer}>
    {items.map((item) => {
      const { formattedSourceValue, formattedTargetValue } =
        formatSourceAndTargetValues(item);

      return (
        <View style={styles.row} key={item.fieldName}>
          <Text style={styles.description}>{item.fieldName}</Text>
          <Text style={styles.value}>{formattedSourceValue}</Text>
          <Text style={styles.value}>{formattedTargetValue}</Text>
        </View>
      );
    })}
  </View>
);

export { PdfReportTable };
