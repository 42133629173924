import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPortfoliosProduction,
  getPortfoliosWorking,
  getAllPortfolioGroups,
  getPortfolioFeeTypes,
  getPortfolioStrategyGroupsFeeTypes,
} from 'services/portfolioCore/portfolioCore.service';
import { getPortfolioManagers } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import { getLastVersionPortfolio } from 'services/portfolioCore/portfolioCore.helper';
import { setPortfolioManagers } from 'store/slices/portfolioManagers/portfolioManagers.slice';
import {
  setIsLoading,
  setProductionPortfolio,
  setWorkingPortfolioAndFetchStrategies,
  setLastWorkingPortfolio,
  setInitialWorkingPortfolio,
  setStrategyGroups,
} from 'store/slices/portfolios';
import {
  getAllStrategiesByIds,
  getStrategyRelatedAttributes,
} from 'services/strategyCatalog/strategyCatalog.service';
import {
  FullPortfolioStrategy,
  initialPortfolioValues,
} from 'services/portfolioCore/portfolioCore.types';
import {
  setPortfolioFeeTypes,
  setProductionStrategyGroups,
  setStrategyGroupsFeeTypes,
} from 'store/slices/portfolios/portfolios.slice';
import {
  setSaraProductionStrategies,
  setStrategiesFeeTypes,
  setStrategyAttributes,
} from '../slices/strategies';

export const init = createAsyncThunk('init', async (_, { dispatch }) => {
  dispatch(setIsLoading(true));

  try {
    const [productionPortfolios, workingPortfolios, portfolioManagers] =
      await Promise.all([
        getPortfoliosProduction(),
        getPortfoliosWorking(),
        getPortfolioManagers(),
      ]);

    const initialProdPortfolios = productionPortfolios.length
      ? productionPortfolios
      : [initialPortfolioValues];
    const initialWorkPortfolios = workingPortfolios.length
      ? workingPortfolios
      : productionPortfolios;

    const lastProductionPortfolio = getLastVersionPortfolio(
      initialProdPortfolios
    );
    const lastWorkingPortfolio = getLastVersionPortfolio(initialWorkPortfolios);

    const portfolioProdStrategiesIds =
      lastProductionPortfolio.strategyAllocations.map(
        (current) => current.strategyId
      );

    const [strategyAttributes, portfolioFeeTypes, prodPortfolioStrategies] =
      await Promise.all([
        getStrategyRelatedAttributes(),
        getPortfolioFeeTypes(),
        getAllStrategiesByIds(portfolioProdStrategiesIds),
      ]);

    dispatch(setPortfolioManagers(portfolioManagers));

    if (lastWorkingPortfolio) {
      dispatch(setLastWorkingPortfolio(lastWorkingPortfolio));
    }

    const actualPortfolio = lastWorkingPortfolio || lastProductionPortfolio;

    const [strategyPortfolioGroups, feeTypes, prodStrategyGroups] =
      await Promise.all([
        getAllPortfolioGroups(actualPortfolio.id),
        getPortfolioStrategyGroupsFeeTypes(),
        getAllPortfolioGroups(lastProductionPortfolio.id),
      ]);

    const mappedProdStrategies: FullPortfolioStrategy[] =
      lastProductionPortfolio.strategyAllocations.map((portfolioStrategy) => {
        return {
          ...portfolioStrategy,
          strategy: prodPortfolioStrategies.find(
            (strategy) => strategy.id === portfolioStrategy.strategyId
          )!,
          strategyGroup: prodStrategyGroups.find(
            (current) => current.id === portfolioStrategy.strategyGroupId
          )!,
        };
      });

    dispatch(setProductionPortfolio(lastProductionPortfolio));

    dispatch(setSaraProductionStrategies(mappedProdStrategies));

    dispatch(setPortfolioFeeTypes(portfolioFeeTypes));
    dispatch(setStrategyGroups(strategyPortfolioGroups));
    dispatch(setProductionStrategyGroups(prodStrategyGroups));
    dispatch(setStrategyGroupsFeeTypes(feeTypes));
    dispatch(setStrategiesFeeTypes(feeTypes));
    dispatch(setInitialWorkingPortfolio(actualPortfolio));
    dispatch(setStrategyAttributes(strategyAttributes));
    dispatch(setWorkingPortfolioAndFetchStrategies(actualPortfolio));
  } finally {
    dispatch(setIsLoading(false));
  }
});
