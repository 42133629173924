import { useMemo } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { DrawerState } from 'components/StrategyList/useStrategyListDrawer';
import { AxiosError } from 'axios';
import i18n from 'i18next';
import {
  GlobalStrategiesResponse,
  StrategyAttribute,
  GlobalStrategiesRequest,
} from 'services/strategyCatalog/strategyCatalog.type';
import { updateGlobalStrategiesDetails } from 'store/thunks/updateGlobalStrategiesDetails';
import { GetTeam } from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { useAppDispatch } from 'store';
import { createNewGlobalStrategy } from 'store/thunks/createNewGlobalStrategy';
import { globalStrategyValidationSchema } from 'validation/globalStrategyValidationSchema';
import {
  GlobalStrategyDetailsEntity,
  VolatilityCapacityHelperProps,
  GlobalStrategyDetailsFormFields,
  TeamOption,
} from './GlobalStrategyDetails.type';
import { StrategyFormFields } from './GlobalStrategyDetails.constants';

type AlphaCategories = {
  scientist: number;
  engineer: number;
  actuary: number;
};

enum StrategyValidationErrors {
  UNIQUE_NAME_CONSTRAINT = 'UNIQUE_NAME_CONSTRAINT',
}

export const createTeamOptions = (teams: GetTeam[]): TeamOption[] =>
  teams
    .map((team) => ({
      label: team.name,
      id: team.id,
      team,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

const getTeamOptionById = (
  teamOptions: TeamOption[],
  portfolioManagerTeamId?: string
) => teamOptions.find(({ id }) => id === portfolioManagerTeamId) || null;

export const calculateVolatilityCapacity = ({
  aumCapacity,
  volatilityTarget,
}: VolatilityCapacityHelperProps) => {
  if (!aumCapacity || !volatilityTarget) {
    return 0;
  }

  return aumCapacity * volatilityTarget;
};

export const convertValuesToOptions = (values: StrategyAttribute[]) => {
  return values.reduce((acc: { [key: string]: string }, current) => {
    acc[current.id] = current.name;
    return acc;
  }, {});
};

const transformToDetails = (
  // TODO: Replace StrategyDetailsEntity & StrategyListEntity with StrategyEntity https://linear.app/clearalpha/issue/UX-818/replace-strategydetailsentity-and-strategylistentity-with
  strategy: GlobalStrategiesResponse
): GlobalStrategyDetailsEntity => {
  const alphaCategories: AlphaCategories = strategy?.alphaCategories.reduce(
    (acc: AlphaCategories, { name, value }) => {
      return { ...acc, [name.toLowerCase()]: value };
    },
    {} as AlphaCategories
  );
  return {
    id: strategy?.id,
    portfolioManagerTeamId: strategy?.portfolioManagerTeamId,
    copyOfId: strategy?.copyOfId,
    copyOfVersion: strategy?.copyOfVersion,
    name: strategy?.name,
    description: strategy?.description,
    strategy_edge: strategy?.strategyEdge,
    gross_sharpe: strategy?.grossSharpe,
    gross_sharpe_historic: strategy?.historicSharpeRatio,
    gross_sharpe_historic_unique: strategy?.grossSharpe,
    margin_type: strategy?.marginType.id,
    description_short: strategy?.assetCategory.id,
    category: strategy?.groupCharacteristic.id,
    scientist: alphaCategories?.scientist,
    engineer: alphaCategories?.engineer,
    actuary: alphaCategories?.actuary,
    average_hold_period: strategy?.averageHoldingPeriod.id,
    strategy_volatility_target: strategy?.volatilityTarget,
    allocation_min: strategy?.minAllocation,
    allocation_terms: strategy?.capacity,
    volatility_capacity: calculateVolatilityCapacity({
      aumCapacity: strategy?.capacity,
      volatilityTarget: strategy?.volatilityTarget,
    }),
  };
};

export const transformToRequest = (
  strategy: GlobalStrategyDetailsFormFields
): GlobalStrategiesRequest => {
  return {
    name: strategy.name,
    description: strategy.description,
    copyOfId: strategy.copyOfId,
    copyOfVersion: strategy.copyOfVersion,
    portfolioManagerTeamId: strategy.team.id,
    assetCategoryId: strategy.description_short,
    marginTypeId: strategy.margin_type,
    averageHoldingPeriodId: strategy.average_hold_period,
    groupCharacteristicId: strategy.category,
    alphaCategories: {
      SCIENTIST: strategy.scientist,
      ENGINEER: strategy.engineer,
      ACTUARY: strategy.actuary,
    },
    historicSharpeRatio: strategy.gross_sharpe_historic,
    grossSharpe: strategy.gross_sharpe,
    capacity: strategy.allocation_terms,
    minAllocation: strategy.allocation_min,
    volatilityTarget: strategy.strategy_volatility_target,
    proportionSystematic: 0,
    strategyEdge: strategy.strategy_edge,
  };
};

export const strategyToFormFields = (
  strategyDetails: GlobalStrategyDetailsEntity | null,
  currentTeamOption: TeamOption
): GlobalStrategyDetailsFormFields => ({
  [StrategyFormFields.TEAM]: currentTeamOption,
  [StrategyFormFields.NAME]: strategyDetails?.name || '',
  [StrategyFormFields.COPY_OF_ID]: strategyDetails?.copyOfId || null,
  [StrategyFormFields.COPY_OF_VERSION]: strategyDetails?.copyOfVersion || null,
  [StrategyFormFields.DESCRIPTION]: strategyDetails?.description || '',
  [StrategyFormFields.STRATEGY_EDGE]: strategyDetails?.strategy_edge || '',
  [StrategyFormFields.SCIENTIST]: strategyDetails?.scientist || 0,
  [StrategyFormFields.ENGINEER]: strategyDetails?.engineer || 0,
  [StrategyFormFields.ACTUARY]: strategyDetails?.actuary || 0,
  [StrategyFormFields.GROSS_SHARPE]: strategyDetails?.gross_sharpe || 0,
  [StrategyFormFields.GROSS_SHARPE_HISTORIC]:
    strategyDetails?.gross_sharpe_historic || 0,
  [StrategyFormFields.MARGIN_TYPE]: strategyDetails?.margin_type || '',
  [StrategyFormFields.DESCRIPTION_SHORT]:
    strategyDetails?.description_short || '',
  [StrategyFormFields.CATEGORY]: strategyDetails?.category || '',
  [StrategyFormFields.AVERAGE_HOLD_PERIOD]:
    strategyDetails?.average_hold_period || '',
  [StrategyFormFields.ALLOCATION_MIN]: strategyDetails?.allocation_min || 0,
  [StrategyFormFields.ALLOCATION_TERMS]: strategyDetails?.allocation_terms || 0,
  [StrategyFormFields.STRATEGY_VOLATILITY_TARGET]:
    strategyDetails?.strategy_volatility_target || 0,
  [StrategyFormFields.VOLATILITY_CAPACITY]: calculateVolatilityCapacity({
    aumCapacity: strategyDetails?.allocation_terms,
    volatilityTarget: strategyDetails?.strategy_volatility_target,
  }),
});

type HandleResponse = {
  payload: any;
  setFieldError: (field: string, message: string | undefined) => void;
  updateDrawer?: (state: Partial<DrawerState>) => void;
};

const handleResponse = ({
  payload,
  setFieldError,
  updateDrawer,
}: HandleResponse) => {
  if (payload instanceof AxiosError) {
    payload.response?.data.errors.forEach(
      ({ errorCode }: { errorCode: string }) => {
        if (errorCode === StrategyValidationErrors.UNIQUE_NAME_CONSTRAINT) {
          setFieldError(
            'name',
            i18n.t('errors.uniqueNameConstraint', {
              name: i18n.t('strategy').toLowerCase(),
            })
          );
        }
      }
    );
    return;
  }
  if (payload && updateDrawer) {
    updateDrawer({
      globalStrategyDetails: payload,
    });
  }
};

type StrategyDetailsFormProps = {
  strategy: GlobalStrategiesResponse | null;
  updateDrawer?: (state: Partial<DrawerState>) => void;
  teamOptions: TeamOption[];
  isCreateForm: boolean;
};

export const useGlobalStrategyDetailsForm = ({
  strategy,
  updateDrawer,
  teamOptions,
  isCreateForm,
}: StrategyDetailsFormProps) => {
  const dispatch = useAppDispatch();

  const strategyId = strategy?.id;

  const strategyDetails = useMemo(
    () => transformToDetails(strategy as GlobalStrategiesResponse),
    [strategy]
  );

  const currentTeamOption = useMemo(
    () =>
      getTeamOptionById(teamOptions, strategyDetails?.portfolioManagerTeamId),
    [teamOptions, strategyDetails]
  );

  const initialValues: GlobalStrategyDetailsFormFields = useMemo(
    () =>
      strategyToFormFields(strategyDetails, currentTeamOption as TeamOption),
    [strategyDetails, currentTeamOption]
  );

  // TODO UX-1400: Check integration when endpoints are up and running UX-1400
  const handleSubmit = async (
    values: GlobalStrategyDetailsFormFields,
    { setFieldError }: FormikHelpers<GlobalStrategyDetailsFormFields>
  ) => {
    const transformedStrategy = transformToRequest(values);

    if (isCreateForm) {
      const { payload } = await dispatch(
        createNewGlobalStrategy({
          newStrategy: transformedStrategy,
        })
      );
      handleResponse({
        payload,
        setFieldError,
        updateDrawer,
      });
    } else {
      const { payload } = await dispatch(
        updateGlobalStrategiesDetails({
          uuid: strategyId as string,
          updatedStrategy: transformedStrategy,
        })
      );
      handleResponse({
        payload,
        setFieldError,
        updateDrawer,
      });
    }
  };

  const formik = useFormik<GlobalStrategyDetailsFormFields>({
    initialValues,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validationSchema: globalStrategyValidationSchema,
  });

  return {
    formik,
    initialValues,
  };
};
