import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Button as BaseButton } from '@clearalpha/common/dist/components/Button';

const Container = styled.div`
  color: ${({ theme: { colors } }) => colors.onSurface};
  overflow: hidden;
  padding: 26px 24px;
  background-color: ${({ theme: { colors } }) => colors.surface};
  width: 100%;
  min-height: 140px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled(Typography)`
  margin-right: 32px;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 68px;
  line-height: 64px;
`;

const Button = styled(BaseButton)`
  min-width: 204px;
  min-height: 50px;
  padding: 10px 22px;
  background-color: ${({ theme: { colors } }) => colors.onSurface};
  .MuiTypography-root {
    font-size: 17px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
  }
`;

export { Container, Title, Button, Wrapper };
