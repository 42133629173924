import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Block,
  BlockTitle,
  Fields,
} from 'components/StrategyDetails/blocks/styled';
import {
  MinAllocation,
  AUMCapacity,
  VolatilityTarget,
  VolatilityCapacity,
} from './fields';

const Capacity = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <BlockTitle>{t('capacity')}</BlockTitle>
      <Fields>
        <MinAllocation />
        <AUMCapacity />
        <VolatilityTarget />
        <VolatilityCapacity />
      </Fields>
    </Block>
  );
};

export default Capacity;
