import { transformToPieChartWidgetData } from 'helpers';
import { ALPHA_CATEGORIES } from 'constants/strategy';
import { RiskAllocationPct } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { KEY_CATEGORY_COLORS } from './RiskAllocationWidget.const';

export const getRiskAllocationPieChartData = (data: RiskAllocationPct[]) => {
  return transformToPieChartWidgetData({
    data,
    keyField: 'groupName',
    percentageField: 'riskAllocationPct',
    locals: ALPHA_CATEGORIES,
    colors: KEY_CATEGORY_COLORS,
  });
};
