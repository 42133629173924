import { createSlice } from '@reduxjs/toolkit';
import { createHashMap } from 'helpers';
import { GetPortfolioResponse } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import {
  ToggleStrategiesAllocationsProps,
  UpdateStrategiesAllocationsProps,
} from 'components/StrategyList/StrategyList.type';
import {
  StrategyGroupsResponse,
  StrategyGroup,
  GetPortfolioStrategies,
  PortfolioFeeTypes,
} from 'services/portfolioCore/portfolioCore.types';
import { PortfolioPerformanceResponse } from 'services/fundCalculator/fundCalculator.types';
import { GroupDetailsFields } from 'components/ManageGroupDetails/ManageGroupDetails.type';
import {
  PORTFOLIOS_SLICE_NAME,
  PORTFOLIOS_SLICE_INITIAL_STATE,
} from './portfolios.constants';
import { PortfoliosState } from './portfolios.types';
import {
  addFeesToGroup,
  changeStrategiesAllocations,
} from './portfolios.helpers';

const portfolios = createSlice({
  name: PORTFOLIOS_SLICE_NAME,
  initialState: PORTFOLIOS_SLICE_INITIAL_STATE,
  reducers: {
    setIsLoading: (state: PortfoliosState, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setIsLoadingOptimization: (
      state: PortfoliosState,
      action: { payload: boolean }
    ) => {
      state.isOptimizationLoading = action.payload;
    },
    setIsOpenConfirmationDialog: (
      state: PortfoliosState,
      action: { payload: boolean }
    ) => {
      state.isConfirmationDialogActive = action.payload;
    },
    setProductionPortfolio: (
      state: PortfoliosState,
      action: { payload: GetPortfolioResponse }
    ) => {
      state.productionPortfolio = action.payload;
    },
    setWorkingPortfolio: (
      state: PortfoliosState,
      action: { payload: GetPortfolioResponse }
    ) => {
      state.workingPortfolio = action.payload;
    },
    setLastWorkingPortfolio: (
      state: PortfoliosState,
      action: { payload: GetPortfolioResponse }
    ) => {
      state.lastWorkingPortfolio = action.payload;
    },
    setWorkingPortfolioAndFetchStrategies: (
      state: PortfoliosState,
      action: { payload: GetPortfolioResponse }
    ) => {
      state.workingPortfolio = action.payload;
    },
    setProductionPortfolioAndFetchPerformance: (
      state: PortfoliosState,
      action: { payload: GetPortfolioResponse }
    ) => {
      state.productionPortfolio = action.payload;
    },
    addNewStrategyToPortfolio: (
      state: PortfoliosState,
      action: { payload: { strategy: GetPortfolioStrategies } }
    ) => {
      // TODO: Update in accordance with new strategy allocation interface
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.workingPortfolio.strategyAllocations.push(action.payload.strategy);
    },
    removeStrategyFromPortfolio: (
      state: PortfoliosState,
      action: { payload: { strategyId: string } }
    ) => {
      state.workingPortfolio = {
        ...state.workingPortfolio,
        strategyAllocations: state.workingPortfolio.strategyAllocations.filter(
          ({ globalStrategyId }) =>
            action.payload.strategyId !== globalStrategyId
        ),
      };
    },
    addNewStrategyAndGroupToPortfolio: (
      state: PortfoliosState,
      action: {
        payload: {
          newStrategy: GetPortfolioStrategies;
          strategyGroup: StrategyGroup;
        };
      }
    ) => {
      state.workingPortfolio.strategyAllocations.push(
        action.payload.newStrategy
      );
      state.workingPortfolio.strategyGroups.push(action.payload.strategyGroup);
    },
    changeWorkingPortfolio: (
      state: PortfoliosState,
      action: { payload: GetPortfolioResponse }
    ) => {
      state.workingPortfolio = action.payload;
    },
    setInitialWorkingPortfolio: (
      state: PortfoliosState,
      action: { payload: GetPortfolioResponse }
    ) => {
      state.initialWorkingPortfolio = action.payload;
    },
    setProductionPortfolioPerformance: (
      state: PortfoliosState,
      action: { payload: PortfolioPerformanceResponse }
    ) => {
      state.productionPortfolioPerformance = action.payload;
    },
    setWorkingPortfolioPerformance: (
      state: PortfoliosState,
      action: { payload: PortfolioPerformanceResponse }
    ) => {
      state.workingPortfolioPerformance = action.payload;
    },
    setMaxCapitalRisk: (
      state: PortfoliosState,
      action: { payload: number }
    ) => {
      state.lastWorkingPortfolio.constraints.targetMaxCapitalAtRisk =
        action.payload;
    },
    setMaxRiskConcentration: (
      state: PortfoliosState,
      action: { payload: number }
    ) => {
      state.lastWorkingPortfolio.constraints.targetMaxPLRiskCR = action.payload;
    },
    setWorkingPortfolioOptimizations: (
      state: PortfoliosState,
      action: { payload: PortfolioPerformanceResponse }
    ) => {
      state.workingPortfolioOptimizations = action.payload;
    },
    changeAssetsUnderManagementAndRunCalculation: (
      state: PortfoliosState,
      action: { payload: number }
    ) => {
      state.workingPortfolio.assetsUnderManagement = action.payload;
    },
    changeCrossMarginBenefitAndRunCalculation: (
      state: PortfoliosState,
      action: { payload: number }
    ) => {
      state.workingPortfolio.crossMarginBenefit = action.payload;
    },
    setStrategiesAllocations: (
      state: PortfoliosState,
      action: {
        payload: UpdateStrategiesAllocationsProps[];
      }
    ) => {
      changeStrategiesAllocations(state, action.payload);
    },
    changeStrategiesAllocationsAndRunCalculation: (
      state: PortfoliosState,
      action: {
        payload: UpdateStrategiesAllocationsProps[];
      }
    ) => {
      changeStrategiesAllocations(state, action.payload);
    },
    toggleLockStrategiesAllocations: (
      state: PortfoliosState,
      action: {
        payload: ToggleStrategiesAllocationsProps[];
      }
    ) => {
      const strategiesHashMap = createHashMap('strategyId', action.payload);

      state.workingPortfolio.strategyAllocations.forEach((strategy) => {
        const changedStrategy = strategiesHashMap[strategy.strategyId];

        if (changedStrategy) {
          strategy.isLocked = changedStrategy.isLocked;
        }

        if (!changedStrategy && strategiesHashMap[strategy.id]) {
          strategy.isLocked = strategiesHashMap[strategy.id].isLocked;
        }
      });
    },
    setStrategyGroups: (
      state: PortfoliosState,
      action: {
        payload: StrategyGroupsResponse[];
      }
    ) => {
      state.strategyGroups = action.payload;
    },
    setSourceStrategyGroups: (
      state: PortfoliosState,
      action: {
        payload: StrategyGroupsResponse[];
      }
    ) => {
      state.sourceStrategyGroups = action.payload;
    },
    setProductionStrategyGroups: (
      state: PortfoliosState,
      action: {
        payload: StrategyGroupsResponse[];
      }
    ) => {
      state.productionStrategyGroups = action.payload;
    },
    deleteStrategyFromStrategyGroup: (
      state: PortfoliosState,
      action: {
        payload: { strategyId: string; strategyGroupId: string };
      }
    ) => {
      const strategyGroup = state.strategyGroups.find(
        ({ id }) => action.payload.strategyGroupId === id
      );
      if (strategyGroup)
        strategyGroup.strategies = strategyGroup.strategies.filter(
          ({ globalStrategyId }) =>
            globalStrategyId !== action.payload.strategyId
        );
    },
    setStrategyGroupsFeeTypes: (
      state: PortfoliosState,
      action: {
        payload: PortfolioFeeTypes[];
      }
    ) => {
      state.strategyGroupFeeTypes = action.payload;
    },
    addNewStrategyGroup: (
      state: PortfoliosState,
      action: {
        payload: StrategyGroupsResponse;
      }
    ) => {
      state.strategyGroups.push(action.payload);
    },
    addNewStrategyToStrategyGroup: (
      state: PortfoliosState,
      action: {
        payload: {
          strategyGroupToUpdateId: string;
          newStrategy: Omit<GetPortfolioStrategies, 'strategyGroup'>;
        };
      }
    ) => {
      const stratGroupToUpdate = state.strategyGroups.find(
        (current) => current.id === action.payload.strategyGroupToUpdateId
      );
      stratGroupToUpdate?.strategies.push(action.payload.newStrategy);
    },
    updateManageGroupDetails: (
      state: PortfoliosState,
      action: {
        payload: {
          newValues: GroupDetailsFields;
          groupIdToUpdate: string;
        };
      }
    ) => {
      const groupInPortfolio = state.workingPortfolio.strategyGroups.find(
        (current) => current.id === action.payload.groupIdToUpdate
      );

      const stratGroupToUpdate = state.strategyGroups.find(
        (current) => current.id === action.payload.groupIdToUpdate
      );

      const feeTypes = state.strategyGroupFeeTypes;

      if (stratGroupToUpdate && groupInPortfolio) {
        if (!stratGroupToUpdate.fees.length)
          addFeesToGroup(stratGroupToUpdate, feeTypes);
        if (!groupInPortfolio.fees.length)
          addFeesToGroup(groupInPortfolio, feeTypes);

        stratGroupToUpdate.fees.forEach((current) => {
          if (current.fee.name === 'Management fee') {
            current.value = action.payload.newValues.mgmtFee;
          }
          if (current.fee.name === 'Performance fee') {
            current.value = action.payload.newValues.perfFee;
          }
          if (current.fee.name === 'Fixed expense budget') {
            current.value = action.payload.newValues.fixedExp;
          }
        });
        stratGroupToUpdate.name = action.payload.newValues.groupName;

        groupInPortfolio.fees.forEach((current) => {
          if (current.fee.name === 'Management fee') {
            current.value = action.payload.newValues.mgmtFee;
          }
          if (current.fee.name === 'Performance fee') {
            current.value = action.payload.newValues.perfFee;
          }
          if (current.fee.name === 'Fixed expense budget') {
            current.value = action.payload.newValues.fixedExp;
          }
        });

        groupInPortfolio.name = action.payload.newValues.groupName;
      }
    },
    setOptimizedAllocationsAndRunCalculation: (state: PortfoliosState) => {
      const optimizedAllocationsHashMap = createHashMap(
        'strategyId',
        state.workingPortfolioOptimizations.strategyAllocation
      );

      state.workingPortfolio.strategyAllocations.forEach((strategy) => {
        const { isLocked, id, notionalAccountValue } = strategy;
        const optimizedNotionalAccountValue =
          optimizedAllocationsHashMap[id].notionalAccountValue;

        if (
          !isLocked &&
          notionalAccountValue !== optimizedNotionalAccountValue
        ) {
          strategy.notionalAccountValue = optimizedNotionalAccountValue;
        }
      });
    },
    deleteStrategyGroup: (
      state: PortfoliosState,
      action: { payload: string }
    ) => {
      state.strategyGroups = state.strategyGroups.filter(
        (group) => group.id !== action.payload
      );
    },
    setReviewPortfolioMode: (
      state: PortfoliosState,
      action: { payload: boolean }
    ) => {
      state.isReviewMode = action.payload;
    },
    setSourceWorkingPortfolio: (
      state: PortfoliosState,
      action: { payload: GetPortfolioResponse }
    ) => {
      state.sourcePortfolio = action.payload;
    },
    setTargetProductionPortfolio: (
      state: PortfoliosState,
      action: { payload: GetPortfolioResponse }
    ) => {
      state.targetPortfolio = action.payload;
    },
    setSourceWorkingPortfolioPerformance: (
      state: PortfoliosState,
      action: { payload: PortfolioPerformanceResponse }
    ) => {
      state.sourcePortfolioPerformance = action.payload;
    },
    setTargetProductionPortfolioPerformance: (
      state: PortfoliosState,
      action: { payload: PortfolioPerformanceResponse }
    ) => {
      state.targetPortfolioPerformance = action.payload;
    },
    setSourceWorkingPortfolioOptimizations: (
      state: PortfoliosState,
      action: { payload: PortfolioPerformanceResponse }
    ) => {
      state.sourcePortfolioOptimizations = action.payload;
    },
    setPortfolioFeeTypes: (
      state: PortfoliosState,
      action: { payload: PortfolioFeeTypes[] }
    ) => {
      state.portfolioFeeTypes = action.payload;
    },
    assignStrategyToAnotherGroup: (
      state: PortfoliosState,
      action: {
        payload: {
          oldStrategyGroupId: string;
          strategyId: string;
          newStrategyGroupId: string;
        };
      }
    ) => {
      const strategyAllocationToReAssign =
        state.workingPortfolio.strategyAllocations.find((current) => {
          return current.id === action.payload.strategyId;
        });
      const oldStrategyGroup = state.strategyGroups.find(
        (current) => current.id === action.payload.oldStrategyGroupId
      );
      const newStrategyGroup = state.strategyGroups.find(
        (current) => current.id === action.payload.newStrategyGroupId
      );
      if (
        strategyAllocationToReAssign &&
        oldStrategyGroup &&
        newStrategyGroup
      ) {
        strategyAllocationToReAssign.strategyGroupId =
          action.payload.newStrategyGroupId;
        let strategy;

        oldStrategyGroup.strategies = oldStrategyGroup.strategies.filter(
          (current) => {
            if (current.id === action.payload.strategyId) {
              strategy = current;
            }
            return current.id !== action.payload.strategyId;
          }
        );
        if (strategy) {
          newStrategyGroup.strategies.push(strategy);
        }
      }
    },
  },
});

export const {
  setProductionPortfolio,
  setWorkingPortfolio,
  setWorkingPortfolioAndFetchStrategies,
  setProductionPortfolioPerformance,
  setWorkingPortfolioPerformance,
  setWorkingPortfolioOptimizations,
  setStrategiesAllocations,
  changeAssetsUnderManagementAndRunCalculation,
  changeCrossMarginBenefitAndRunCalculation,
  setOptimizedAllocationsAndRunCalculation,
  changeStrategiesAllocationsAndRunCalculation,
  toggleLockStrategiesAllocations,
  changeWorkingPortfolio,
  setIsLoading,
  setIsLoadingOptimization,
  setIsOpenConfirmationDialog,
  setLastWorkingPortfolio,
  setInitialWorkingPortfolio,
  setMaxCapitalRisk,
  setMaxRiskConcentration,
  addNewStrategyToPortfolio,
  setStrategyGroups,
  setStrategyGroupsFeeTypes,
  setReviewPortfolioMode,
  addNewStrategyGroup,
  addNewStrategyAndGroupToPortfolio,
  removeStrategyFromPortfolio,
  addNewStrategyToStrategyGroup,
  setSourceWorkingPortfolio,
  setTargetProductionPortfolio,
  setSourceWorkingPortfolioOptimizations,
  setTargetProductionPortfolioPerformance,
  setSourceWorkingPortfolioPerformance,
  updateManageGroupDetails,
  deleteStrategyGroup,
  setPortfolioFeeTypes,
  setProductionStrategyGroups,
  deleteStrategyFromStrategyGroup,
  assignStrategyToAnotherGroup,
  setSourceStrategyGroups,
  setProductionPortfolioAndFetchPerformance,
} = portfolios.actions;

export default portfolios.reducer;
