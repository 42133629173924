export enum TeamDetailsFormFieldName {
  NAME = 'name',
  STATUS = 'status',
  DESCRIPTION = 'description',
  ARCESIUM_ID = 'arcesiumId',
  TYPE = 'type',
  TOTAL_PERSONNEL = 'totalPersonnel',
  INVESTMENT_PROFESSIONALS = 'investmentProfessionals',
  MEMBERS = 'members',
  TEAM_LEAD_ID = 'teamLeadId',
}
