import styled from '@emotion/styled';

const Description = styled.p`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  opacity: 0.87;
  text-align: justify;

  &.highlighted {
    background-color: ${({ theme: { colors } }) => colors.highlightColor};
  }
`;

export { Description };
