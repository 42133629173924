import React from 'react';
import { useSelector } from 'react-redux';
import { ModeEditIcon } from '@clearalpha/common';
import { useFormikContext } from 'formik';
import { FormikText, FormikValue } from 'components/formik/fields';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { ARCESIUM_ID } from 'constants/fields';
import { StrategyDetailsFormFields } from '../../StrategyDetails.type';
import { StrategyGroupProps } from './StrategyId.type';
import { StrategyFormFields } from '../../StrategyDetails.constants';
import { IconButton, IdInputWrapper, IdFieldWrapper } from '../styled';

const StrategyId = ({
  isStrategyIdEditMode,
  handleStrategyIdEditModeClick,
}: StrategyGroupProps) => {
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.arcesiumId &&
    values.productionValues.arcesiumId !== values.arcesiumId;

  return (
    <IdFieldWrapper>
      {isStrategyIdEditMode ? (
        <FormikText
          label={ARCESIUM_ID}
          placeholder={ARCESIUM_ID}
          name={StrategyFormFields.ARCESIUM_ID}
          fullWidth
        />
      ) : (
        <>
          <IdInputWrapper className={isValueHighlighted ? 'highlighted' : ''}>
            <FormikValue
              name={StrategyFormFields.ARCESIUM_ID}
              format={(value) => value || ARCESIUM_ID}
            />
          </IdInputWrapper>
          <IconButton
            onClick={handleStrategyIdEditModeClick}
            disabled={isPortfolioReviewMode}
          >
            <ModeEditIcon />
          </IconButton>
        </>
      )}
    </IdFieldWrapper>
  );
};

export default StrategyId;
