import { UpdateStrategiesAllocationsProps } from 'components/StrategyList/StrategyList.type';
import { createHashMap } from 'helpers';
import {
  PortfolioFeeTypes,
  StrategyGroup,
  StrategyGroupsResponse,
} from 'services/portfolioCore/portfolioCore.types';
import { PortfoliosState } from './portfolios.types';
import { ZERO } from '../../../validation/validationNumbers';

export const changeStrategiesAllocations = (
  state: PortfoliosState,
  changedStrategies: UpdateStrategiesAllocationsProps[]
) => {
  const strategiesHashMap = createHashMap('strategyId', changedStrategies);
  state.workingPortfolio.strategyAllocations.forEach((strategy) => {
    const changedStrategy = strategiesHashMap[strategy.id];
    if (changedStrategy) {
      strategy.notionalAccountValue = changedStrategy.notionalAccountValue;

      if (changedStrategy.newStrategyId) {
        strategy.strategyId = changedStrategy.newStrategyId;
      }
    }
  });
};

export const addFeesToGroup = (
  group: StrategyGroupsResponse | StrategyGroup,
  fees: PortfolioFeeTypes[]
): void => {
  fees.forEach((fee) => {
    group.fees.push({ fee: { ...fee, deletedAt: null }, value: ZERO });
  });
};
