import { isString } from 'formik';
import { ROLES, ROLES_PATH, ROLES_PATH_SECONDARY } from 'constants/roles';

export const createHashMap = (
  fieldNameAsKey: string,
  arrayToTransform: any[],
  fieldNameAsValue?: any
) =>
  arrayToTransform.reduce((acc, current) => {
    acc[current[fieldNameAsKey]] = fieldNameAsValue
      ? current[fieldNameAsValue]
      : current;
    return acc;
  }, {} as any);

export const transformToPieChartWidgetData = ({
  data,
  keyField,
  percentageField,
  locals,
  colors,
}: {
  data: any[];
  keyField: string;
  percentageField: string;
  locals?: Record<string, string>;
  colors?: Record<string, string>;
}) =>
  data.map((current) => ({
    key: locals?.[current[keyField]] || current[keyField],
    percentage: current[percentageField],
    color: colors?.[current[keyField]],
  }));

export const cloneDeepArray = (arr: any[]) => {
  return JSON.parse(JSON.stringify(arr));
};

const isRole = (value: unknown): boolean =>
  isString(value) && Object.values(ROLES).includes(value as ROLES);

export const extractRolesFromJwt = (jwtToken: string): ROLES[] => {
  const tokens = jwtToken.split('.');
  const decodedJwtData = JSON.parse(window.atob(tokens[1]));
  let decodedJwtDataRoles = decodedJwtData?.[ROLES_PATH];

  const NO_ROLES: ROLES[] = [];

  if (!decodedJwtDataRoles || !isString(decodedJwtDataRoles)) {
    decodedJwtDataRoles = decodedJwtData?.[ROLES_PATH_SECONDARY];
  }

  if (!decodedJwtDataRoles || !isString(decodedJwtDataRoles)) return NO_ROLES;

  const hasMultipleRoles = decodedJwtDataRoles.startsWith('[');
  const rolesDecoded = hasMultipleRoles
    ? decodedJwtDataRoles.slice(1, decodedJwtDataRoles.length - 1).split(', ')
    : [decodedJwtDataRoles];

  if (!rolesDecoded || !Array.isArray(rolesDecoded)) return NO_ROLES;

  return rolesDecoded.filter(isRole).map((r) => r as ROLES);
};
