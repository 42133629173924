import { transformToPieChartWidgetData } from 'helpers';
import { STRATEGY_CATEGORIES } from 'constants/strategy';
import { AssetCategoryPct } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';

export const getStrategyCategoriesWidgetData = (data: AssetCategoryPct[]) => {
  return {
    data: transformToPieChartWidgetData({
      data,
      keyField: 'groupName',
      percentageField: 'assetCategoryPct',
      locals: STRATEGY_CATEGORIES,
    }),
  };
};
