import { FieldValueEnum, RowType } from '@clearalpha/common';
import { PerformanceStats } from 'services/fundCalculator/fundCalculator.types';
import i18n from 'translations/i18n';

export const PERFORMANCE_STATS_WIDGET_CONFIG: RowType<
  keyof PerformanceStats
>[] = [
  {
    fieldName: i18n.t('widgets.projectedPerformance.expNetTotalReturn'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
    variableName: 'expNetTotalReturnPct',
  },
  {
    fieldName: i18n.t('widgets.projectedPerformance.expNetExcessReturn'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
    variableName: 'expNetExcessReturnPct',
  },
  {
    fieldName: i18n.t('widgets.projectedPerformance.expVol'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
    variableName: 'expVolPct',
  },
  {
    fieldName: i18n.t('widgets.projectedPerformance.expNetSharpe'),
    hasDelimiter: true,
    type: FieldValueEnum.NUMERIC_TEXT_DECIMAL,
    variableName: 'expNetSharpe',
  },
  {
    fieldName: i18n.t('widgets.projectedPerformance.probLessTen'),
    hasDelimiter: true,
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
    variableName: 'probabilityLess10Pct',
  },
  {
    fieldName: i18n.t('widgets.projectedPerformance.capitalAtRisk'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
    variableName: 'capitalAtRisk',
  },
  {
    fieldName: i18n.t('widgets.projectedPerformance.maxCapitalAtRisk'),
    type: FieldValueEnum.NUMERIC_TEXT_PERCENT,
    variableName: 'maxCapitalRisk',
  },
];
