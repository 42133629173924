export const STRATEGY_LIBRARY_HEADER_LOCALS = {
  STRATEGY_LIBRARY: 'Strategy Library',
  CREATE_STRATEGY: 'Create Strategy',
  TEAM_MEMBERS: 'User Directory',
  CREATE_MANAGER: 'Add new team member',
  TEAM_DIRECTORY: 'Team Directory',
  CREATE_TEAM: 'Add team',
  CREATE_GROUP: 'Create new group',
  STRATEGY_GROUPS: 'Strategy Groups',
};
