import isEqual from 'lodash.isequal';
import { GetPortfolioResponse } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import {
  FullPortfolioStrategy,
  GetPortfolioStrategies,
  StrategyGroupsResponse,
} from 'services/portfolioCore/portfolioCore.types';
import { getFeeValue } from 'services/portfolioCore/portfolioCore.helper';
import { IsEqualPortfoliosParamsProps } from './PromotePortfolio.types';

const getStrategiesDiff = (
  portfolioAStrategies: FullPortfolioStrategy[],
  portfolioBStrategies: FullPortfolioStrategy[]
): {
  strategiesADiff: FullPortfolioStrategy[];
  strategiesBDiff: FullPortfolioStrategy[];
} => {
  const strategiesAMap = new Map<string, FullPortfolioStrategy>();

  portfolioAStrategies.forEach((strategy) => {
    strategiesAMap.set(strategy.globalStrategyId, strategy);
  });

  const strategiesBDiff = portfolioBStrategies.reduce((acc, strategy) => {
    const { globalStrategyId, strategy: strategyBParams } = strategy;

    if (!strategiesAMap.has(globalStrategyId)) {
      return [...acc, strategy];
    }

    const strategyToCompare = strategiesAMap.get(
      globalStrategyId
    ) as FullPortfolioStrategy;

    const {
      strategy: {
        id,
        description,
        name,
        deletedAt,
        ...strategyAParamsToCompare
      },
    } = strategyToCompare;

    const {
      id: bId,
      description: bDescription,
      name: bName,
      deletedAt: bDeletedAt,
      ...strategyBParamsToCompare
    } = strategyBParams;

    if (!isEqual(strategyAParamsToCompare, strategyBParamsToCompare)) {
      acc.push(strategy);
    }

    strategiesAMap.delete(globalStrategyId);
    return acc;
  }, [] as FullPortfolioStrategy[]);

  const strategiesADiff: FullPortfolioStrategy[] = [];
  strategiesAMap.forEach((value) => strategiesADiff.push(value));

  return {
    strategiesADiff,
    strategiesBDiff,
  };
};

const getGroupsDiff = (
  strategyGroupsA: StrategyGroupsResponse[],
  strategyGroupsB: StrategyGroupsResponse[]
) => {
  const copyOfstrategyGroupsA: StrategyGroupsResponse[] = JSON.parse(
    JSON.stringify(strategyGroupsA)
  );
  const copyOfstrategyGroupsB: StrategyGroupsResponse[] = JSON.parse(
    JSON.stringify(strategyGroupsB)
  );
  if (copyOfstrategyGroupsA.length !== copyOfstrategyGroupsB.length) {
    return copyOfstrategyGroupsB;
  }

  const strategyGroupsAMap = new Map<string, StrategyGroupsResponse>();

  copyOfstrategyGroupsA.forEach((group) => {
    strategyGroupsAMap.set(group.strategyGroupOriginId, group);
  });

  return copyOfstrategyGroupsB.reduce(
    (acc: StrategyGroupsResponse[], itemB) => {
      if (!strategyGroupsAMap.has(itemB.strategyGroupOriginId)) {
        acc.push(itemB);
        return acc;
      }

      const itemA = strategyGroupsAMap.get(itemB.strategyGroupOriginId);

      if (!itemA) return acc;

      if (itemA.name !== itemB.name) {
        acc.push(itemB);
        return acc;
      }

      const feeMapA = itemA.fees.reduce(
        (feeMap: { [key: string]: number }, current) => {
          feeMap[current.fee.name] = current.value;
          return feeMap;
        },
        {}
      );

      for (let i = 0; i < itemB.fees.length; i++) {
        if (feeMapA[itemB.fees[i].fee.name] !== itemB.fees[i].value) {
          acc.push(itemB);
          break;
        }
      }

      if (itemA.strategies.length !== itemB.strategies.length) {
        acc.push(itemB);
        return acc;
      }

      const sortedStrategiesA = itemA.strategies.sort((a, b) =>
        a.globalStrategyId.localeCompare(b.globalStrategyId)
      );
      const sortedStrategiesB = itemB.strategies.sort((a, b) =>
        a.globalStrategyId.localeCompare(b.globalStrategyId)
      );

      sortedStrategiesA.forEach((strategyA, index) => {
        if (
          strategyA.globalStrategyId !==
          sortedStrategiesB[index].globalStrategyId
        ) {
          acc.push(itemB);
          return acc;
        }
      });

      return acc;
    },
    []
  );
};

export const getStrategiesAllocationsDiff = (
  strategyAllocationsA: GetPortfolioStrategies[],
  strategyAllocationsB: GetPortfolioStrategies[]
): {
  strategyAllocationsADiff: GetPortfolioStrategies[];
  strategyAllocationsBDiff: GetPortfolioStrategies[];
} => {
  const strategyAllocationsAMap = new Map<string, GetPortfolioStrategies>();

  strategyAllocationsA.forEach((allocation) => {
    strategyAllocationsAMap.set(allocation.globalStrategyId, allocation);
  });

  const strategyAllocationsBDiff = strategyAllocationsB.reduce(
    (acc, allocation) => {
      const { globalStrategyId, isLocked, notionalAccountValue } = allocation;

      if (!strategyAllocationsAMap.has(globalStrategyId)) {
        return [...acc, allocation];
      }

      const allocationsToCompare = strategyAllocationsAMap.get(
        globalStrategyId
      ) as GetPortfolioStrategies;

      if (
        allocationsToCompare.isLocked !== isLocked ||
        allocationsToCompare.notionalAccountValue !== notionalAccountValue
      ) {
        acc.push(allocation);
      }

      strategyAllocationsAMap.delete(globalStrategyId);

      return acc;
    },
    [] as GetPortfolioStrategies[]
  );

  const strategyAllocationsADiff: GetPortfolioStrategies[] = [];
  strategyAllocationsAMap.forEach((value) =>
    strategyAllocationsADiff.push(value)
  );

  return {
    strategyAllocationsADiff,
    strategyAllocationsBDiff,
  };
};

const getPortfolioMainParams = ({
  assetsUnderManagement,
  averageCorrelation,
  constraints,
  crossMarginBenefit,
  crossCapitalAtRiskBenefit,
  fees,
  freeCashFromVolMultiplier,
  minFreeCashFromAum,
  tbillYield,
}: GetPortfolioResponse) => {
  return {
    assetsUnderManagement,
    averageCorrelation,
    constraints,
    crossMarginBenefit,
    crossCapitalAtRiskBenefit,
    performanceFee: getFeeValue('Performance Fee', fees),
    fixedExpenses: getFeeValue('Platform Fixed Expenses', fees),
    freeCashFromVolMultiplier,
    minFreeCashFromAum,
    tbillYield,
  };
};

export const isEqualPortfoliosParams = ({
  portfolioA,
  portfolioB,
  portfolioAStrategies,
  portfolioBStrategies,
  scenarioGroups,
  productionGroups,
}: IsEqualPortfoliosParamsProps) => {
  const { strategyAllocations: portfolioAStrategyAllocations } = portfolioA;
  const { strategyAllocations: portfolioBStrategyAllocations } = portfolioB;

  if (
    !isEqual(
      getPortfolioMainParams(portfolioA),
      getPortfolioMainParams(portfolioB)
    )
  ) {
    return false;
  }

  const isEqualStrategyAllocationsCount =
    portfolioAStrategyAllocations.length ===
    portfolioBStrategyAllocations.length;

  if (!isEqualStrategyAllocationsCount) {
    return false;
  }

  const { strategyAllocationsADiff, strategyAllocationsBDiff } =
    getStrategiesAllocationsDiff(
      portfolioAStrategyAllocations,
      portfolioBStrategyAllocations
    );

  if (!!strategyAllocationsADiff.length || !!strategyAllocationsBDiff.length) {
    return false;
  }

  const { strategiesADiff, strategiesBDiff } = getStrategiesDiff(
    portfolioAStrategies,
    portfolioBStrategies
  );

  const groupsDiff = getGroupsDiff(scenarioGroups, productionGroups);

  return (
    !strategiesADiff.length && !strategiesBDiff.length && !groupsDiff.length
  );
};
