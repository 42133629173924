export const MINUS_TEN = -10;
export const MINUS_ONE = -1;
export const ZERO = 0;
export const ONE = 1;
export const TEN = 10;
export const ONE_HUNDRED = 100;
export const ONE_BILLION = 1000000000;
export const TEN_BILLION = 10000000000;
export const ONE_HUNDRED_BILLION = 100000000000;
export const TRILLION = 1000000000000;
