import { createSlice } from '@reduxjs/toolkit';
import { ChangeRequestResponse } from 'services/portfolioCore/portfolioCore.types';
import { ChangeRequestsState } from './changeRequests.types';
import {
  CHANGE_REQUESTS_SLICE_INITIAL_STATE,
  CHANGE_REQUESTS_SLICE_NAME,
} from './changeRequests.constants';

const changeRequests = createSlice({
  name: CHANGE_REQUESTS_SLICE_NAME,
  initialState: CHANGE_REQUESTS_SLICE_INITIAL_STATE,
  reducers: {
    setChangeRequests: (
      state: ChangeRequestsState,
      action: { payload: ChangeRequestResponse[] }
    ) => {
      state.changeRequests = action.payload;
    },
    setSelectedRequest: (
      state: ChangeRequestsState,
      action: { payload: ChangeRequestResponse }
    ) => {
      state.currentRequest = action.payload;
    },
    removeChangeRequest: (
      state: ChangeRequestsState,
      action: { payload: string }
    ) => {
      state.changeRequests = state.changeRequests.filter(
        ({ id }) => id !== action.payload
      );
      state.currentRequest = null;
    },
  },
});

export const { setChangeRequests, setSelectedRequest, removeChangeRequest } =
  changeRequests.actions;

export default changeRequests.reducer;
