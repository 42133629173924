import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { ModeEditIcon } from '@clearalpha/common';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { FormikAutocomplete, FormikValue } from 'components/formik/fields';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import {
  StrategyDetailsFormFields,
  StrategyGroupOption,
} from '../../StrategyDetails.type';
import { StrategyGroupProps } from './StrategyGroup.type';
import { StrategyFormFields } from '../../StrategyDetails.constants';
import { Button, IconButton, FieldWrapper } from '../styled';

const StrategyGroup = ({
  strategyGroupOptions,
  isStrategyGroupEditMode,
  handleStrategyGroupEditModeClick,
  handleStrategyGroupRedirectClick,
}: StrategyGroupProps) => {
  const { t } = useTranslation();

  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.productionValues.strategyGroup?.name !==
      values.strategyGroup?.strategyGroup.name;

  return (
    <Box mb='20px'>
      {isStrategyGroupEditMode ? (
        <FormikAutocomplete<StrategyGroupOption>
          options={strategyGroupOptions}
          label={t('strategyGroup')}
          name={StrategyFormFields.STRATEGY_GROUP}
        />
      ) : (
        <>
          <Button
            onClick={handleStrategyGroupRedirectClick}
            data-testid={t('actions.viewGroupDetails')}
            variant='text'
          >
            <FieldWrapper className={isValueHighlighted ? 'highlighted' : ''}>
              <FormikValue
                name={StrategyFormFields.STRATEGY_GROUP}
                format={(value) => value?.label}
              />
            </FieldWrapper>
          </Button>
          <IconButton
            onClick={handleStrategyGroupEditModeClick}
            disabled={isPortfolioReviewMode}
          >
            <ModeEditIcon />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default StrategyGroup;
