import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Button as BaseButton, Select } from '@clearalpha/common';
import { BreakpointToChangeDirection } from './WorkspaceHeader.type';

const Container = styled.div`
  color: ${({ theme: { colors } }) => colors.onSurface};
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 26px 24px;
  background-color: ${({ theme: { colors } }) => colors.surface};
  width: 100%;
  min-height: 168px;
  gap: 50px;
  @media (max-width: ${BreakpointToChangeDirection}px) {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex: 1;
`;

const FundMenu = styled(Select)`
  margin-right: 32px;
`;

const RightSection = styled(LeftSection)`
  display: flex;
  gap: 30px;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;

const Title = styled(Typography)`
  margin-right: 32px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 52px;
`;

const GroupTitle = styled(Title)`
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 22px;
  white-space: nowrap;
`;

const TitleItem = styled(Title)`
  margin-right: 0;
  font-size: 28px;
  line-height: 36px;
  :not(:last-child) {
    margin-right: 24px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 10px;
`;

const Button = styled(BaseButton)`
  min-height: 60px;
  padding: 10px 22px;
  .MuiTypography-root {
    font-size: 18px;
    font-family: 'Work Sans';
    font-weight: 500;
  }
`;

export {
  Container,
  LeftSection,
  RightSection,
  Title,
  GroupTitle,
  TitleItem,
  ButtonGroup,
  Button,
  FundMenu,
};
