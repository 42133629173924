import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SensitiveDataBlock } from '@clearalpha/common';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { isPMEditModeSelector } from 'store/slices/portfolioManagers';
import { FormikText, FormikValue } from 'components/formik/fields';
import {
  DescriptionSection,
  SectionTitle,
  Description as StyledDescription,
} from '../../PortfolioManagerDetails.styled';
import { ManagerFormFields } from '../../PortfolioManagerDetails.constants';

const Description = () => {
  const { t } = useTranslation();
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);
  const isEditMode = useSelector(isPMEditModeSelector);

  return (
    <DescriptionSection>
      <SectionTitle>{t('about')}</SectionTitle>

      {isEditMode ? (
        <FormikText
          name={ManagerFormFields.DESCRIPTION}
          placeholder={t('about')}
          fullWidth
          multiline
        />
      ) : (
        <SensitiveDataBlock isVisible={isVisibleSensitiveData} minHeight='80px'>
          <StyledDescription>
            <FormikValue name={ManagerFormFields.DESCRIPTION} />
          </StyledDescription>
        </SensitiveDataBlock>
      )}
    </DescriptionSection>
  );
};

export default Description;
