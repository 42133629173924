import { ChangeEvent } from 'react';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

export const LOCALS = {
  STRATEGY_COLUMN: 'strategy',
  ASSET_CATEGORY: 'category',
  CAPACITY_PRODUCTION: 'production',
  CAPACITY_SCENARIO: 'scenario',
  MIN_ALLOCATION: 'min allocation',
  CAPACITY: 'capacity',
};

export interface HeadTooltipProps {
  children: string;
  sx?: SxProps<Theme>;
}

export interface StrategyListTableHeaderProps {
  handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void;
  strategiesCount: number;
  selectedStrategiesCount: number;
}
