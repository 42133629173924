import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReadonlyData } from '@clearalpha/common';
import { ManagerFormFields } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.constants';
import { FormikSelectEntityAttributes } from 'components/formik/fields';
import { isPMEditModeSelector } from 'store/slices/portfolioManagers';
import { getPortfolioManagerStatuses } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import { GetPortfolioManagerStatus } from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { OverviewStatName } from '../../styled';

type StatusFieldProps = {
  status?: GetPortfolioManagerStatus | null;
};

const StatusField = ({ status }: StatusFieldProps) => {
  const { t } = useTranslation();

  const isEditMode = useSelector(isPMEditModeSelector);

  if (isEditMode) {
    return (
      <FormikSelectEntityAttributes
        name={ManagerFormFields.STATUS}
        label={t('status')}
        getData={getPortfolioManagerStatuses}
      />
    );
  }

  return (
    <OverviewStatName>
      {t('status')}
      <ReadonlyData>{status?.name}</ReadonlyData>
    </OverviewStatName>
  );
};

export default StatusField;
