import React from 'react';
import { ToasterContainer } from '@clearalpha/common';
import './style.css';
import './marker-io';
import Router from 'router/Router';

const App = () => (
  <>
    <Router />
    <ToasterContainer />
  </>
);

export default App;
