import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { setIsRolesLoading, setRoles } from 'store/slices/portfolioManagers';
import { getPortfolioManagerRoles } from 'services/portfolioManagerCore/portfolioManagerCore.service';

export const fetchPortfolioManagerRoles = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchPortfolioManagerRoles', async (_, { dispatch }) => {
  dispatch(setIsRolesLoading(true));

  try {
    const roles = await getPortfolioManagerRoles();

    dispatch(setRoles(roles));
  } finally {
    dispatch(setIsRolesLoading(false));
  }
});
