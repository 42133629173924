import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SensitiveDataBlock, TextField } from '@clearalpha/common';
import { getManagerTeamNames } from 'components/StrategyLibrary/PortfolioManagerList/PortfolioManagerList.helpers';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { isPMEditModeSelector } from 'store/slices/portfolioManagers';
import { TeamName } from '../../styled';
import { OverviewProps } from '../../Overview.types';

const TeamField = ({ portfolioManager, handleOpenTeam }: OverviewProps) => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isPMEditModeSelector);
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);

  const teamNames = getManagerTeamNames(portfolioManager);

  if (isEditMode) {
    return (
      <TextField
        label={t('team')}
        placeholder={t('team')}
        disabled
        fullWidth
        multiline
        maxRows={20}
        value={teamNames}
      />
    );
  }

  if (!portfolioManager?.teams?.length) {
    return null;
  }

  return (
    <SensitiveDataBlock isVisible={isVisibleSensitiveData} minHeight='40px'>
      {portfolioManager.teams.map(({ name, id }) => (
        <TeamName key={id} onClick={() => handleOpenTeam(id)}>
          {name}
        </TeamName>
      ))}
    </SensitiveDataBlock>
  );
};

export default TeamField;
