import { object, number } from 'yup';
import { PortfolioParametersFields } from '../components/Dialogs/ParametersDialog/ParametersDialog.type';
import { ONE, ONE_BILLION, ZERO } from './validationNumbers';
import i18n from '../translations/i18n';
import { optionalText } from './defaultValidationRules';
import { ARCESIUM_ID, FIELD_LENGTH } from '../constants/fields';

export const portfolioParametersValidationSchema = object().shape({
  [PortfolioParametersFields.ARCESIUM_ID]: optionalText({
    fieldName: ARCESIUM_ID,
    maxLength: FIELD_LENGTH.ARCESIUM_ID,
  }),
  [PortfolioParametersFields.MIN_FREE_CASH_FROM_AUM]: number()
    .moreThan(
      ZERO,
      i18n.t('errors.moreThan', {
        name: i18n.t('dialogs.parameters.minFreeCashAum'),
        value: '0%',
      })
    )
    .lessThan(
      ONE,
      i18n.t('errors.lessThan', {
        name: i18n.t('dialogs.parameters.minFreeCashAum'),
        value: '100%',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.minFreeCashAum'),
      })
    ),
  [PortfolioParametersFields.MAX_CAPITAL_AT_RISK]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('dialogs.parameters.maxCapitalAtRisk'),
        value: '0%',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.maxCapitalAtRisk'),
      })
    ),
  [PortfolioParametersFields.MAX_LIQUIDITY_CONCENTRATION_RATIO]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('dialogs.parameters.maxLiquidityCR'),
        value: '0',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.maxLiquidityCR'),
      })
    ),
  [PortfolioParametersFields.MAX_PL_RISK_CONCENTRATION_RATIO]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('dialogs.parameters.maxPLRiskCR'),
        value: '0',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.maxPLRiskCR'),
      })
    ),
  [PortfolioParametersFields.MIN_NICHE_RISK]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('dialogs.parameters.minNicheRisk'),
        value: '0%',
      })
    )
    .max(
      ONE,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('dialogs.parameters.minNicheRisk'),
        value: '100%',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.minNicheRisk'),
      })
    ),
  [PortfolioParametersFields.MAX_EQUITY_LEVERAGE]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('dialogs.parameters.maxEquityLeverage'),
        value: '0',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.maxEquityLeverage'),
      })
    ),
  [PortfolioParametersFields.MIN_PROBABILITY_RETURN_THRESHOLD]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('dialogs.parameters.minProbReturnThreshold'),
        value: '0%',
      })
    )
    .lessThan(
      ONE,
      i18n.t('errors.lessThan', {
        name: i18n.t('dialogs.parameters.minProbReturnThreshold'),
        value: '100%',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.minProbReturnThreshold'),
      })
    ),
  [PortfolioParametersFields.MIN_NET_EXCESS_RETURN]: number()
    .moreThan(
      ZERO,
      i18n.t('errors.moreThan', {
        name: i18n.t('dialogs.parameters.minNetExcess'),
        value: '0%',
      })
    )
    .lessThan(
      ONE,
      i18n.t('errors.lessThan', {
        name: i18n.t('dialogs.parameters.minNetExcess'),
        value: '100%',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.minNetExcess'),
      })
    ),
  [PortfolioParametersFields.PLATFORM_FIXED_EXPENSES]: number()
    .moreThan(
      ZERO,
      i18n.t('errors.moreThan', {
        name: i18n.t('dialogs.parameters.fixedExpenses'),
        value: '0',
      })
    )
    .max(
      ONE_BILLION,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('dialogs.parameters.fixedExpenses'),
        value: '1B',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.fixedExpenses'),
      })
    ),
  [PortfolioParametersFields.PLATFORM_PERFORMANCE_FEE]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('dialogs.parameters.performanceFee'),
        value: '0%',
      })
    )
    .max(
      ONE,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('dialogs.parameters.performanceFee'),
        value: '100%',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.performanceFee'),
      })
    ),
  [PortfolioParametersFields.CROSS_CAPITAL_AT_RISK_BENEFIT]: number()
    .moreThan(
      ZERO,
      i18n.t('errors.moreThan', {
        name: i18n.t('dialogs.parameters.crossCapitalAtRiskBenefit'),
        value: '0%',
      })
    )
    .max(
      ONE,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('dialogs.parameters.crossCapitalAtRiskBenefit'),
        value: '100%',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.crossCapitalAtRiskBenefit'),
      })
    ),
  [PortfolioParametersFields.CROSS_MARGIN_BENEFIT]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('dialogs.parameters.crossMarginBenefit'),
        value: '0%',
      })
    )
    .max(
      ONE,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('dialogs.parameters.crossMarginBenefit'),
        value: '100%',
      })
    )
    .required(
      i18n.t('errors.required', {
        name: i18n.t('dialogs.parameters.crossMarginBenefit'),
      })
    ),
});
