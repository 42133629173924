import dayjs, { Dayjs, isDayjs } from 'dayjs';

export const dateToDayjsInstance = (date?: string): Dayjs | null => {
  if (!date) return null;

  const dayjsDate = dayjs(date);

  return dayjsDate.isValid() ? dayjsDate : null;
};

export const formatDate = (date: null | string | Dayjs) => {
  if (!date) return '';

  if (isDayjs(date)) {
    return date.isValid() ? date.format() : '';
  }

  const dayjsDate = dateToDayjsInstance(date);

  return dayjsDate ? dayjsDate.format() : '';
};
