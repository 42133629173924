import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DialogProps } from '@clearalpha/common';
import {
  getPortfolioHistory,
  getPortfolioVersion,
} from 'services/portfolioCore/portfolioCore.service';
import { useAppDispatch } from 'store';
import {
  FIRST_VERSION,
  lastWorkingPortfolioSelector,
  setStrategyGroups,
  setWorkingPortfolioAndFetchStrategies,
} from 'store/slices/portfolios';
import { GetPortfolioResponse } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { GetPortfolioStrategies } from 'services/portfolioCore/portfolioCore.types';
import {
  VersionItem,
  VersionListDialogHelperParams,
} from './VersionListDialog.type';

export const getVersionsData = (
  portfolios: GetPortfolioResponse[]
): VersionItem[] =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [...portfolios].map(({ id, version }) => ({
    id,
    asOf: new Date().toISOString(), // TODO: Update with "updatedAt" when possible
    version,
  })) || [];

export const useVersionListDialog = ({
  isOpen,
  handleClose,
}: DialogProps): VersionListDialogHelperParams => {
  const dispatch = useAppDispatch();

  const [versions, setVersions] = useState<VersionItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const lastWorkingPortfolio = useSelector(lastWorkingPortfolioSelector);
  const { id: lastPortfolioId, version: lastPortfolioVersion } =
    lastWorkingPortfolio;

  const isHistoryAvailable =
    Boolean(lastPortfolioId) && lastPortfolioVersion > FIRST_VERSION;

  const currentVersion = lastWorkingPortfolio.id
    ? getVersionsData([lastWorkingPortfolio])[0]
    : null;

  const handleRestoreVersion = async (id: string, selectedVersion: number) => {
    const workingPortfolioToRestore = versions.find(
      ({ id: portfolioId, version }) =>
        portfolioId === id && version === selectedVersion
    );

    const lastPortfolioToRestore =
      lastWorkingPortfolio.id === id &&
      lastWorkingPortfolio.version === selectedVersion
        ? lastWorkingPortfolio
        : null;

    const versionToRestore =
      workingPortfolioToRestore || lastPortfolioToRestore;

    if (!versionToRestore) return;

    const restoredPortfolio = await getPortfolioVersion(
      versionToRestore.id,
      versionToRestore.version
    );
    const prepareFullStrategyGroups = () => {
      const allocationsHashMap: { [key: string]: GetPortfolioStrategies[] } =
        restoredPortfolio.strategyAllocations.reduce(
          (acc: { [key: string]: GetPortfolioStrategies[] }, current) => {
            if (current.strategyGroupId && !acc[current.strategyGroupId]) {
              acc[current.strategyGroupId] = [
                { ...current, portfolioId: restoredPortfolio.id },
              ];
            } else if (
              current.strategyGroupId &&
              acc[current.strategyGroupId]
            ) {
              acc[current.strategyGroupId].push({
                ...current,
                portfolioId: restoredPortfolio.id,
              });
            }
            return acc;
          },
          {}
        );

      return restoredPortfolio.strategyGroups.map((current) => ({
        ...current,
        portfolioId: restoredPortfolio.id,
        strategies: allocationsHashMap[current.id] || [],
      }));
    };

    const preparedGroups = prepareFullStrategyGroups();
    dispatch(setStrategyGroups(preparedGroups));
    dispatch(setWorkingPortfolioAndFetchStrategies(restoredPortfolio));
    handleClose();
  };

  const fetchHistory = useCallback(async () => {
    if (!isHistoryAvailable) return;

    setIsLoading(true);

    try {
      const content = await getPortfolioHistory(lastPortfolioId);

      setVersions((prevState) => {
        return [...prevState, ...content];
      });
    } finally {
      setIsLoading(false);
    }
  }, [isHistoryAvailable, lastPortfolioId]);

  const resetState = () => {
    setVersions([]);
  };

  useEffect(() => {
    if (isOpen) {
      fetchHistory();
    } else {
      resetState();
    }
  }, [fetchHistory, isOpen]);

  const sortedVersions = versions.sort((a, b) => b.version - a.version);

  return {
    isLoading,
    currentVersion,
    savedVersions: sortedVersions,
    handleRestoreVersion,
  };
};
