import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGroupCharacteristics } from 'services/strategyCatalog/strategyCatalog.service';
import { RootState } from 'store';
import {
  setGroupCharacteristics,
  setLoadingGroupCharacteristics,
} from 'store/slices/strategies';

export const fetchGroupCharacteristics = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchGroupCharacteristics', async (_, { dispatch }) => {
  dispatch(setLoadingGroupCharacteristics(true));

  try {
    const groupCharacteristics = await getGroupCharacteristics();

    dispatch(setGroupCharacteristics(groupCharacteristics));
  } finally {
    dispatch(setLoadingGroupCharacteristics(false));
  }
});
