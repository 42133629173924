import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import {
  AccountCircleFilledIcon,
  IconButtonToggle,
  LogoutIcon,
  MenuItem,
  NavLogo,
  THEME_COLORS,
  VisibilityIcon,
  VisibilityOffIcon,
  WidgetMenu as Menu,
} from '@clearalpha/common';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  sensitiveDataVisibilitySelector,
  toggleSensitiveDataVisibility,
} from 'store/slices/settings';
import {
  hasSaraUserPermissionSelector,
  hasStrategyLibraryPermissionSelector,
  hasSaraFullPermissionSelector,
} from 'store/slices/permissions';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { signOutInit } from 'services/auth/auth.service';
import { defaultMenuSxStyles } from 'components/StrategyList/StrategyListToolBar/StrategyListToolBar.type';
import { Notification } from 'components/Notification/Notification';
import { menuItems } from './Navigation.const';
import { LeftSection, RightSection, TopWrapper } from './Navigation.styled';

// TODO: ADD NavigationProps<NavTabs> props if need, now disable to prevent ts error
const Navigation = () => {
  const navigate = useNavigate();
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const hasStrategyLibraryPermission = useSelector(
    hasStrategyLibraryPermissionSelector
  );
  const hasSaraUserPermission = useSelector(hasSaraUserPermissionSelector);
  const hasSaraFullPermissions = useSelector(hasSaraFullPermissionSelector);
  const isReviewPortfolioMode = useSelector(isReviewModePortfolioSelector);

  const menuItemsWithPermissions = menuItems.filter(
    (menuItem) =>
      ((hasSaraUserPermission || hasSaraFullPermissions) &&
        menuItem.key === 'saraPage') ||
      (hasStrategyLibraryPermission && menuItem.key === 'strategyLibraryPage')
  );

  return (
    <TopWrapper>
      <LeftSection>
        <NavLogo />
      </LeftSection>
      <RightSection>
        <IconButtonToggle
          iconColor={THEME_COLORS.white}
          checked={isVisibleSensitiveData}
          onChange={() => dispatch(toggleSensitiveDataVisibility())}
          icon={<VisibilityOffIcon />}
          checkedIcon={<VisibilityIcon />}
        />
        <Menu
          anchorEl={anchorEl}
          sx={defaultMenuSxStyles}
          PaperProps={{
            sx: {
              width: '300px',
            },
          }}
          setAnchorEl={setAnchorEl}
        >
          {menuItemsWithPermissions.map((item) => (
            <MenuItem
              sx={{
                minWidth: 250,
                width: 250,
              }}
              key={item.name}
              onClick={() => navigate(item.path)}
              disabled={isReviewPortfolioMode}
            >
              {item.name}
            </MenuItem>
          ))}
        </Menu>

        {hasSaraFullPermissions && <Notification />}

        <IconButton>
          <AccountCircleFilledIcon />
        </IconButton>
        <IconButton onClick={signOutInit}>
          <LogoutIcon />
        </IconButton>
      </RightSection>
    </TopWrapper>
  );
};

export { Navigation };
