import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog, InfoIcon } from '@clearalpha/common';
import { ErrorDialogProps } from './ErrorDialog.type';

const ErrorDialog = ({
  handleClose,
  isOpen,
  title,
  description,
}: ErrorDialogProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      data-testid='errorDialog'
      title={title}
      handleClose={handleClose}
      isOpen={isOpen}
      message={description}
      cancelButton={{
        label: t('actions.cancel'),
        action: handleClose,
      }}
      icon={<InfoIcon />}
    />
  );
};

export default ErrorDialog;
