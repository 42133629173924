import { createSlice } from '@reduxjs/toolkit';
import { UserState } from './user.types';
import { USER_SLICE_NAME, USER_SLICE_INITIAL_STATE } from './user.constants';

const user = createSlice({
  name: USER_SLICE_NAME,
  initialState: USER_SLICE_INITIAL_STATE,
  reducers: {
    setUser: (
      state: UserState,
      action: { payload: Omit<UserState, 'redirectTo'> }
    ) => {
      state.accessToken = action.payload.accessToken;
      state.signOutAt = action.payload.signOutAt;
      state.refreshToken = action.payload.refreshToken;
      state.isSignedIn = action.payload.isSignedIn;
      state.idTokenRefreshAt = action.payload.idTokenRefreshAt;
      state.refreshTokenExpiresAt = action.payload.refreshTokenExpiresAt;
      state.idToken = action.payload.idToken;
      state.isSignInInitiated = action.payload.isSignInInitiated;
      state.isSignOutInitiated = action.payload.isSignOutInitiated;
    },
    setRedirectTo: (state: UserState, action: { payload: string }) => {
      state.redirectTo = action.payload;
    },
  },
});

export const { setUser, setRedirectTo } = user.actions;

export default user.reducer;
