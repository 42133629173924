import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReadonlyData } from '@clearalpha/common';
import { ManagerFormFields } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.constants';
import { FormikText, FormikValue } from 'components/formik/fields';
import { useSelector } from 'react-redux';
import { isPMEditModeSelector } from 'store/slices/portfolioManagers';
import { OverviewStatName } from '../../styled';

const LocationField = () => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isPMEditModeSelector);

  if (isEditMode) {
    return (
      <FormikText
        name={ManagerFormFields.LOCATION}
        label={t('location')}
        placeholder={t('location')}
        fullWidth
      />
    );
  }

  return (
    <OverviewStatName>
      {t('location')}
      <ReadonlyData>
        <FormikValue name={ManagerFormFields.LOCATION} />
      </ReadonlyData>
    </OverviewStatName>
  );
};

export default LocationField;
