import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getAllStrategiesByIds } from 'services/strategyCatalog/strategyCatalog.service';
import { setStrategies, setInitialStrategies } from 'store/slices/strategies';
import { PORTFOLIOS_SLICE_NAME, setIsLoading } from 'store/slices/portfolios';
import { FullPortfolioStrategy } from 'services/portfolioCore/portfolioCore.types';

export const fetchPortfolioStrategies = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchPortfolioStrategies', async (_, { dispatch, getState }) => {
  const {
    [PORTFOLIOS_SLICE_NAME]: { workingPortfolio },
  } = getState();

  dispatch(setIsLoading(true));

  try {
    const payload = workingPortfolio.strategyAllocations.map(
      ({ strategyId }) => strategyId
    );

    const allStrategies = await getAllStrategiesByIds(payload);

    const mappedPortfolioStrategies: FullPortfolioStrategy[] =
      workingPortfolio.strategyAllocations.map((portfolioStrategy) => {
        const strategy = allStrategies.find(
          (strategy) => strategy.id === portfolioStrategy.strategyId
        )!;
        const groupId = portfolioStrategy.strategyGroup?.id
          ? portfolioStrategy.strategyGroup.id
          : portfolioStrategy.strategyGroupId;
        return {
          ...portfolioStrategy,
          strategyGroup: workingPortfolio.strategyGroups.find(
            (group) => groupId === group.id
          )!,
          strategy,
        };
      });
    dispatch(setStrategies(mappedPortfolioStrategies));
    dispatch(setInitialStrategies(mappedPortfolioStrategies));
  } finally {
    dispatch(setIsLoading(false));
  }
});
