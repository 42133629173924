import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import i18n from 'translations/i18n';
import {
  NavLogo,
  FingerPrintIcon,
  MicrosoftLogoIcon,
  Loader,
} from '@clearalpha/common';
import { routes } from 'constants/routes';
import { signInInit } from 'services/auth/auth.service';
import {
  isUserSignedInSelector,
  isUserSigningInSelector,
  isUserSigningOutSelector,
  redirectToSelector,
} from 'store/slices/user/user.selectors';
import {
  Container,
  Header,
  ContentWrapper,
  LogoContainer,
  OptionLogo,
  OptionsContainer,
  OptionText,
  OtherSsoOption,
  SignInOption,
} from './Welcome.styled';

const WelcomeLayout = () => {
  const navigate = useNavigate();

  const isSignedIn = useSelector(isUserSignedInSelector);
  const isSigningIn = useSelector(isUserSigningInSelector);
  const isSigningOut = useSelector(isUserSigningOutSelector);
  const redirectTo = useSelector(redirectToSelector);

  useEffect(() => {
    if (isSignedIn) {
      navigate(redirectTo || routes.ROOT.path, {
        replace: true,
      });
    }
  }, [redirectTo, isSignedIn, navigate]);

  return (
    <Container>
      <Header>
        <LogoContainer>
          <NavLogo />
        </LogoContainer>
      </Header>

      <ContentWrapper>
        <OptionsContainer>
          <SignInOption onClick={signInInit}>
            <OptionLogo>
              <MicrosoftLogoIcon />
            </OptionLogo>
            <OptionText>{i18n.t('login.microsoft')}</OptionText>
          </SignInOption>

          <SignInOption>
            <OptionLogo>
              <FingerPrintIcon />
            </OptionLogo>
            <OptionText>{i18n.t('login.passkey')}</OptionText>
          </SignInOption>

          <OtherSsoOption>{i18n.t('login.otherSso')}</OtherSsoOption>
        </OptionsContainer>
      </ContentWrapper>

      <Loader loading={isSigningIn || isSigningOut} />
    </Container>
  );
};

export default WelcomeLayout;
