import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SensitiveDataBlock } from '@clearalpha/common';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { isTeamEditModeSelector } from 'store/slices/teams';
import { FormikText, FormikValue } from 'components/formik/fields';
import { Description as StyledDescription } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.styled';
import { TeamDetailsFormFieldName } from '../TeamDetails.constants';
import Section from '../Section/Section';

const TeamDescription = () => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isTeamEditModeSelector);
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);

  return (
    <Section title={t('teamDescription')}>
      {isEditMode ? (
        <FormikText
          name={TeamDetailsFormFieldName.DESCRIPTION}
          placeholder={t('teamDescription')}
          fullWidth
          multiline
        />
      ) : (
        <SensitiveDataBlock isVisible={isVisibleSensitiveData} minHeight='80px'>
          <StyledDescription>
            <FormikValue name={TeamDetailsFormFieldName.DESCRIPTION} />
          </StyledDescription>
        </SensitiveDataBlock>
      )}
    </Section>
  );
};

export default TeamDescription;
