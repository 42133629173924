import React from 'react';
import { useLocation } from 'react-router-dom';
import { AddIcon } from '@clearalpha/common';
import { routes } from 'constants/routes';
import { Link } from 'components/Navigation/Navigation.styled';
import {
  Button,
  Container,
  Title,
  Wrapper,
} from './StrategyLibraryHeader.styled';
import { STRATEGY_LIBRARY_TABS } from './StrategyLibraryHeader.const';
import { STRATEGY_LIBRARY_HEADER_LOCALS } from './StrategyLibraryHeader.type';
import { StrategyLibraryHeaderProps } from '../StrategyLibrary.types';

const StrategyLibraryHeader = ({
  handleOpenPMDrawer,
  handleOpenTeamDrawer,
  handleOpenGlobalStrategyDrawer,
}: StrategyLibraryHeaderProps) => {
  const { pathname } = useLocation();

  const getTitle = () => {
    if (pathname.includes(routes.TEAMS.path)) {
      return STRATEGY_LIBRARY_HEADER_LOCALS.TEAM_DIRECTORY;
    }

    if (pathname.includes(routes.TEAM_MEMBERS.path)) {
      return STRATEGY_LIBRARY_HEADER_LOCALS.TEAM_MEMBERS;
    }

    return STRATEGY_LIBRARY_HEADER_LOCALS.STRATEGY_LIBRARY;
  };

  const getButtonConfig = () => {
    if (pathname.includes(routes.TEAM_MEMBERS.path)) {
      return {
        label: STRATEGY_LIBRARY_HEADER_LOCALS.CREATE_MANAGER,
        action: () => handleOpenPMDrawer(),
      };
    }

    if (pathname.includes(routes.TEAMS.path)) {
      return {
        label: STRATEGY_LIBRARY_HEADER_LOCALS.CREATE_TEAM,
        action: handleOpenTeamDrawer,
      };
    }

    return {
      label: STRATEGY_LIBRARY_HEADER_LOCALS.CREATE_STRATEGY,
      action: handleOpenGlobalStrategyDrawer,
    };
  };

  return (
    <Container>
      <Wrapper>
        <Title>{getTitle()}</Title>
        <Button
          startIcon={<AddIcon height={12} width={12} />}
          variant='filled'
          onClick={getButtonConfig().action}
        >
          {getButtonConfig().label}
        </Button>
      </Wrapper>

      {STRATEGY_LIBRARY_TABS.map((item) => (
        <Link key={routes[item].name} to={routes[item].path}>
          {routes[item].name}
        </Link>
      ))}
    </Container>
  );
};

export default StrategyLibraryHeader;
