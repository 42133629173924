import { RootState } from 'store';
import {
  GetPortfolioManager,
  GetPortfolioManagerRole,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { PORTFOLIO_MANAGERS_SLICE_NAME } from './portfolioManagers.constants';

export const portfolioManagersSelector = (
  state: RootState
): GetPortfolioManager[] =>
  state[PORTFOLIO_MANAGERS_SLICE_NAME].portfolioManagers;

export const isLoadingPMsSelector = (state: RootState): boolean =>
  state[PORTFOLIO_MANAGERS_SLICE_NAME].isLoadingPMs;

export const isPMEditModeSelector = (state: RootState): boolean =>
  state[PORTFOLIO_MANAGERS_SLICE_NAME].isPMEditMode;

export const isLoadingRolesSelector = (state: RootState): boolean =>
  state[PORTFOLIO_MANAGERS_SLICE_NAME].isLoadingRoles;

export const rolesSelector = (state: RootState): GetPortfolioManagerRole[] =>
  state[PORTFOLIO_MANAGERS_SLICE_NAME].roles;
