import React from 'react';
import {
  DialogFooter,
  DialogProps,
  InfinityIcon,
  NumericTextCurrency,
  NumericTextDecimal,
  NumericTextPercent,
  PenPaperIcon,
  TextFieldWrapper,
} from '@clearalpha/common';
import { FormikProvider } from 'formik';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  isReviewModePortfolioSelector,
  productionPortfolioSelector,
  sourcePortfolioSelector,
  targetPortfolioSelector,
} from 'store/slices/portfolios';
import {
  FormikDecimalNumbers,
  FormikPercentNumbers,
  FormikCurrencyNumbers,
  FormikText,
} from 'components/formik/fields';
import { getFeeValue } from 'services/portfolioCore/portfolioCore.helper';
import { ARCESIUM_ID } from 'constants/fields';
import {
  ContentWrapper,
  Description,
  Label,
  ParametersDialogWrapper,
  InputBlock,
  BlockHeader,
  FieldLabel,
  InputSection,
  UnderlinedRow,
  ColumnHeader,
  Button,
  HighlightedWrapper,
  IdWrapper,
} from './ParametersDialog.styled';
import { PortfolioParametersFields } from './ParametersDialog.type';
import { useParametersForm } from './ParametersDialog.helper';
import UnsavedChangesDialog from '../UnsavedChangesDialog/UnsavedChangesDialog';

const ParametersDialog = ({ isOpen, handleClose }: DialogProps) => {
  const { t } = useTranslation();
  const isInputDisabled = useSelector(isReviewModePortfolioSelector);

  const productionPortfolio = useSelector(productionPortfolioSelector);
  const sourcePortfolio = useSelector(sourcePortfolioSelector);
  const targetPortfolio = useSelector(targetPortfolioSelector);
  const isReviewMode = useSelector(isReviewModePortfolioSelector);
  const currentProduction = isReviewMode
    ? targetPortfolio
    : productionPortfolio;
  const isArcesiumFieldHighlighted =
    isReviewMode && sourcePortfolio.arcesiumId !== targetPortfolio.arcesiumId;

  const { fees, constraints, crossCapitalAtRiskBenefit, crossMarginBenefit } =
    currentProduction;
  const { fees: sourceFees } = sourcePortfolio;
  const platformFixedExpenses =
    getFeeValue('Platform Fixed Expenses', fees) || 0;
  const platformPerformanceFee = getFeeValue('Performance Fee', fees) || 0;
  const sourceFixedExpenses =
    getFeeValue('Platform Fixed Expenses', sourceFees) || 0;
  const sourcePerformanceFee = getFeeValue('Performance Fee', sourceFees) || 0;

  const {
    formik,
    handleCancel,
    isOpenUnsavedChanges,
    handleConfirmUnsavedChanges,
    handleCancelUnsavedChanges,
  } = useParametersForm(isOpen, handleClose);

  const { handleSubmit, dirty } = formik;

  const getCustomInput = (valueSource: number, valueTarget: number) => {
    if (isReviewMode && valueSource !== valueTarget) {
      return HighlightedWrapper;
    }

    return TextFieldWrapper;
  };

  return (
    <ParametersDialogWrapper open={isOpen} onClose={handleCancel}>
      <ContentWrapper>
        <Label>{t('dialogs.parameters.title')}</Label>
        <Description>{t('dialogs.parameters.description')}</Description>
        <FormikProvider value={formik}>
          <form onSubmit={handleSubmit}>
            <IdWrapper
              className={isArcesiumFieldHighlighted ? 'highlighted' : ''}
            >
              <FormikText
                name={PortfolioParametersFields.ARCESIUM_ID}
                label={ARCESIUM_ID}
                placeholder={ARCESIUM_ID}
                fullWidth
                disabled={isInputDisabled}
              />
            </IdWrapper>
            <UnderlinedRow>
              <div />
              <ColumnHeader>
                <Typography>{t('production')}</Typography>
                <InfinityIcon />
              </ColumnHeader>
              <ColumnHeader>
                <Typography>{t('scenario')}</Typography>
                <PenPaperIcon />
              </ColumnHeader>
            </UnderlinedRow>
            <InputBlock>
              <BlockHeader>{t('dialogs.parameters.constraints')}</BlockHeader>
              <InputSection>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.minFreeCashAum')}
                  </FieldLabel>
                  <NumericTextPercent
                    value={constraints.targetFreeCashFromAum}
                  />
                  <FormikPercentNumbers
                    name={PortfolioParametersFields.MIN_FREE_CASH_FROM_AUM}
                    customInput={getCustomInput(
                      constraints.targetFreeCashFromAum,
                      sourcePortfolio.constraints.targetFreeCashFromAum
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.minNetExcess')}
                  </FieldLabel>
                  <NumericTextPercent
                    value={constraints.targetNetExcessReturn}
                  />
                  <FormikPercentNumbers
                    name={PortfolioParametersFields.MIN_NET_EXCESS_RETURN}
                    customInput={getCustomInput(
                      constraints.targetNetExcessReturn,
                      sourcePortfolio.constraints.targetNetExcessReturn
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.maxCapitalAtRisk')}
                  </FieldLabel>
                  <NumericTextPercent
                    value={constraints.targetMaxCapitalAtRisk}
                  />
                  <FormikPercentNumbers
                    name={PortfolioParametersFields.MAX_CAPITAL_AT_RISK}
                    customInput={getCustomInput(
                      constraints.targetMaxCapitalAtRisk,
                      sourcePortfolio.constraints.targetMaxCapitalAtRisk
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.maxLiquidityCR')}
                  </FieldLabel>
                  <NumericTextDecimal
                    value={constraints.targetMaxLiquidityCR}
                  />
                  <FormikDecimalNumbers
                    name={
                      PortfolioParametersFields.MAX_LIQUIDITY_CONCENTRATION_RATIO
                    }
                    customInput={getCustomInput(
                      constraints.targetMaxLiquidityCR,
                      sourcePortfolio.constraints.targetMaxLiquidityCR
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
                <UnderlinedRow>
                  <FieldLabel>{t('dialogs.parameters.maxPLRiskCR')}</FieldLabel>
                  <NumericTextDecimal value={constraints.targetMaxPLRiskCR} />
                  <FormikDecimalNumbers
                    name={
                      PortfolioParametersFields.MAX_PL_RISK_CONCENTRATION_RATIO
                    }
                    customInput={getCustomInput(
                      constraints.targetMaxPLRiskCR,
                      sourcePortfolio.constraints.targetMaxPLRiskCR
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.minNicheRisk')}
                  </FieldLabel>
                  <NumericTextPercent value={constraints.targetMinNicheRisk} />
                  <FormikPercentNumbers
                    name={PortfolioParametersFields.MIN_NICHE_RISK}
                    customInput={getCustomInput(
                      constraints.targetMinNicheRisk,
                      sourcePortfolio.constraints.targetMinNicheRisk
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.maxEquityLeverage')}
                  </FieldLabel>
                  <NumericTextDecimal
                    value={constraints.targetMaxEquityLeverage}
                  />
                  <FormikDecimalNumbers
                    name={PortfolioParametersFields.MAX_EQUITY_LEVERAGE}
                    customInput={getCustomInput(
                      constraints.targetMaxEquityLeverage,
                      sourcePortfolio.constraints.targetMaxEquityLeverage
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.minProbReturnThreshold')}
                  </FieldLabel>
                  <NumericTextPercent
                    value={constraints.minProbReturnThreshold}
                  />
                  <FormikPercentNumbers
                    name={
                      PortfolioParametersFields.MIN_PROBABILITY_RETURN_THRESHOLD
                    }
                    customInput={getCustomInput(
                      constraints.targetNetExcessReturn,
                      sourcePortfolio.constraints.targetNetExcessReturn
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
              </InputSection>
            </InputBlock>
            <InputBlock>
              <BlockHeader>{t('dialogs.parameters.parameters')}</BlockHeader>
              <InputSection>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.fixedExpenses')}
                  </FieldLabel>
                  <NumericTextCurrency
                    value={platformFixedExpenses as number}
                  />
                  <FormikCurrencyNumbers
                    name={PortfolioParametersFields.PLATFORM_FIXED_EXPENSES}
                    customInput={getCustomInput(
                      platformFixedExpenses,
                      sourceFixedExpenses
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.performanceFee')}
                  </FieldLabel>
                  <NumericTextPercent
                    value={platformPerformanceFee as number}
                  />
                  <FormikPercentNumbers
                    name={PortfolioParametersFields.PLATFORM_PERFORMANCE_FEE}
                    customInput={getCustomInput(
                      platformPerformanceFee,
                      sourcePerformanceFee
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.crossCapitalAtRiskBenefit')}
                  </FieldLabel>
                  <NumericTextPercent value={crossCapitalAtRiskBenefit} />
                  <FormikPercentNumbers
                    name={
                      PortfolioParametersFields.CROSS_CAPITAL_AT_RISK_BENEFIT
                    }
                    customInput={getCustomInput(
                      crossCapitalAtRiskBenefit,
                      sourcePortfolio.crossCapitalAtRiskBenefit
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
                <UnderlinedRow>
                  <FieldLabel>
                    {t('dialogs.parameters.crossMarginBenefit')}
                  </FieldLabel>
                  <NumericTextPercent value={crossMarginBenefit} />
                  <FormikPercentNumbers
                    name={PortfolioParametersFields.CROSS_MARGIN_BENEFIT}
                    customInput={getCustomInput(
                      crossMarginBenefit,
                      sourcePortfolio.crossMarginBenefit
                    )}
                    disabled={isInputDisabled}
                  />
                </UnderlinedRow>
              </InputSection>
            </InputBlock>
            <DialogFooter>
              <Button type='reset' onClick={handleCancel}>
                {t('actions.cancel')}
              </Button>
              <Button disabled={!dirty} type='submit'>
                {t('actions.apply')}
              </Button>
            </DialogFooter>
          </form>
        </FormikProvider>
      </ContentWrapper>

      <UnsavedChangesDialog
        isOpen={isOpenUnsavedChanges}
        handleClose={handleCancelUnsavedChanges}
        confirmAction={handleConfirmUnsavedChanges}
        cancelAction={handleCancelUnsavedChanges}
      />
    </ParametersDialogWrapper>
  );
};

export default ParametersDialog;
