import { transformToPieChartWidgetData } from 'helpers';
import { ALPHA_ARCHETYPE } from 'constants/strategy';
import { AlphaCategoriesPct } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { KEY_ARCHETYPE_COLORS } from './AlphaArchetypeWidget.const';

export const getAlphaArchetypeWidgetData = (data: AlphaCategoriesPct[]) => {
  return transformToPieChartWidgetData({
    data,
    keyField: 'groupName',
    percentageField: 'alphaCategoriesPct',
    locals: ALPHA_ARCHETYPE,
    colors: KEY_ARCHETYPE_COLORS,
  });
};
