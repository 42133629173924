import React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer } from '@clearalpha/common';
import { FormikProvider } from 'formik';
import DeleteConfirmationDialog from 'components/Dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import UnsavedChangesDialog from 'components/Dialogs/UnsavedChangesDialog/UnsavedChangesDialog';
import ErrorDialog from 'components/Dialogs/ErrorDialog/ErrorDialog';
import { TeamDetailsProps } from './TeamDetails.types';
import TeamDescription from './TeamDescription/TeamDescription';
import TeamOverview from './TeamOverview/TeamOverview';
import TeamHeader from './TeamHeader/TeamHeader';
import useTeamDetails from './TeamDetails.helpers';
import TeamMembers from './TeamMembers/TeamMembers';
import TeamArcesiumId from './TeamArcesiumId/TeamArcesiumId';

const TeamDetails = ({
  isOpen,
  handleClose,
  teamDetails,
  updateDrawer,
  handleOpenTeamMember,
}: TeamDetailsProps) => {
  const { t } = useTranslation();
  const {
    formik,
    handleCancel,
    handleOutsideClick,
    handleConfirmUnsavedChanges,
    handleCancelUnsavedChanges,
    isOpenUnsavedChanges,
    isCreateForm,
    isOpenDeleteDialog,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleDeleteTeam,
    isOpenDeleteErrorDialog,
    handleCloseDeleteErrorDialog,
  } = useTeamDetails({
    isOpen,
    handleCloseDrawer: handleClose,
    teamDetails,
    updateDrawer,
  });

  return (
    <>
      <Drawer
        data-testid='team-details'
        anchor='right'
        open={isOpen}
        onClose={handleOutsideClick}
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <TeamHeader
              isCreateForm={isCreateForm}
              isDirty={formik.dirty}
              handleCancel={handleCancel}
              handleCloseDrawer={handleClose}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
            />

            <TeamDescription />
            <TeamArcesiumId />
            <TeamOverview teamDetails={teamDetails} />
            <TeamMembers handleOpenTeamMember={handleOpenTeamMember} />
          </form>
        </FormikProvider>
      </Drawer>

      <UnsavedChangesDialog
        isOpen={isOpenUnsavedChanges}
        handleClose={handleCancelUnsavedChanges}
        confirmAction={handleConfirmUnsavedChanges}
        cancelAction={handleCancelUnsavedChanges}
      />

      <DeleteConfirmationDialog
        isOpen={isOpenDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handleDeleteTeam}
        title={t('dialogs.deleteTeam.title')}
        description={t('dialogs.deleteTeam.message')}
      />

      <ErrorDialog
        isOpen={isOpenDeleteErrorDialog}
        handleClose={handleCloseDeleteErrorDialog}
        title={t('errors.error')}
        description={t('dialogs.deleteTeam.error.message')}
      />
    </>
  );
};

export default TeamDetails;
