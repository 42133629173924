import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { AutocompleteField, Button } from '@clearalpha/common';
import { useAppDispatch } from 'store';
import {
  portfolioManagersSelector,
  rolesSelector,
  setRoles,
  isLoadingRolesSelector,
} from 'store/slices/portfolioManagers';
import { isTeamEditModeSelector } from 'store/slices/teams';
import { fetchPortfolioManagerRoles } from 'store/thunks';
import { ErrorMessage, TeamMemberFormContainer } from './TeamMemberForm.styled';
import {
  TeamDetailsFormFields,
  TeamMemberOption,
} from '../../TeamDetails.types';
import { TeamDetailsFormFieldName } from '../../TeamDetails.constants';
import { membersToOptions } from './TeamMemberForm.helper';

const TeamMemberForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isEditMode = useSelector(isTeamEditModeSelector);
  const managers = useSelector(portfolioManagersSelector);
  const roles = useSelector(rolesSelector);
  const isRolesLoading = useSelector(isLoadingRolesSelector);
  const [member, setMember] = useState<TeamMemberOption | null>(null);

  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext<TeamDetailsFormFields>();

  const { members: selectedTeamMembers, teamLeadId: selectedTeamLeadId } =
    values;
  const { members: membersErrors, teamLeadId: teamLeadErrors } = errors;
  const { members: membersTouched, teamLeadId: teamLeadTouched } = touched;

  const selectedTeamMemberIds = selectedTeamMembers.map(({ id }) => id);

  const managerOptions = useMemo(
    () => membersToOptions(managers, roles),
    [managers, roles]
  );

  const getOptionDisabled = (option: TeamMemberOption) => {
    return selectedTeamMemberIds.includes(option.id);
  };

  const handleChange = (newValue: TeamMemberOption | null) => {
    setMember(newValue);
  };

  const handleClear = () => handleChange(null);

  const addToTeam = () => {
    // TODO: Update condition when roles will be implemented
    if (!selectedTeamMembers.length) {
      setFieldValue(TeamDetailsFormFieldName.TEAM_LEAD_ID, member?.id);
      setFieldTouched(TeamDetailsFormFieldName.TEAM_LEAD_ID, true);
    }

    const updatedTeamMembers = [...selectedTeamMembers, member];

    setFieldValue(TeamDetailsFormFieldName.MEMBERS, updatedTeamMembers);
    setFieldTouched(TeamDetailsFormFieldName.MEMBERS, true);

    setMember(null);
  };

  useEffect(() => {
    dispatch(fetchPortfolioManagerRoles());

    return () => {
      dispatch(setRoles([]));
    };
  }, [dispatch]);

  if (!isEditMode) {
    return null;
  }

  return (
    <>
      <TeamMemberFormContainer>
        <AutocompleteField<TeamMemberOption>
          options={managerOptions}
          onChange={handleChange}
          onClear={handleClear}
          label={t('search')}
          value={member}
          name={TeamDetailsFormFieldName.MEMBERS}
          variant='outlined'
          getOptionDisabled={getOptionDisabled || isRolesLoading}
        />

        <Button
          variant='filled'
          onClick={addToTeam}
          disabled={!member}
          data-testid={t('actions.addToTeam')}
        >
          {t('actions.addToTeam')}
        </Button>
      </TeamMemberFormContainer>

      {membersErrors && membersTouched ? (
        <ErrorMessage error>{membersErrors as string}</ErrorMessage>
      ) : null}
      {teamLeadErrors && teamLeadTouched && !selectedTeamLeadId ? (
        <ErrorMessage error>{teamLeadErrors as string}</ErrorMessage>
      ) : null}
    </>
  );
};

export default TeamMemberForm;
