import { IconButton, Stack } from '@mui/material';
import styled from '@emotion/styled';
import {
  ActionMenu,
  ActionMenuItem,
  AddIcon,
  TextField as BaseTextField,
  MoreVertIcon,
} from '@clearalpha/common';
import { useSelector } from 'react-redux';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from 'translations/i18n';
import { routes } from 'constants/routes';
import { useAppDispatch } from 'store';
import { fetchGlobalStrategies, fetchTeams } from 'store/thunks';
import { StrategyListToolBarProps } from './StrategyListToolBar.type';
import StrategyListBulkActionMenu from '../StrategyListBulkActionMenu/StrategyListBulkActionMenu';
import { IconButtonWrapper } from './StrategyListToolBar.styled';
import AddNewStrategyDialog from '../AddNewStrategyDialog/AddNewStrategyDialog';

const Container = styled(Stack)`
  height: 120px;
  background: ${({ theme: { colors } }) => colors.systemSurfaceAtPlusThree};
  padding: 32px 24px;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px rgba(0, 0, 0, 0.3);
`;

const TextField = styled(BaseTextField)`
  margin-left: 24px;
  height: 56px;
  max-width: 464px;
  width: calc(100% - 312px);
`;

const StrategyListToolBar = ({ bulkActions }: StrategyListToolBarProps) => {
  const [isStrategyDialogOpened, setIsStrategyDialogOpened] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    dispatch(fetchGlobalStrategies());
    dispatch(fetchTeams());

    setIsStrategyDialogOpened(true);
  };
  const handleClose = () => setIsStrategyDialogOpened(false);
  const navigate = useNavigate();

  const menuItems: ActionMenuItem[] = [
    {
      action: () =>
        navigate(
          `/${routes.MONITOR.path}/${routes.MANAGE_STRATEGY_GROUPS.path}`
        ),
      label: i18n.t('manageStrategyGroups'),
    },
  ];
  const isButtonDisabled = useSelector(isReviewModePortfolioSelector);

  return (
    <>
      <Container flexDirection='row'>
        <StrategyListBulkActionMenu bulkActions={bulkActions} />
        <TextField isSearch label='Label' variant='outlined' />
        <IconButton onClick={handleOpen} disabled={isButtonDisabled}>
          <IconButtonWrapper>
            <AddIcon height={12} width={12} />
          </IconButtonWrapper>
        </IconButton>
        <ActionMenu menuItems={menuItems} menuIcon={<MoreVertIcon />} />
      </Container>
      <AddNewStrategyDialog
        isOpen={isStrategyDialogOpened}
        handleClose={handleClose}
      />
    </>
  );
};

export default StrategyListToolBar;
