export enum PortfolioParametersFields {
  ARCESIUM_ID = 'arcesiumId',
  MIN_FREE_CASH_FROM_AUM = 'targetFreeCashFromAum',
  MAX_CAPITAL_AT_RISK = 'targetMaxCapitalAtRisk',
  MAX_LIQUIDITY_CONCENTRATION_RATIO = 'targetMaxLiquidityCR',
  MAX_PL_RISK_CONCENTRATION_RATIO = 'targetMaxPLRiskCR',
  MIN_NICHE_RISK = 'targetMinNicheRisk',
  MAX_EQUITY_LEVERAGE = 'targetMaxEquityLeverage',
  MAX_GROSS_EXPOSURE = 'targetMaxGrossExposure',
  MIN_PROBABILITY_RETURN_THRESHOLD = 'minProbReturnThreshold',
  MIN_NET_EXCESS_RETURN = 'targetNetExcessReturn',
  PLATFORM_FIXED_EXPENSES = 'platformFixedExpenses',
  PLATFORM_MGMT_FEE = 'platformMgmtFeePct',
  PLATFORM_PERFORMANCE_FEE = 'platformPerformanceFee',
  CROSS_CAPITAL_AT_RISK_BENEFIT = 'crossCapitalAtRiskBenefit',
  CROSS_MARGIN_BENEFIT = 'crossMarginBenefit',
}

export type PortfolioParameters = {
  arcesiumId: string;
  targetMaxLiquidityCR: number;
  targetMaxEquityLeverage: number;
  targetMaxGrossExposure: number;
  minProbReturnThreshold: number;
  targetFreeCashFromAum: number;
  targetNetExcessReturn: number;
  targetMinNicheRisk: number;
  targetMaxCapitalAtRisk: number;
  targetMaxPLRiskCR: number;
  platformFixedExpenses: number;
  platformMgmtFeePct: number;
  platformPerformanceFee: number;
  crossCapitalAtRiskBenefit: number;
  crossMarginBenefit: number;
};
