import { DialogProps } from '@clearalpha/common';

export type AddNewStrategyDialogProps = DialogProps;

export type OptionsEntity = { [key: string]: string };

export interface FormState {
  strategy: string;
  strategyGroup: string;
  strategyGroupName: string;
  strategyGroupMgmtFee: string;
  strategyGroupPerformanceFee: string;
  strategyGroupFixedExpenses: string;
}

export const initialFormState = {
  strategy: '',
  strategyGroup: '',
  strategyGroupName: '',
  strategyGroupMgmtFee: '',
  strategyGroupPerformanceFee: '',
  strategyGroupFixedExpenses: '',
};

export enum Steps {
  STEP_ONE = 1,
  STEP_TWO = 2,
}

export enum StrategyFieldNames {
  STRATEGY = 'strategy',
  STRATEGY_GROUP = 'strategyGroup',
  STRATEGY_GROUP_NAME = 'strategyGroupName',
  STRATEGY_GROUP_MGMT_FEE = 'strategyGroupMgmtFee',
  STRATEGY_GROUP_PERFORMANCE_FEE = 'strategyGroupPerformanceFee',
  STRATEGY_GROUP_FIXED_EXPENSES = 'strategyGroupFixedExpenses',
}
