import {
  createListenerMiddleware,
  isAnyOf,
  TypedStartListening,
} from '@reduxjs/toolkit';
import { AppThunkDispatch, RootState } from 'store';
import { fetchPerformanceWork, fetchPortfolioStrategies } from 'store/thunks';
import {
  changeAssetsUnderManagementAndRunCalculation,
  changeCrossMarginBenefitAndRunCalculation,
  changeStrategiesAllocationsAndRunCalculation,
  setOptimizedAllocationsAndRunCalculation,
  setWorkingPortfolioAndFetchStrategies,
  setWorkingPortfolio,
} from './slices/portfolios';
import {
  setInitialStrategies,
  updateStrategyDetails,
} from './slices/strategies';
import {
  addNewStrategyAndGroupToPortfolio,
  addNewStrategyToPortfolio,
  setProductionPortfolioAndFetchPerformance,
} from './slices/portfolios/portfolios.slice';
import { fetchPerformanceProduction } from './thunks/fetchPerformanceProduction';
import { updateGroupDataInWorkingStrategy } from './slices/strategies/strategies.slice';

const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<
  RootState,
  AppThunkDispatch
>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

startAppListening({
  matcher: isAnyOf(
    setInitialStrategies,
    changeAssetsUnderManagementAndRunCalculation,
    changeCrossMarginBenefitAndRunCalculation,
    changeStrategiesAllocationsAndRunCalculation,
    updateStrategyDetails,
    setWorkingPortfolio
  ),
  effect: (action, { dispatch }) => {
    dispatch(fetchPerformanceWork());
    dispatch(fetchPerformanceProduction());
  },
});

startAppListening({
  matcher: isAnyOf(
    setOptimizedAllocationsAndRunCalculation,
    updateGroupDataInWorkingStrategy
  ),
  effect: (action, { dispatch }) => {
    dispatch(fetchPerformanceWork());
  },
});

startAppListening({
  matcher: isAnyOf(
    setWorkingPortfolioAndFetchStrategies,
    addNewStrategyToPortfolio,
    addNewStrategyAndGroupToPortfolio
  ),
  effect: (action, { dispatch }) => {
    dispatch(fetchPortfolioStrategies());
  },
});

startAppListening({
  matcher: isAnyOf(setProductionPortfolioAndFetchPerformance),
  effect: (action, { dispatch }) => {
    dispatch(fetchPerformanceProduction());
  },
});

export { listenerMiddleware };
