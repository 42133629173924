import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  FieldValue,
  FieldValueWarning,
  THEME_COLORS,
} from '@clearalpha/common';
import {
  TableWidgetBackbone,
  TitleCell,
  ValueCell,
} from '@clearalpha/common/dist/components/Dashboard/Widgets/TableWidgetBackbone';
import { TableBody, TableRow } from '@mui/material';
import {
  isReviewModePortfolioSelector,
  productionPortfolioPerformanceSelector,
  productionPortfolioSelector,
  workingPortfolioPerformanceSelector,
  workingPortfolioSelector,
  sourcePortfolioSelector,
  targetPortfolioSelector,
  targetPortfolioPerformanceSelector,
  sourcePortfolioPerformanceSelector,
} from 'store/slices/portfolios';
import { fundOverviewWidgetConfig } from './FundOverviewWidget.type';
import { getFundWidgetData } from './FundOverviewWidget.helper';
import AUMRow from './AUMRow';

const FundOverviewWidget = () => {
  const { t } = useTranslation();

  const productionPerformance = useSelector(
    productionPortfolioPerformanceSelector
  );
  const targetPerformance = useSelector(targetPortfolioPerformanceSelector);
  const workingPerformance = useSelector(workingPortfolioPerformanceSelector);
  const sourcePerformance = useSelector(sourcePortfolioPerformanceSelector);
  const productionPortfolio = useSelector(productionPortfolioSelector);
  const targetPortfolio = useSelector(targetPortfolioSelector);
  const workingPortfolio = useSelector(workingPortfolioSelector);
  const sourcePortfolio = useSelector(sourcePortfolioSelector);
  const isReviewMode = useSelector(isReviewModePortfolioSelector);

  const currentPortfolio = isReviewMode ? sourcePortfolio : workingPortfolio;
  const currentProduction = isReviewMode
    ? targetPortfolio
    : productionPortfolio;
  const currentProductionPerformance = isReviewMode
    ? targetPerformance
    : productionPerformance;
  const currentWorkingPerformance = isReviewMode
    ? sourcePerformance
    : workingPerformance;

  const { data: production } = useMemo(
    () =>
      getFundWidgetData(
        currentProductionPerformance.portfolio,
        currentProduction
      ),
    [currentProductionPerformance.portfolio, currentProduction]
  );

  const { data: scenario, warnings: scenarioWarnings } = useMemo(
    () =>
      getFundWidgetData(
        currentWorkingPerformance.portfolio,
        currentPortfolio,
        currentWorkingPerformance.warnings
      ),
    [
      currentWorkingPerformance.portfolio,
      currentWorkingPerformance.warnings,
      currentPortfolio,
    ]
  );

  const { statRows, aumRow } = fundOverviewWidgetConfig;

  return (
    <TableWidgetBackbone title={t('widgets.fundOverview')}>
      <TableBody>
        <AUMRow
          aumRow={aumRow}
          production={production}
          scenario={scenario}
          scenarioWarnings={scenarioWarnings}
        />
        {statRows.map(({ type: { left, right }, variableName, ...row }) => (
          <TableRow key={row.fieldName}>
            <TitleCell>{row.fieldName}</TitleCell>
            <ValueCell align='right'>
              <FieldValue
                row={{ ...row, variableName, type: left }}
                value={production[variableName]}
              />
            </ValueCell>
            <ValueCell
              align='right'
              style={
                isReviewMode &&
                scenario[variableName] !== production[variableName]
                  ? { backgroundColor: THEME_COLORS.highlightColor }
                  : {}
              }
            >
              <FieldValue
                row={{ ...row, variableName, type: right }}
                value={scenario[variableName]}
              />
              {scenarioWarnings[variableName] && (
                <FieldValueWarning>
                  {scenarioWarnings[variableName]?.join(' ')}
                </FieldValueWarning>
              )}
            </ValueCell>
          </TableRow>
        ))}
      </TableBody>
    </TableWidgetBackbone>
  );
};

export default FundOverviewWidget;
