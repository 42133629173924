import {
  GetPortfolioResponse,
  PortfolioFee,
} from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';

export const getLastVersionPortfolio = (data: GetPortfolioResponse[]) => {
  const [lastVersion] = [...data].sort(
    (a: GetPortfolioResponse, b: GetPortfolioResponse) => b.version - a.version
  );

  return lastVersion;
};

export const getFeeValue = (feeName: string, fees: PortfolioFee[]) => {
  const fee = fees.find(({ fee: { name } }) => feeName === name);
  return fee?.value || 0;
};
