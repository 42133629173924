import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, BlockTitle, FieldsWithProduction } from '../styled';
import { Scientist, Engineer, Actuary } from './fields';
import ColumnsHeader from '../ColumnsHeader/ColumnsHeader';

const AlphaArchetypes = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <BlockTitle>{t('alphaArchetypes')}</BlockTitle>
      <FieldsWithProduction>
        <ColumnsHeader />
        <Scientist />
        <Engineer />
        <Actuary />
      </FieldsWithProduction>
    </Block>
  );
};

export default AlphaArchetypes;
