import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getGlobalStrategies } from 'services/strategyCatalog/strategyCatalog.service';
import {
  setGlobalStrategies,
  setIsGlobalStrategiesLoading,
} from '../slices/strategies';

export const fetchGlobalStrategies = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchStrategyList', async (_, { dispatch }) => {
  dispatch(setIsGlobalStrategiesLoading(true));

  try {
    const strategies = await getGlobalStrategies();

    dispatch(setGlobalStrategies(strategies));
  } finally {
    dispatch(setIsGlobalStrategiesLoading(false));
  }
});
