import { useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { useAppDispatch } from 'store';
import { setIsTeamEditMode } from 'store/slices/teams';
import { createNewTeam } from 'store/thunks/createNewTeam';
import { teamValidationSchema } from 'validation/teamValidationSchema';
import {
  GetTeam,
  GetTeamManagerFromTeam,
  TeamEntityRequest,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { editTeam } from 'store/thunks/editTeam';
import { removeTeam } from 'store/thunks/removeTeam';
import { TeamDetailsFormFieldName } from './TeamDetails.constants';
import {
  TeamMemberOption,
  TeamDetailsFormFields,
  TeamDetailsHelperParams,
  TeamDetailsHelperProps,
} from './TeamDetails.types';
import { getTeamManagerFullName } from '../TeamsList/TeamsList.helpers';

export const getTeamMemberOptions = (
  teamMembers?: GetTeamManagerFromTeam[]
): TeamMemberOption[] => {
  return (
    teamMembers?.map((member) => ({
      id: member.managerId,
      label: getTeamManagerFullName(member),
      roleName: member.role.name,
      roleId: member.role.id,
    })) || []
  );
};

export const teamDetailsToFormValues = (
  teamDetails?: GetTeam | null
): TeamDetailsFormFields => {
  const totalPersonnel = teamDetails?.teamManagers?.length || 0;

  return {
    [TeamDetailsFormFieldName.NAME]: teamDetails?.name || '',
    [TeamDetailsFormFieldName.STATUS]: teamDetails?.status?.id || '',
    [TeamDetailsFormFieldName.DESCRIPTION]: teamDetails?.description || '',
    [TeamDetailsFormFieldName.ARCESIUM_ID]: teamDetails?.arcesiumId || '',
    [TeamDetailsFormFieldName.TYPE]: teamDetails?.type?.id || '',
    [TeamDetailsFormFieldName.TOTAL_PERSONNEL]: totalPersonnel,
    // TODO: Update other roles than PMs will be implemented
    [TeamDetailsFormFieldName.INVESTMENT_PROFESSIONALS]: totalPersonnel,
    [TeamDetailsFormFieldName.MEMBERS]: getTeamMemberOptions(
      teamDetails?.teamManagers
    ),
    [TeamDetailsFormFieldName.TEAM_LEAD_ID]:
      teamDetails?.teamLead?.managerId || '',
  };
};

export const formValuesToTeamDetails = ({
  name,
  arcesiumId,
  description,
  status,
  type,
  teamLeadId,
  members,
}: TeamDetailsFormFields): TeamEntityRequest => {
  const teamManagers = members.reduce(
    (acc: { [key: string]: string }, current) => {
      acc[current.id] = current.roleId;
      return acc;
    },
    {}
  );

  return {
    name,
    arcesiumId,
    description,
    teamLeadId,
    statusId: status,
    typeId: type,
    teamManagers,
  };
};

const useTeamDetails = ({
  isOpen,
  handleCloseDrawer,
  teamDetails,
  updateDrawer,
}: TeamDetailsHelperProps): TeamDetailsHelperParams => {
  const dispatch = useAppDispatch();

  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] = useState(false);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenDeleteErrorDialog, setIsOpenDeleteErrorDialog] = useState(false);

  const isCreateForm = !teamDetails;

  const initialValues = teamDetailsToFormValues(teamDetails);

  const saveTeam = async (
    values: TeamDetailsFormFields,
    { resetForm }: FormikHelpers<TeamDetailsFormFields>
  ) => {
    const newTeam = formValuesToTeamDetails(values);

    let updatedTeam;

    if (isCreateForm) {
      const { payload } = await dispatch(createNewTeam({ team: newTeam }));

      updatedTeam = payload as GetTeam;
    } else {
      const { payload } = await dispatch(
        editTeam({ uuid: teamDetails?.id, team: newTeam })
      );

      updatedTeam = payload as GetTeam;
    }

    if (updatedTeam) {
      updateDrawer({ teamDetails: updatedTeam });
      resetForm({ values });
    }
  };

  const formik = useFormik<TeamDetailsFormFields>({
    initialValues,
    onSubmit: saveTeam,
    validationSchema: teamValidationSchema,
  });

  const { dirty, resetForm } = formik;

  const handleCancel = () => {
    setIsCancelClicked(true);

    if (dirty) {
      return setIsOpenUnsavedChanges(true);
    }

    if (isCreateForm) {
      return handleCloseDrawer();
    }

    dispatch(setIsTeamEditMode(false));
  };

  const handleOutsideClick = () => {
    setIsCancelClicked(false);
    if (dirty) {
      return setIsOpenUnsavedChanges(true);
    }

    handleCloseDrawer();
  };

  const handleCancelUnsavedChanges = () => {
    setIsOpenUnsavedChanges(false);
  };

  const handleConfirmUnsavedChanges = () => {
    handleCancelUnsavedChanges();
    resetForm({ values: initialValues });

    if (isCancelClicked && !isCreateForm) {
      dispatch(setIsTeamEditMode(false));
    } else {
      handleCloseDrawer();
    }
  };

  const handleOpenDeleteDialog = () => {
    // TODO: Open a Delete Error dialog when the team cannot be deleted.
    // if (hasStrategyGroups) {
    //   return setIsOpenDeleteErrorDialog(true);
    // }

    setIsOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsOpenDeleteDialog(false);
  };

  const handleCloseDeleteErrorDialog = () => {
    setIsOpenDeleteErrorDialog(false);
  };

  const handleDeleteTeam = async () => {
    if (!teamDetails?.id) return;

    await dispatch(removeTeam({ uuid: teamDetails?.id }));

    handleCloseDeleteDialog();
    handleCloseDrawer();
  };

  useEffect(() => {
    if (!isOpen) return;

    dispatch(setIsTeamEditMode(isCreateForm));
    resetForm({ values: initialValues });
  }, [isOpen]);

  return {
    formik,
    handleCancel,
    handleOutsideClick,
    handleConfirmUnsavedChanges,
    handleCancelUnsavedChanges,
    isOpenUnsavedChanges,
    isCreateForm,
    isOpenDeleteDialog,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    handleDeleteTeam,
    isOpenDeleteErrorDialog,
    handleCloseDeleteErrorDialog,
  };
};

export default useTeamDetails;
