export const routes = {
  ROOT: {
    name: 'ROOT',
    path: '/',
  },
  MONITOR: {
    name: 'MONITOR',
    path: 'monitor',
  },
  FEATURES: {
    name: 'FEATURES',
    path: 'features',
  },
  STRATEGIES: {
    name: 'STRATEGIES',
    path: 'strategies',
  },
  TEAMS: {
    name: 'TEAMS',
    path: 'teams',
  },
  TEAM_MEMBERS: {
    name: 'TEAM MEMBERS',
    path: 'team-members',
  },
  MANAGE_STRATEGY_GROUPS: {
    name: 'MANAGE STRATEGY GROUPS',
    path: 'strategy-groups',
  },
  WELCOME: {
    name: 'AUTHORIZATION',
    path: 'welcome',
  },
  NO_ACCESS: {
    name: 'NO ACCESS',
    path: 'oops',
  },
};
