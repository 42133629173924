export enum FeaturesTabs {
  STRATEGIES = 'STRATEGIES',
  TEAM_MEMBERS = 'TEAM_MEMBERS',
  TEAMS = 'TEAMS',
}

export const STRATEGY_LIBRARY_TABS = [
  FeaturesTabs.STRATEGIES,
  FeaturesTabs.TEAM_MEMBERS,
  FeaturesTabs.TEAMS,
];
