import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReadonlyData } from '@clearalpha/common';
import { FormikSelect } from 'components/formik/fields';
import {
  groupCharacteristicSelector,
  isGlobalStrategyEditModeSelector,
} from 'store/slices/strategies';
import { useAppDispatch } from 'store';
import { fetchGroupCharacteristics } from 'store/thunks/fetchGroupCharacteristics';
import { FieldLabel } from 'components/StrategyDetails/blocks/styled';
import { GlobalStrategyDetailsFormFields } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.type';
import { StrategyFormFields } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.constants';
import { convertValuesToOptions } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.helper';

const AlphaCategory = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<GlobalStrategyDetailsFormFields>();
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);
  const groupCharacteristics = useSelector(groupCharacteristicSelector);

  useEffect(() => {
    if (!groupCharacteristics.length) {
      dispatch(fetchGroupCharacteristics());
    }
  }, [dispatch, groupCharacteristics.length]);

  const options = useMemo(
    () => convertValuesToOptions(groupCharacteristics),
    [groupCharacteristics]
  );

  return (
    <>
      <FieldLabel>{t('alphaCategory')}</FieldLabel>
      {isEditMode ? (
        <FormikSelect name={StrategyFormFields.CATEGORY} options={options} />
      ) : (
        <ReadonlyData>
          {
            groupCharacteristics.find(
              (groupCharacteristic) =>
                groupCharacteristic.id === values.category
            )?.name
          }
        </ReadonlyData>
      )}
    </>
  );
};

export default AlphaCategory;
