import i18n from 'translations/i18n';

export const GroupFields = {
  GROUP_NAME: i18n.t('groupName'),
  MANAGEMENT_TEAM: i18n.t('managementTeam'),
  GROUP_FIXED_EXPENSES: i18n.t('fixedExpenseBudget'),
  MANAGMENT_FEE: i18n.t('managementFee'),
  PERFORMANCE_FEE: i18n.t('performanceFee'),
};

export enum GroupDetailsFieldsNames {
  GROUP_NAME = 'groupName',
  FIXED_EXP = 'fixedExp',
  MGMT_FEE = 'mgmtFee',
  PERF_FEE = 'perfFee',
}
export interface GroupDetailsFields {
  groupName: string;
  fixedExp: number;
  mgmtFee: number;
  perfFee: number;
}

export const initialFormState = {
  groupName: '',
  fixedExp: 0,
  mgmtFee: 0,
  perfFee: 0,
};
