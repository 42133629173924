import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { WidgetMenu as Menu } from '@clearalpha/common';

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 34px 20px 24px;
  justify-content: space-between;
  background-color: ${({ theme: { colors } }) => colors.surface};
  width: 100%;
  height: 64px;
  overflow: visible;
`;

export const LeftSection = styled.div`
  display: flex;
  overflow: visible;
  svg {
    margin-right: 36px;
  }
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;

  button:first-of-type {
    justify-content: center;
  }

  .MuiIconButton-root {
    path {
      fill: ${({ theme: { colors } }) => colors.white};
    }
  }
`;

export const Link = styled(NavLink)`
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme: { colors } }) => colors.white};
  margin-right: 32px;
  text-decoration: none;

  &.active {
    padding-bottom: 5px;
    border-bottom: 2px solid ${({ theme: { colors } }) => colors.onSurface};
  }
`;

export const NotificationMenu = styled(Menu)`
  width: 0;
  display: flex;

  .MuiPaper-root {
    min-width: 265px;
  }
`;
