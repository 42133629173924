import { ActionMenu } from '@clearalpha/common';
import styled from '@emotion/styled';

export const ListItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 6px;
  min-height: 52px;
  gap: 150px;
`;

export const BaseText = styled.div`
  font-family: Public Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: 0.17px;
`;

export const ContentBlock = styled.div`
  margin-top: 20px;
  padding: 0px 24px;
  display: flex;
  gap: 24px;
`;

export const IconsWrapper = styled.div`
  width: 60px;
  display: flex;
  gap: 15px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledActionMenu = styled(ActionMenu)`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
`;

export const ItemWrapper = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
`;
