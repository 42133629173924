import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReadonlyData } from '@clearalpha/common';
import { ManagerFormFields } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.constants';
import {
  StatName,
  StatRow,
} from 'components/PortfolioManagerDetails/PortfolioManagerDetails.styled';
import { FormikText, FormikValue } from 'components/formik/fields';
import { isPMEditModeSelector } from 'store/slices/portfolioManagers';

const HighestDegree = () => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isPMEditModeSelector);

  return (
    <StatRow>
      <StatName>{t('highestDegree')}</StatName>

      {isEditMode ? (
        <FormikText
          name={ManagerFormFields.HIGHEST_DEGREE}
          placeholder={t('highestDegree')}
          fullWidth
        />
      ) : (
        <ReadonlyData>
          <FormikValue name={ManagerFormFields.HIGHEST_DEGREE} />
        </ReadonlyData>
      )}
    </StatRow>
  );
};

export default HighestDegree;
