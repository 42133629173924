import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useStrategyListDrawer } from 'components/StrategyList/useStrategyListDrawer';
import { getGlobalStrategyToView } from 'components/StrategyDetails/StrategyDetails.helper';
import PortfolioManagerDetails from 'components/PortfolioManagerDetails/PortfolioManagerDetails';
import TeamDetails from 'components/Teams/TeamDetails/TeamDetails';
import { teamsSelector } from 'store/slices/teams';
import { globalStrategiesSelector } from 'store/slices/strategies';
import StrategyLibraryHeader from './StrategyLibraryHeader/StrategyLibraryHeader';
import GlobalStrategyDetails from '../GlobalStrategyDetails/GlobalStrategyDetails';

const StrategyLibrary = () => {
  const globalStrategies = useSelector(globalStrategiesSelector);
  const teams = useSelector(teamsSelector);

  const getGlobalStrategy = (strategyId: string) => {
    return getGlobalStrategyToView({
      strategyId,
      strategies: globalStrategies,
    });
  };

  const {
    drawer,
    handleCloseDrawer,
    handleOpenPMDrawer,
    handleOpenStrategyDrawer,
    handleOpenGlobalStrategyDrawer,
    updateManager,
    updateDrawer,
    handleOpenTeamDrawer,
  } = useStrategyListDrawer({
    getGlobalStrategy,
    teams,
  });

  return (
    <>
      <StrategyLibraryHeader
        handleOpenStrategyDrawer={handleOpenStrategyDrawer}
        handleOpenGlobalStrategyDrawer={handleOpenGlobalStrategyDrawer}
        handleOpenPMDrawer={handleOpenPMDrawer}
        handleOpenTeamDrawer={handleOpenTeamDrawer}
      />

      <Outlet
        context={{
          handleCloseDrawer,
          handleOpenPMDrawer,
          handleOpenStrategyDrawer,
          handleOpenGlobalStrategyDrawer,
          handleOpenTeamDrawer,
        }}
      />

      <PortfolioManagerDetails
        isOpen={drawer.isOpenPMDrawer}
        portfolioManager={drawer.managerDetails}
        handleOpenTeam={handleOpenTeamDrawer}
        handleCloseDrawer={handleCloseDrawer}
        updateManager={updateManager}
      />
      <GlobalStrategyDetails
        isOpen={drawer.isOpenGlobalStrategiesDrawer}
        strategy={drawer.globalStrategyDetails}
        handleCloseDrawer={handleCloseDrawer}
        handleOpenTeam={handleOpenTeamDrawer}
        updateDrawer={updateDrawer}
      />
      <TeamDetails
        isOpen={drawer.isOpenTeamDrawer}
        handleClose={handleCloseDrawer}
        teamDetails={drawer.teamDetails}
        updateDrawer={updateDrawer}
        handleOpenTeamMember={handleOpenPMDrawer}
      />
    </>
  );
};

export default StrategyLibrary;
