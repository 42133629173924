import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { fetchMarginTypes } from 'store/thunks/fetchMarginTypes';
import { ReadonlyData } from '@clearalpha/common';
import {
  isGlobalStrategyEditModeSelector,
  marginTypesSelector,
} from 'store/slices/strategies';
import { FormikSelect } from 'components/formik/fields';
import { FieldLabel } from 'components/StrategyDetails/blocks/styled';
import { GlobalStrategyDetailsFormFields } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.type';
import { StrategyFormFields } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.constants';

const MarginType = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<GlobalStrategyDetailsFormFields>();
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);
  const marginTypes = useSelector(marginTypesSelector);

  useEffect(() => {
    if (!marginTypes.length) {
      dispatch(fetchMarginTypes());
    }
  }, [dispatch, marginTypes.length]);

  const options = useMemo(
    () =>
      marginTypes.reduce((acc: { [key: string]: string }, current) => {
        acc[current.id] = current.name;
        return acc;
      }, {}),
    [marginTypes]
  );

  return (
    <>
      <FieldLabel>{t('marginType')}</FieldLabel>
      {isEditMode ? (
        <FormikSelect name={StrategyFormFields.MARGIN_TYPE} options={options} />
      ) : (
        <ReadonlyData>
          {
            marginTypes.find(
              (marginType) => marginType.id === values.margin_type
            )?.name
          }
        </ReadonlyData>
      )}
    </>
  );
};

export default MarginType;
