import { createSlice } from '@reduxjs/toolkit';
import { GetTeam } from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { TEAMS_SLICE_NAME, TEAMS_SLICE_INITIAL_STATE } from './teams.constants';
import { TeamsState } from './teams.types';

const teams = createSlice({
  name: TEAMS_SLICE_NAME,
  initialState: TEAMS_SLICE_INITIAL_STATE,
  reducers: {
    setIsTeamLoading: (state: TeamsState, action: { payload: boolean }) => {
      state.isTeamsLoading = action.payload;
    },
    setIsTeamEditMode: (state: TeamsState, action: { payload: boolean }) => {
      state.isTeamEditMode = action.payload;
    },
    addNewTeam: (state: TeamsState, action: { payload: GetTeam }) => {
      state.teams.push(action.payload);
    },
    updateSingleTeam: (state: TeamsState, action: { payload: GetTeam }) => {
      state.teams = state.teams.map((team) => {
        if (team.id === action.payload.id) {
          return action.payload;
        }

        return team;
      });
    },
    setTeams: (state: TeamsState, action: { payload: GetTeam[] }) => {
      state.teams = action.payload;
    },
    deleteTeam: (state: TeamsState, action: { payload: string }) => {
      state.teams = state.teams.filter(({ id }) => id !== action.payload);
    },
  },
});

export const {
  updateSingleTeam,
  setIsTeamLoading,
  setIsTeamEditMode,
  addNewTeam,
  setTeams,
  deleteTeam,
} = teams.actions;

export default teams.reducer;
