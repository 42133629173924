import {
  Select as Base,
  Button as ButtonBase,
  SelectHelperText,
} from '@clearalpha/common';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 480px;
  height: 230px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  position: relative;
  padding: 24px 24px 0px 24px;
`;
export const Title = styled.div`
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.15px;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Select = styled(Base)`
  max-width: 100%;
  width: 550px;
`;

export const ErrorMessage = styled(SelectHelperText)`
  position: unset;
  text-align: left;
  margin-left: 15px;
  margin-top: 5px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  justify-self: flex-end;
  gap: 16px;
  width: 100%;
  position: absolute;
  bottom: 24px;
`;

export const Button = styled(ButtonBase)`
  width: 100px;
`;
