import { RootState } from 'store';
import { PERMISSIONS_SLICE_NAME } from './permissions.constants';

export const arePermissionsDefinedSelector = (state: RootState) =>
  state[PERMISSIONS_SLICE_NAME].arePermissionsDefined;

export const hasStrategyLibraryPermissionSelector = (state: RootState) =>
  state[PERMISSIONS_SLICE_NAME].isStrategyLibraryPermission;

export const hasSaraUserPermissionSelector = (state: RootState) =>
  state[PERMISSIONS_SLICE_NAME].isSaraUserPermission;

export const hasSaraFullPermissionSelector = (state: RootState) =>
  state[PERMISSIONS_SLICE_NAME].isSaraFullPermission;
