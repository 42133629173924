import { RootState } from 'store';
import { ChangeRequestResponse } from 'services/portfolioCore/portfolioCore.types';
import { CHANGE_REQUESTS_SLICE_NAME } from './changeRequests.constants';

export const changeRequestsSelector = (
  state: RootState
): ChangeRequestResponse[] => state[CHANGE_REQUESTS_SLICE_NAME].changeRequests;

export const selectedChangeRequestSelector = (
  state: RootState
): ChangeRequestResponse => state[CHANGE_REQUESTS_SLICE_NAME].currentRequest;
