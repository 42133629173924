import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { ReadonlyData } from '@clearalpha/common';
import { averageHoldPeriodsSelector } from 'store/slices/strategies';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { fetchAverageHoldPeriods } from 'store/thunks/fetchAverageHoldPeriods';
import { FormikSelect } from 'components/formik/fields';
import { FieldLabel, FieldWrapper } from '../../styled';
import { StrategyFormFields } from '../../../StrategyDetails.constants';
import { StrategyDetailsFormFields } from '../../../StrategyDetails.type';

const AverageHoldPeriod = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const averageHoldPeriods = useSelector(averageHoldPeriodsSelector);
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.productionValues.averageHoldingPeriod !==
      values.averageHoldingPeriod;

  useEffect(() => {
    if (!averageHoldPeriods.length) {
      dispatch(fetchAverageHoldPeriods());
    }
  }, [dispatch, averageHoldPeriods.length]);

  const options = useMemo(
    () =>
      averageHoldPeriods.reduce((acc: { [key: string]: string }, current) => {
        acc[current.id] = current.name;
        return acc;
      }, {}),
    [averageHoldPeriods]
  );

  const productionValue = averageHoldPeriods.find(
    ({ id }) => id === values.productionValues.averageHoldingPeriod
  );

  return (
    <>
      <FieldLabel>{t('averageHoldPeriod')}</FieldLabel>
      <ReadonlyData>{productionValue?.name || t('empty')}</ReadonlyData>
      {!isPortfolioReviewMode ? (
        <FormikSelect
          name={StrategyFormFields.AVERAGE_HOLD_PERIOD}
          options={options}
        />
      ) : (
        <FieldWrapper className={isValueHighlighted ? 'highlighted' : ''}>
          <ReadonlyData>
            {
              averageHoldPeriods.find(
                (averageHoldPeriod) =>
                  averageHoldPeriod.id === values.averageHoldingPeriod
              )?.name
            }
          </ReadonlyData>
        </FieldWrapper>
      )}
    </>
  );
};

export default AverageHoldPeriod;
