import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AxiosError } from 'axios';
import { deletePortfolioManager } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import { setIsPortfolioMangersLoading } from 'store/slices/portfolioManagers';
import {
  fetchGlobalStrategies,
  fetchPortfolioManagers,
  fetchTeams,
} from 'store/thunks';

export const deletePM = createAsyncThunk<
  string | AxiosError,
  { uuid: string },
  { state: RootState }
>('deletePM', async ({ uuid }, { dispatch }): Promise<string | AxiosError> => {
  dispatch(setIsPortfolioMangersLoading(true));
  try {
    const response = await deletePortfolioManager(uuid);

    dispatch(fetchTeams());
    dispatch(fetchPortfolioManagers());
    dispatch(fetchGlobalStrategies()); // TODO: Remove in UX-1245

    return response;
  } catch (e) {
    return e as AxiosError;
  } finally {
    dispatch(setIsPortfolioMangersLoading(false));
  }
});
