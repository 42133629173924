import React, { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import { List } from '@clearalpha/common';
import { ListContainer } from '../StrategyLibrary.styled';
import { StrategyLibraryOutletContextParams } from '../StrategyLibrary.types';
import { useStrategiesListConfig } from './GlobalStrategiesList.helpers';
import StrategyLibraryContent from '../StrategyLibraryContent';

const GlobalStrategiesList = () => {
  const { handleOpenGlobalStrategyDrawer } =
    useOutletContext<StrategyLibraryOutletContextParams>();

  const handleOpenGlobalStrategyDetails = useCallback(
    (id: string) => handleOpenGlobalStrategyDrawer(id),
    [handleOpenGlobalStrategyDrawer]
  );

  const { rows, columns } = useStrategiesListConfig(
    handleOpenGlobalStrategyDetails
  );

  return (
    <StrategyLibraryContent>
      <ListContainer>
        <List
          columns={columns}
          rows={rows}
          checkboxSelection
          columnBuffer={columns.length + 1}
        />
      </ListContainer>
    </StrategyLibraryContent>
  );
};

export default GlobalStrategiesList;
