import styled from '@emotion/styled';
import { Dialog } from '@clearalpha/common';
import { TableRow } from '@mui/material';

const ChangeReportDialogWrapper = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 1000px;
    width: 800px;
    border-radius: 16px;
  }
`;

const ContentWrapper = styled.div`
  background: ${({ theme: { colors } }) => colors.systemSurfaceAtPlusOne};
  font-family: Work Sans, sans-serif;
  padding: 20px;
`;

const ChangeReportDialogHeader = styled.div``;

const Title = styled.h3`
  font-size: 20px;
`;

const Message = styled.p`
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.onSurfaceVariant};
  margin-bottom: 10px;
`;

const RequestInfo = styled.p`
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.onSurfaceVariant};
  white-space: pre-line;
`;

const StrategyChangeBlock = styled.div`
  border-top: 1px solid red;
`;

const StrategySubtitle = styled.h4`
  font-size: 18px;
  color: ${({ theme: { colors } }) => colors.onSurfaceVariant};
  margin: 30px 0 5px;
`;

const SectionTitleRow = styled(TableRow)`
  td {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme: { colors } }) => colors.onSurface};
    padding: 15px 0;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 35px;

  button {
    margin-left: 10px;
  }
`;

export {
  ChangeReportDialogWrapper,
  ContentWrapper,
  ChangeReportDialogHeader,
  Title,
  Message,
  RequestInfo,
  StrategySubtitle,
  SectionTitleRow,
  ButtonGroup,
  StrategyChangeBlock,
};
