import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReadonlyData } from '@clearalpha/common';
import { ManagerFormFields } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.constants';
import { PortfolioManagerFormFields } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.type';
import {
  StatName,
  StatRow,
} from 'components/PortfolioManagerDetails/PortfolioManagerDetails.styled';
import { DatePickerField } from 'components/formik/fields';
import { isPMEditModeSelector } from 'store/slices/portfolioManagers';
import { useFormikContext } from 'formik';

const InvestingExperience = () => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isPMEditModeSelector);

  const {
    values: { investingExperienceStartDate },
  } = useFormikContext<PortfolioManagerFormFields>();

  if (isEditMode) {
    return (
      <StatRow>
        <StatName>{t('investingExperienceStartDate')}</StatName>
        <DatePickerField
          name={ManagerFormFields.INVESTING_EXPERIENCE}
          disableFuture
        />
      </StatRow>
    );
  }

  return (
    <StatRow>
      <StatName>{t('investingExperience')}</StatName>
      <ReadonlyData>
        {investingExperienceStartDate
          ? investingExperienceStartDate.fromNow(true)
          : ''}
      </ReadonlyData>
    </StatRow>
  );
};

export default InvestingExperience;
