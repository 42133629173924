import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Block,
  BlockTitle,
  DescriptiveFields,
} from 'components/StrategyDetails/blocks/styled';
import {
  MarginType,
  StrategyCategory,
  AlphaCategory,
  AverageHoldPeriod,
} from './fields';

const Descriptive = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <BlockTitle>{t('descriptive')}</BlockTitle>
      <DescriptiveFields>
        <MarginType />
        <StrategyCategory />
        <AlphaCategory />
        <AverageHoldPeriod />
      </DescriptiveFields>
    </Block>
  );
};

export default Descriptive;
