import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ActionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
`;

export const FormButtonsContainer = styled(Box)`
  display: flex;
  gap: 20px;

  button {
    width: 142px;
  }
`;
