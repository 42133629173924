import { RootState } from 'store';
import { GetPortfolioResponse } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { StrategyGroupsResponse } from 'services/portfolioCore/portfolioCore.types';
import { PortfolioPerformanceResponse } from 'services/fundCalculator/fundCalculator.types';
import { PORTFOLIOS_SLICE_NAME } from './portfolios.constants';

export const productionPortfolioSelector = (
  state: RootState
): GetPortfolioResponse => state[PORTFOLIOS_SLICE_NAME].productionPortfolio;

export const initialWorkingPortfolioSelector = (
  state: RootState
): GetPortfolioResponse => state[PORTFOLIOS_SLICE_NAME].initialWorkingPortfolio;

export const workingPortfolioSelector = (
  state: RootState
): GetPortfolioResponse => state[PORTFOLIOS_SLICE_NAME].workingPortfolio;

export const lastWorkingPortfolioSelector = (
  state: RootState
): GetPortfolioResponse => state[PORTFOLIOS_SLICE_NAME].lastWorkingPortfolio;

export const sourcePortfolioSelector = (
  state: RootState
): GetPortfolioResponse => state[PORTFOLIOS_SLICE_NAME].sourcePortfolio;

export const targetPortfolioSelector = (
  state: RootState
): GetPortfolioResponse => state[PORTFOLIOS_SLICE_NAME].targetPortfolio;

export const productionPortfolioPerformanceSelector = (
  state: RootState
): PortfolioPerformanceResponse =>
  state[PORTFOLIOS_SLICE_NAME].productionPortfolioPerformance;

export const workingPortfolioPerformanceSelector = (
  state: RootState
): PortfolioPerformanceResponse =>
  state[PORTFOLIOS_SLICE_NAME].workingPortfolioPerformance;

export const targetPortfolioPerformanceSelector = (
  state: RootState
): PortfolioPerformanceResponse =>
  state[PORTFOLIOS_SLICE_NAME].targetPortfolioPerformance;

export const sourcePortfolioPerformanceSelector = (
  state: RootState
): PortfolioPerformanceResponse =>
  state[PORTFOLIOS_SLICE_NAME].sourcePortfolioPerformance;

export const workingPortfolioOptimizationsSelector = (
  state: RootState
): PortfolioPerformanceResponse =>
  state[PORTFOLIOS_SLICE_NAME].workingPortfolioOptimizations;

export const sourcePortfolioOptimizationsSelector = (
  state: RootState
): PortfolioPerformanceResponse =>
  state[PORTFOLIOS_SLICE_NAME].sourcePortfolioOptimizations;

export const isLoadingPortfolioSelector = (state: RootState): boolean =>
  state[PORTFOLIOS_SLICE_NAME].isLoading;

export const isOptimizationLoadingSelector = (state: RootState): boolean =>
  state[PORTFOLIOS_SLICE_NAME].isOptimizationLoading;

export const isConfirmationDialogActiveSelector = (state: RootState) =>
  state[PORTFOLIOS_SLICE_NAME].isConfirmationDialogActive;

export const strategyGroupsSelector = (
  state: RootState
): StrategyGroupsResponse[] => state[PORTFOLIOS_SLICE_NAME].strategyGroups;

export const sourceStrategyGroupsSelector = (
  state: RootState
): StrategyGroupsResponse[] =>
  state[PORTFOLIOS_SLICE_NAME].sourceStrategyGroups;

export const productionStrategyGroupsSelector = (
  state: RootState
): StrategyGroupsResponse[] =>
  state[PORTFOLIOS_SLICE_NAME].productionStrategyGroups;

export const isReviewModePortfolioSelector = (state: RootState) =>
  state[PORTFOLIOS_SLICE_NAME].isReviewMode;

export const portfolioFeeTypesSelector = (state: RootState) =>
  state[PORTFOLIOS_SLICE_NAME].portfolioFeeTypes;

export const strategyGroupFeeTypesSelector = (state: RootState) =>
  state[PORTFOLIOS_SLICE_NAME].strategyGroupFeeTypes;
