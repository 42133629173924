import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { convertToPerformancePayload } from 'services/fundCalculator/fundCalculator.helper';
import { mapStateToResponse } from 'components/StrategyList/StrategyList.helpers';
import { getCustomPortfolioPerformance } from 'services/fundCalculator/fundCalculator.service';
import {
  PORTFOLIOS_SLICE_NAME,
  setIsLoading,
  setProductionPortfolioPerformance,
} from 'store/slices/portfolios';
import { STRATEGIES_SLICE_NAME } from 'store/slices/strategies';

export const fetchPerformanceProduction = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchPerformanceProduction', async (_, { getState, dispatch }) => {
  const {
    [PORTFOLIOS_SLICE_NAME]: { productionPortfolio },
    [STRATEGIES_SLICE_NAME]: { saraProductionStrategies },
  } = getState();

  const payload = {
    portfolio: convertToPerformancePayload(productionPortfolio),
    strategies: saraProductionStrategies.map(mapStateToResponse),
  };

  dispatch(setIsLoading(true));

  try {
    const performance = await getCustomPortfolioPerformance(payload);

    dispatch(setProductionPortfolioPerformance(performance));
  } finally {
    dispatch(setIsLoading(false));
  }
});
