import { object, number, ref } from 'yup';
import i18n from 'translations/i18n';
import { StrategyFormFields } from '../components/StrategyDetails/StrategyDetails.constants';
import {
  MINUS_TEN,
  ZERO,
  ONE,
  TEN,
  ONE_BILLION,
  TEN_BILLION,
  ONE_HUNDRED_BILLION,
} from './validationNumbers';
import { ARCESIUM_ID, FIELD_LENGTH } from '../constants/fields';
import { optionalText, requiredText } from './defaultValidationRules';
import { testArchetypesSum } from './validation.helpers';

export const strategyValidationSchema = object().shape({
  [StrategyFormFields.NAME]: requiredText({
    fieldName: i18n.t('name'),
  }),
  [StrategyFormFields.ARCESIUM_ID]: optionalText({
    fieldName: ARCESIUM_ID,
    maxLength: FIELD_LENGTH.ARCESIUM_ID,
  }),
  [StrategyFormFields.STRATEGY_GROUP]: object().required(
    i18n.t('errors.required', { name: i18n.t('strategyGroup') })
  ),
  [StrategyFormFields.DESCRIPTION]: optionalText({
    fieldName: i18n.t('strategyDescription'),
    maxLength: FIELD_LENGTH.LONG_TEXT,
  }),
  [StrategyFormFields.STRATEGY_EDGE]: optionalText({
    fieldName: i18n.t('strategyEdge'),
    maxLength: FIELD_LENGTH.LONG_TEXT,
  }),
  [StrategyFormFields.SCIENTIST]: object()
    .shape({
      value: number().required(),
    })
    .test({
      name: 'Test archetypes sum',
      skipAbsent: true,
      test(val, ctx) {
        if (typeof val.value === 'number') {
          return testArchetypesSum(
            val.value,
            this.parent[StrategyFormFields.ENGINEER].value,
            this.parent[StrategyFormFields.ACTUARY].value,
            ctx
          );
        }
      },
    }),
  [StrategyFormFields.ENGINEER]: object()
    .shape({
      value: number().required(),
    })
    .test({
      name: 'Test archetypes sum',
      skipAbsent: true,
      test(val, ctx) {
        if (typeof val.value === 'number') {
          return testArchetypesSum(
            this.parent[StrategyFormFields.SCIENTIST].value,
            val.value,
            this.parent[StrategyFormFields.ACTUARY].value,
            ctx
          );
        }
      },
    })
    .required(),
  [StrategyFormFields.ACTUARY]: object()
    .shape({
      value: number().required(),
    })
    .test({
      name: 'Test archetypes sum',
      skipAbsent: true,
      test(val, ctx) {
        if (typeof val.value === 'number') {
          return testArchetypesSum(
            this.parent[StrategyFormFields.SCIENTIST].value,
            this.parent[StrategyFormFields.ENGINEER].value,
            val.value,
            ctx
          );
        }
      },
    })
    .required(),
  [StrategyFormFields.GROSS_SHARPE]: number()
    .min(
      MINUS_TEN,
      i18n.t('errors.notLessThan', {
        name: i18n.t('grossSharpeRatio'),
        value: MINUS_TEN,
      })
    )
    .max(
      TEN,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('grossSharpeRatio'),
        value: TEN,
      })
    )
    .required(),
  [StrategyFormFields.MARGIN_TYPE]: requiredText({
    fieldName: i18n.t('marginType'),
  }),
  [StrategyFormFields.GROUP_CHARACTERISTIC]: requiredText({
    fieldName: i18n.t('alphaCategory'),
  }),
  [StrategyFormFields.AVERAGE_HOLD_PERIOD]: requiredText({
    fieldName: i18n.t('averageHoldPeriod'),
  }),
  [StrategyFormFields.STRATEGY_VOLATILITY_TARGET]: number()
    .moreThan(
      ZERO,
      i18n.t('errors.notLessOrEqualTo', {
        name: i18n.t('volatilityTarget'),
        value: ZERO,
      })
    )
    .max(
      TEN,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('volatilityTarget'),
        value: TEN,
      })
    )
    .required(),
  [StrategyFormFields.ALLOCATION_MIN]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('minAllocation'),
        value: ZERO,
      })
    )
    .test({
      name: 'Test min allocation value',
      skipAbsent: true,
      test(value, ctx) {
        if (value && value > TEN_BILLION) {
          return ctx.createError({
            message: i18n.t('errors.notMoreThan', {
              name: i18n.t('minAllocation'),
              value: '10B',
            }),
          });
        }
        if (value && value > this.parent[StrategyFormFields.ALLOCATION_TERMS]) {
          return ctx.createError({
            message: i18n.t('errors.notMoreThan', {
              name: i18n.t('minAllocation'),
              value: i18n.t('aumCapacity'),
            }),
          });
        }
        return true;
      },
    })
    .required(),
  [StrategyFormFields.ALLOCATION_TERMS]: number()
    .min(
      ref(StrategyFormFields.ALLOCATION_MIN),
      i18n.t('errors.notLessThan', {
        name: i18n.t('aumCapacity'),
        value: i18n.t('minAllocation'),
      })
    )
    .max(
      ONE_HUNDRED_BILLION,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('aumCapacity'),
        value: '100B',
      })
    )
    .required(),
  [StrategyFormFields.ACCOUNT_VALUE]: number()
    .test({
      name: 'Test notional account value',
      skipAbsent: true,
      test(value, ctx) {
        if (value === ZERO) {
          return true;
        }
        if (value && value < this.parent[StrategyFormFields.ALLOCATION_MIN]) {
          return ctx.createError({
            message: i18n.t('errors.notLessThanOrEqualTo', {
              name: i18n.t('notionalAccountValueShort'),
              lessThan: i18n.t('minAllocation'),
              equal: '$0',
            }),
          });
        }
        if (value && value > ONE_HUNDRED_BILLION) {
          return ctx.createError({
            message: i18n.t('errors.notMoreThan', {
              name: i18n.t('notionalAccountValueShort'),
              value: '$100B',
            }),
          });
        }
        if (value && value > this.parent[StrategyFormFields.ALLOCATION_TERMS]) {
          return ctx.createError({
            message: i18n.t('errors.notMoreThanOrEqualTo', {
              name: i18n.t('notionalAccountValueShort'),
              largerThan: i18n.t('aumCapacity'),
              equal: '$0',
            }),
          });
        }
        return true;
      },
    })
    .required(),
  [StrategyFormFields.FEE_MANAGEMENT]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('managementFee'),
        value: '0%',
      })
    )
    .max(
      ONE,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('managementFee'),
        value: '100%',
      })
    )
    .required(),
  [StrategyFormFields.FEE_PERFORMANCE]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('performanceFee'),
        value: '0%',
      })
    )
    .max(
      ONE,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('performanceFee'),
        value: '100%',
      })
    )
    .required(),
  [StrategyFormFields.EXPENSES]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('expenseBudget'),
        value: '$0',
      })
    )
    .max(
      ONE_BILLION,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('expenseBudget'),
        value: '$1B',
      })
    )
    .required(),
});
