import styled from '@emotion/styled';

const ContentContainer = styled.div`
  background-color: ${({ theme: { colors } }) => colors.surface};
`;

// TODO: IN TASK WITH CHANGING TAB ADD BREAKPOINTS FOR CONTAINER
const DashboardContainer = styled.div`
  overflow: hidden;
`;

export { ContentContainer, DashboardContainer };
