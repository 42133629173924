import * as yup from 'yup';
import { FIELD_LENGTH } from 'constants/fields';
import { LATIN_CHARACTERS_NUMBER_SYMBOLS_SPACES_REG_EXP } from 'constants/regExp';
import i18n from 'translations/i18n';
import dayjs, { Dayjs, isDayjs } from 'dayjs';

export const optionalText = ({
  fieldName,
  maxLength = FIELD_LENGTH.SHORT_TEXT,
}: {
  fieldName: string;
  maxLength?: number;
}) => {
  return yup
    .string()
    .trim()
    .test(
      'latin-characters-numbers',
      i18n.t('errors.latinCharactersNumbersSpecialSymbols'),
      (value) => {
        if (!value || value?.trim()?.length === 0) {
          return true;
        }

        return !!value?.match(LATIN_CHARACTERS_NUMBER_SYMBOLS_SPACES_REG_EXP);
      }
    )
    .max(
      maxLength,
      i18n.t('errors.maxLength', { name: fieldName, length: maxLength })
    )
    .nullable();
};

export const requiredText = ({
  fieldName,
  maxLength = FIELD_LENGTH.SHORT_TEXT,
}: {
  fieldName: string;
  maxLength?: number;
}) =>
  optionalText({ fieldName, maxLength }).required(
    i18n.t('errors.required', { name: fieldName })
  );

export const optionalDate = () => {
  return yup
    .object()
    .nullable()
    .test('valid-format', i18n.t('errors.invalidFormat'), (value) => {
      if (!value) return true;

      return isDayjs(value) && value.isValid();
    });
};

export const optionalPastDate = ({ fieldName }: { fieldName: string }) => {
  return optionalDate().test(
    'before-or-today',
    i18n.t('errors.cannotBeInTheFuture', { name: fieldName }),
    (value) => {
      if (!value || !isDayjs(value)) return true;

      return (value as Dayjs).isToday() || (value as Dayjs).isBefore(dayjs());
    }
  );
};

export const requiredPastDate = ({ fieldName }: { fieldName: string }) => {
  return optionalPastDate({ fieldName }).test(
    'not-empty',
    i18n.t('errors.required', { name: fieldName }),
    (value) => !!value
  );
};
