import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TableBody, TableRow } from '@mui/material';
import {
  TableWidgetBackbone,
  TableDelimiter,
  TitleCell,
  ValueCell,
} from '@clearalpha/common/dist/components/Dashboard/Widgets/TableWidgetBackbone';
import {
  FieldValue,
  FieldValueWarning,
  THEME_COLORS,
} from '@clearalpha/common';
import {
  productionPortfolioPerformanceSelector,
  productionPortfolioSelector,
  isReviewModePortfolioSelector,
  workingPortfolioPerformanceSelector,
  workingPortfolioSelector,
  sourcePortfolioSelector,
  targetPortfolioSelector,
  targetPortfolioPerformanceSelector,
  sourcePortfolioPerformanceSelector,
} from 'store/slices/portfolios';
import { PERFORMANCE_STATS_WIDGET_CONFIG } from './ProjectedPerformanceStatsWidget.const';
import { getPerformanceStatsWidgetData } from './ProjectedPerformanceStatsWidget.helper';

const ProjectedPerformanceStatsWidget = () => {
  const { t } = useTranslation();

  const productionPerformance = useSelector(
    productionPortfolioPerformanceSelector
  );
  const targetPerformance = useSelector(targetPortfolioPerformanceSelector);
  const workingPerformance = useSelector(workingPortfolioPerformanceSelector);
  const sourcePerformance = useSelector(sourcePortfolioPerformanceSelector);
  const productionPortfolio = useSelector(productionPortfolioSelector);
  const targetPortfolio = useSelector(targetPortfolioSelector);
  const workingPortfolio = useSelector(workingPortfolioSelector);
  const sourcePortfolio = useSelector(sourcePortfolioSelector);
  const isReviewMode = useSelector(isReviewModePortfolioSelector);

  const currentPortfolio = isReviewMode ? sourcePortfolio : workingPortfolio;
  const currentProduction = isReviewMode
    ? targetPortfolio
    : productionPortfolio;
  const currentProductionPerformance = isReviewMode
    ? targetPerformance
    : productionPerformance;
  const currentWorkingPerformance = isReviewMode
    ? sourcePerformance
    : workingPerformance;

  const { data: production } = useMemo(
    () =>
      getPerformanceStatsWidgetData(
        currentProductionPerformance.portfolio,
        currentProduction
      ),
    [currentProductionPerformance.portfolio, currentProduction]
  );

  const { data: scenario, warnings: scenarioWarnings } = useMemo(
    () =>
      getPerformanceStatsWidgetData(
        currentWorkingPerformance.portfolio,
        currentPortfolio,
        currentWorkingPerformance.warnings
      ),
    [
      currentWorkingPerformance.portfolio,
      currentPortfolio,
      currentWorkingPerformance.warnings,
    ]
  );

  return (
    <TableWidgetBackbone title={t('widgets.projectedPerformance')}>
      <TableBody>
        {PERFORMANCE_STATS_WIDGET_CONFIG.map(({ ...row }) => (
          <Fragment key={row.variableName}>
            <TableRow>
              <TitleCell>{row.fieldName}</TitleCell>
              <ValueCell align='right'>
                <FieldValue
                  row={{ ...row }}
                  value={production[row.variableName]}
                />
              </ValueCell>
              <ValueCell
                align='right'
                style={
                  isReviewMode &&
                  scenario[row.variableName] !== production[row.variableName]
                    ? { backgroundColor: THEME_COLORS.highlightColor }
                    : {}
                }
              >
                <FieldValue
                  row={{ ...row }}
                  value={scenario[row.variableName]}
                />
                {scenarioWarnings[row.variableName] && (
                  <FieldValueWarning>
                    {scenarioWarnings[row.variableName]?.join(' ')}
                  </FieldValueWarning>
                )}
              </ValueCell>
            </TableRow>
            {row.hasDelimiter && <TableDelimiter />}
          </Fragment>
        ))}
      </TableBody>
    </TableWidgetBackbone>
  );
};

export default ProjectedPerformanceStatsWidget;
