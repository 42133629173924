import { FullPortfolioStrategy } from 'services/portfolioCore/portfolioCore.types';
import { FormikHelpers, useFormik } from 'formik';
import { useState } from 'react';
import { correlationsValidationSchema } from 'validation/correlationsValidationSchema';
import { CorrelationsDialogFormFields } from 'components/Dialogs/CorrelationsDialog/CorrelationsDialog.constants';
import {
  Correlation,
  PortfolioCorrelationSet,
} from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import {
  CorrelationFormField,
  InitialValues,
  UpdateCorrelationRequest,
  CorrelationField,
} from './CorrelationsDialog.type';

export const getStrategyName = (
  strategies: FullPortfolioStrategy[],
  strategyId: string
) => {
  return strategies.find(({ id }) => id === strategyId)?.strategy.name || null;
};

export const getStrategyInitials = (
  strategies: FullPortfolioStrategy[],
  strategyId: string
) => {
  const strategyName = getStrategyName(strategies, strategyId);
  const firstLetters = strategyName
    ?.split(' ')
    .map((word) => word.charAt(0))
    .filter((firstLetter) => /[A-Z]/.test(firstLetter))
    .join('')
    .slice(0, 4);
  return firstLetters || '';
};

export const correlationsToFormFields = (
  correlations: Correlation[]
): CorrelationFormField[] => {
  const strategyIds: string[] = [];

  correlations.forEach(({ firstStrategyId, secondStrategyId }) => {
    if (!strategyIds.includes(firstStrategyId)) {
      strategyIds.push(firstStrategyId);
    } else if (!strategyIds.includes(secondStrategyId)) {
      strategyIds.push(secondStrategyId);
    }
  });

  const correlationFormFields = strategyIds.map((id) => {
    const correlationsArr: CorrelationField[] = [];

    correlations.forEach(({ firstStrategyId, secondStrategyId, value }) => {
      if (id === firstStrategyId) {
        correlationsArr.push({
          portfolioStrategyId: secondStrategyId,
          correlation: value,
        });
      } else if (id === secondStrategyId) {
        correlationsArr.push({
          portfolioStrategyId: firstStrategyId,
          correlation: value,
        });
      }
    });

    return {
      portfolioStrategyId: id,
      correlations: correlationsArr.sort((a, b) =>
        a.portfolioStrategyId.localeCompare(b.portfolioStrategyId)
      ),
    };
  });

  return correlationFormFields;
};

export const transformMatrixToRequest = (
  correlationValues: CorrelationFormField[]
): UpdateCorrelationRequest[] => {
  const uniquePairs = new Set<string>();

  return correlationValues.reduce(
    (acc: UpdateCorrelationRequest[], correlationObject) => {
      const { correlations, portfolioStrategyId: firstPortfolioStrategyId } =
        correlationObject;

      correlations.forEach((correlation) => {
        const {
          portfolioStrategyId: secondPortfolioStrategyId,
          correlation: correlationValue,
        } = correlation;

        const pairKey = [firstPortfolioStrategyId, secondPortfolioStrategyId]
          .sort()
          .join();

        if (!uniquePairs.has(pairKey)) {
          uniquePairs.add(pairKey);

          acc.push({
            firstPortfolioStrategyId,
            secondPortfolioStrategyId,
            correlation: correlationValue,
          });
        }
      });

      return acc;
    },
    []
  );
};

export const useCorrelationsForm = (
  isOpen: boolean,
  handleClose: () => void,
  correlationSets: PortfolioCorrelationSet[]
) => {
  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] = useState(false);

  const regularCorrelations = correlationSets.find(
    ({ name }) => name === CorrelationsDialogFormFields.REGULAR
  )?.correlations;
  const tailCorrelations = correlationSets.find(
    ({ name }) => name === CorrelationsDialogFormFields.TAIL
  )?.correlations;

  const initialValuesRegular: InitialValues = {
    correlations: correlationsToFormFields(
      regularCorrelations as Correlation[]
    ),
  };

  const initialValuesTail: InitialValues = {
    correlations: correlationsToFormFields(tailCorrelations as Correlation[]),
  };

  const handleSubmitRegular = (
    values: InitialValues,
    { resetForm }: FormikHelpers<InitialValues>
  ) => {
    // TODO: Fix this in the scope of the task to update the correlation
    // dispatch(setRegularCorrelations(values.correlations));
    resetForm({ values });
  };

  const handleSubmitTail = (
    values: InitialValues,
    { resetForm }: FormikHelpers<InitialValues>
  ) => {
    // TODO: Fix this in the scope of the task to update the correlation
    // dispatch(setTailCorrelations(values.correlations));
    resetForm({ values });
  };

  const formikRegular = useFormik({
    initialValues: initialValuesRegular,
    onSubmit: handleSubmitRegular,
    validationSchema: correlationsValidationSchema,
  });

  const formikTail = useFormik({
    initialValues: initialValuesTail,
    onSubmit: handleSubmitTail,
    validationSchema: correlationsValidationSchema,
  });

  const { dirty: dirtyRegular, resetForm: resetFormRegular } = formikRegular;
  const { dirty: dirtyTail, resetForm: resetFormTail } = formikTail;

  const handleCancelUnsavedChanges = () => {
    setIsOpenUnsavedChanges(false);
  };

  const handleConfirmUnsavedChanges = () => {
    handleCancelUnsavedChanges();
    resetFormRegular();
    resetFormTail();
    handleClose();
  };

  const handleCancel = () => {
    if (dirtyRegular || dirtyTail) {
      return setIsOpenUnsavedChanges(true);
    }
    handleClose();
  };

  return {
    formikRegular,
    formikTail,
    handleConfirmUnsavedChanges,
    handleCancel,
    handleCancelUnsavedChanges,
    isOpenUnsavedChanges,
    initialValuesRegular,
    initialValuesTail,
  };
};
