import styled from '@emotion/styled';

export const TeamMembers = styled.ul`
  list-style: none;
  padding: 0;
`;

export const TeamMember = styled.li<{ isEditMode?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.outline};
  word-break: break-word;
`;

export const TeamMemberInfo = styled.div``;

export const TeamMemberName = styled.div``;

export const TeamMemberNameLink = styled.button<{ isEditMode?: boolean }>`
  background: transparent;
  border: none;
  padding: 0;
  font-family: Work Sans, sans-serif;
  color: ${({ theme: { colors } }) => colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  cursor: ${({ isEditMode }) => (isEditMode ? 'default' : 'pointer')};
  outline: none;
`;

export const TeamMemberRole = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  color: ${({ theme: { colors } }) => colors.whiteOpacity70};
`;

export const TeamMemberActions = styled.div`
  display: flex;
  align-items: center;

  & > button {
    margin-left: 12px;
  }
`;
