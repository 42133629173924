import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { FormikValue } from 'components/formik/fields';
import { Description as StyledDescription } from './Description.styled';
import { Block, BlockTitle } from '../styled';
import { StrategyFormFields } from '../../StrategyDetails.constants';
import { StrategyDetailsFormFields } from '../../StrategyDetails.type';

const Description = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.productionValues.description !== values.description;

  return (
    <Block>
      <BlockTitle>{t('strategyDescription')}</BlockTitle>
      <StyledDescription className={isValueHighlighted ? 'highlighted' : ''}>
        <FormikValue name={StrategyFormFields.DESCRIPTION} />
      </StyledDescription>
    </Block>
  );
};

export default Description;
