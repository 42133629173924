import {
  FullPortfolioStrategy,
  PortfolioFeeTypes,
} from 'services/portfolioCore/portfolioCore.types';
import {
  AssetCategory,
  AverageHoldPeriod,
  GroupCharacteristic,
  MarginType,
  GlobalStrategiesResponse,
} from 'services/strategyCatalog/strategyCatalog.type';

export const STRATEGIES_SLICE_NAME = 'strategies';

export const STRATEGIES_SLICE_INITIAL_STATE = {
  initialStrategies: [] as FullPortfolioStrategy[],
  strategies: [] as FullPortfolioStrategy[],
  saraProductionStrategies: [] as FullPortfolioStrategy[],
  sourceStrategies: [] as FullPortfolioStrategy[],
  targetStrategies: [] as FullPortfolioStrategy[],
  globalStrategies: [] as GlobalStrategiesResponse[],
  isLoadingStrategies: false,
  isGlobalStrategyEditMode: false,
  marginTypes: [] as MarginType[],
  isLoadingMarginTypes: false,
  groupCharacteristics: [] as GroupCharacteristic[],
  isLoadingGroupCharacteristics: false,
  averageHoldPeriods: [] as AverageHoldPeriod[],
  isLoadingAverageHoldPeriods: false,
  assetCategories: [] as AssetCategory[],
  isLoadingAssetCategories: false,
  strategiesFeeTypes: [] as PortfolioFeeTypes[],
  strategiesColors: {},
};
