import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PieChartsWidget, SortingOrder } from '@clearalpha/common';
import {
  productionPortfolioPerformanceSelector,
  workingPortfolioPerformanceSelector,
  sourcePortfolioPerformanceSelector,
  targetPortfolioPerformanceSelector,
  isReviewModePortfolioSelector,
} from 'store/slices/portfolios';
import { getRiskAllocationPieChartData } from './RiskAllocationWidget.helper';

const RiskAllocationWidget = () => {
  const { t } = useTranslation();

  const isReviewMode = useSelector(isReviewModePortfolioSelector);
  const productionPerformance = useSelector(
    productionPortfolioPerformanceSelector
  );
  const workingPerformance = useSelector(workingPortfolioPerformanceSelector);
  const sourcePerformance = useSelector(sourcePortfolioPerformanceSelector);
  const targetPerformance = useSelector(targetPortfolioPerformanceSelector);

  const currentWorkingPerformance = isReviewMode
    ? sourcePerformance
    : workingPerformance;
  const currentProdPerformance = isReviewMode
    ? targetPerformance
    : productionPerformance;

  const prodPie = getRiskAllocationPieChartData(
    currentProdPerformance.portfolio.riskAllocationPct
  );

  const scenarioPie = getRiskAllocationPieChartData(
    currentWorkingPerformance.portfolio.riskAllocationPct
  );

  return (
    <PieChartsWidget
      title={t('riskAllocation')}
      prodPie={prodPie}
      workPie={scenarioPie}
      sortingOrder={SortingOrder.DESC}
    />
  );
};

export default RiskAllocationWidget;
