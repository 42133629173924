import React from 'react';
import {
  StyledSectionRow,
  SectionRowName,
  SectionRowContent,
} from './Section.styled';
import { SectionRowProps } from './Section.types';

const SectionRow = ({ title, children }: SectionRowProps) => (
  <StyledSectionRow>
    <SectionRowName>{title}</SectionRowName>
    <SectionRowContent>{children}</SectionRowContent>
  </StyledSectionRow>
);

export default SectionRow;
