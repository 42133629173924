import { array, number, object } from 'yup';
import { CorrelationsDialogFormFields } from 'components/Dialogs/CorrelationsDialog/CorrelationsDialog.constants';
import { MINUS_ONE, ONE } from './validationNumbers';

export const correlationsValidationSchema = object().shape({
  [CorrelationsDialogFormFields.CORRELATIONS]: array().of(
    object().shape({
      correlations: array().of(
        object().shape({
          correlation: number().min(MINUS_ONE).max(ONE).required(),
        })
      ),
    })
  ),
});
