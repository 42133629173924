import React from 'react';
import {
  TableHeadCell,
  TableHeadProdCell,
  TableHeadWorkCell,
} from '@clearalpha/common/src/components/Dashboard/Widgets/TableWidgetBackbone/styled';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TitleCell } from '@clearalpha/common/dist/components/Dashboard/Widgets/TableWidgetBackbone';
import { STRING_EMPTY } from 'store/slices/portfolios';
import {
  TextButton,
  FilledButton,
} from 'components/ChangeRequests/ApprovalHeader/styled';
import {
  ChangeReportDialogHeader,
  ChangeReportDialogWrapper,
  ContentWrapper,
  Message,
  RequestInfo,
  Title,
  StrategySubtitle,
  // StrategyChangeBlock,
  SectionTitleRow,
  ButtonGroup,
} from './ChangeReportDialog.styled';
import { collectPortfolioChangesToConfigs } from './ChangeReportDialog.helpers';
import { ChangeReportDialogProps } from './ChangeReportDialog.type';
import { ChangeReportDialogTable } from './ChangeReportDialogTable/ChangeReportDialogTable';
import { ChangeReportDialogTableStrategies } from './ChangeReportDialogTable/ChangeReportDialogTableStrategies';

const ChangeReportDialog = ({
  isOpen,
  handleClose,
  sourcePortfolio,
  targetPortfolio,
  sourceStrategies,
  targetStrategies,
  sourcePerformance,
  targetPerformance,
  submitText,
  changeReportText,
  changeReportInfo,
  handleSubmit,
}: ChangeReportDialogProps) => {
  const {
    fundLevelDiffsConfig,
    strategyLevelDiffsConfig,
    strategyGroupDiffsConfig,
    addedStrategiesConfig,
    deletedStrategiesConfig,
    editedStrategiesConfig,
    // addedGroupsConfig,
    // deletedGroupsConfig,
  } = collectPortfolioChangesToConfigs(
    sourcePortfolio,
    targetPortfolio,
    sourceStrategies,
    targetStrategies,
    sourcePerformance,
    targetPerformance
  );
  const { t } = useTranslation();

  return (
    <ChangeReportDialogWrapper open={isOpen} onClose={handleClose}>
      <ContentWrapper>
        <ChangeReportDialogHeader>
          <Title>{t('dialogs.changeReport.title')}</Title>
          <Message>{changeReportText}</Message>
          <RequestInfo>{changeReportInfo}</RequestInfo>
        </ChangeReportDialogHeader>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell />
              <TableHeadProdCell align='right'>
                {t('production')}
              </TableHeadProdCell>
              <TableHeadWorkCell align='right'>
                {t('scenario')}
              </TableHeadWorkCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!fundLevelDiffsConfig.length && (
              <SectionTitleRow>
                <TitleCell colSpan={3}>{t('fundLevelChanges')}</TitleCell>
              </SectionTitleRow>
            )}
            {fundLevelDiffsConfig.map((configRow) => (
              <React.Fragment key={configRow.fieldName}>
                <ChangeReportDialogTable
                  name={STRING_EMPTY}
                  config={configRow}
                />
              </React.Fragment>
            ))}
            {!!editedStrategiesConfig.length && (
              <SectionTitleRow>
                <TitleCell colSpan={3}>{t('strategyChanged')}</TitleCell>
              </SectionTitleRow>
            )}
            {editedStrategiesConfig.map(({ id, name, config }) => (
              <React.Fragment key={id}>
                <ChangeReportDialogTableStrategies
                  name={name}
                  config={config}
                />
              </React.Fragment>
            ))}
            {!!addedStrategiesConfig.length && (
              <SectionTitleRow>
                <TitleCell colSpan={3}>{t('strategyAdded')}</TitleCell>
              </SectionTitleRow>
            )}
            {addedStrategiesConfig.map(({ id, name, config }) => (
              <React.Fragment key={id}>
                <ChangeReportDialogTableStrategies
                  name={name}
                  config={config}
                />
              </React.Fragment>
            ))}
            {!!deletedStrategiesConfig.length && (
              <SectionTitleRow>
                <TitleCell colSpan={3}>{t('strategyDeleted')}</TitleCell>
              </SectionTitleRow>
            )}
            {deletedStrategiesConfig.map(({ id, name, config }) => (
              <React.Fragment key={id}>
                <ChangeReportDialogTableStrategies
                  name={name}
                  config={config}
                />
              </React.Fragment>
            ))}

            {/* TODO: ADD groups when endpoint will be ready */}

            {/* {!!addedGroupsConfig.length && (
              <SectionTitleRow>
                <TitleCell colSpan={3}>{t('groupAdded')}</TitleCell>
              </SectionTitleRow>
            )}
            {addedGroupsConfig.map(({ id, name, config }) => (
              <React.Fragment key={id}>
                <ChangeReportDialogTableStrategies
                  name={name}
                  config={config}
                />
              </React.Fragment>
            ))}
            {!!deletedGroupsConfig.length && (
              <SectionTitleRow>
                <TitleCell colSpan={3}>{t('groupDeleted')}</TitleCell>
              </SectionTitleRow>
            )}
            {deletedGroupsConfig.map(({ id, name, config }) => (
              <React.Fragment key={id}>
                <ChangeReportDialogTableStrategies
                  name={name}
                  config={config}
                />
              </React.Fragment>
            ))}
            TODO: ADD edited groups
            <SectionTitleRow>
              <TitleCell colSpan={3}>{t('groupEdited')}</TitleCell>
            </SectionTitleRow> */}
            {!!strategyLevelDiffsConfig.length && (
              <SectionTitleRow>
                <TitleCell colSpan={3}>{t('strategyLevelChanges')}</TitleCell>
              </SectionTitleRow>
            )}
            {strategyLevelDiffsConfig.map((strategy) => (
              <React.Fragment key={strategy.id}>
                <TableRow>
                  <TitleCell>
                    <StrategySubtitle>{strategy.name}</StrategySubtitle>
                  </TitleCell>
                </TableRow>
                {strategy.config.map((configRow) => (
                  <React.Fragment key={configRow.fieldName}>
                    <ChangeReportDialogTable
                      name={STRING_EMPTY}
                      config={configRow}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
            {!!strategyGroupDiffsConfig.length && (
              <SectionTitleRow>
                <TitleCell colSpan={3}>{t('strategyGroups')}</TitleCell>
              </SectionTitleRow>
            )}
            {strategyGroupDiffsConfig.map((group) => (
              <React.Fragment key={group.id}>
                <TableRow>
                  <TitleCell>
                    <StrategySubtitle>{group.name}</StrategySubtitle>
                  </TitleCell>
                </TableRow>
                {group.config.map((configRow) => (
                  <React.Fragment key={configRow.fieldName}>
                    <ChangeReportDialogTable
                      name={STRING_EMPTY}
                      config={configRow}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <ButtonGroup>
          <TextButton variant='text' onClick={handleClose}>
            {t('actions.cancel')}
          </TextButton>
          <FilledButton variant='filled' onClick={handleSubmit}>
            {submitText}
          </FilledButton>
        </ButtonGroup>
      </ContentWrapper>
    </ChangeReportDialogWrapper>
  );
};

export { ChangeReportDialog };
