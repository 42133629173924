import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAverageHoldPeriods } from 'services/strategyCatalog/strategyCatalog.service';
import { RootState } from 'store';
import {
  setLoadingAverageHoldPeriods,
  setAverageHoldPeriods,
} from 'store/slices/strategies';

export const fetchAverageHoldPeriods = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchAverageHoldPeriods', async (_, { dispatch }) => {
  dispatch(setLoadingAverageHoldPeriods(true));

  try {
    const averageHoldPeriods = await getAverageHoldPeriods();

    dispatch(setAverageHoldPeriods(averageHoldPeriods));
  } finally {
    dispatch(setLoadingAverageHoldPeriods(false));
  }
});
