import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getPortfoliosProduction,
  getAllPortfolioGroups,
} from 'services/portfolioCore/portfolioCore.service';
import { getLastVersionPortfolio } from 'services/portfolioCore/portfolioCore.helper';
import { setIsLoading } from 'store/slices/portfolios';
import { getAllStrategiesByIds } from 'services/strategyCatalog/strategyCatalog.service';
import {
  FullPortfolioStrategy,
  initialPortfolioValues,
} from 'services/portfolioCore/portfolioCore.types';
import {
  setProductionPortfolioAndFetchPerformance,
  setProductionStrategyGroups,
} from 'store/slices/portfolios/portfolios.slice';
import { setSaraProductionStrategies } from '../slices/strategies';

export const productionInit = createAsyncThunk(
  'productionInit',
  async (_, { dispatch }) => {
    dispatch(setIsLoading(true));

    try {
      const [productionPortfolios] = await Promise.all([
        getPortfoliosProduction(),
      ]);

      const initialProdPortfolios = productionPortfolios.length
        ? productionPortfolios
        : [initialPortfolioValues];

      const lastProductionPortfolio = getLastVersionPortfolio(
        initialProdPortfolios
      );

      const portfolioProdStrategiesIds =
        lastProductionPortfolio.strategyAllocations.map(
          (current) => current.strategyId
        );

      const [prodPortfolioStrategies] = await Promise.all([
        getAllStrategiesByIds(portfolioProdStrategiesIds),
      ]);

      const [prodStrategyGroups] = await Promise.all([
        getAllPortfolioGroups(lastProductionPortfolio.id),
      ]);

      const mappedProdStrategies: FullPortfolioStrategy[] =
        lastProductionPortfolio.strategyAllocations.map((portfolioStrategy) => {
          return {
            ...portfolioStrategy,
            strategy: prodPortfolioStrategies.find(
              (strategy) => strategy.id === portfolioStrategy.strategyId
            )!,
            strategyGroup: prodStrategyGroups.find(
              (current) => current.id === portfolioStrategy.strategyGroupId
            )!,
          };
        });

      dispatch(setSaraProductionStrategies(mappedProdStrategies));
      dispatch(setProductionStrategyGroups(prodStrategyGroups));
      dispatch(
        setProductionPortfolioAndFetchPerformance(lastProductionPortfolio)
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
