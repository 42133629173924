import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatToCommonDate, localizeDatetime } from '@clearalpha/common';
import { ChangeRequestNotificationProps } from './ChangeRequestNotification.type';
import { Title, Message, ChangeRequestInfo, NotificationItem } from './styled';

const ChangeRequestNotification = ({
  handleViewClick,
  changeRequest,
}: ChangeRequestNotificationProps) => {
  const { t } = useTranslation();
  const dateTimeLocalized = localizeDatetime(changeRequest?.createdAt || '');
  const { time, date } = formatToCommonDate(dateTimeLocalized);

  return (
    <NotificationItem
      onClick={handleViewClick}
      data-key={changeRequest.id}
      data-testid='notification'
    >
      <Title>{t('dialogs.changeRequest.title')}</Title>
      <Message>{t('dialogs.changeRequest.message')}</Message>
      <ChangeRequestInfo>
        {t('dialogs.changeRequest.info', {
          date: `${date}`,
          time: `${time}`,
        })}
      </ChangeRequestInfo>
    </NotificationItem>
  );
};

export default ChangeRequestNotification;
