import { number, object } from 'yup';
import i18n from 'translations/i18n';
import { GroupDetailsFieldsNames } from 'components/ManageGroupDetails/ManageGroupDetails.type';
import { requiredText } from './defaultValidationRules';
import { ONE_BILLION, ONE_HUNDRED } from './validationNumbers';

export const manageGroupDetailsValidation = object().shape({
  [GroupDetailsFieldsNames.GROUP_NAME]: requiredText({
    fieldName: i18n.t('createGroupFields.name'),
  }),
  [GroupDetailsFieldsNames.MGMT_FEE]: number().max(
    ONE_HUNDRED,
    i18n.t('errors.notMoreThan', {
      name: i18n.t('managementFee'),
      value: '100%',
    })
  ),
  [GroupDetailsFieldsNames.PERF_FEE]: number().max(
    ONE_HUNDRED,
    i18n.t('errors.notMoreThan', {
      name: i18n.t('performanceFee'),
      value: '100%',
    })
  ),
  [GroupDetailsFieldsNames.FIXED_EXP]: number().max(
    ONE_BILLION,
    i18n.t('errors.notMoreThan', {
      name: i18n.t('groupFixedExpenses'),
      value: '1B',
    })
  ),
});
