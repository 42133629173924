import { v4 as uuidv4 } from 'uuid';
import { AbstractLayout, SaraWidgets } from '@clearalpha/common';
import { AlphaArchetype, StrategyStatuses } from 'constants/strategy';
import {
  EntityIdInfo,
  GetPortfolioStrategies,
  StrategyGroup,
  StrategyType,
} from 'services/portfolioCore/portfolioCore.types';
import {
  AlphaCategoryWithValue,
  AssetCategory,
  AverageHoldPeriod,
  GroupCharacteristic,
  PbMapping,
} from 'services/strategyCatalog/strategyCatalog.type';

export interface SaraWrapperProps {
  dashboardContainerHeight: string | number;
}

export const SaraAbstractConfig: AbstractLayout[] = [
  {
    id: uuidv4(),
    type: SaraWidgets.FUND_OVERVIEW,
    priority: 0,
    isTab: false,
    mobile: {
      height: 1,
      width: 1,
    },
    tablet: {
      width: 2,
      height: 2,
    },
    laptop: {
      width: 2,
      height: 2,
    },
  },
  {
    id: uuidv4(),
    type: SaraWidgets.PROJECTED_PERFORMANCE_STATISTICS,
    priority: 1,
    isTab: false,
    mobile: {
      height: 1,
      width: 1,
    },
    tablet: {
      width: 2,
      height: 2,
    },
    laptop: {
      width: 2,
      height: 2,
    },
  },
  {
    id: uuidv4(),
    type: SaraWidgets.VOLATILITY,
    priority: 2,
    isTab: false,
    mobile: {
      height: 1,
      width: 1,
    },
    tablet: {
      width: 2,
      height: 2,
    },
    laptop: {
      width: 2,
      height: 2,
    },
  },
  {
    id: uuidv4(),
    type: SaraWidgets.ASSET_CATEGORY,
    priority: 3,
    isTab: false,
    mobile: {
      height: 1,
      width: 1,
    },
    tablet: {
      width: 2,
      height: 2,
    },
    laptop: {
      width: 2,
      height: 2,
    },
  },
  {
    id: uuidv4(),
    type: SaraWidgets.RISK_ALLOCATION,
    priority: 4,
    isTab: false,
    mobile: {
      height: 1,
      width: 1,
    },
    tablet: {
      width: 2,
      height: 2,
    },
    laptop: {
      width: 2,
      height: 2,
    },
  },
  {
    id: uuidv4(),
    type: SaraWidgets.PNL_BREAKDOWN,
    priority: 5,
    isTab: false,
    mobile: {
      height: 1,
      width: 1,
    },
    tablet: {
      width: 2,
      height: 2,
    },
    laptop: {
      width: 2,
      height: 2,
    },
  },
  {
    id: uuidv4(),
    type: SaraWidgets.ALPHA_ARCHETYPE,
    priority: 6,
    isTab: false,
    mobile: {
      height: 1,
      width: 1,
    },
    tablet: {
      width: 2,
      height: 2,
    },
    laptop: {
      width: 2,
      height: 2,
    },
  },
  {
    id: uuidv4(),
    type: SaraWidgets.AVERAGE_HOLD_PERIOD,
    priority: 7,
    isTab: false,
    mobile: {
      height: 1,
      width: 1,
    },
    tablet: {
      width: 2,
      height: 2,
    },
    laptop: {
      width: 2,
      height: 2,
    },
  },
  // TODO: UNCOMMENT AFTER BRIAN'S DEMO
  // {
  //   id: uuidv4(),
  //   type: SaraWidgets.EXPENSES,
  //   priority: 8,
  //   isTab: false,
  //   mobile: {
  //     height: 1,
  //     width: 1,
  //   },
  //   tablet: {
  //     width: 2,
  //     height: 2,
  //   },
  //   laptop: {
  //     width: 2,
  //     height: 2,
  //   },
  // },
  {
    id: uuidv4(),
    type: SaraWidgets.STRATEGY_LIST,
    priority: 9,
    isTab: true,
    mobile: {
      height: 1,
      width: 1,
    },
    tablet: {
      width: 2,
      height: 2,
    },
    laptop: {
      width: 3,
      height: 3,
    },
  },
];

export enum WorkspaceHeaderHeight {
  SMALL = 170,
  AVERAGE = 250,
  MEDIUM = 269,
  HUGE = 442,
  ULTRA = 551,
}

export enum WorkspaceHeaderBreakpoints {
  Tablet = 1080,
  MediumDisplay = 1800,
  Laptop = 1400,
  BigScreen = 2200,
}

export enum TabWidth {
  DEFAULT_TAB = 580,
  BIG_SCREEN = 2755,
  BIG_COLUMN = 688,
  GAP = 75,
}

export interface StrategyAllocationInfo {
  strategyId: string;
  notionalAccountValue: number;
  vol: number;
  volTargetOnFundPct: number;
  varianceOnFundPct: number;
  avgLmv: number;
  avgSmv: number;
  volDivMarginReqPct: number;
  expGrossReturn: number;
  expGrossReturnPct: number;
  expNetReturn: number;
  expNetReturnPct: number;
  netSharpe: number;
  netPAndLRelativeWeightPct: number;
  estimatedMarginReq: number;
  riskCapital: number;
  expGrossReturnDivRiskCapitalPct: number;
  relativeWeightPct: number;
}

export interface AverageHoldPeriodPct {
  groupName: string;
  averageHoldPeriodPct: number;
}

export interface AssetCategoryPct {
  groupName: string;
  assetCategoryPct: number;
}

export interface AlphaCategoriesPct {
  groupName: string;
  alphaCategoriesPct: number;
}

export interface RiskAllocationPct {
  groupName: string;
  riskAllocationPct: number;
}

export interface PnlBreakdownPct {
  groupName: string;
  pAndLBreakdownPct: number;
}

export interface Portfolio {
  leverage: number;
  concentrationRatio: number;
  aum: number;
  lmv: number;
  smv: number;
  sumOfMarginReqs: number;
  crossMarginBenefitPct: number;
  capitalAtRisk: number;
  capitalAtRiskPct: number;
  stressedFreeCash: number;
  stressedFreeCashPct: number;
  marginReqs: number;
  freeCash: number;
  freeCashPct: number;
  riskAllocationPct: RiskAllocationPct[];
  pAndLBreakdownPct: PnlBreakdownPct[];
  expNetTotalReturnPct: number;
  expNetExcessReturnPct: number;
  expVolPct: number;
  expNetSharpe: number;
  probabilityLess0Pct: number;
  probabilityLess10Pct: number;
  averageCorrelation: number;
  volFromCorrelationPct: number;
  platformFixedExpensesPct: number;
  pmFixedExpensesPct: number;
  pmPerformanceFeesPct: number;
  expensesForRunningFundPct: number;
  caPerfFeePct: number;
  totalInvestorExpensesPct: number;
  fixedTotalFeesPct: number;
  variableTotalFeesPct: number;
  averageHoldPeriodPct: AverageHoldPeriodPct[];
  assetCategoryPct: AssetCategoryPct[];
  alphaCategoriesPct: AlphaCategoriesPct[];
}

interface PortfolioChangeRequest {
  id: string;
  ownerId: string;
  targetId: string;
  targetVersion: string;
  sourceId: string;
  sourceVersion: string;
  reviewerId: string;
  changeRequestStatus: EntityIdInfo;
  description: string;
  createdAt: string;
  deletedAt: string | null;
}

export interface PortfolioConstraints {
  targetMaxCapitalAtRisk: number;
  targetMaxLiquidityCR: number;
  targetMaxPLRiskCR: number;
  targetMinNicheRisk: number;
  targetMaxEquityLeverage: number;
  targetMaxGrossExposure: number;
  minProbReturnThreshold: number;
  targetFreeCashFromAum: number;
  targetNetExcessReturn: number;
}

export interface Correlation {
  firstStrategyId: string;
  secondStrategyId: string;
  value: number;
}

export interface PortfolioCorrelationSet {
  correlations: Correlation[];
  id: string;
  name: string;
}

export interface PortfolioFee {
  fee: EntityIdInfo;
  value: number;
}

export interface GetPortfolioResponse {
  id: string;
  updatedAt: string;
  portfolioOriginId: string;
  ownerId: string;
  portfolioType: EntityIdInfo;
  portfolioStatus: EntityIdInfo;
  changeRequest: PortfolioChangeRequest;
  axiomaId: string;
  arcesiumId: string;
  name: string;
  version: number;
  assetsUnderManagement: number;
  crossMarginBenefit: number;
  minFreeCashFromAum: number;
  freeCashFromVolMultiplier: number;
  averageCorrelation: number;
  constraints: PortfolioConstraints;
  correlationSets: PortfolioCorrelationSet[];
  crossCapitalAtRiskBenefit: number;
  fees: PortfolioFee[];
  strategyAllocations: GetPortfolioStrategies[];
  strategyGroups: StrategyGroup[];
  deletedAt: string | null;
  tbillYield: {
    id: string;
    value: number;
    deletedAt: string | null;
  };
}

interface MarginType {
  id: string;
  name: string;
  marginRatio: number;
  notionalMultiplier: number;
  deletedAt: string | null;
}

export type AlphaCategory = {
  id: string;
  name: string;
  value: number;
  deletedAt: string | null;
};

export interface Strategy {
  id: string;
  alphaCategories: AlphaCategoryWithValue[];
  assetCategory: AssetCategory;
  averageHoldingPeriod: AverageHoldPeriod;
  capacity: number;
  capitalAtRiskWindowBdays: number;
  deletedAt: string | null;
  description: string;
  grossSharpe: number;
  groupCharacteristic: GroupCharacteristic;
  historicSharpeRatio: number;
  marginType: MarginType;
  minAllocation: number;
  name: string;
  pbMapping: PbMapping;
  proportionSystematic: number;
  strategyEdge: string;
  volatilityTarget: number;
}

export interface StrategiesResponse {
  id: string;
  arcesiumId: string;
  axiomaId: string;
  deletedAt: string | null;
  globalStrategyId: string;
  globalStrategyVersion: number;
  isLocked: boolean;
  notionalAccountValue: number;
  ownerId: string;
  portfolioId: string;
  status: StrategyStatuses;
  strategyGroup: StrategyGroup;
  strategyEdge: string;
  strategyId: string;
  strategyType: StrategyType;
  version: number;
  changeRequestId: string;
}

export type StrategyExtraFields = {
  accelerator_threshold: number;
  accelerator_percentage: number;
  stepup_threshold: number;
  stepup_percentage: number;
  capacity_used: number;
  account_value: number;
  remaining_capacity: number;
  gross_sharpe_historic_unique: number;
  gross_sharpe_historic: number;
};

export interface ProductionValuesForDetails {
  status: string;
  arcesiumId: string;
  description: string;
  strategyEdge: string;
  scientist: AlphaCategoryWithValue | null;
  engineer: AlphaCategoryWithValue | null;
  actuary: AlphaCategoryWithValue | null;
  averageHoldingPeriod: string;
  assetCategory: string;
  groupCharacteristic: string;
  marginType: string;
  managementFee: number;
  performanceFee: number;
  fixedExpenses: number;
  acceleratorThreshold: number;
  acceleratorPercentage: number;
  stepUpThreshold: number;
  stepUpPercentage: number;
  strategyGroup: StrategyGroup | null;
  name: string;
  capacity: number;
  grossSharpe: number;
  historicSharpeRatio: number;
  minAllocation: number;
  volatilityTarget: number;
  workingNotionalAccountValue: number;
}

export interface StrategyListEntity {
  isStrategyPersonal: boolean;
  isStrategyChanged: boolean;
  id: string;
  arcesiumId: string;
  status: EntityIdInfo;
  strategy: Strategy & StrategyExtraFields;
  productionValues: ProductionValuesForDetails;
  strategyGroup: StrategyGroup;
  optimisation: string;
  workingNotionalAccountValue: number;
  productionNotionalAccountValue: number;
  recommendedNotionalAccountValue: number;
  locked: boolean;
  version: number;
  globalStrategyId?: string;
}

export interface StrategyParams {
  id: string;
  arcesiumId: string;
  alphaCategoriesIds: { [key in keyof typeof AlphaArchetype]: number };
  assetCategory: string;
  averageHoldingPeriod: string;
  capacity: number;
  description: string;
  grossSharpe: number;
  groupCharacteristic: string;
  historicSharpeRatio: number;
  marginType: string;
  minAllocation: number;
  name: string;
  proportionSystematic: number;
  strategyEdge: string;
  volatilityTarget: number;
  notionalAccountValue?: number;
  capitalAtRiskWindowBdays: number;
}

interface StrategyGroupPortfolio
  extends Omit<StrategyGroup, 'deletedAt' | 'version' | 'fees'> {
  fees: Record<string, number>;
}

interface PortfolioStrategy {
  arcesiumId: string;
  axiomaId: string;
  globalStrategyId: string;
  globalStrategyVersion: number;
  id: string;
  isLocked: boolean;
  notionalAccountValue: number;
  portfolioId: string;
  strategyGroupId: string;
  strategyId: string;
  strategyTypeId: string;
}

export interface SavePortfolioRequest {
  arcesiumId: string;
  assetsUnderManagement: number;
  averageCorrelation: number;
  axiomaId: string;
  constraints: PortfolioConstraints;
  crossCapitalAtRiskBenefit: number;
  crossMarginBenefit: number;
  fees: Record<string, number>;
  freeCashFromVolMultiplier: number;
  minFreeCashFromAum: number;
  name: string;
  portfolioOriginId: string;
  portfolioStrategies: PortfolioStrategy[];
  strategyGroups: StrategyGroupPortfolio[];
}
