import { useTranslation } from 'react-i18next';
import { NoAccessContainer, NoAccessMessage } from './NoAccess.styled';

const NoAccess = () => {
  const { t } = useTranslation();
  return (
    <NoAccessContainer>
      <NoAccessMessage>{t('noAccess')}</NoAccessMessage>
    </NoAccessContainer>
  );
};

export default NoAccess;
