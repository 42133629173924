export enum StrategyFormFields {
  ARCESIUM_ID = 'arcesiumId',
  NAME = 'name',
  STRATEGY_GROUP = 'strategyGroup',
  DESCRIPTION = 'description',
  STRATEGY_EDGE = 'strategy_edge',
  SCIENTIST = 'scientist',
  ENGINEER = 'engineer',
  ACTUARY = 'actuary',
  GROSS_SHARPE = 'gross_sharpe',
  GROSS_SHARPE_HISTORIC = 'gross_sharpe_historic',
  MARGIN_TYPE = 'margin_type',
  ASSET_CATEGORY = 'assetCategory',
  PROPORTION_SYSTEMATIC = 'proportionSystematic',
  CATEGORY = 'category',
  AVERAGE_HOLD_PERIOD = 'averageHoldingPeriod',
  ALLOCATION_MIN = 'allocation_min',
  ALLOCATION_TERMS = 'allocation_terms',
  STRATEGY_VOLATILITY_TARGET = 'strategy_volatility_target',
  VOLATILITY_CAPACITY = 'volatility_capacity',
  ACCOUNT_VALUE = 'account_value',
  REMAINING_CAPACITY = 'remaining_capacity',
  EXPENSES = 'expenses',
  FEE_MANAGEMENT = 'fee_management',
  FEE_PERFORMANCE = 'fee_performance',
  ACCELERATOR_THRESHOLD = 'accelerator_threshold',
  ACCELERATOR_PERCENTAGE = 'accelerator_percentage',
  STEPUP_THRESHOLD = 'stepup_threshold',
  STEPUP_PERCENTAGE = 'stepup_percentage',
  STATUS = 'status',
  HISTORIC_SHARPE_RATIO = 'historicSharpeRatio',
  GROUP_CHARACTERISTIC = 'groupCharacteristic',
  PRODUCTION_VALUES = 'productionValues',
}
