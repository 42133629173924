import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReadonlyData } from '@clearalpha/common';
import {
  FormikNumbers,
  FormikValue,
  FormikSelectEntityAttributes,
} from 'components/formik/fields';
import SectionRow from 'components/Teams/TeamDetails/Section/SectionRow';
import Section from 'components/Teams/TeamDetails/Section/Section';
import { TeamDetailsFormFieldName } from 'components/Teams/TeamDetails/TeamDetails.constants';
import { isTeamEditModeSelector } from 'store/slices/teams';
import { getTeamTypes } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import { GetTeam } from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { TeamDetailsFormFields } from '../TeamDetails.types';

const TeamOverview = ({ teamDetails }: { teamDetails: null | GetTeam }) => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isTeamEditModeSelector);
  const { values, setFieldValue } = useFormikContext<TeamDetailsFormFields>();

  const { members } = values;

  useEffect(() => {
    const numberOfTeamMembers = members.length;

    setFieldValue(
      TeamDetailsFormFieldName.TOTAL_PERSONNEL,
      numberOfTeamMembers
    );

    // TODO: Update when other roles will be implemented
    setFieldValue(
      TeamDetailsFormFieldName.INVESTMENT_PROFESSIONALS,
      numberOfTeamMembers
    );
  }, [members, setFieldValue]);

  return (
    <Section title={t('teamOverview')} withBackground>
      <SectionRow title={t('type')}>
        {isEditMode ? (
          <FormikSelectEntityAttributes
            name={TeamDetailsFormFieldName.TYPE}
            getData={getTeamTypes}
          />
        ) : (
          <ReadonlyData>{teamDetails?.type?.name || ''}</ReadonlyData>
        )}
      </SectionRow>

      <SectionRow title={t('totalPersonnel')}>
        {isEditMode ? (
          <FormikNumbers
            name={TeamDetailsFormFieldName.TOTAL_PERSONNEL}
            disabled
          />
        ) : (
          <ReadonlyData>
            <FormikValue name={TeamDetailsFormFieldName.TOTAL_PERSONNEL} />
          </ReadonlyData>
        )}
      </SectionRow>

      <SectionRow title={t('numberOfInvestments')}>
        {isEditMode ? (
          <FormikNumbers
            name={TeamDetailsFormFieldName.INVESTMENT_PROFESSIONALS}
            disabled
          />
        ) : (
          <ReadonlyData>
            <FormikValue
              name={TeamDetailsFormFieldName.INVESTMENT_PROFESSIONALS}
            />
          </ReadonlyData>
        )}
      </SectionRow>
    </Section>
  );
};

export default TeamOverview;
