import { createSlice } from '@reduxjs/toolkit';
import {
  AssetCategory,
  AverageHoldPeriod,
  GlobalStrategiesResponse,
  GroupCharacteristic,
  MarginType,
  StrategyRelatedAttributesResponse,
} from 'services/strategyCatalog/strategyCatalog.type';
import { StrategyParams } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import {
  FullPortfolioStrategy,
  PortfolioFeeTypes,
  StrategyGroup,
} from 'services/portfolioCore/portfolioCore.types';
import {
  ToggleStrategiesAllocationsProps,
  UpdateStrategiesAllocationsProps,
} from 'components/StrategyList/StrategyList.type';
import { createHashMap } from 'helpers';
import { GroupDetailsFields } from 'components/ManageGroupDetails/ManageGroupDetails.type';
import { AlphaArchetype } from 'constants/strategy';
import { PortfolioPerformanceResponse } from 'services/fundCalculator/fundCalculator.types';
import { StrategiesState } from './strategies.types';
import {
  STRATEGIES_SLICE_NAME,
  STRATEGIES_SLICE_INITIAL_STATE,
} from './strategies.constants';
import { addFeesToGroup } from '../portfolios/portfolios.helpers';

const strategies = createSlice({
  name: STRATEGIES_SLICE_NAME,
  initialState: STRATEGIES_SLICE_INITIAL_STATE,
  reducers: {
    setStrategies: (
      state: StrategiesState,
      action: { payload: FullPortfolioStrategy[] }
    ) => {
      state.strategies = action.payload;
    },
    setStrategyColor: (
      state: StrategiesState,
      action: { payload: Record<string, string> }
    ) => {
      state.strategiesColors = {
        ...state.strategiesColors,
        ...action.payload,
      };
    },
    setMarginTypes: (
      state: StrategiesState,
      action: { payload: MarginType[] }
    ) => {
      state.marginTypes = action.payload;
    },
    setLoadingMarginTypes: (
      state: StrategiesState,
      action: { payload: boolean }
    ) => {
      state.isLoadingMarginTypes = action.payload;
    },
    setGroupCharacteristics: (
      state: StrategiesState,
      action: { payload: GroupCharacteristic[] }
    ) => {
      state.groupCharacteristics = action.payload;
    },
    setLoadingGroupCharacteristics: (
      state: StrategiesState,
      action: { payload: boolean }
    ) => {
      state.isLoadingGroupCharacteristics = action.payload;
    },
    setAverageHoldPeriods: (
      state: StrategiesState,
      action: { payload: AverageHoldPeriod[] }
    ) => {
      state.averageHoldPeriods = action.payload;
    },
    setLoadingAverageHoldPeriods: (
      state: StrategiesState,
      action: { payload: boolean }
    ) => {
      state.isLoadingAverageHoldPeriods = action.payload;
    },
    setAssetCategories: (
      state: StrategiesState,
      action: { payload: AssetCategory[] }
    ) => {
      state.assetCategories = action.payload;
    },
    setLoadingAssetCategories: (
      state: StrategiesState,
      action: { payload: boolean }
    ) => {
      state.isLoadingAssetCategories = action.payload;
    },
    setSaraProductionStrategies: (
      state,
      action: { payload: FullPortfolioStrategy[] }
    ) => {
      state.saraProductionStrategies = action.payload;
    },
    setSourceStrategies: (
      state,
      action: { payload: FullPortfolioStrategy[] }
    ) => {
      state.sourceStrategies = action.payload;
    },
    setTargetStrategies: (
      state,
      action: { payload: FullPortfolioStrategy[] }
    ) => {
      state.targetStrategies = action.payload;
    },
    deleteStrategy: (state, action: { payload: string }) => {
      state.strategies = state.strategies.filter(
        (current) => current.globalStrategyId !== action.payload
      );
    },
    setInitialStrategies: (
      state: StrategiesState,
      action: { payload: FullPortfolioStrategy[] }
    ) => {
      state.initialStrategies = action.payload;
    },
    updateStrategiesAllocation: (
      state: StrategiesState,
      action: { payload: UpdateStrategiesAllocationsProps[] }
    ) => {
      action.payload.forEach((strategyAllocation) => {
        const strategyToUpdate = state.strategies.find(
          (strategy) => strategy.id === strategyAllocation.strategyId
        );

        if (!strategyToUpdate) return;

        strategyToUpdate.notionalAccountValue =
          strategyAllocation.notionalAccountValue;
      });
    },
    updateStrategiesLock: (
      state: StrategiesState,
      action: { payload: ToggleStrategiesAllocationsProps[] }
    ) => {
      action.payload.forEach((strategyLock) => {
        const strategyToUpdate = state.strategies.find(
          (strategy) => strategy.id === strategyLock.strategyId
        );

        if (!strategyToUpdate) return;

        strategyToUpdate.isLocked = strategyLock.isLocked;
      });
    },
    updateStrategiesOptimizedData: (
      state: StrategiesState,
      action: { payload: PortfolioPerformanceResponse }
    ) => {
      action.payload.strategyAllocation.forEach((strategyOptimized) => {
        const strategyToUpdate = state.strategies.find(
          (strategy) => strategy.id === strategyOptimized.strategyId
        );

        if (
          strategyToUpdate &&
          !strategyToUpdate.isLocked &&
          strategyToUpdate.notionalAccountValue !==
            strategyOptimized.notionalAccountValue
        ) {
          strategyToUpdate.notionalAccountValue =
            strategyOptimized.notionalAccountValue;
        }
      });
    },
    updateStrategyDetails: (
      state: StrategiesState,
      action: {
        payload: {
          id: string;
          updatedStrategy: StrategyParams;
          newStrategyId?: string;
        };
      }
    ) => {
      const strategyToUpdate = state.strategies.find(
        (strategy) => strategy.id === action.payload.id
      );
      if (!strategyToUpdate) return;

      const averageHoldPeriods = createHashMap('id', state.averageHoldPeriods);
      const assetCategories = createHashMap('id', state.assetCategories);
      const marginTypes = createHashMap('id', state.marginTypes);
      const groupCharacteristics = createHashMap(
        'id',
        state.groupCharacteristics
      );

      const {
        arcesiumId,
        averageHoldingPeriod,
        assetCategory,
        alphaCategoriesIds,
        groupCharacteristic,
        marginType,
        ...newStrategyParams
      } = action.payload.updatedStrategy;

      strategyToUpdate.arcesiumId = arcesiumId;
      strategyToUpdate.notionalAccountValue =
        action.payload.updatedStrategy.notionalAccountValue || 0;
      strategyToUpdate.strategyId =
        action.payload.newStrategyId || strategyToUpdate.strategyId;
      strategyToUpdate.strategy = {
        ...strategyToUpdate.strategy,
        ...newStrategyParams,
        alphaCategories: strategyToUpdate.strategy.alphaCategories.map(
          ({ id, ...rest }) => ({
            ...rest,
            id,
            value: alphaCategoriesIds[id as keyof typeof AlphaArchetype],
          })
        ),
        averageHoldingPeriod: averageHoldPeriods[averageHoldingPeriod],
        assetCategory: assetCategories[assetCategory],
        marginType: marginTypes[marginType],
        groupCharacteristic: groupCharacteristics[groupCharacteristic],
        ...(action.payload.newStrategyId
          ? { id: action.payload.newStrategyId }
          : {}),
      };
    },
    setIsGlobalStrategyEditMode: (
      state: StrategiesState,
      action: { payload: boolean }
    ) => {
      state.isGlobalStrategyEditMode = action.payload;
    },
    addNewGlobalStrategy: (
      state: StrategiesState,
      action: { payload: GlobalStrategiesResponse }
    ) => {
      state.globalStrategies.push(action.payload);
    },
    updateSingleGlobalStrategy: (
      state: StrategiesState,
      action: { payload: GlobalStrategiesResponse }
    ) => {
      state.globalStrategies = state.globalStrategies.map((strategy) => {
        if (strategy.id !== action.payload.id) return strategy;

        return action.payload;
      });
    },
    setGlobalStrategies: (
      state: StrategiesState,
      action: { payload: GlobalStrategiesResponse[] }
    ) => {
      state.globalStrategies = action.payload;
    },
    setIsGlobalStrategiesLoading: (
      state: StrategiesState,
      action: { payload: boolean }
    ) => {
      state.isLoadingStrategies = action.payload;
    },
    setIsPortfolioStrategiesLoading: (
      state: StrategiesState,
      action: { payload: boolean }
    ) => {
      state.isLoadingStrategies = action.payload;
    },
    changeStrategyGroupInWorkingStrategy: (
      state: StrategiesState,
      action: {
        payload: {
          strategyId: string;
          newStrategyGroup: StrategyGroup;
        };
      }
    ) => {
      const strategy = state.strategies.find(
        (current) => current.id === action.payload.strategyId
      );

      if (strategy) {
        strategy.strategyGroup = action.payload.newStrategyGroup;
        strategy.strategyGroupId = action.payload.newStrategyGroup.id;
      }
    },
    setStrategyAttributes: (
      state,
      action: { payload: StrategyRelatedAttributesResponse }
    ) => {
      state.assetCategories = action.payload.assetCategories;
      state.averageHoldPeriods = action.payload.averageHoldingPeriods;
      state.marginTypes = action.payload.marginTypes;
      state.groupCharacteristics = action.payload.groupCharacteristics;
    },
    setStrategiesFeeTypes: (
      state,
      action: {
        payload: PortfolioFeeTypes[];
      }
    ) => {
      state.strategiesFeeTypes = action.payload;
    },
    updateGroupDataInWorkingStrategy: (
      state,
      action: {
        payload: {
          newValues: GroupDetailsFields;
          groupIdToUpdate: string;
        };
      }
    ) => {
      const strategiesToUpdate = state.strategies.filter(
        ({ strategyGroupId }) =>
          strategyGroupId === action.payload.groupIdToUpdate
      );

      const feeTypes = state.strategiesFeeTypes;

      if (strategiesToUpdate.length) {
        strategiesToUpdate.forEach((strategy) => {
          if (!strategy.strategyGroup.fees.length)
            addFeesToGroup(strategy.strategyGroup, feeTypes);

          strategy.strategyGroup.fees.forEach((current) => {
            if (current.fee.name === 'Management fee') {
              current.value = action.payload.newValues.mgmtFee;
            }
            if (current.fee.name === 'Performance fee') {
              current.value = action.payload.newValues.perfFee;
            }
            if (current.fee.name === 'Fixed expense budget') {
              current.value = action.payload.newValues.fixedExp;
            }
          });
          strategy.strategyGroup.name = action.payload.newValues.groupName;

          const strategyToUpdate = state.strategies.find(
            (current) => current.id === strategy.id
          );
          if (strategyToUpdate) {
            strategyToUpdate.strategyGroup = strategy.strategyGroup;
          }
        });
      }
    },
  },
});

export const {
  setIsGlobalStrategyEditMode,
  setStrategies,
  setStrategyColor,
  setMarginTypes,
  setLoadingMarginTypes,
  setGroupCharacteristics,
  setLoadingGroupCharacteristics,
  setAverageHoldPeriods,
  setLoadingAverageHoldPeriods,
  setLoadingAssetCategories,
  setAssetCategories,
  updateStrategyDetails,
  setInitialStrategies,
  setSaraProductionStrategies,
  addNewGlobalStrategy,
  updateSingleGlobalStrategy,
  setGlobalStrategies,
  setIsGlobalStrategiesLoading,
  setStrategyAttributes,
  setStrategiesFeeTypes,
  setIsPortfolioStrategiesLoading,
  updateStrategiesAllocation,
  setSourceStrategies,
  setTargetStrategies,
  deleteStrategy,
  changeStrategyGroupInWorkingStrategy,
  updateGroupDataInWorkingStrategy,
  updateStrategiesLock,
  updateStrategiesOptimizedData,
} = strategies.actions;

export default strategies.reducer;
