import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NumericTextPercent, TextFieldWrapper } from '@clearalpha/common';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import FormikPercentNumbers from 'components/formik/fields/FormikPercentNumbers';
import { FieldLabel, FieldWrapper } from '../../styled';
import { StrategyFormFields } from '../../../StrategyDetails.constants';
import { StrategyDetailsFormFields } from '../../../StrategyDetails.type';

const Scientist = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.productionValues.scientist?.value !== values.scientist?.value;

  return (
    <>
      <FieldLabel>{t('alphaArchetype.scientist')}</FieldLabel>
      <NumericTextPercent
        value={values.productionValues.scientist?.value || 0}
      />
      {!isPortfolioReviewMode ? (
        <FormikPercentNumbers
          name={StrategyFormFields.SCIENTIST}
          minLimit={0}
          maxLimit={100}
          customInput={TextFieldWrapper}
          changeObject
        />
      ) : (
        <FieldWrapper className={isValueHighlighted ? 'highlighted' : ''}>
          <NumericTextPercent value={values.scientist?.value || 0} />
        </FieldWrapper>
      )}
    </>
  );
};

export default Scientist;
