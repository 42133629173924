import React from 'react';
import { TableRow } from '@mui/material';
import { FieldValue } from '@clearalpha/common';
import {
  TitleCell,
  ValueCell,
} from '@clearalpha/common/dist/components/Dashboard/Widgets/TableWidgetBackbone';
import { StrategySubtitle } from '../ChangeReportDialog.styled';
import { ChangeReportDialogTableProps } from './ChangeReportDialogTable.type';

const ChangeReportDialogTable = ({
  config,
  name,
}: ChangeReportDialogTableProps) => (
  <>
    {!!name.length && (
      <TableRow>
        <TitleCell colSpan={3}>
          <StrategySubtitle>{name}</StrategySubtitle>
        </TitleCell>
      </TableRow>
    )}
    <TableRow>
      <TitleCell>{config.fieldName}</TitleCell>
      <ValueCell align='right'>
        <FieldValue
          row={{
            type: config.type,
            fieldName: config.fieldName,
            variableName: config.variableName,
          }}
          value={config.targetValue}
        />
      </ValueCell>
      <ValueCell align='right'>
        <FieldValue
          row={{
            type: config.type,
            fieldName: config.fieldName,
            variableName: config.variableName,
          }}
          value={config.sourceValue}
        />
      </ValueCell>
    </TableRow>
  </>
);

export { ChangeReportDialogTable };
