import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { ReadonlyData } from '@clearalpha/common';
import { groupCharacteristicSelector } from 'store/slices/strategies';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { FormikSelect } from 'components/formik/fields';
import { fetchGroupCharacteristics } from 'store/thunks/fetchGroupCharacteristics';
import { FieldLabel, FieldWrapper } from '../../styled';
import { StrategyFormFields } from '../../../StrategyDetails.constants';
import { StrategyDetailsFormFields } from '../../../StrategyDetails.type';

const AlphaCategory = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const groupCharacteristics = useSelector(groupCharacteristicSelector);
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.productionValues.groupCharacteristic !== values.groupCharacteristic;

  useEffect(() => {
    if (!groupCharacteristics.length) {
      dispatch(fetchGroupCharacteristics());
    }
  }, [dispatch, groupCharacteristics.length]);

  const options = useMemo(
    () =>
      groupCharacteristics.reduce((acc: { [key: string]: string }, current) => {
        acc[current.id] = current.name;
        return acc;
      }, {}),
    [groupCharacteristics]
  );

  const productionValue = groupCharacteristics.find(
    ({ id }) => id === values.productionValues.groupCharacteristic
  );

  return (
    <>
      <FieldLabel>{t('alphaCategory')}</FieldLabel>
      <ReadonlyData>{productionValue?.name || t('empty')}</ReadonlyData>
      {!isPortfolioReviewMode ? (
        <FormikSelect
          name={StrategyFormFields.GROUP_CHARACTERISTIC}
          options={options}
        />
      ) : (
        <FieldWrapper className={isValueHighlighted ? 'highlighted' : ''}>
          <ReadonlyData>
            {
              groupCharacteristics.find(
                (groupCharacteristic) =>
                  groupCharacteristic.id === values.groupCharacteristic
              )?.name
            }
          </ReadonlyData>
        </FieldWrapper>
      )}
    </>
  );
};

export default AlphaCategory;
