import { FC } from 'react';
import {
  CheckCircleIcon,
  CommonConfirmationDialogProps,
  ConfirmationDialog,
} from '@clearalpha/common';
import { useTranslation } from 'react-i18next';

export const SuccessDialog: FC<CommonConfirmationDialogProps> = ({
  isOpen,
  handleClose,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      {...props}
      cancelButton={{
        label: t('actions.close'),
        action: handleClose,
      }}
      handleClose={handleClose}
      isOpen={isOpen}
      icon={<CheckCircleIcon />}
    />
  );
};
