import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@clearalpha/common';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import App from './App';
import './translations/i18n';

import '@clearalpha/common/dist/scss/index.scss'; // TODO: ADD MORE CONVENIENT WAY OF FONTS' IMPORT

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);
