import i18n from 'translations/i18n';

export enum StrategyCategory {
  COMMODITIES = 'COMMODITIES',
  CURRENCIES = 'CURRENCIES',
  CRYPTO = 'CRYPTO',
  DISCRETIONARY_EQUITY = 'DISCRETIONARY_EQUITY',
  EQUITY_ARBITRAGE = 'EQUITY_ARBITRAGE',
  EQUITY_MARKET_NEUTRAL = 'EQUITY_MARKET_NEUTRAL',
  EVENT_DRIVEN = 'EVENT_DRIVEN',
  FIXED_INCOME_RV = 'FIXED_INCOME_RV',
  GLOBAL_MACRO = 'GLOBAL_MACRO',
  INDEX_AND_ETF_ARBITRAGE = 'INDEX_AND_ETF_ARBITRAGE',
  INDEX_REBALANCE_ARBITRAGE = 'INDEX_REBALANCE_ARBITRAGE',
  MANAGED_FUTURES = 'MANAGED_FUTURES',
  SHORT_TERM_FUTURES = 'SHORT_TERM_FUTURES',
  STATISTICAL_ARBITRAGE = 'STATISTICAL_ARBITRAGE',
  WEATHER = 'WEATHER',
  INDEX_ARBITRAGE = 'INDEX_ARBITRAGE',
  SPECIAL_SITUATIONS = 'SPECIAL_SITUATIONS',
  DEDICATED_SHORT_BIASED = 'DEDICATED_SHORT_BIASED',
  HIGH_FREQUENCY_TRADING = 'HIGH_FREQUENCY_TRADING',
}

export enum MarginType {
  SHORT_TERM_FUTURES = 'SHORT_TERM_FUTURES',
  DIRECTIONAL_FUTURES = 'DIRECTIONAL_FUTURES',
  RELVAL_FUTURES = 'RELVAL_FUTURES',
  CONCENTRATED_EQUITY = 'CONCENTRATED_EQUITY',
  DIVERSIFIED_EQUITY = 'DIVERSIFIED_EQUITY',
  NEAR_FULLY_FUNDED = 'NEAR_FULLY_FUNDED',
  FULLY_FUNDED = 'FULLY_FUNDED',
}

export enum StrategyStatuses {
  NOT_APPROVED = 'NOT_APPROVED',
  ALLOCATED = 'ALLOCATED',
  ON_BENCH = 'ON_BENCH',
}

export const STRATEGY_CATEGORIES: Record<string, string> = {
  [StrategyCategory.COMMODITIES]: i18n.t('strategyCategory.commodities'),
  [StrategyCategory.CURRENCIES]: i18n.t('strategyCategory.currencies'),
  [StrategyCategory.CRYPTO]: i18n.t('strategyCategory.crypto'),
  [StrategyCategory.DISCRETIONARY_EQUITY]: i18n.t(
    'strategyCategory.discretionaryEquity'
  ),
  [StrategyCategory.EQUITY_ARBITRAGE]: i18n.t(
    'strategyCategory.equityArbitrage'
  ),
  [StrategyCategory.EQUITY_MARKET_NEUTRAL]: i18n.t(
    'strategyCategory.equityMarketNeutral'
  ),
  [StrategyCategory.EVENT_DRIVEN]: i18n.t('strategyCategory.eventDriven'),
  [StrategyCategory.FIXED_INCOME_RV]: i18n.t('strategyCategory.fixedIncomeRV'),
  [StrategyCategory.GLOBAL_MACRO]: i18n.t('strategyCategory.globalMacro'),
  [StrategyCategory.INDEX_AND_ETF_ARBITRAGE]: i18n.t(
    'strategyCategory.indexAndETFArbitrage'
  ),
  [StrategyCategory.INDEX_REBALANCE_ARBITRAGE]: i18n.t(
    'strategyCategory.indexRebalanceArbitrage'
  ),
  [StrategyCategory.MANAGED_FUTURES]: i18n.t('strategyCategory.managedFutures'),
  [StrategyCategory.SHORT_TERM_FUTURES]: i18n.t(
    'strategyCategory.shortTermFutures'
  ),
  [StrategyCategory.STATISTICAL_ARBITRAGE]: i18n.t(
    'strategyCategory.statisticalArbitrage'
  ),
  [StrategyCategory.WEATHER]: i18n.t('strategyCategory.weather'),
  [StrategyCategory.INDEX_ARBITRAGE]: i18n.t('strategyCategory.indexArbitrage'),
  [StrategyCategory.SPECIAL_SITUATIONS]: i18n.t(
    'strategyCategory.specialSituations'
  ),
  [StrategyCategory.DEDICATED_SHORT_BIASED]: i18n.t(
    'strategyCategory.dedicatedShortBiased'
  ),
  [StrategyCategory.HIGH_FREQUENCY_TRADING]: i18n.t(
    'strategyCategory.highFrequencyTrading'
  ),
};

export const STRATEGY_STATUSES: Record<StrategyStatuses, string> = {
  [StrategyStatuses.NOT_APPROVED]: 'Not approved',
  [StrategyStatuses.ALLOCATED]: 'Allocated',
  [StrategyStatuses.ON_BENCH]: 'On bench',
};

export enum AlphaCategory {
  NICHE = 'Niche',
  OPPORTUNISTIC = 'Opportunistic',
  RISK_MGMT = 'Risk Mgmt',
}

export enum AlphaArchetype {
  SCIENTIST = 'scientist',
  ENGINEER = 'engineer',
  ACTUARY = 'actuary',
}

export enum AlphaArchetypeIds {
  SCIENTIST = 'SCIENTIST',
  ENGINEER = 'ENGINEER',
  ACTUARY = 'ACTUARY',
}

export enum AverageHoldPeriod {
  MORE_THAN_1_MONTH = 'More than 1 month',
  LESS_THAN_1_MONTH = 'Less than 1 month',
  LESS_THAN_10_DAYS = 'Less than 10 days',
  INTRADAY = 'Intraday',
}

export const MARGIN_TYPE: Record<string, string> = {
  [MarginType.SHORT_TERM_FUTURES]: 'Short Term Futures',
  [MarginType.DIRECTIONAL_FUTURES]: 'Directional Futures',
  [MarginType.RELVAL_FUTURES]: 'Relval Futures',
  [MarginType.CONCENTRATED_EQUITY]: 'Concentrated Equity',
  [MarginType.DIVERSIFIED_EQUITY]: 'Diversified Equity',
  [MarginType.NEAR_FULLY_FUNDED]: 'Near Fully Funded',
  [MarginType.FULLY_FUNDED]: 'Fully Funded',
};

export const ALPHA_CATEGORIES: Record<AlphaCategory, string> = {
  [AlphaCategory.RISK_MGMT]: i18n.t('alphaCategories.riskMgmt'),
  [AlphaCategory.NICHE]: i18n.t('alphaCategories.niche'),
  [AlphaCategory.OPPORTUNISTIC]: i18n.t('alphaCategories.opportunistic'),
};

export const ALPHA_ARCHETYPE: Record<AlphaArchetype, string> = {
  [AlphaArchetype.SCIENTIST]: i18n.t('alphaArchetype.scientist'),
  [AlphaArchetype.ENGINEER]: i18n.t('alphaArchetype.engineer'),
  [AlphaArchetype.ACTUARY]: i18n.t('alphaArchetype.actuary'),
};

export const AVERAGE_HOLD_PERIODS: Record<AverageHoldPeriod, string> = {
  [AverageHoldPeriod.MORE_THAN_1_MONTH]: i18n.t(
    'averageHoldPeriod.moreThanMonth'
  ),
  [AverageHoldPeriod.LESS_THAN_1_MONTH]: i18n.t(
    'averageHoldPeriod.lessThanMonth'
  ),
  [AverageHoldPeriod.LESS_THAN_10_DAYS]: i18n.t(
    'averageHoldPeriod.lessThan10Days'
  ),
  [AverageHoldPeriod.INTRADAY]: i18n.t('averageHoldPeriod.intraday'),
};
