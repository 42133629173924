import { useState } from 'react';
import { Toasters } from '@clearalpha/common';
import { useTranslation } from 'react-i18next';
import { StrategyListEntity } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import {
  GetPortfolioManager,
  GetTeam,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { GetStrategyGroup } from 'services/strategyGroupCore/strategyGroupCore.type';
import { getPortfolioManagerById } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import { useAppDispatch } from 'store';
import { setIsPortfolioMangersLoading } from 'store/slices/portfolioManagers';
import { GlobalStrategiesResponse } from 'services/strategyCatalog/strategyCatalog.type';

const INITIAL_DRAWER_STATE = {
  isOpenPMDrawer: false,
  isOpenTeamDrawer: false,
  isOpenStrategiesDrawer: false,
  isOpenGlobalStrategiesDrawer: false,
  isOpenStrategiesGroupDrawer: false,
  strategyDetails: null,
  globalStrategyDetails: null,
  managerDetails: null,
  teamDetails: null,
  groupDetails: null,
};

const DRAWER_SWITCH_TIMEOUT = 200;

export type DrawerState = {
  isOpenPMDrawer: boolean;
  isOpenTeamDrawer: boolean;
  isOpenStrategiesDrawer: boolean;
  isOpenGlobalStrategiesDrawer: boolean;
  isOpenStrategiesGroupDrawer: boolean;
  strategyDetails: StrategyListEntity | null;
  globalStrategyDetails: GlobalStrategiesResponse | null;
  managerDetails: GetPortfolioManager | null;
  teamDetails: GetTeam | null;
  groupDetails: GetStrategyGroup | null;
};

type StrategyListDrawerHelperProps = {
  getStrategy?: (strategyId: string) => StrategyListEntity | null;
  getGlobalStrategy?: (strategyId: string) => GlobalStrategiesResponse | null;
  teams?: GetTeam[];
};

type StrategyListDrawerHelperParams = {
  drawer: DrawerState;
  handleOpenStrategyDrawer: (strategyId?: string) => void;
  handleOpenGlobalStrategyDrawer: (strategyId?: string) => void;
  handleCloseDrawer: () => void;
  handleOpenPMDrawer: (managerId?: string) => void;
  handleOpenTeamDrawer: (teamId?: string) => void;
  updateManager: (manager: GetPortfolioManager) => void;
  updateDrawer: (state: Partial<DrawerState>) => void;
};

export const useStrategyListDrawer = ({
  getStrategy,
  getGlobalStrategy,
  teams,
}: StrategyListDrawerHelperProps): StrategyListDrawerHelperParams => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [drawer, setDrawer] = useState<DrawerState>(INITIAL_DRAWER_STATE);

  const isAnyDrawerOpened =
    drawer.isOpenTeamDrawer ||
    drawer.isOpenPMDrawer ||
    drawer.isOpenStrategiesDrawer ||
    drawer.isOpenGlobalStrategiesDrawer ||
    drawer.isOpenStrategiesGroupDrawer;

  const switchDrawer = async () => {
    setDrawer((prevState) => ({
      ...prevState,
      isOpenPMDrawer: false,
      isOpenTeamDrawer: false,
      isOpenStrategiesDrawer: false,
      isOpenGlobalStrategiesDrawer: false,
      isOpenStrategiesGroupDrawer: false,
    }));

    await new Promise((resolve) => {
      setTimeout(resolve, DRAWER_SWITCH_TIMEOUT);
    });
  };

  const handleCloseDrawer = async () => {
    setDrawer((prevState) => ({
      ...prevState,
      isOpenStrategiesDrawer: false,
      isOpenGlobalStrategiesDrawer: false,
      isOpenPMDrawer: false,
      isOpenTeamDrawer: false,
      isOpenStrategiesGroupDrawer: false,
      managerDetails: null,
      strategyDetails: null,
      teamDetails: null,
      groupDetails: null,
    }));
  };

  const updateManager = (manager: GetPortfolioManager) => {
    setDrawer((prevState) => ({
      ...prevState,
      managerDetails: manager,
    }));
  };

  const updateDrawer = (updatedDrawer: Partial<DrawerState>) => {
    setDrawer((prevState) => ({
      ...prevState,
      ...updatedDrawer,
    }));
  };

  const handleOpenTeamDrawer = async (teamId?: string) => {
    if (isAnyDrawerOpened) {
      await switchDrawer();
    }

    const teamDetails = teamId
      ? teams?.find((team) => team.id === teamId) || null
      : null;

    return setDrawer((prevState) => ({
      ...prevState,
      isOpenTeamDrawer: true,
      teamDetails,
    }));
  };

  const handleOpenPMDrawer = async (managerId?: string) => {
    if (isAnyDrawerOpened) {
      await switchDrawer();
    }

    if (!managerId) {
      setDrawer((prevState) => ({
        ...prevState,
        isOpenPMDrawer: true,
        managerDetails: null,
      }));
    } else {
      try {
        dispatch(setIsPortfolioMangersLoading(true));
        const managerDetails = await getPortfolioManagerById(managerId);

        setDrawer((prevState) => ({
          ...prevState,
          isOpenPMDrawer: true,
          managerDetails,
        }));
      } catch (e) {
        Toasters.error(t('errors.somethingWentWrong'));
      } finally {
        dispatch(setIsPortfolioMangersLoading(false));
      }
    }
  };

  const handleOpenStrategyDrawer = async (strategyId?: string) => {
    if (isAnyDrawerOpened) await switchDrawer();

    const strategyDetails =
      strategyId && getStrategy ? getStrategy(strategyId) : null;

    setDrawer((prevState) => ({
      ...prevState,
      isOpenStrategiesDrawer: true,
      strategyDetails,
    }));
  };

  const handleOpenGlobalStrategyDrawer = async (strategyId?: string) => {
    if (isAnyDrawerOpened) await switchDrawer();

    const globalStrategyDetails =
      strategyId && getGlobalStrategy ? getGlobalStrategy(strategyId) : null;

    setDrawer((prevState) => ({
      ...prevState,
      isOpenGlobalStrategiesDrawer: true,
      globalStrategyDetails,
    }));
  };

  return {
    drawer,
    handleOpenStrategyDrawer,
    handleOpenGlobalStrategyDrawer,
    handleCloseDrawer,
    handleOpenPMDrawer,
    handleOpenTeamDrawer,
    updateManager,
    updateDrawer,
  };
};
