import {
  PerformanceStats,
  PerformanceStatsWarnings,
  PortfolioPerformanceWarnings,
} from 'services/fundCalculator/fundCalculator.types';
import {
  Portfolio,
  GetPortfolioResponse,
} from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';

export const getPerformanceStatsWidgetData = (
  portfolio: Portfolio,
  portfolioCore: GetPortfolioResponse,
  warnings?: PortfolioPerformanceWarnings
): {
  data: PerformanceStats;
  warnings: PerformanceStatsWarnings;
} => {
  return {
    data: {
      expNetTotalReturnPct: portfolio?.expNetTotalReturnPct,
      expNetExcessReturnPct: portfolio?.expNetExcessReturnPct,
      expVolPct: portfolio?.expVolPct,
      expNetSharpe: portfolio?.expNetSharpe,
      probabilityLess10Pct: portfolio?.probabilityLess10Pct,
      averageCorrelation: portfolio?.averageCorrelation,
      volFromCorrelationPct: portfolio?.volFromCorrelationPct,
      maxCapitalRisk: portfolioCore?.constraints.targetMaxCapitalAtRisk || 0,
      capitalAtRisk: portfolio?.capitalAtRiskPct || 0,
    },
    warnings: {
      expNetTotalReturnPct: warnings?.portfolio?.expNetTotalReturnPct,
      expNetExcessReturnPct: warnings?.portfolio?.expNetExcessReturnPct,
      expVolPct: warnings?.portfolio?.expVolPct,
      expNetSharpe: warnings?.portfolio?.expNetSharpe,
      probabilityLess10Pct: warnings?.portfolio?.probabilityLess10Pct,
      averageCorrelation: warnings?.portfolio?.averageCorrelation,
      volFromCorrelationPct: warnings?.portfolio?.volFromCorrelationPct,
      maxCapitalRisk: warnings?.portfolio?.maxCapitalRisk,
      capitalAtRisk: warnings?.portfolio?.capitalAtRisk,
    },
  };
};
