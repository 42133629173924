import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  setIsPortfolioMangersLoading,
  setPortfolioManagers,
} from 'store/slices/portfolioManagers';
import { getPortfolioManagers } from 'services/portfolioManagerCore/portfolioManagerCore.service';

export const fetchPortfolioManagers = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchPortfolioManagers', async (_, { dispatch }) => {
  dispatch(setIsPortfolioMangersLoading(true));

  try {
    const managers = await getPortfolioManagers();

    dispatch(setPortfolioManagers(managers));
  } finally {
    dispatch(setIsPortfolioMangersLoading(false));
  }
});
