import styled from '@emotion/styled';
import { StatName } from '../../PortfolioManagerDetails.styled';

export const Title = styled.h2`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 52px;
  margin: 0 0 32px;
  word-break: break-word;
`;

export const Overview = styled.div`
  display: flex;
  column-gap: 24px;
  margin-bottom: 24px;
`;

export const PortfolioPicture = styled.div`
  background-color: ${({ theme: { colors } }) => colors.onSurface};
  border-radius: 8px;
  height: 198px;
  opacity: 0.6;
  width: 184px;
`;

export const OverviewStats = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc(100% - 200px);
`;

export const OverviewStatName = styled(StatName)`
  color: ${({ theme: { colors } }) => colors.white};

  span {
    word-break: break-word;
  }
`;

export const TeamName = styled.div`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.5px;
  margin: 0;
  word-break: break-word;
  cursor: pointer;
`;
