export enum ManagerFormFields {
  NAME = 'name',
  SURNAME = 'surname',
  STATUS = 'statusId',
  DESCRIPTION = 'description',
  STRATEGY_EXPERTISE = 'strategyExpertisesIds',
  CURRENT_FIRM = 'currentFirmId',
  FORMER_FIRMS = 'formerFirmsIds',
  INVESTING_EXPERIENCE = 'investingExperienceStartDate',
  LOCATION = 'location',
  HIGHEST_DEGREE = 'highestDegree',
  SCHOOLS = 'schoolsIds',
  CERTIFICATION = 'certification',
  TEAMS = 'teamsIds',
  // CRM_SYSTEM = 'crmSystem', TODO: TBD
}
