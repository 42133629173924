import styled from '@emotion/styled';

const Title = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 46px;
  line-height: 150%;
  margin-top: 0;
  margin-bottom: 10px;
`;

export { Title };
