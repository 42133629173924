import { transformToPieChartWidgetData } from 'helpers';
import { AVERAGE_HOLD_PERIODS } from 'constants/strategy';
import { AverageHoldPeriodPct } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { KEY_HOLD_PERIOD_COLORS } from './AverageHoldPeriodWidget.const';

export const getAverageHoldPeriodsPieChartData = (
  data: AverageHoldPeriodPct[]
) => {
  return transformToPieChartWidgetData({
    data,
    keyField: 'groupName',
    percentageField: 'averageHoldPeriodPct',
    locals: AVERAGE_HOLD_PERIODS,
    colors: KEY_HOLD_PERIOD_COLORS,
  });
};
