import {
  currencyFormatterShorthand,
  percentFormatterShorthand,
  decimalFormatterShorthand,
  xTimesFormatter,
  FieldValueEnum,
} from '@clearalpha/common';

type FormattedData = {
  formattedSourceValue: string;
  formattedTargetValue: string;
};

export const formatSourceAndTargetValues = (element: any): FormattedData => {
  let formattedSourceValue;
  let formattedTargetValue;

  switch (element.type) {
    case FieldValueEnum.NUMERIC_TEXT_DECIMAL:
      formattedSourceValue = decimalFormatterShorthand(element.sourceValue);
      formattedTargetValue = decimalFormatterShorthand(element.targetValue);
      break;
    case FieldValueEnum.NUMERIC_TEXT_CURRENCY:
      formattedSourceValue = currencyFormatterShorthand(element.sourceValue);
      formattedTargetValue = currencyFormatterShorthand(element.targetValue);
      break;
    case FieldValueEnum.NUMERIC_TEXT_PERCENT:
      formattedSourceValue = percentFormatterShorthand(element.sourceValue);
      formattedTargetValue = percentFormatterShorthand(element.targetValue);
      break;
    case FieldValueEnum.NUMERIC_TEXT_X_TIMES:
      formattedSourceValue = xTimesFormatter(element.sourceValue);
      formattedTargetValue = xTimesFormatter(element.targetValue);
      break;
    default:
      formattedSourceValue = element.sourceValue;
      formattedTargetValue = element.targetValue;
  }

  return {
    formattedSourceValue,
    formattedTargetValue,
  };
};
