import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'components/Navigation/Navigation.styled';
import { GlobalStrategiesResponse } from 'services/strategyCatalog/strategyCatalog.type';
import { globalStrategiesSelector } from 'store/slices/strategies/strategies.selectors';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings/settings.selectors';
import {
  sourceStrategyGroupsSelector,
  strategyGroupsSelector,
} from 'store/slices/portfolios';
import { teamsSelector } from 'store/slices/teams';
import { StrategyGroupsResponse } from 'services/portfolioCore/portfolioCore.types';
import { t } from 'i18next';
import {
  StrategyGroupRow,
  StrategyGroupsListLocales,
} from './ManageGroupsList.type';
import StrategyLibraryCell from '../StrategyLibrary/StrategyLibraryCell/StrategyLibraryCell';

export const sortStrategyGroups = (
  strategyGroups: StrategyGroupsResponse[]
): StrategyGroupsResponse[] => {
  return [...strategyGroups].sort((a, b) => a.name.localeCompare(b.name));
};

const getStrategyNames = (
  strategies: GlobalStrategiesResponse[],
  ids: string[]
) => {
  return ids
    .map((id) => {
      const strategy = strategies.find((current) => current.id === id);

      if (!strategy) {
        return t('unknownStrategy');
      }

      return strategy.name;
    })
    .join(', ');
};

export const useManageGroupsListConfig = () => {
  const portfolioStrategyGroups = useSelector(strategyGroupsSelector);
  const sourceStrategyGroups = useSelector(sourceStrategyGroupsSelector);
  const strategyGroups = sourceStrategyGroups.length
    ? sourceStrategyGroups
    : portfolioStrategyGroups;
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);
  const teams = useSelector(teamsSelector);
  const gloabalStrategies = useSelector(globalStrategiesSelector);

  const rows: StrategyGroupRow[] = useMemo(() => {
    return sortStrategyGroups(strategyGroups).map((entity) => {
      const { id, strategies, portfolioManagerTeamId, name } = entity;
      const strategiesInGroup = getStrategyNames(
        gloabalStrategies,
        strategies.map((current) => current.globalStrategyId)
      );
      const team = teams.find(
        (current) => current.id === portfolioManagerTeamId
      );

      return {
        id,
        name,
        team: team?.name || 'Unknown team',
        strategies: strategiesInGroup,
      };
    });
  }, [strategyGroups, teams, gloabalStrategies]);

  const columns: GridColDef[] = [
    {
      field: StrategyGroupsListLocales.name.field,
      headerName: StrategyGroupsListLocales.name.headerName,
      width: 250,
      sortable: true,
      renderCell: ({ row: { name, id } }) => (
        <Link to={id}>
          <StrategyLibraryCell isClickableCell isVisible content={name} />
        </Link>
      ),
    },
    {
      field: StrategyGroupsListLocales.team.field,
      headerName: StrategyGroupsListLocales.team.headerName,
      width: 250,
      sortable: true,
      renderCell: ({ row: { team } }) => (
        <StrategyLibraryCell
          isClickableCell={false}
          isVisible={isVisibleSensitiveData}
          content={team}
        />
      ),
    },
    {
      field: StrategyGroupsListLocales.strategies.field,
      headerName: StrategyGroupsListLocales.strategies.headerName,
      width: 300,
      sortable: true,
    },
  ];

  return {
    rows,
    columns,
  };
};
