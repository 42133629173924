import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@clearalpha/common';
import {
  InitialValues,
  CorrelationsTableProps,
} from './CorrelationsDialog.type';
import CorrelationRow from './CorrelationRow';
import {
  getStrategyName,
  getStrategyInitials,
} from './CorrelationsDialog.helper';
import {
  CorrelationWrapper,
  CorrelationHeader,
  ColumnName,
  StrategyInitials,
  NoDataContainer,
  StrategyName,
  RowName,
  HeaderFiller,
} from './CorrelationsDialog.styled';

const CorrelationsTable = ({ form, strategies }: CorrelationsTableProps) => {
  const { t } = useTranslation();
  const {
    values: { correlations: rowCorrelations },
  }: { values: InitialValues } = form;

  const rowsLength = rowCorrelations.length;

  if (!rowsLength) {
    return <NoDataContainer>{t('noDataToDisplay')}</NoDataContainer>;
  }

  return (
    <CorrelationWrapper>
      <CorrelationHeader>
        <RowName>
          <StrategyName />
          <HeaderFiller />
        </RowName>
        {rowCorrelations.map(({ portfolioStrategyId }) => (
          <ColumnName key={portfolioStrategyId}>
            <Tooltip
              title={getStrategyName(strategies, portfolioStrategyId)}
              placement='top'
              arrow
            >
              <StrategyInitials>
                {getStrategyInitials(strategies, portfolioStrategyId)}
              </StrategyInitials>
            </Tooltip>
          </ColumnName>
        ))}
      </CorrelationHeader>
      {rowCorrelations.map(
        ({ portfolioStrategyId, correlations: columnCorrelations }, yIndex) => (
          <CorrelationRow
            key={portfolioStrategyId}
            columnCorrelations={columnCorrelations}
            yIndex={yIndex}
            rowsLength={rowsLength}
            strategies={strategies}
            portfolioStrategyId={portfolioStrategyId}
          />
        )
      )}
    </CorrelationWrapper>
  );
};

export default CorrelationsTable;
