import styled from '@emotion/styled';
import { Button, Dialog } from '@clearalpha/common';

const HistoryWrapper = styled.div`
  width: 645px;
  display: flex;
  background: ${({ theme: { colors } }) => colors.systemSurfaceAtPlusThree};
  border-radius: 16px;
  flex-direction: column;
  padding: 16px 32px 16px 32px;
  overflow: hidden;
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const VersionDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 1000px;
    width: 645px;
    border-radius: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  margin-bottom: 24px;
`;

const VersionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  & :last-child {
    border-bottom: none;
  }
  max-height: 321px;
  overflow-y: auto;
`;

const Label = styled.div`
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: ${({ theme: { colors } }) => colors.onSurface};
`;

const VersionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  gap: 105px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.outline};
  height: 74px;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const TimeWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

const DateAndStatusPart = styled.div`
  font-family: Droid Sans Mono, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1px;
`;

const ColumnName = styled.div`
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme: { colors } }) => colors.onSurface};
`;

const NoData = styled(ColumnName)`
  text-align: center;
  padding: 20px;
`;

const ButtonText = styled.p`
  margin: 0;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
`;

const RestoreVersionButton = styled(Button)`
  width: 191px;
  height: 42px;
`;

export const StatusWrapper = styled.div`
  height: 32px;
  border-radius: 5px;
  padding: 6px 16px;
  border: 1px solid ${({ theme: { colors } }) => colors.defaultPurple} !important;
`;

export const StatusText = styled.p`
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  margin: 0;
  display: flex;
  justify-content: center;
`;

export {
  ButtonText,
  HistoryWrapper,
  Header,
  VersionsContainer,
  Label,
  VersionItem,
  DateContainer,
  TimeWrapper,
  DateAndStatusPart,
  ColumnName,
  VersionDialog,
  RestoreVersionButton,
  NoData,
};
