import { GetPortfolioResponse } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { PortfolioPerformanceResponse } from 'services/fundCalculator/fundCalculator.types';

export const PORTFOLIOS_SLICE_NAME = 'portfolios';
export const FIRST_VERSION = 1;
export const STRING_EMPTY = '';
const NUMBER_EMPTY = 0;

const PORTFOLIO_STATE_DEFAULT: GetPortfolioResponse = {
  updatedAt: STRING_EMPTY,
  strategyGroups: [],
  arcesiumId: STRING_EMPTY,
  assetsUnderManagement: NUMBER_EMPTY,
  averageCorrelation: NUMBER_EMPTY,
  axiomaId: STRING_EMPTY,
  changeRequest: {
    changeRequestStatus: {
      deletedAt: STRING_EMPTY,
      id: STRING_EMPTY,
      name: STRING_EMPTY,
    },
    createdAt: STRING_EMPTY,
    deletedAt: STRING_EMPTY,
    description: STRING_EMPTY,
    id: STRING_EMPTY,
    ownerId: STRING_EMPTY,
    reviewerId: STRING_EMPTY,
    sourceId: STRING_EMPTY,
    sourceVersion: STRING_EMPTY,
    targetId: STRING_EMPTY,
    targetVersion: STRING_EMPTY,
  },
  constraints: {
    minProbReturnThreshold: NUMBER_EMPTY,
    targetFreeCashFromAum: NUMBER_EMPTY,
    targetMaxCapitalAtRisk: NUMBER_EMPTY,
    targetMaxEquityLeverage: NUMBER_EMPTY,
    targetMaxGrossExposure: NUMBER_EMPTY,
    targetMaxLiquidityCR: NUMBER_EMPTY,
    targetMaxPLRiskCR: NUMBER_EMPTY,
    targetMinNicheRisk: NUMBER_EMPTY,
    targetNetExcessReturn: NUMBER_EMPTY,
  },
  correlationSets: [],
  crossCapitalAtRiskBenefit: NUMBER_EMPTY,
  crossMarginBenefit: NUMBER_EMPTY,
  deletedAt: STRING_EMPTY,
  fees: [],
  freeCashFromVolMultiplier: NUMBER_EMPTY,
  id: STRING_EMPTY,
  minFreeCashFromAum: NUMBER_EMPTY,
  name: STRING_EMPTY,
  ownerId: STRING_EMPTY,
  portfolioOriginId: STRING_EMPTY,
  portfolioStatus: {
    deletedAt: STRING_EMPTY,
    id: STRING_EMPTY,
    name: STRING_EMPTY,
  },
  portfolioType: {
    deletedAt: STRING_EMPTY,
    id: STRING_EMPTY,
    name: STRING_EMPTY,
  },
  strategyAllocations: [],
  tbillYield: {
    deletedAt: STRING_EMPTY,
    id: STRING_EMPTY,
    value: NUMBER_EMPTY,
  },
  version: NUMBER_EMPTY,
};

export const PERFORMANCE_STATE_DEFAULT: PortfolioPerformanceResponse = {
  strategyAllocation: [],
  portfolio: {
    leverage: NUMBER_EMPTY,
    concentrationRatio: NUMBER_EMPTY,
    aum: NUMBER_EMPTY,
    lmv: NUMBER_EMPTY,
    smv: NUMBER_EMPTY,
    sumOfMarginReqs: NUMBER_EMPTY,
    crossMarginBenefitPct: NUMBER_EMPTY,
    marginReqs: NUMBER_EMPTY,
    freeCash: NUMBER_EMPTY,
    freeCashPct: NUMBER_EMPTY,
    riskAllocationPct: [],
    pAndLBreakdownPct: [],
    expNetTotalReturnPct: NUMBER_EMPTY,
    expNetExcessReturnPct: NUMBER_EMPTY,
    expVolPct: NUMBER_EMPTY,
    expNetSharpe: NUMBER_EMPTY,
    probabilityLess10Pct: NUMBER_EMPTY,
    averageCorrelation: NUMBER_EMPTY,
    volFromCorrelationPct: NUMBER_EMPTY,
    platformFixedExpensesPct: NUMBER_EMPTY,
    pmFixedExpensesPct: NUMBER_EMPTY,
    pmPerformanceFeesPct: NUMBER_EMPTY,
    expensesForRunningFundPct: NUMBER_EMPTY,
    caPerfFeePct: NUMBER_EMPTY,
    totalInvestorExpensesPct: NUMBER_EMPTY,
    fixedTotalFeesPct: NUMBER_EMPTY,
    variableTotalFeesPct: NUMBER_EMPTY,
    averageHoldPeriodPct: [],
    assetCategoryPct: [],
    alphaCategoriesPct: [],
    capitalAtRiskPct: NUMBER_EMPTY,
    stressedFreeCashPct: NUMBER_EMPTY,
    capitalAtRisk: NUMBER_EMPTY,
    probabilityLess0Pct: NUMBER_EMPTY,
    stressedFreeCash: NUMBER_EMPTY,
  },
  warnings: {},
  strategyGroups: [],
};

export const PORTFOLIOS_SLICE_INITIAL_STATE = {
  productionPortfolio: PORTFOLIO_STATE_DEFAULT,
  lastWorkingPortfolio: PORTFOLIO_STATE_DEFAULT,
  workingPortfolio: PORTFOLIO_STATE_DEFAULT,
  sourcePortfolio: PORTFOLIO_STATE_DEFAULT,
  targetPortfolio: PORTFOLIO_STATE_DEFAULT,
  initialWorkingPortfolio: PORTFOLIO_STATE_DEFAULT,
  productionPortfolioPerformance: PERFORMANCE_STATE_DEFAULT,
  workingPortfolioPerformance: PERFORMANCE_STATE_DEFAULT,
  workingPortfolioOptimizations: PERFORMANCE_STATE_DEFAULT,
  sourcePortfolioOptimizations: PERFORMANCE_STATE_DEFAULT,
  sourcePortfolioPerformance: PERFORMANCE_STATE_DEFAULT,
  targetPortfolioPerformance: PERFORMANCE_STATE_DEFAULT,
  strategyGroups: [],
  sourceStrategyGroups: [],
  productionStrategyGroups: [],
  isLoading: false,
  isOptimizationLoading: false,
  isConfirmationDialogActive: false,
  isReviewMode: false,
  portfolioFeeTypes: [],
  strategyGroupFeeTypes: [],
};
