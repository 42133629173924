import React, { ChangeEvent, FocusEvent, useCallback } from 'react';
import { useField } from 'formik';
import { TextField } from '@clearalpha/common';

type FormikTextProps = {
  name: string;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  disabled?: boolean;
  maxRows?: number;
  testId?: string;
  type?: 'text' | 'number';
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

const MAX_ROWS = 20;

const FormikText = ({
  name,
  label = '',
  placeholder = '',
  fullWidth = false,
  multiline = false,
  disabled = false,
  maxRows,
  testId,
  type = 'text',
  onKeyDown,
}: FormikTextProps) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] =
    useField(name);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTouched(true);
      setValue(e.target.value);
    },
    [setTouched, setValue]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setTouched(true);
      setValue(e.target.value.trim());
    },
    [setTouched, setValue]
  );

  const handleClear = useCallback(() => {
    setTouched(true);
    setValue('');
  }, [setTouched, setValue]);

  const rowsLimit = multiline ? maxRows || MAX_ROWS : undefined;
  const hasError = Boolean(error && touched);
  const errorMessage = hasError ? error : '';

  return (
    <TextField
      name={name}
      value={value}
      label={label}
      placeholder={placeholder}
      fullWidth={fullWidth}
      multiline={multiline}
      onKeyDown={onKeyDown}
      type={type}
      maxRows={rowsLimit}
      onChange={handleChange}
      onClear={handleClear}
      onBlur={handleBlur}
      error={hasError}
      helperText={errorMessage}
      testId={testId || name}
      disabled={disabled}
    />
  );
};

export default FormikText;
