import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Welcome from 'layouts/Welcome/Welcome';
import { routes } from 'constants/routes';
import { refreshSession } from 'services/auth/auth.service';
import NoAccess from 'components/NoAccess/NoAccess';
import ProtectedRoute from './ProtectedRoute';
import AppRouter from './AppRouter';

const Router = () => {
  const location = useLocation();

  useEffect(() => {
    refreshSession();
  }, [location]);

  return (
    <Routes>
      <Route path={routes.WELCOME.path} element={<Welcome />} />

      <Route
        path={routes.NO_ACCESS.path}
        element={
          <ProtectedRoute isAllowed>
            <NoAccess />
          </ProtectedRoute>
        }
      />

      <Route
        element={
          <ProtectedRoute isAllowed>
            <AppRouter />
          </ProtectedRoute>
        }
        path='*'
      />
    </Routes>
  );
};

export default Router;
