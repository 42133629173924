import { createAsyncThunk } from '@reduxjs/toolkit';
import * as service from 'services/portfolioCore/portfolioCore.service';
import { getCustomPortfolioPerformance } from 'services/fundCalculator/fundCalculator.service';
import { convertToPerformancePayload } from 'services/fundCalculator/fundCalculator.helper';
import { mapStateToResponse } from 'components/StrategyList/StrategyList.helpers';
import { getAllStrategiesByIds } from 'services/strategyCatalog/strategyCatalog.service';
import {
  FullPortfolioStrategy,
  StrategyPortfolioGroupResponse,
} from 'services/portfolioCore/portfolioCore.types';
import { RootState } from '../index';
import {
  setIsLoading,
  setReviewPortfolioMode,
  setSourceStrategyGroups,
  setSourceWorkingPortfolio,
  setSourceWorkingPortfolioPerformance,
  setTargetProductionPortfolio,
  setTargetProductionPortfolioPerformance,
} from '../slices/portfolios/portfolios.slice';
import { removeChangeRequest } from '../slices/changeRequests/changeRequests.slice';
import {
  setSourceStrategies,
  setTargetStrategies,
} from '../slices/strategies/strategies.slice';

export const applyChangeRequest = createAsyncThunk<
  unknown,
  string,
  { state: RootState }
>('applyChangeRequest', async (id, { dispatch }) => {
  dispatch(setIsLoading(true));

  try {
    await service.applyChangeRequest(id);
    dispatch(removeChangeRequest(id));
  } catch (e) {
    return e;
  } finally {
    dispatch(setReviewPortfolioMode(false));
    dispatch(setIsLoading(false));
  }
});

export const declineChangeRequest = createAsyncThunk<
  unknown,
  string,
  { state: RootState }
>('declineChangeRequest', async (id, { dispatch }) => {
  dispatch(setIsLoading(true));

  try {
    await service.declineChangeRequest(id);
    dispatch(removeChangeRequest(id));
  } catch (e) {
    return e;
  } finally {
    dispatch(setReviewPortfolioMode(false));
    dispatch(setIsLoading(false));
  }
});

export const getChangeRequestDiffs = createAsyncThunk<
  unknown,
  {
    sourceId: string;
    targetId: string;
    sourceVersion: number;
  },
  { state: RootState }
>(
  'getChangeRequestDiffs',
  async ({ sourceId, targetId, sourceVersion }, { dispatch }) => {
    dispatch(setIsLoading(true));

    try {
      dispatch(setReviewPortfolioMode(true));

      const sourcePortfolio = await service.getPortfolioVersion(
        sourceId,
        sourceVersion
      );
      const targetPortfolio = await service.getPortfolioById(targetId);

      const sourcePortfolioStrategiesIds =
        sourcePortfolio.strategyAllocations.map(({ strategyId }) => strategyId);
      const targetPortfolioStrategiesIds =
        targetPortfolio.strategyAllocations.map(({ strategyId }) => strategyId);
      const sourceStrategies = await getAllStrategiesByIds(
        sourcePortfolioStrategiesIds
      );
      const sourceMappedPortfolioStrategies: FullPortfolioStrategy[] =
        sourcePortfolio.strategyAllocations.map((portfolioStrategy) => {
          const groupId = portfolioStrategy.strategyGroup?.id
            ? portfolioStrategy.strategyGroup.id
            : portfolioStrategy.strategyGroupId;

          return {
            ...portfolioStrategy,
            strategy: sourceStrategies.find(
              (strategy) => strategy.id === portfolioStrategy.strategyId
            )!,
            strategyGroup: sourcePortfolio.strategyGroups.find(
              (group) => groupId === group.id
            )!,
          };
        });
      const targetStrategies = await getAllStrategiesByIds(
        targetPortfolioStrategiesIds
      );
      const targetMappedPortfolioStrategies: FullPortfolioStrategy[] =
        targetPortfolio.strategyAllocations.map((portfolioStrategy) => {
          const groupId = portfolioStrategy.strategyGroup?.id
            ? portfolioStrategy.strategyGroup.id
            : portfolioStrategy.strategyGroupId;

          return {
            ...portfolioStrategy,
            strategy: targetStrategies.find(
              (strategy) => strategy.id === portfolioStrategy.strategyId
            )!,
            strategyGroup: targetPortfolio.strategyGroups.find(
              (group) => groupId === group.id
            )!,
          };
        });

      const preparedStrategies = sourcePortfolio.strategyAllocations.map(
        (current) => ({ ...current, portfolioId: sourcePortfolio.id })
      );

      const shortSourceStrategiesHashmap = preparedStrategies.reduce(
        (acc: { [key: string]: StrategyPortfolioGroupResponse[] }, current) => {
          if (acc[current.strategyGroupId]) {
            acc[current.strategyGroupId].push(current);
          } else {
            acc[current.strategyGroupId] = [current];
          }
          return acc;
        },
        {}
      );
      const prepareStrategyGroups = sourcePortfolio.strategyGroups.map(
        (current) => ({
          ...current,
          portfolioId: sourcePortfolio.id,
          strategies: shortSourceStrategiesHashmap[current.id] || [],
        })
      );

      dispatch(setSourceStrategyGroups(prepareStrategyGroups));
      dispatch(setSourceStrategies(sourceMappedPortfolioStrategies));
      dispatch(setTargetStrategies(targetMappedPortfolioStrategies));
      dispatch(setSourceWorkingPortfolio(sourcePortfolio));
      dispatch(setTargetProductionPortfolio(targetPortfolio));

      const sourcePortfolioPayload = {
        portfolio: convertToPerformancePayload(sourcePortfolio),
        strategies: sourceMappedPortfolioStrategies.map(mapStateToResponse),
      };
      const targetPortfolioPayload = {
        portfolio: convertToPerformancePayload(targetPortfolio),
        strategies: targetMappedPortfolioStrategies.map(mapStateToResponse),
      };

      const sourcePortfolioPerformance = await getCustomPortfolioPerformance(
        sourcePortfolioPayload
      );
      const targetPortfolioPerformance = await getCustomPortfolioPerformance(
        targetPortfolioPayload
      );

      dispatch(
        setSourceWorkingPortfolioPerformance(sourcePortfolioPerformance)
      );
      dispatch(
        setTargetProductionPortfolioPerformance(targetPortfolioPerformance)
      );
    } catch (e) {
      return e;
    } finally {
      dispatch(setIsLoading(false));
    }
  }
);
