import styled from '@emotion/styled';
import {
  Box,
  Typography,
  DialogTitle as MUIDialogTitle,
  DialogActions as MUIDialogActions,
  Tab as MUITab,
  Tabs as MUITabs,
  Slider as MUISlider,
  TextField,
} from '@mui/material';
import {
  Dialog as BaseDialog,
  IconButton as BaseIconButton,
} from '@clearalpha/common';

const Dialog = styled(BaseDialog)`
  .MuiPaper-root {
    background: ${({ theme: { colors } }) => colors.systemSurfaceAtPlusThree};
    border-radius: 16px;
    padding: 24px;
    width: 100%;
    max-width: 100%;
  }
`;

const DialogTitle = styled(MUIDialogTitle)`
  color: ${({ theme: { colors } }) => colors.white};
  font-family: Work Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.4%;
`;

const DialogActions = styled(MUIDialogActions)`
  margin-bottom: 20px;
`;

const Description = styled(Typography)`
  color: ${({ theme: { colors } }) => colors.whiteOpacity70};
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
`;

const CorrelationWrapper = styled.div`
  display: grid;
`;

const CorrelationHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.inverseSurface};
  padding: 0 0 5px;
`;

const ColumnName = styled.div`
  display: flex;
  justify-content: center;
  width: 90px;
`;

const RowName = styled.div`
  display: flex;
  align-items: center;
`;

const StrategyName = styled(Typography)`
  font-family: Work Sans, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  width: 250px;
`;

const StrategyInitials = styled(Typography)`
  background: ${({ theme: { colors } }) => colors.violetOpacity2};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Work Sans, sans-serif;
  padding: 4px;
  border-radius: 100px;
  width: 50px;
  height: 30px;
`;

const Row = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.inverseSurface};
  display: grid;
  grid-auto-flow: column;
  gap: 5px;
  padding: 10px 0;
`;

const MainDiagonalField = styled.div`
  background: ${({ theme: { colors } }) => colors.surface};
  border: 1px solid ${({ theme: { colors } }) => colors.surfaceVariant2};
  color: ${({ theme: { colors } }) => colors.surfaceVariant2};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  font-family: Work Sans, sans-serif;
  height: 35px;
  line-height: 35px;
  padding: 3px 5px;
  width: 85px;
`;

const Input = styled.input`
  background: ${({ theme: { colors } }) => colors.surface};
  border: 1px solid ${({ theme: { colors } }) => colors.surfaceVariant2};
  border-radius: 4px;
  font-size: 16px;
  font-family: Droid Sans Mono, serif;
  height: 35px;
  line-height: 35px;
  padding: 3px 5px;
  text-align: center;
  width: 85px;

  &.error {
    border: 1px solid ${({ theme: { colors } }) => colors.sysColorDarkError};
  }

  &:enabled {
    color: ${({ theme: { colors } }) => colors.onSurfaceVariant};
  }

  &:disabled {
    color: ${({ theme: { colors } }) => colors.surfaceVariant2};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const NoDataContainer = styled(Box)`
  font-family: Work Sans, sans-serif;
  width: 100%;
  height: 100%;
  color: ${({ theme: { colors } }) => colors.onSurface};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
`;

const Tabs = styled(MUITabs)`
  .MuiTabs-indicator {
    background-color: ${({ theme: { colors } }) => colors.onSurface} !important;
  }
`;

const Tab = styled(MUITab)`
  font-family: Work Sans, sans-serif;
  color: ${({ theme: { colors } }) => colors.onSurface};
  &.Mui-selected {
    color: ${({ theme: { colors } }) => colors.onSurface};
  }
`;

const MixWrapper = styled.div``;

const MixTitle = styled(DialogTitle)`
  font-size: 14px;
  font-weight: 700;
`;

const SliderWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding-left: 24px;
  padding-bottom: 25px;
  align-items: center;
`;

const Slider = styled(MUISlider)`
  color: ${({ theme: { colors } }) => colors.onSurface};
  width: 265px;
  position: relative;
  bottom: 12px;
`;

const MixLabel = styled(Typography)`
  font-family: Public Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 166%;
  display: flex;
  justify-content: center;
  color: ${({ theme: { colors } }) => colors.whiteOpacity70};
  margin-top: 4px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HeaderFiller = styled(StrategyInitials)`
  background: none;
`;

const IconButton = styled(BaseIconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const PercentFieldSmall = styled(TextField)`
  color: ${({ theme: { colors } }) => colors.onSurface};
  width: 80px;
  font-family: 'Work Sans', sans-serif;
  & .MuiInputBase-input {
    text-align: right;
    color: ${({ theme: { colors } }) => colors.onSurface};
  }
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: ${({ theme: { colors } }) => colors.surfaceVariant2};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme: { colors } }) => colors.onSurface};
    }
  }
  & fieldset {
    border-color: ${({ theme: { colors } }) => colors.surfaceVariant2};
  }
  p {
    color: ${({ theme: { colors } }) => colors.onSurface};
  }
`;

export {
  Dialog,
  DialogTitle,
  DialogActions,
  Description,
  CorrelationWrapper,
  CorrelationHeader,
  ColumnName,
  RowName,
  StrategyName,
  StrategyInitials,
  Row,
  MainDiagonalField,
  Input,
  NoDataContainer,
  Tab,
  Tabs,
  MixWrapper,
  MixTitle,
  SliderWrapper,
  Slider,
  MixLabel,
  Form,
  HeaderFiller,
  IconButton,
  PercentFieldSmall,
};
