import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { teamsSelector } from 'store/slices/teams';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import StrategyLibraryCell from 'components/StrategyLibrary/StrategyLibraryCell/StrategyLibraryCell';
import {
  GetTeam,
  GetTeamManagerFromTeam,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { TeamRow, TeamsListHelperParams } from './TeamsList.types';
import { StrategyLibraryOutletContextParams } from '../../StrategyLibrary/StrategyLibrary.types';

export const sortTeams = (teams: GetTeam[]): GetTeam[] => {
  return [...teams].sort((a, b) => a.name.localeCompare(b.name));
};

export const getTeamManagerFullName = (manager: GetTeamManagerFromTeam) => {
  const { managerName, managerSurname } = manager;

  return `${managerName} ${managerSurname}`;
};

const useTeamsList = (): TeamsListHelperParams => {
  const { t } = useTranslation();
  const { handleOpenTeamDrawer } =
    useOutletContext<StrategyLibraryOutletContextParams>();

  const teams = useSelector(teamsSelector);
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);

  const rows: TeamRow[] = useMemo(() => {
    return sortTeams(teams).map((entity) => {
      const { id, name, teamLead, status } = entity;

      return {
        id,
        name,
        teamLead: getTeamManagerFullName(teamLead),
        status: status.name,
      };
    });
  }, [teams]);

  const columns: GridColDef<TeamRow>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('teamName'),
        width: 250,
        sortable: true,
        renderCell: ({ row: { name, id } }) => (
          <StrategyLibraryCell
            isClickableCell
            isVisible={isVisibleSensitiveData}
            content={name}
            handleClick={() => handleOpenTeamDrawer(id)}
          />
        ),
      },
      {
        field: 'teamLead',
        headerName: t('teamLead'),
        width: 300,
        sortable: true,
        renderCell: ({ row: { teamLead } }) => (
          <StrategyLibraryCell
            isVisible={isVisibleSensitiveData}
            content={teamLead}
          />
        ),
      },
      {
        field: 'status',
        headerName: t('status'),
        width: 135,
        sortable: true,
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'status-tag',
      },
    ],
    [t, isVisibleSensitiveData, handleOpenTeamDrawer]
  );

  return {
    rows,
    columns,
  };
};

export default useTeamsList;
