import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NumericTextCurrency, TextFieldWrapper } from '@clearalpha/common';
import FormikCurrencyNumbers from 'components/formik/fields/FormikCurrencyNumbers';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { FieldLabel, FieldWrapper } from '../../styled';
import { StrategyFormFields } from '../../../StrategyDetails.constants';
import { StrategyDetailsFormFields } from '../../../StrategyDetails.type';

const MinAllocation = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.productionValues.minAllocation !== values.allocation_min;

  return (
    <>
      <FieldLabel>{t('minAllocation')}</FieldLabel>
      <NumericTextCurrency value={values.productionValues.minAllocation} />
      {!isPortfolioReviewMode ? (
        <FormikCurrencyNumbers
          name={StrategyFormFields.ALLOCATION_MIN}
          customInput={TextFieldWrapper}
        />
      ) : (
        <FieldWrapper className={isValueHighlighted ? 'highlighted' : ''}>
          <NumericTextCurrency value={values.allocation_min} />
        </FieldWrapper>
      )}
    </>
  );
};

export default MinAllocation;
