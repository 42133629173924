import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: '7pt 0',
  },
  description: {
    width: '60%',
  },
  value: {
    width: '20%',
  },
});
