import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMarginTypes } from 'services/strategyCatalog/strategyCatalog.service';
import { RootState } from 'store';
import { setLoadingMarginTypes, setMarginTypes } from 'store/slices/strategies';

export const fetchMarginTypes = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchMarginTypes', async (_, { dispatch }) => {
  dispatch(setLoadingMarginTypes(true));

  try {
    const marginTypes = await getMarginTypes();

    dispatch(setMarginTypes(marginTypes));
  } finally {
    dispatch(setLoadingMarginTypes(false));
  }
});
