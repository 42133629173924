import React from 'react';
import { Tooltip } from '@clearalpha/common';
import { CorrelationRowProps } from './CorrelationsDialog.type';
import {
  getStrategyName,
  getStrategyInitials,
} from './CorrelationsDialog.helper';
import CorrelationCell from './CorrelationCell';
import {
  Row,
  RowName,
  StrategyName,
  StrategyInitials,
} from './CorrelationsDialog.styled';

const CorrelationRow = ({
  columnCorrelations,
  yIndex,
  rowsLength,
  strategies,
  portfolioStrategyId,
}: CorrelationRowProps) => {
  const columnsLength = columnCorrelations.length;

  return (
    <Row>
      <RowName>
        <Tooltip
          title={getStrategyName(strategies, portfolioStrategyId)}
          placement='top'
          arrow
        >
          <StrategyName>
            {getStrategyName(strategies, portfolioStrategyId)}
          </StrategyName>
        </Tooltip>
        <Tooltip
          title={getStrategyName(strategies, portfolioStrategyId)}
          placement='top'
          arrow
        >
          <StrategyInitials>
            {getStrategyInitials(strategies, portfolioStrategyId)}
          </StrategyInitials>
        </Tooltip>
      </RowName>
      {columnCorrelations.map(({ portfolioStrategyId: id }, xIndex) => (
        <CorrelationCell
          key={id}
          yIndex={yIndex}
          rowsLength={rowsLength}
          xIndex={xIndex}
          columnsLength={columnsLength}
        />
      ))}
    </Row>
  );
};

export default CorrelationRow;
