import { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { DialogContent } from '@mui/material';
import {
  NumericInputCurrency,
  NumericTextCurrency,
  currencyFormatter,
  NON_DRAGGABLE,
} from '@clearalpha/common';
import { updateAumValidationSchema } from 'validation/updateAumValidationSchema';
import {
  UpdateAumDialogProps,
  updateAumDialogRows,
} from './UpdateAumDialog.type';
import {
  Dialog,
  DialogTitle,
  ProductionAumRow,
  TotalAumRow,
  TotalAumValue,
  Error,
  FlowRow,
  TextField,
  Note,
  FieldLabel,
  FlowFieldLabel,
  DialogActions,
  Button,
} from './styled';

const UpdateAumDialog: FC<UpdateAumDialogProps> = ({
  updateAumDialogData,
  handleClose,
  onUpdate,
  isOpen,
  ...props
}) => {
  const { t } = useTranslation();

  const { values, setFieldValue, errors, isValid, handleSubmit } = useFormik({
    initialValues: {
      production_aum: updateAumDialogData.production_aum,
      total_aum: updateAumDialogData.total_aum,
      inflow: 0,
      outflow: 0,
    },
    validationSchema: updateAumValidationSchema,
    onSubmit: () => {
      onUpdate(values.total_aum);
      handleClose();
    },
  });
  const { productionAumRow, totalAumRow, flowRows } = updateAumDialogRows;

  useEffect(() => {
    setFieldValue(
      productionAumRow.variableName,
      updateAumDialogData.production_aum
    );
    setFieldValue(totalAumRow.variableName, updateAumDialogData.total_aum);
    flowRows.forEach((flowRow) => setFieldValue(flowRow.variableName, 0));
  }, [isOpen, updateAumDialogData]);

  useEffect(() => {
    setFieldValue(
      totalAumRow.variableName,
      updateAumDialogData.total_aum + values.inflow - values.outflow
    );
  }, [values.inflow, values.outflow]);

  const handeClickCancel = () => {
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      {...props}
      className={NON_DRAGGABLE}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t('dialogs.aum.title')}</DialogTitle>
        <DialogContent>
          <ProductionAumRow>
            <FieldLabel>{productionAumRow.fieldName}</FieldLabel>
            <NumericTextCurrency
              value={values[productionAumRow.variableName]}
            />
          </ProductionAumRow>
          <TotalAumRow isValid={isValid}>
            <FieldLabel>{totalAumRow.fieldName}</FieldLabel>
            <TotalAumValue>
              {currencyFormatter(values[totalAumRow.variableName])}
            </TotalAumValue>
            <Error>{errors.total_aum}</Error>
          </TotalAumRow>
          {flowRows.map((row) => (
            <FlowRow key={row.fieldName}>
              <Note>{row.note}</Note>
              <FlowFieldLabel>{row.fieldName}</FlowFieldLabel>
              <NumericInputCurrency
                value={values[row.variableName]}
                variableName={row.variableName}
                setFieldValue={setFieldValue}
                customInput={TextField}
              />
            </FlowRow>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant='filled' onClick={handeClickCancel}>
            {t('actions.cancel')}
          </Button>
          <Button type='submit' variant='filled' disabled={!isValid}>
            {t('actions.update')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateAumDialog;
