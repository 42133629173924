import styled from '@emotion/styled';
import {
  Typography,
  DialogTitle as MUIDialogTitle,
  DialogActions as MUIDialogActions,
} from '@mui/material';
import { Dialog as BaseDialog, Button as BaseButton } from '@clearalpha/common';

const Dialog = styled(BaseDialog)`
  background-color: ${({ theme: { colors } }) => colors.blackOverlay25};

  .MuiPaper-root {
    background: ${({ theme: { colors } }) => colors.surfacePlusFive};
    border-radius: 8px;
    color: ${({ theme: { colors } }) => colors.onSurface};
    padding: 8px;
    width: 464px;
  }
`;

const DialogTitle = styled(MUIDialogTitle)`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

const StrategyName = styled(Typography)`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  text-transform: uppercase;
  opacity: 0.6;
  padding: 0 24px 16px 24px;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 13px 0;
`;

const DialogActions = styled(MUIDialogActions)`
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 14px 24px;
`;

const TitleProduction = styled(Typography)`
  font-family: 'Work Sans';
  font-size: 18px;
  ::before {
    position: relative;
    top: 3px;
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ theme: { colors } }) => colors.brandColor};
    display: inline-block;
    margin-right: 16px;
  }
`;

const TitleWorking = styled(TitleProduction)`
  font-family: 'Work Sans';
  font-size: 18px;
  ::before {
    background: ${({ theme: { colors } }) => colors.dataFour};
  }
`;

const Value = styled(Typography)`
  font-family: 'Droid Sans Mono';
  font-size: 18px;
  margin-right: 12px;
`;

const Button = styled(BaseButton)`
  height: 40px;
  padding: 7px 23px;
  width: 105px;

  .MuiTypography-root {
    font-size: 18px;
  }
`;

const SystemRecommendation = styled.div`
  display: flex;
  justify-content: end;
`;

const SystemRecommendationText = styled(Typography)`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  opacity: 0.86;
`;

const SystemRecommendationValue = styled(SystemRecommendationText)`
  font-family: 'Droid Sans Mono';
  margin-right: 5px;
`;

export {
  Dialog,
  DialogTitle,
  StrategyName,
  Row,
  DialogActions,
  TitleProduction,
  TitleWorking,
  Value,
  Button,
  SystemRecommendation,
  SystemRecommendationText,
  SystemRecommendationValue,
};
