import { Button as BaseButton } from '@clearalpha/common';
import styled from '@emotion/styled';

const ButtonWrap = styled.span`
  padding-top: 10px;
`;

const Button = styled(BaseButton)`
  border: 1px solid ${({ theme: { colors } }) => colors.outline};
  height: 40px;
  width: 136px;

  span {
    color: ${({ theme: { colors } }) => colors.onSurface};
    font-family: Droid Sans Mono, serif;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 1px;
    opacity: 0.87;
    text-align: right;
    width: 100%;
  }
`;

const EditableCell = styled.div`
  .MuiInputBase-root {
    width: 136px !important;
  }
`;

export { ButtonWrap, Button, EditableCell };
