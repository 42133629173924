import { THEME_COLORS } from '@clearalpha/common';

export const STRATEGY_ALLOCATION_WIDGET_MIN_ANGLE = 0;

export const STRATEGY_ALLOCATION_WIDGET_COLORS = [
  THEME_COLORS.dataOne,
  THEME_COLORS.dataTwo,
  THEME_COLORS.dataThree,
  THEME_COLORS.dataFour,
  THEME_COLORS.dataFive,
  THEME_COLORS.dataSix,
  THEME_COLORS.dataSeven,
  THEME_COLORS.dataEight,
  THEME_COLORS.dataNine,
  THEME_COLORS.dataTen,
  THEME_COLORS.dataEleven,
  THEME_COLORS.dataTwelve,
];
