import React from 'react';
import { useField } from 'formik';
import { FieldValueSelect } from '@clearalpha/common';

type FormikSelectProps = {
  name: string;
  options: Record<string, string>;
  label?: string;
  testId?: string;
  disabled?: boolean;
  multiple?: boolean;
};

const FormikSelect = ({
  name,
  label,
  options,
  multiple = false,
  disabled = false,
  testId,
}: FormikSelectProps) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] =
    useField(name);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleChange = (_: string, selectedValue: any) => {
    setTouched(true);
    setValue(selectedValue);
  };

  const hasError = Boolean(error && touched);
  const errorMessage = hasError ? error : '';

  // TODO: Add the possibility to reset Select with an empty value https://linear.app/clearalpha/issue/UX-1075/add-the-possibility-to-reset-select-fileds-with-an-empty-value
  return (
    <FieldValueSelect
      value={value}
      variableName={name}
      label={label}
      setFieldValue={handleChange}
      menuOptions={options}
      multiple={multiple}
      testId={testId || name}
      disabled={disabled}
      error={errorMessage}
    />
  );
};

export default FormikSelect;
