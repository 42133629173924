import {
  StrategyAllocationInfo,
  StrategyListEntity,
} from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { createHashMap } from 'helpers';
import {
  FullPortfolioStrategy,
  GetPortfolioStrategies,
} from 'services/portfolioCore/portfolioCore.types';
import { StrategyPerformanceRequest } from 'services/fundCalculator/fundCalculator.types';
import { AlphaArchetypeIds } from 'constants/strategy';
import { getFeeValue } from 'services/portfolioCore/portfolioCore.helper';
import { getAlphaCategoryValue } from '../StrategyDetails/StrategyDetails.helper';

export const prepareStrategiesData = (
  prodStrategiesData: GetPortfolioStrategies[],
  workStrategiesData: GetPortfolioStrategies[],
  strategies: FullPortfolioStrategy[],
  strategiesProd: FullPortfolioStrategy[],
  optimizations: StrategyAllocationInfo[]
): StrategyListEntity[] => {
  const prodHashMap = createHashMap('globalStrategyId', prodStrategiesData);
  const workHashMap = createHashMap('globalStrategyId', workStrategiesData);
  const optimizationsHashMap = createHashMap('globalStrategyId', optimizations);
  const strategiesProdHashMap = createHashMap(
    'globalStrategyId',
    strategiesProd
  );
  const unionHashMapOfStrategies = createHashMap(
    'globalStrategyId',
    strategies
  );
  strategiesProd.forEach((current) => {
    if (!unionHashMapOfStrategies[current.globalStrategyId]) {
      unionHashMapOfStrategies[current.globalStrategyId] = current;
    }
  });

  const unionListOfStrategies: FullPortfolioStrategy[] = Object.values(
    unionHashMapOfStrategies
  );
  return unionListOfStrategies
    .map((current) => {
      const copyOf = ''; // TODO: current?.copyOf ||
      const productionStrategy =
        strategiesProdHashMap[current.globalStrategyId];
      return {
        isStrategyPersonal: !current.status?.id,
        isStrategyChanged: false,
        id: current.id,
        arcesiumId: current.arcesiumId,
        globalStrategyId: current.globalStrategyId,
        status: current.status,
        strategy: {
          id: current.strategy.id,
          capitalAtRiskWindowBdays: current.strategy.capitalAtRiskWindowBdays,
          deletedAt: current.strategy.deletedAt,
          pbMapping: current.strategy.pbMapping,
          alphaCategory: current.strategy.alphaCategories,
          proportionSystematic: current.strategy.proportionSystematic,
          name: current.strategy.name,
          assetCategory: current.strategy?.assetCategory,
          groupCharacteristic: current.strategy?.groupCharacteristic,
          description: current.strategy.description,
          strategyEdge: current.strategy.strategyEdge,
          averageHoldingPeriod: current.strategy?.averageHoldingPeriod,
          historicSharpeRatio: current.strategy.historicSharpeRatio,
          volatilityTarget: current.strategy.volatilityTarget,
          alphaCategories: current.strategy.alphaCategories,
          grossSharpe: current.strategy.grossSharpe,
          marginType: current.strategy.marginType,
          accelerator_threshold: 0, // TODO: HARDCODED VALUE
          accelerator_percentage: 0, // TODO: HARDCODED VALUE
          stepup_threshold: 0, // TODO: HARDCODED VALUE
          stepup_percentage: 0, // TODO: HARDCODED VALUE
          capacity_used: 0, // TODO: HARDCODED VALUE
          account_value: 0, // TODO: HARDCODED VALUE
          remaining_capacity: 0, // TODO: HARDCODED VALUE
          gross_sharpe_historic: 0, // TODO CHANGE HARDCODED VALUE
          gross_sharpe_historic_unique: 0,
          minAllocation: current.strategy.minAllocation,
          capacity: current.strategy.capacity,
        },
        productionValues: {
          status: productionStrategy?.status?.id || '',
          arcesiumId: productionStrategy?.arcesiumId || '',
          description: productionStrategy?.strategy.description || '',
          strategyEdge: productionStrategy?.strategy.strategyEdge || '',
          scientist: getAlphaCategoryValue(
            'SCIENTIST',
            productionStrategy?.strategy.alphaCategories
          ),
          engineer: getAlphaCategoryValue(
            'ENGINEER',
            productionStrategy?.strategy.alphaCategories
          ),
          actuary: getAlphaCategoryValue(
            'ACTUARY',
            productionStrategy?.strategy.alphaCategories
          ),
          averageHoldingPeriod:
            productionStrategy?.strategy.averageHoldingPeriod.id || '',
          assetCategory: productionStrategy?.strategy.assetCategory.id || '',
          groupCharacteristic:
            productionStrategy?.strategy.groupCharacteristic.id || '',
          marginType: productionStrategy?.strategy.marginType.id || '',
          managementFee:
            productionStrategy?.strategyGroup?.fees?.find(
              (item: any) => item.fee.name === 'mgmtFee'
            )?.value || 0,
          performanceFee:
            productionStrategy?.strategyGroup?.fees?.find(
              (item: any) => item.fee.name === 'performanceFee'
            )?.value || 0,
          fixedExpenses:
            productionStrategy?.strategyGroup?.fees?.find(
              (item: any) => item.fee.name === 'fixedExpenses'
            )?.value || 0,
          acceleratorThreshold: 0, // TODO: HARDCODED VALUE
          acceleratorPercentage: 0, // TODO: HARDCODED VALUE
          stepUpThreshold: 0, // TODO: HARDCODED VALUE
          stepUpPercentage: 0, // TODO: HARDCODED VALUE
          capacity: productionStrategy?.strategy.capacity || 0,
          grossSharpe: productionStrategy?.strategy.grossSharpe || 0,
          historicSharpeRatio:
            productionStrategy?.strategy.historicSharpeRatio || 0,
          minAllocation: productionStrategy?.strategy.minAllocation || 0,
          volatilityTarget: productionStrategy?.strategy.volatilityTarget || 0,
          workingNotionalAccountValue:
            productionStrategy?.notionalAccountValue || 0,
          strategyGroup: productionStrategy?.strategyGroup || null,
          name: productionStrategy?.strategy.name || '',
        },
        strategyGroup: current.strategyGroup,
        optimisation: 'Allocation', // TODO: CHANGE HARDCODED VALUE
        workingNotionalAccountValue: workHashMap[current.globalStrategyId]
          ? workHashMap[current.globalStrategyId]?.notionalAccountValue
          : null,
        productionNotionalAccountValue: prodHashMap[
          copyOf || current.globalStrategyId
        ]
          ? prodHashMap[copyOf || current.globalStrategyId]
              ?.notionalAccountValue
          : null,
        recommendedNotionalAccountValue:
          optimizationsHashMap[current.globalStrategyId]
            ?.notionalAccountValue || 0,
        locked: workHashMap[current.globalStrategyId]?.isLocked || false,
        version: current.version,
      };
    })
    .sort((a, b) => a.strategy.name.localeCompare(b.strategy.name));
};

export const mapStateToResponse = (
  strategy: FullPortfolioStrategy
): StrategyPerformanceRequest => {
  const alphaCategoriesHashMap = createHashMap(
    'id',
    strategy.strategy.alphaCategories
  );
  // fix mgmt perf

  return {
    uuid: strategy.id,
    version: strategy.version,
    strategy: {
      uuid: strategy.strategy.id,
      name: strategy.strategy.name,
      description: strategy.strategy.description,
      averageHoldingPeriod: strategy.strategy.averageHoldingPeriod.name,
      assetCategory: strategy.strategy.assetCategory.name,
      strategyEdge: strategy.strategy.strategyEdge,
      alphaCategory: {
        scientist:
          alphaCategoriesHashMap[AlphaArchetypeIds.SCIENTIST]?.value || 0,
        engineer:
          alphaCategoriesHashMap[AlphaArchetypeIds.ENGINEER]?.value || 0,
        actuary: alphaCategoriesHashMap[AlphaArchetypeIds.ACTUARY]?.value || 0,
      },
      volatilityTarget: strategy.strategy.volatilityTarget,
      capitalAtRiskWindowBdays: strategy.strategy.capitalAtRiskWindowBdays,
      proportionSystematic: strategy.strategy.proportionSystematic,
      performanceAttributes: {
        marginType: strategy.strategy.marginType.name,
        capacity: strategy.strategy.capacity,
        grossSharpe: strategy.strategy.grossSharpe,
        historicSharpeRatio: strategy.strategy.historicSharpeRatio,
        minAllocation: strategy.strategy.minAllocation,
        marginRatio: strategy.strategy.marginType.marginRatio,
        notionalMultiplier: strategy.strategy.marginType.notionalMultiplier,
        groupCharacteristic: strategy.strategy.groupCharacteristic.name,
      },
    },
    strategyGroup: {
      uuid: strategy.strategyGroup.id,
      name: strategy.strategyGroup.name,
      fixedExpenses: getFeeValue(
        'Fixed expense budget',
        strategy.strategyGroup.fees
      ),
      mgmtFee: getFeeValue('Management fee', strategy.strategyGroup.fees),
      performanceFee: getFeeValue(
        'Performance fee',
        strategy.strategyGroup.fees
      ),
    },
  };
};
