import { number, object, ref } from 'yup';
import i18n from 'translations/i18n';
import { StrategyFormFields } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.constants';
import { FIELD_LENGTH } from '../constants/fields';
import { requiredText } from './defaultValidationRules';
import {
  MINUS_TEN,
  ONE_HUNDRED_BILLION,
  TEN,
  TEN_BILLION,
  ZERO,
} from './validationNumbers';
import { testArchetypesSum } from './validation.helpers';

export const globalStrategyValidationSchema = object().shape({
  [StrategyFormFields.TEAM]: object().required(
    i18n.t('errors.required', { name: i18n.t('team') })
  ),
  [StrategyFormFields.NAME]: requiredText({
    fieldName: i18n.t('name'),
  }),
  [StrategyFormFields.DESCRIPTION]: requiredText({
    fieldName: i18n.t('strategyDescription'),
    maxLength: FIELD_LENGTH.LONG_TEXT,
  }),
  [StrategyFormFields.STRATEGY_EDGE]: requiredText({
    fieldName: i18n.t('strategyEdge'),
    maxLength: FIELD_LENGTH.LONG_TEXT,
  }),
  [StrategyFormFields.SCIENTIST]: number()
    .test({
      name: 'Test archetypes sum',
      skipAbsent: true,
      test(value, ctx) {
        if (typeof value === 'number') {
          return testArchetypesSum(
            value,
            this.parent[StrategyFormFields.ENGINEER],
            this.parent[StrategyFormFields.ACTUARY],
            ctx
          );
        }
      },
    })
    .required(),
  [StrategyFormFields.ENGINEER]: number()
    .test({
      name: 'Test archetypes sum',
      skipAbsent: true,
      test(value, ctx) {
        if (typeof value === 'number') {
          return testArchetypesSum(
            this.parent[StrategyFormFields.SCIENTIST],
            value,
            this.parent[StrategyFormFields.ACTUARY],
            ctx
          );
        }
      },
    })
    .required(),
  [StrategyFormFields.ACTUARY]: number()
    .test({
      name: 'Test archetypes sum',
      skipAbsent: true,
      test(value, ctx) {
        if (typeof value === 'number') {
          return testArchetypesSum(
            this.parent[StrategyFormFields.SCIENTIST],
            this.parent[StrategyFormFields.ENGINEER],
            value,
            ctx
          );
        }
      },
    })
    .required(),
  [StrategyFormFields.GROSS_SHARPE]: number()
    .min(
      MINUS_TEN,
      i18n.t('errors.notLessThan', {
        name: i18n.t('grossSharpeRatio'),
        value: MINUS_TEN,
      })
    )
    .max(
      TEN,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('grossSharpeRatio'),
        value: TEN,
      })
    )
    .required(),
  [StrategyFormFields.MARGIN_TYPE]: requiredText({
    fieldName: i18n.t('marginType'),
  }),
  [StrategyFormFields.DESCRIPTION_SHORT]: requiredText({
    fieldName: i18n.t('strategyCategory'),
  }),
  [StrategyFormFields.CATEGORY]: requiredText({
    fieldName: i18n.t('alphaCategory'),
  }),
  [StrategyFormFields.AVERAGE_HOLD_PERIOD]: requiredText({
    fieldName: i18n.t('averageHoldPeriod'),
  }),
  [StrategyFormFields.STRATEGY_VOLATILITY_TARGET]: number()
    .moreThan(
      ZERO,
      i18n.t('errors.notLessOrEqualTo', {
        name: i18n.t('volatilityTarget'),
        value: ZERO,
      })
    )
    .max(
      TEN,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('volatilityTarget'),
        value: TEN,
      })
    )
    .required(),
  [StrategyFormFields.ALLOCATION_MIN]: number()
    .min(
      ZERO,
      i18n.t('errors.notLessThan', {
        name: i18n.t('minAllocation'),
        value: ZERO,
      })
    )
    .test({
      name: 'Test min allocation value',
      skipAbsent: true,
      test(value, ctx) {
        if (value && value > TEN_BILLION) {
          return ctx.createError({
            message: i18n.t('errors.notMoreThan', {
              name: i18n.t('minAllocation'),
              value: '10B',
            }),
          });
        }
        if (value && value > this.parent[StrategyFormFields.ALLOCATION_TERMS]) {
          return ctx.createError({
            message: i18n.t('errors.notMoreThan', {
              name: i18n.t('minAllocation'),
              value: i18n.t('aumCapacity'),
            }),
          });
        }
        return true;
      },
    })
    .required(),
  [StrategyFormFields.ALLOCATION_TERMS]: number()
    .min(
      ref(StrategyFormFields.ALLOCATION_MIN),
      i18n.t('errors.notLessThan', {
        name: i18n.t('aumCapacity'),
        value: i18n.t('minAllocation'),
      })
    )
    .max(
      ONE_HUNDRED_BILLION,
      i18n.t('errors.notMoreThan', {
        name: i18n.t('aumCapacity'),
        value: '100B',
      })
    )
    .required(),
});
