import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {
  setReviewPortfolioMode,
  setSourceStrategyGroups,
} from 'store/slices/portfolios/portfolios.slice';
import {
  applyChangeRequest,
  declineChangeRequest,
} from 'store/thunks/changeRequest';
import {
  sourcePortfolioPerformanceSelector,
  sourcePortfolioSelector,
  targetPortfolioPerformanceSelector,
  targetPortfolioSelector,
} from 'store/slices/portfolios';
import {
  sourceStrategiesSelector,
  targetStrategiesSelector,
} from 'store/slices/strategies';
import {
  Button,
  CloseIcon,
  formatToCommonDate,
  localizeDatetime,
} from '@clearalpha/common';
import { ConfirmationDialog } from 'components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import { ChangeReportDialog } from 'components/Dialogs/ChangeReportDialog/ChangeReportDialog';
import { PdfReport } from 'components/PdfReport/PdfReport';
import { getEstDateString } from 'helpers/datetime';
import { productionInit } from 'store/thunks/productionInit';
import {
  ApprovalHeaderWrapper,
  ButtonGroup,
  InfoText,
  FilledButton,
  TextButton,
} from './styled';
import { ApprovalHeaderProps } from './ApprovalHeader.type';
import { collectPortfolioChangesToConfigs } from '../../Dialogs/ChangeReportDialog/ChangeReportDialog.helpers';

const ApprovalHeader = ({ changeRequest }: ApprovalHeaderProps) => {
  const { t } = useTranslation();
  const dateTimeLocalized = localizeDatetime(changeRequest?.createdAt || '');
  const { time, date } = formatToCommonDate(dateTimeLocalized);
  const dispatch = useAppDispatch();
  const [isApproveDialogOpened, setApproveDialogOpened] = useState(false);
  const [isDeclineDialogOpened, setDeclineDialogOpened] = useState(false);
  const [isChangeReportDialogOpened, setChangeReportDialogOpened] =
    useState(false);

  const sourcePortfolio = useSelector(sourcePortfolioSelector);
  const targetPortfolio = useSelector(targetPortfolioSelector);
  const sourceStrategies = useSelector(sourceStrategiesSelector);
  const targetStrategies = useSelector(targetStrategiesSelector);
  const sourcePerformance = useSelector(sourcePortfolioPerformanceSelector);
  const targetPerformance = useSelector(targetPortfolioPerformanceSelector);

  const handleOpenChangeReportDialog = () => {
    setChangeReportDialogOpened(true);
  };

  const handleCloseChangeReportDialog = () => {
    setChangeReportDialogOpened(false);
  };

  const handleOpenApproveDialog = () => {
    setApproveDialogOpened(true);
  };

  const handleOpenDeclineDialog = () => {
    setDeclineDialogOpened(true);
  };

  const handleApproveDialogClose = () => {
    setApproveDialogOpened(false);
  };

  const handleApproveChangeRequest = async () => {
    const selectedId = changeRequest?.id || '';
    const sourceVersion = sourcePortfolio.version;

    setApproveDialogOpened(false);
    setChangeReportDialogOpened(false);
    const { payload: error } = await dispatch(applyChangeRequest(selectedId));

    if (!error) {
      const {
        fundLevelDiffsConfig,
        strategyLevelDiffsConfig,
        strategyGroupDiffsConfig,
        addedStrategiesConfig,
        deletedStrategiesConfig,
        editedStrategiesConfig,
        // addedGroupsConfig,
        // deletedGroupsConfig,
      } = collectPortfolioChangesToConfigs(
        sourcePortfolio,
        targetPortfolio,
        sourceStrategies,
        targetStrategies,
        sourcePerformance,
        targetPerformance
      );
      const pdfReportTemplate = (
        <PdfReport
          sourceVersion={sourceVersion}
          fundLevelConfig={fundLevelDiffsConfig}
          strategyLevelConfig={strategyLevelDiffsConfig}
          addedStrategiesConfig={addedStrategiesConfig}
          deletedStrategiesConfig={deletedStrategiesConfig}
          editedStrategiesConfig={editedStrategiesConfig}
          // addedGroupsConfig={addedGroupsConfig}
          // deletedGroupsConfig={deletedGroupsConfig}
          groupLevelConfig={strategyGroupDiffsConfig}
        />
      );
      const pdfReportDocument = pdf();
      pdfReportDocument.updateContainer(pdfReportTemplate);
      const blob = await pdfReportDocument.toBlob();
      const dateString = getEstDateString();
      saveAs(blob, `SummaryChangeReport_${dateString}.pdf`);
    }

    dispatch(setSourceStrategyGroups([]));
    dispatch(productionInit());
  };

  const handleDeclineDialogClose = () => {
    setDeclineDialogOpened(false);
  };

  const handleDeclineChangeRequest = async () => {
    const selectedId = changeRequest?.id || '';

    setDeclineDialogOpened(false);
    await dispatch(declineChangeRequest(selectedId));
    dispatch(setSourceStrategyGroups([]));
  };

  const handleCancelReview = async () => {
    dispatch(setReviewPortfolioMode(false));
    dispatch(setSourceStrategyGroups([]));
  };

  if (!changeRequest) {
    return null;
  }

  return (
    <ApprovalHeaderWrapper>
      <InfoText>
        {t('approvalInfo', {
          date: `${date}`,
          time: `${time}`,
        })}
      </InfoText>
      <ButtonGroup>
        <TextButton variant='text' onClick={handleOpenDeclineDialog}>
          {t('actions.reject')}
        </TextButton>
        <FilledButton variant='filled' onClick={handleOpenChangeReportDialog}>
          {t('actions.approve')}
        </FilledButton>
      </ButtonGroup>
      <Button variant='text' onClick={handleCancelReview}>
        <CloseIcon />
      </Button>
      <ConfirmationDialog
        isOpen={isApproveDialogOpened}
        title={t('dialogs.approveRequest.title')}
        message={t('dialogs.approveRequest.message')}
        testId='approve-dialog'
        confirmButtonText={t('dialogs.approveRequest.confirm')}
        handleClose={handleApproveDialogClose}
        handleConfirm={handleApproveChangeRequest}
      />
      <ConfirmationDialog
        isOpen={isDeclineDialogOpened}
        title={t('dialogs.declineRequest.title')}
        message={t('dialogs.declineRequest.message')}
        testId='decline-dialog'
        confirmButtonText={t('dialogs.declineRequest.confirm')}
        handleClose={handleDeclineDialogClose}
        handleConfirm={handleDeclineChangeRequest}
      />
      <ChangeReportDialog
        isOpen={isChangeReportDialogOpened}
        handleClose={handleCloseChangeReportDialog}
        sourcePortfolio={sourcePortfolio}
        targetPortfolio={targetPortfolio}
        sourceStrategies={sourceStrategies}
        targetStrategies={targetStrategies}
        sourcePerformance={sourcePerformance}
        targetPerformance={targetPerformance}
        submitText={t('actions.approve')}
        changeReportText={t('dialogs.changeReport.messageToApprove')}
        changeReportInfo={t('dialogs.changeReport.info', {
          date,
          time,
        })}
        handleSubmit={handleOpenApproveDialog}
      />
    </ApprovalHeaderWrapper>
  );
};

export { ApprovalHeader };
