import { transformToPieChartWidgetData } from 'helpers';
import { ALPHA_CATEGORIES } from 'constants/strategy';
import { PnlBreakdownPct } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { KEY_CATEGORY_COLORS } from './PnLBreakdownWidget.const';

export const getPnLBreakdownPieChartData = (data: PnlBreakdownPct[]) => {
  return transformToPieChartWidgetData({
    data,
    keyField: 'groupName',
    percentageField: 'pAndLBreakdownPct',
    locals: ALPHA_CATEGORIES,
    colors: KEY_CATEGORY_COLORS,
  });
};
