import styled from '@emotion/styled';
import {
  Button as BaseButton,
  IconButton as BaseIconButton,
} from '@clearalpha/common';

const BlockTitle = styled.h4`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 16px;
`;

const Block = styled.div`
  background-color: ${({ theme: { colors } }) =>
    colors.stateLayersDarkPrimaryOpacity};
  border-radius: 8px;
  padding: 32px;
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 232px 1fr;
  align-items: center;
  gap: 24px;
  color: ${({ theme: { colors } }) => colors.onSurface};
  opacity: 0.87;

  span {
    display: flex;
    justify-content: end;
    text-align: right;
  }
`;

const DescriptiveFields = styled(Fields)`
  .MuiFormHelperText-root {
    position: absolute;
    top: 100%;
    white-space: nowrap;
    right: 0;
    margin: 0;
  }
`;

const DescriptiveFieldsWithProduction = styled(DescriptiveFields)`
  grid-template-columns: 1.3fr 0.7fr 1fr;
`;

const FieldsWithProduction = styled(Fields)`
  grid-template-columns: 232px 1fr 1fr;
`;

const FieldLabel = styled.p`
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 26px;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
`;

const Button = styled(BaseButton)`
  & .MuiTypography-root {
    color: ${({ theme: { colors } }) => colors.onSurface};
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`;

const IconButton = styled(BaseIconButton)`
  margin-left: 10px;
`;

const FieldWrapper = styled.div`
  &.highlighted {
    background-color: ${({ theme: { colors } }) => colors.highlightColor};
  }
`;

const IdInputWrapper = styled(FieldWrapper)`
  margin-left: 12px;
`;

const IdFieldWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export {
  Block,
  BlockTitle,
  Fields,
  DescriptiveFields,
  DescriptiveFieldsWithProduction,
  FieldsWithProduction,
  FieldLabel,
  Button,
  IconButton,
  FieldWrapper,
  IdInputWrapper,
  IdFieldWrapper,
};
