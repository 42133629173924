import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetPortfolioManager } from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { Section, SectionTitle } from '../../PortfolioManagerDetails.styled';
import { StrategyExpertise, CurrentFirm, FormerFirms } from './fields';

type ExperienceProps = {
  portfolioManager: GetPortfolioManager | null;
};

const Experience = ({ portfolioManager }: ExperienceProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle>{t('experience')}</SectionTitle>
      <Section>
        <StrategyExpertise expertises={portfolioManager?.strategyExpertises} />
        <CurrentFirm currentFirm={portfolioManager?.currentFirm} />
        <FormerFirms formerFirms={portfolioManager?.formerFirms} />
      </Section>
    </>
  );
};

export default Experience;
