import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, BlockTitle, DescriptiveFieldsWithProduction } from '../styled';
import {
  MarginType,
  StrategyCategory,
  AlphaCategory,
  AverageHoldPeriod,
} from './fields';
import ColumnsHeader from '../ColumnsHeader/ColumnsHeader';

const Descriptive = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <BlockTitle>{t('descriptive')}</BlockTitle>
      <DescriptiveFieldsWithProduction>
        <ColumnsHeader />
        <MarginType />
        <StrategyCategory />
        <AlphaCategory />
        <AverageHoldPeriod />
      </DescriptiveFieldsWithProduction>
    </Block>
  );
};

export default Descriptive;
