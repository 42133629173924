import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConfirmationDialog, InfoIcon, Toasters } from '@clearalpha/common';
import i18n from 'translations/i18n';
import { useAppDispatch } from 'store';
import {
  productionPortfolioSelector,
  setWorkingPortfolio,
  productionStrategyGroupsSelector,
  setStrategyGroups,
  workingPortfolioSelector,
} from 'store/slices/portfolios';
import {
  saraProductionStrategiesSelector,
  setStrategies,
  strategiesSelector,
} from 'store/slices/strategies';
import { copyListOfStrategies } from 'services/strategyCatalog/strategyCatalog.service';
import { ResetToProductionDialogProps } from './ResetToProductionDialog.type';

const ResetToProductionDialog = ({
  isOpen,
  handleClose,
}: ResetToProductionDialogProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const saraProductionStrategies = useSelector(
    saraProductionStrategiesSelector
  );
  const strategies = useSelector(strategiesSelector);
  const productionPortfolio = useSelector(productionPortfolioSelector);
  const workingPortfolio = useSelector(workingPortfolioSelector);
  const productionStrategyGroups = useSelector(
    productionStrategyGroupsSelector
  );

  const handleResetToProduction = async () => {
    try {
      const saraProductionStrategyIds = saraProductionStrategies.map(
        ({ strategyId }) => strategyId
      );

      const newStrategyIds = await copyListOfStrategies(
        saraProductionStrategyIds
      );

      const newStrategies = saraProductionStrategies.map(
        (saraProductionStrategy) => {
          const id = strategies.find(
            ({ globalStrategyId }) =>
              globalStrategyId === saraProductionStrategy.globalStrategyId
          )?.id;

          return {
            ...saraProductionStrategy,
            id: id || saraProductionStrategy.id,
            strategyId: newStrategyIds[saraProductionStrategy.strategyId],
            strategyType: {
              id: 'SCENARIO',
              name: t('scenario'),
              deletedAt: null,
            },
            strategy: {
              ...saraProductionStrategy.strategy,
              id: newStrategyIds[saraProductionStrategy.strategyId],
            },
          };
        }
      );

      const newStrategyAllocations =
        productionPortfolio.strategyAllocations.map(
          (productionStrategyAllocation) => {
            const id = workingPortfolio.strategyAllocations.find(
              ({ globalStrategyId }) =>
                globalStrategyId ===
                productionStrategyAllocation.globalStrategyId
            )?.id;

            return {
              ...productionStrategyAllocation,
              id: id || productionStrategyAllocation.id,
              strategyId:
                newStrategyIds[productionStrategyAllocation.strategyId],
              strategyType: {
                id: 'SCENARIO',
                name: t('scenario'),
                deletedAt: null,
              },
            };
          }
        );

      dispatch(setStrategies(newStrategies));
      dispatch(
        setWorkingPortfolio({
          ...productionPortfolio,
          id: workingPortfolio.id,
          portfolioType: workingPortfolio.portfolioType,
          portfolioOriginId: workingPortfolio.portfolioOriginId,
          portfolioStatus: workingPortfolio.portfolioStatus,
          ownerId: workingPortfolio.ownerId,
          name: workingPortfolio.name,
          axiomaId: workingPortfolio.axiomaId,
          changeRequest: workingPortfolio.changeRequest,
          version: workingPortfolio.version,
          strategyAllocations: newStrategyAllocations,
        })
      );
      dispatch(setStrategyGroups(productionStrategyGroups));
      Toasters.success(i18n.t('scenarioCleared'));
    } catch {
      Toasters.error(i18n.t('errors.somethingWentWrong'));
    } finally {
      handleClose();
    }
  };

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      handleClose={handleClose}
      title={t('dialogs.resetToProduction.title')}
      message={t('dialogs.resetToProduction.desc')}
      cancelButton={{
        label: t('actions.cancel'),
        action: handleClose,
      }}
      confirmButton={{
        label: t('actions.proceed'),
        action: handleResetToProduction,
      }}
      icon={<InfoIcon />}
    />
  );
};

export default ResetToProductionDialog;
