import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { THEME_COLORS } from '@clearalpha/common';
import {
  BreadcrumbsWrapper,
  StyledBreadcrumbs,
  StyledLink,
} from './SaraBreadcrumbs.styled';
import { SaraBreadcrumbsProps } from './SaraBreadcrumbs.type';

const SaraBreadcrumbs = ({ items, backButtonUrl }: SaraBreadcrumbsProps) => {
  return (
    <BreadcrumbsWrapper>
      <StyledLink key='home' color='inherit' to={backButtonUrl}>
        <ArrowBackIosIcon fontSize='small' htmlColor={THEME_COLORS.grey400} />
      </StyledLink>
      <StyledBreadcrumbs separator='/' items={items} />
    </BreadcrumbsWrapper>
  );
};

export default SaraBreadcrumbs;
