import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 650px;
`;

const Description = styled.div`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.5px;
  opacity: 0.77;
  margin: 0;
  word-break: break-word;
`;

const DescriptionSection = styled.section`
  margin-bottom: 48px;
`;

const Section = styled.section`
  margin-bottom: 48px;
  background-color: ${({ theme: { colors } }) =>
    colors.stateLayersDarkPrimaryOpacity};
  border-radius: 8px;
  padding: 24px;
`;

const SectionTitle = styled.h4`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 8px 0;
`;

const StatRow = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 160px 1fr;
  margin-bottom: 16px;

  span {
    margin: 0;
    word-break: break-word;
  }
`;

const StatName = styled.p`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  opacity: 0.87;
  margin: 0 0 10px;
`;

export {
  Wrapper,
  DescriptionSection,
  Section,
  StatRow,
  StatName,
  SectionTitle,
  Description,
};
