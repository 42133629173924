import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isGlobalStrategyEditModeSelector } from 'store/slices/strategies';
import { FormikText, FormikValue } from 'components/formik/fields';
import { Block, BlockTitle } from 'components/StrategyDetails/blocks/styled';
import { Description as StyledDescription } from './Description.styled';
import { StrategyFormFields } from '../../GlobalStrategyDetails.constants';

const Description = () => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);

  return (
    <Block>
      <BlockTitle>{t('strategyDescription')}</BlockTitle>

      {isEditMode ? (
        <FormikText
          name={StrategyFormFields.DESCRIPTION}
          placeholder={t('strategyDescription')}
          fullWidth
          multiline
        />
      ) : (
        <StyledDescription>
          <FormikValue name={StrategyFormFields.DESCRIPTION} />
        </StyledDescription>
      )}
    </Block>
  );
};

export default Description;
