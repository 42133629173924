import React from 'react';
import {
  DatePicker,
  DatePickerValue,
  DatePickerProps,
} from '@clearalpha/common';
import { useField } from 'formik';

type DatePickerFieldProps = Omit<
  DatePickerProps,
  'value' | 'onChange' | 'error'
> & {
  name: string;
};

const DatePickerField = ({
  name,
  label,
  minDate,
  maxDate,
  disableFuture,
  disablePast,
  disabled,
}: DatePickerFieldProps) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] =
    useField(name);

  const hasError = Boolean(error && touched);
  const errorMessage = hasError ? error : '';

  const handleChange = (newValue: DatePickerValue) => {
    setTouched(true);
    setValue(newValue);
  };

  return (
    <DatePicker
      label={label}
      value={value}
      onChange={handleChange}
      error={errorMessage}
      minDate={minDate}
      maxDate={maxDate}
      disableFuture={disableFuture}
      disablePast={disablePast}
      disabled={disabled}
    />
  );
};

export default DatePickerField;
