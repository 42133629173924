import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@clearalpha/common';
import { FormButtonsContainer } from './Actions.styles';
import { FormControlsProps } from './Actions.type';

const FormButtons = ({
  isEditMode,
  isDirty,
  handleCancel,
  handleCloseDrawer,
  submitButtonLabel,
}: FormControlsProps) => {
  const { t } = useTranslation();

  return (
    <FormButtonsContainer>
      {!isEditMode ? (
        <Button variant='outlined' onClick={handleCloseDrawer}>
          {t('actions.close')}
        </Button>
      ) : (
        <>
          <Button
            data-testid='doneBtn'
            variant='filled'
            type='submit'
            disabled={!isDirty}
          >
            {submitButtonLabel || t('actions.done')}
          </Button>
          <Button variant='outlined' onClick={handleCancel}>
            {t('actions.cancel')}
          </Button>
        </>
      )}
    </FormButtonsContainer>
  );
};

export default FormButtons;
