import { createAsyncThunk } from '@reduxjs/toolkit';
import { Toasters } from '@clearalpha/common';
import { RootState } from 'store';
import * as service from 'services/portfolioManagerCore/portfolioManagerCore.service';
import { deleteTeam, setIsTeamLoading } from 'store/slices/teams';
import i18n from 'translations/i18n';
import { fetchTeams } from './fetchTeams';
import { fetchPortfolioManagers } from './fetchPortfolioManagers';

export const removeTeam = createAsyncThunk<
  void,
  { uuid: string },
  { state: RootState }
>('removeTeam', async ({ uuid }, { dispatch }) => {
  dispatch(setIsTeamLoading(true));

  try {
    await service.deleteTeam(uuid);

    dispatch(deleteTeam(uuid));

    dispatch(fetchTeams());
    dispatch(fetchPortfolioManagers());

    Toasters.success(i18n.t('success.teamDeleted'));
  } finally {
    dispatch(setIsTeamLoading(false));
  }
});
