import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { fetchGlobalStrategies } from 'store/thunks';
import { deleteGlobalStrategy } from 'services/strategyCatalog/strategyCatalog.service';
import { setIsGlobalStrategiesLoading } from '../slices/strategies';

export const removeGlobalStrategy = createAsyncThunk<
  void,
  {
    uuid: string;
  },
  { state: RootState }
>('removeGlobalStrategy', async ({ uuid }, { dispatch }) => {
  dispatch(setIsGlobalStrategiesLoading(true));

  try {
    await deleteGlobalStrategy(uuid);

    dispatch(fetchGlobalStrategies());
  } finally {
    dispatch(setIsGlobalStrategiesLoading(false));
  }
});
