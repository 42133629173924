import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { NumericTextCurrency } from '@clearalpha/common';
import { FieldLabel, FieldWrapper } from '../../styled';
import { StrategyDetailsFormFields } from '../../../StrategyDetails.type';
import { calculateRemainingCapacity } from '../../../StrategyDetails.helper';

const RemainingCapacity = () => {
  const { t } = useTranslation();
  const {
    values: {
      allocation_terms: aumCapacity,
      account_value: notionalAccountValue,
      productionValues,
    },
  } = useFormikContext<StrategyDetailsFormFields>();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);

  const productionRemainingCapacity = calculateRemainingCapacity({
    aumCapacity: productionValues.capacity,
    notionalAccountValue: productionValues.workingNotionalAccountValue,
  });
  const workingRemainingCapacity = calculateRemainingCapacity({
    aumCapacity,
    notionalAccountValue,
  });

  const isValueHighlighted =
    isPortfolioReviewMode &&
    productionRemainingCapacity !== workingRemainingCapacity;

  return (
    <>
      <FieldLabel>{t('remainingCapacity')}</FieldLabel>
      <NumericTextCurrency value={productionRemainingCapacity} />
      <FieldWrapper className={isValueHighlighted ? 'highlighted' : ''}>
        <NumericTextCurrency value={workingRemainingCapacity} />
      </FieldWrapper>
    </>
  );
};

export default RemainingCapacity;
