import { config } from 'config';
import { post } from 'services/http';
import { addAlphaCategoriesMock } from './fundCalculator.helper';
import {
  PerformanceRequest,
  PortfolioPerformanceResponse,
} from './fundCalculator.types';

const ROOT_URL = `${config.api.fundCalculator}/fund-calculator/api/v3`;

const ROUTES = {
  PERFORMANCE: `${ROOT_URL}/portfolios/performance`,
  OPTIMIZATION: `${ROOT_URL}/portfolios/optimization`,
};

export const getCustomPortfolioPerformance = async (
  data: PerformanceRequest
): Promise<PortfolioPerformanceResponse> => {
  const result = await post(ROUTES.PERFORMANCE, data);

  return addAlphaCategoriesMock(result);
};

export const getCustomPortfolioOptimization = async (
  data: PerformanceRequest
): Promise<PortfolioPerformanceResponse> => {
  return post(ROUTES.OPTIMIZATION, data);
};
