import React from 'react';
import { useTranslation } from 'react-i18next';
import { GetPortfolioManager } from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { InvestingExperience, HighestDegree, School } from './fields';
import { Section, SectionTitle } from '../../PortfolioManagerDetails.styled';

type TeamProps = {
  portfolioManager?: GetPortfolioManager | null;
};

const Team = ({ portfolioManager }: TeamProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionTitle>{t('teams')}</SectionTitle>
      <Section>
        <InvestingExperience />
        <HighestDegree />
        <School schools={portfolioManager?.schools} />
      </Section>
    </>
  );
};

export default Team;
