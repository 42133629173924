import { useEffect, useMemo, useState } from 'react';
import { FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import { Drawer } from '@clearalpha/common';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { ActionMenuItems } from 'constants/locales';
import { removeGlobalStrategy } from 'store/thunks/removeGlobalStrategy';
import { teamsSelector } from 'store/slices/teams';
import { StyledForm } from 'components/StrategyDetails/StrategyDetails.styled';
import { StrategyDetailsProps } from './GlobalStrategyDetails.type';
import { Wrapper, SectionGrid } from './GlobalStrategyDetails.styled';
import {
  createTeamOptions,
  useGlobalStrategyDetailsForm,
} from './GlobalStrategyDetails.helper';
import Actions from '../StrategyLibrary/Actions/Actions';
import UnsavedChangesDialog from '../Dialogs/UnsavedChangesDialog/UnsavedChangesDialog';
import DeleteConfirmationDialog from '../Dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import {
  PmTeam,
  Capacity,
  Description,
  Name,
  StrategyEdge,
  Performance,
  AlphaArchetypes,
  Descriptive,
} from './blocks';
import {
  isGlobalStrategyEditModeSelector,
  setIsGlobalStrategyEditMode,
} from '../../store/slices/strategies';

const GlobalStrategyDetails = ({
  isOpen,
  handleCloseDrawer,
  handleOpenTeam,
  updateDrawer,
  strategy,
}: StrategyDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isOpenUnsavedChanges, setIsOpenUnsavedChanges] = useState(false);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] =
    useState<boolean>(false);
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);
  const teams = useSelector(teamsSelector);

  const teamOptions = useMemo(() => createTeamOptions(teams), [teams]);

  const isCreateForm = !strategy;

  const { formik, initialValues } = useGlobalStrategyDetailsForm({
    strategy,
    updateDrawer,
    teamOptions,
    isCreateForm,
  });

  const { dirty, resetForm, handleSubmit } = formik;

  const handleCancel = () => {
    setIsCancelClicked(true);

    if (dirty) {
      return setIsOpenUnsavedChanges(true);
    }

    if (isCreateForm) {
      return handleCloseDrawer();
    }

    dispatch(setIsGlobalStrategyEditMode(false));
  };

  const handleOutsideClick = () => {
    setIsCancelClicked(false);
    if (dirty) {
      return setIsOpenUnsavedChanges(true);
    }

    handleCloseDrawer();
  };

  const handleCancelUnsavedChanges = () => {
    setIsOpenUnsavedChanges(false);
  };

  const handleConfirmUnsavedChanges = () => {
    handleCancelUnsavedChanges();
    resetForm({ values: initialValues });

    if (isCancelClicked && !isCreateForm) {
      dispatch(setIsGlobalStrategyEditMode(false));
    } else {
      handleCloseDrawer();
    }
  };

  const handleDeleteAction = () => {
    setIsDeleteDialogOpened(true);
  };

  const handleDeleteStrategy = () => {
    dispatch(removeGlobalStrategy({ uuid: strategy?.id || '' }));
    handleCloseDrawer();
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpened(false);
  };

  useEffect(() => {
    if (!isOpen) return;

    setIsDeleteDialogOpened(false);
    dispatch(setIsGlobalStrategyEditMode(isCreateForm));
    resetForm({ values: initialValues });
  }, [isOpen, resetForm, dispatch, isCreateForm, initialValues]);

  const menuItems: ActionMenuItems[] = useMemo(
    () => [
      {
        label: t('actions.edit'),
        isVisible: !isEditMode,
        action: () => dispatch(setIsGlobalStrategyEditMode(true)),
      },
      {
        label: t('actions.archive'),
      },
      {
        label: t('actions.delete'),
        action: handleDeleteAction,
      },
    ],
    [t, isEditMode, dispatch]
  );

  return (
    <Drawer
      data-testid='strategyDetailsDrawer'
      anchor='right'
      open={isOpen}
      onClose={handleOutsideClick}
    >
      <FormikProvider value={formik}>
        <StyledForm onSubmit={handleSubmit}>
          <Actions
            isDirty={dirty}
            isCreateForm={isCreateForm}
            handleCancel={handleCancel}
            handleCloseDrawer={handleCloseDrawer}
            isEditModeSelector={isGlobalStrategyEditModeSelector}
            menuItems={menuItems}
          />
          <Wrapper data-testid='strategyDetailsWrapper'>
            <PmTeam handleOpenTeam={handleOpenTeam} teamOptions={teamOptions} />
            <Name />
            <SectionGrid>
              <Description />
              <StrategyEdge />
              <AlphaArchetypes />
              <Performance />
              <Descriptive />
              <Capacity />
            </SectionGrid>
          </Wrapper>
        </StyledForm>
      </FormikProvider>
      <UnsavedChangesDialog
        isOpen={isOpenUnsavedChanges}
        handleClose={handleCancelUnsavedChanges}
        confirmAction={handleConfirmUnsavedChanges}
        cancelAction={handleCancelUnsavedChanges}
      />
      <DeleteConfirmationDialog
        handleClose={handleCloseDeleteDialog}
        isOpen={isDeleteDialogOpened}
        handleDelete={handleDeleteStrategy}
        title={t('dialogs.deleteStrategy.title')}
        description={t('dialogs.deleteStrategy.message')}
      />
    </Drawer>
  );
};

export default GlobalStrategyDetails;
