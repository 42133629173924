import styled from '@emotion/styled';
import {
  Stack,
  TableBody as BaseTableBody,
  TableContainer as BaseTableContainer,
} from '@mui/material';

const Container = styled(Stack)`
  height: 100%;
`;

const TableContainer = styled(BaseTableContainer)`
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const TableBody = styled(BaseTableBody)(
  ({ theme: { colors } }) => `
  background: ${colors.systemSurfaceAtPlusOne};
  position: relative;
`
);

export { Container, TableContainer, TableBody };
