import React from 'react';
import {
  ActionMenu,
  MoreVertIcon,
  SensitiveDataButton,
  StarIcon,
  Tooltip,
} from '@clearalpha/common';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { isTeamEditModeSelector } from 'store/slices/teams';
import {
  TeamMember,
  TeamMemberActions,
  TeamMemberInfo,
  TeamMemberName,
  TeamMemberNameLink,
  TeamMemberRole,
} from './TeamMemberList.styled';
import { TeamMemberListItemProps } from './TeamMemberList.types';

const TeamMemberListItem = ({
  member,
  teamLeadId,
  assignTeamLead,
  removeTeamMember,
  handleOpenTeamMember,
}: TeamMemberListItemProps) => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isTeamEditModeSelector);
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);

  const isTeamLead = teamLeadId === member.uuid;

  const menuItems = [
    {
      label: t('actions.assignTeamLead'),
      action: () => assignTeamLead(member.uuid),
      disabled: isTeamLead,
    },
    {
      label: t('actions.remove'),
      action: () => removeTeamMember(member.uuid),
    },
  ];

  const handleOpenMemberDetails = () => {
    if (!isEditMode) {
      handleOpenTeamMember(member.uuid);
    }
  };

  return (
    <TeamMember data-testid={member.name}>
      <TeamMemberInfo>
        <TeamMemberName>
          <SensitiveDataButton
            alignItems='flex-start'
            onClick={handleOpenMemberDetails}
            isVisible={isEditMode || isVisibleSensitiveData}
          >
            <TeamMemberNameLink
              type='button'
              isEditMode={isEditMode}
              onClick={handleOpenMemberDetails}
            >
              {member.name}
            </TeamMemberNameLink>
          </SensitiveDataButton>
        </TeamMemberName>
        <TeamMemberRole>{member.role}</TeamMemberRole>
      </TeamMemberInfo>
      <TeamMemberActions>
        {isTeamLead && (
          <Tooltip title={t('teamLead')} placement='top'>
            <div>
              <StarIcon />
            </div>
          </Tooltip>
        )}

        {isEditMode && (
          <ActionMenu
            menuItems={menuItems}
            menuIcon={<MoreVertIcon />}
            testId='menu'
          />
        )}
      </TeamMemberActions>
    </TeamMember>
  );
};

export default TeamMemberListItem;
