import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { ModeEditIcon } from '@clearalpha/common';
import { FormikSelect } from 'components/formik/fields';
import { STRATEGY_STATUSES, StrategyStatuses } from 'constants/strategy';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { StrategyDetailsFormFields } from '../../StrategyDetails.type';
import { StatusProps } from './Status.type';
import { StrategyFormFields } from '../../StrategyDetails.constants';
import { Wrapper, StyledStatus } from './Status.styled';
import { IconButton } from '../styled';

const Status = ({
  isStatusEditMode,
  handleStatusEditModeClick,
}: StatusProps) => {
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);

  return (
    <Wrapper>
      {isStatusEditMode ? (
        <FormikSelect
          name={StrategyFormFields.STATUS}
          options={STRATEGY_STATUSES}
        />
      ) : (
        <>
          <StyledStatus>
            {STRATEGY_STATUSES[values.status as StrategyStatuses]}
          </StyledStatus>
          <IconButton
            onClick={handleStatusEditModeClick}
            disabled={isPortfolioReviewMode}
          >
            <ModeEditIcon />
          </IconButton>
        </>
      )}
    </Wrapper>
  );
};

export default Status;
