import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  GetTeam,
  TeamEntityRequest,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { createTeam } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import { setIsTeamEditMode, setIsTeamLoading } from 'store/slices/teams';
import { fetchTeams } from './fetchTeams';
import { fetchPortfolioManagers } from './fetchPortfolioManagers';

export const createNewTeam = createAsyncThunk<
  GetTeam | null,
  { team: TeamEntityRequest },
  { state: RootState }
>('createNewTeam', async ({ team }, { dispatch }) => {
  dispatch(setIsTeamLoading(true));

  try {
    const newTeam = await createTeam(team);

    dispatch(fetchTeams());
    dispatch(fetchPortfolioManagers());

    dispatch(setIsTeamEditMode(false));

    return newTeam;
  } catch {
    return null;
  } finally {
    dispatch(setIsTeamLoading(false));
  }
});
