import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@clearalpha/common';
import { FormikSelect } from 'components/formik/fields';
import { useSelector } from 'react-redux';
import { globalStrategiesSelector } from 'store/slices/strategies';
import { SelectStrategyProps } from './SelectStrategy.type';
import {
  ButtonWrapper,
  Content,
  ErrorMessage,
  Title,
  Wrapper,
} from './SelectStrategy.styled';

const SelectStrategy = ({
  options,
  handleCancel,
  moveToTheNextStep,
  formState,
  setSelectedStrategy,
}: SelectStrategyProps) => {
  const { t } = useTranslation();
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);
  const globalStrategies = useSelector(globalStrategiesSelector);

  const handleClick = () => {
    if (!formState.strategy) {
      setIsErrorVisible(true);
    } else {
      const strategy = globalStrategies.find(
        (current) => current.id === formState.strategy
      );
      if (strategy) {
        setSelectedStrategy(strategy);
      }
      moveToTheNextStep();
      setIsErrorVisible(false);
    }
  };

  return (
    <Wrapper>
      <Title data-testid='strategyTitle'>{t('selectStrategy')}</Title>
      <Content>
        <FormikSelect label={t('strategy')} name='strategy' options={options} />
        {isErrorVisible && (
          <ErrorMessage data-testid='error' error>
            {t('errors.required', { name: t('strategy') })}
          </ErrorMessage>
        )}
      </Content>
      <ButtonWrapper>
        <Button onClick={handleCancel} variant='text'>
          {t('actions.cancel')}
        </Button>
        <Button
          data-testid='assignGroup'
          onClick={handleClick}
          variant='filled'
        >
          {t('continue')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default SelectStrategy;
