import i18n from 'i18next';
import { FieldValueEnum } from '@clearalpha/common';
import { GetPortfolioResponse } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { getFeeValue } from 'services/portfolioCore/portfolioCore.helper';
import {
  EditedStrategy,
  FullPortfolioStrategy,
  StrategyGroup,
} from 'services/portfolioCore/portfolioCore.types';
import { PortfolioPerformanceResponse } from 'services/fundCalculator/fundCalculator.types';
import { fundOverviewWidgetConfig } from 'components/widgets/FundOverviewWidget/FundOverviewWidget.type';
import { PERFORMANCE_STATS_WIDGET_CONFIG } from 'components/widgets/ProjectedPerformanceStatsWidget/ProjectedPerformanceStatsWidget.const';
import {
  calculateRemainingCapacity,
  calculateVolatilityCapacity,
  getAlphaCategoryValue,
} from '../../StrategyDetails/StrategyDetails.helper';
import {
  AllocationDiffConfig,
  ConfigurationWithName,
  GroupDifferences,
  RowTypeWithValue,
  StrategiesDifferences,
} from './ChangeReportDialog.type';
import {
  STRATEGY_LEVEL_CONFIG_BASE,
  GROUP_LEVEL_CONFIG_BASE,
  ZERO_ALLOCATIONS_VALUE,
  PARAMETERS_CONFIG,
} from './ChangeReportDialog.constants';

const comparePortfolios = (
  portfolioA: GetPortfolioResponse,
  portfolioB: GetPortfolioResponse,
  performanceA: PortfolioPerformanceResponse,
  performanceB: PortfolioPerformanceResponse
) => {
  const portfolioAFees = portfolioA?.fees || [];
  const portfolioBFees = portfolioB?.fees || [];

  return {
    aum: {
      source: portfolioA?.assetsUnderManagement || 0,
      target: portfolioB?.assetsUnderManagement || 0,
    },
    leverage: {
      source: performanceA?.portfolio.leverage || 0,
      target: performanceB?.portfolio.leverage || 0,
    },
    marginReqs: {
      source: performanceA?.portfolio.marginReqs || 0,
      target: performanceB?.portfolio.marginReqs || 0,
    },
    freeCash: {
      source: performanceA?.portfolio.freeCash || 0,
      target: performanceB?.portfolio.freeCash || 0,
    },
    freeCashPct: {
      source: performanceA?.portfolio.freeCashPct || 0,
      target: performanceB?.portfolio.freeCashPct || 0,
    },
    concentrationRatio: {
      source: performanceA?.portfolio?.concentrationRatio || 0,
      target: performanceB?.portfolio?.concentrationRatio || 0,
    },
    stressedFreeCashPct: {
      source: performanceA?.portfolio?.stressedFreeCashPct || 0,
      target: performanceB?.portfolio?.stressedFreeCashPct || 0,
    },
    expNetTotalReturnPct: {
      source: performanceA?.portfolio?.expNetTotalReturnPct || 0,
      target: performanceB?.portfolio?.expNetTotalReturnPct || 0,
    },
    expNetExcessReturnPct: {
      source: performanceA?.portfolio?.expNetExcessReturnPct || 0,
      target: performanceB?.portfolio?.expNetExcessReturnPct || 0,
    },
    expVolPct: {
      source: performanceA?.portfolio?.expVolPct || 0,
      target: performanceB?.portfolio?.expVolPct || 0,
    },
    expNetSharpe: {
      source: performanceA?.portfolio?.expNetSharpe || 0,
      target: performanceB?.portfolio?.expNetSharpe || 0,
    },
    probabilityLess10Pct: {
      source: performanceA?.portfolio?.probabilityLess10Pct || 0,
      target: performanceB?.portfolio?.probabilityLess10Pct || 0,
    },
    capitalAtRisk: {
      source: performanceA?.portfolio?.capitalAtRiskPct || 0,
      target: performanceB?.portfolio?.capitalAtRiskPct || 0,
    },
    crossMarginBenefit: {
      source: portfolioA?.crossMarginBenefit || 0,
      target: portfolioB?.crossMarginBenefit || 0,
    },
    averageCorrelation: {
      source: portfolioA?.averageCorrelation || 0,
      target: portfolioB?.averageCorrelation || 0,
    },
    crossCapitalAtRiskBenefit: {
      source: portfolioA?.crossCapitalAtRiskBenefit || 0,
      target: portfolioB?.crossCapitalAtRiskBenefit || 0,
    },
    targetNetExcessReturn: {
      source: portfolioA?.constraints?.targetNetExcessReturn || 0,
      target: portfolioB?.constraints?.targetNetExcessReturn || 0,
    },
    minProbReturnThreshold: {
      source: portfolioA?.constraints?.minProbReturnThreshold || 0,
      target: portfolioB?.constraints?.minProbReturnThreshold || 0,
    },
    targetMaxGrossExposure: {
      source: portfolioA?.constraints?.targetMaxGrossExposure || 0,
      target: portfolioB?.constraints?.targetMaxGrossExposure || 0,
    },
    targetMaxCapitalAtRisk: {
      source: portfolioA?.constraints?.targetMaxCapitalAtRisk || 0,
      target: portfolioB?.constraints?.targetMaxCapitalAtRisk || 0,
    },
    targetMaxLiquidityCR: {
      source: portfolioA?.constraints?.targetMaxLiquidityCR || 0,
      target: portfolioB?.constraints?.targetMaxLiquidityCR || 0,
    },
    targetFreeCashFromAum: {
      source: portfolioA?.constraints?.targetFreeCashFromAum || 0,
      target: portfolioB?.constraints?.targetFreeCashFromAum || 0,
    },
    targetMaxEquityLeverage: {
      source: portfolioA?.constraints?.targetMaxEquityLeverage || 0,
      target: portfolioB?.constraints?.targetMaxEquityLeverage || 0,
    },
    targetMinNicheRisk: {
      source: portfolioA?.constraints?.targetMinNicheRisk || 0,
      target: portfolioB?.constraints?.targetMinNicheRisk || 0,
    },
    targetMaxPLRiskCR: {
      source: portfolioA?.constraints?.targetMaxPLRiskCR || 0,
      target: portfolioB?.constraints?.targetMaxPLRiskCR || 0,
    },
    platformPerformanceFeePct: {
      source: getFeeValue('Performance Fee', portfolioAFees),
      target: getFeeValue('Performance Fee', portfolioBFees),
    },
    platformFixedExpenses: {
      source: getFeeValue('Platform Fixed Expenses', portfolioAFees),
      target: getFeeValue('Platform Fixed Expenses', portfolioBFees),
    },
  };
};

const compareStrategies = (
  strategyA: FullPortfolioStrategy,
  strategyB: FullPortfolioStrategy,
  strategyGroupsA: StrategyGroup[],
  strategyGroupsB: StrategyGroup[]
): StrategiesDifferences => {
  const isStrategyMoved =
    strategyA?.strategyGroupId !== strategyB?.strategyGroupId;
  const sourceStrategyGroupName =
    strategyGroupsA.find((group) => strategyA?.strategyGroupId === group.id)
      ?.name || '';
  const targetStrategyGroupName =
    strategyGroupsB.find((group) => strategyB?.strategyGroupId === group.id)
      ?.name || '';

  return {
    id: strategyA?.id || '',
    notionalAccountValue: {
      source: strategyA?.notionalAccountValue || 0,
      target: strategyB?.notionalAccountValue || 0,
    },
    scientist: {
      source:
        getAlphaCategoryValue('SCIENTIST', strategyA.strategy.alphaCategories)
          ?.value || 0,
      target:
        getAlphaCategoryValue('SCIENTIST', strategyB.strategy.alphaCategories)
          ?.value || 0,
    },
    engineer: {
      source:
        getAlphaCategoryValue('ENGINEER', strategyA.strategy.alphaCategories)
          ?.value || 0,
      target:
        getAlphaCategoryValue('ENGINEER', strategyB.strategy.alphaCategories)
          ?.value || 0,
    },
    actuary: {
      source:
        getAlphaCategoryValue('ACTUARY', strategyA.strategy.alphaCategories)
          ?.value || 0,
      target:
        getAlphaCategoryValue('ACTUARY', strategyB.strategy.alphaCategories)
          ?.value || 0,
    },
    assetCategory: {
      source: strategyA?.strategy?.assetCategory?.name || '',
      target: strategyB?.strategy?.assetCategory?.name || '',
    },
    averageHoldingPeriod: {
      source: strategyA?.strategy?.averageHoldingPeriod?.name || '',
      target: strategyB?.strategy?.averageHoldingPeriod?.name || '',
    },
    capacity: {
      source: strategyA?.strategy?.capacity || 0,
      target: strategyB?.strategy?.capacity || 0,
    },
    grossSharpe: {
      source: strategyA?.strategy?.grossSharpe || 0,
      target: strategyB?.strategy?.grossSharpe || 0,
    },
    strategyGroup: {
      source: isStrategyMoved ? sourceStrategyGroupName || '' : '',
      target: isStrategyMoved ? targetStrategyGroupName || '' : '',
    },
    groupCharacteristic: {
      source: strategyA?.strategy?.groupCharacteristic?.name || '',
      target: strategyB?.strategy?.groupCharacteristic?.name || '',
    },
    historicSharpeRatio: {
      source: strategyA?.strategy?.historicSharpeRatio || 0,
      target: strategyB?.strategy?.historicSharpeRatio || 0,
    },
    marginType: {
      source: strategyA?.strategy?.marginType?.name || '',
      target: strategyB?.strategy?.marginType?.name || '',
    },
    minAllocation: {
      source: strategyA?.strategy?.minAllocation || 0,
      target: strategyB?.strategy?.minAllocation || 0,
    },
    name: {
      source: strategyA?.strategy?.name || '',
      target: strategyB?.strategy?.name || '',
    },
    volatilityTarget: {
      source: strategyA?.strategy?.volatilityTarget || 0,
      target: strategyB?.strategy?.volatilityTarget || 0,
    },
    volatilityCapacity: {
      source: calculateVolatilityCapacity({
        aumCapacity: strategyA.strategy.capacity,
        volatilityTarget: strategyA.strategy.volatilityTarget,
      }),
      target: calculateVolatilityCapacity({
        aumCapacity: strategyB.strategy.capacity,
        volatilityTarget: strategyB.strategy.volatilityTarget,
      }),
    },
    remainingCapacity: {
      source: calculateRemainingCapacity({
        aumCapacity: strategyA?.strategy?.capacity,
        notionalAccountValue: strategyA?.notionalAccountValue,
      }),
      target: calculateRemainingCapacity({
        aumCapacity: strategyB?.strategy?.capacity,
        notionalAccountValue: strategyB?.notionalAccountValue,
      }),
    },
  };
};

const compareGroups = (
  strategyGroupA: StrategyGroup,
  strategyGroupB: StrategyGroup
): GroupDifferences => {
  const groupAFees = strategyGroupA?.fees || [];
  const groupBFees = strategyGroupB?.fees || [];

  return {
    id: strategyGroupA?.id || '',
    name: {
      source: strategyGroupA?.name || '',
      target: strategyGroupB?.name || '',
    },
    performanceFee: {
      source: getFeeValue('Performance Fee', groupAFees),
      target: getFeeValue('Performance Fee', groupBFees),
    },
    managementFee: {
      source: getFeeValue('Management Fee', groupAFees),
      target: getFeeValue('Management Fee', groupBFees),
    },
    fixedExpenses: {
      source: getFeeValue('Fixed expense budget', groupAFees),
      target: getFeeValue('Fixed expense budget', groupBFees),
    },
  };
};

const compareStrategyGroupsAllocations = (
  strategyGroupsA: StrategyGroup[],
  strategyGroupsB: StrategyGroup[]
) => {
  const deletedGroups: StrategyGroup[] = [];
  const addedGroups: StrategyGroup[] = [];

  strategyGroupsA.forEach((strategyGroupA) => {
    const strategyGroupB = strategyGroupsB.find(
      (strategyGroup) => strategyGroup.id === strategyGroupA.id
    );

    if (!strategyGroupB) {
      addedGroups.push(strategyGroupA);
    }
  });

  strategyGroupsB.forEach((strategyGroupB) => {
    const strategyGroupA = strategyGroupsA.find(
      (strategyGroup) => strategyGroup.id === strategyGroupB.id
    );

    if (!strategyGroupA) {
      deletedGroups.push(strategyGroupB);
    }
  });

  return {
    added: addedGroups,
    deleted: deletedGroups,
  };
};

const compareStrategyAllocations = (
  portfolioStrategiesA: FullPortfolioStrategy[],
  portfolioStrategiesB: FullPortfolioStrategy[]
) => {
  const deletedStrategies: FullPortfolioStrategy[] = [];
  const addedStrategies: FullPortfolioStrategy[] = [];
  const editedStrategies: EditedStrategy[] = [];

  portfolioStrategiesA.forEach((strategyA) => {
    const strategyB = portfolioStrategiesB.find(
      (strategy) => strategy.globalStrategyId === strategyA.globalStrategyId
    );

    if (!strategyB) {
      addedStrategies.push(strategyA);
    } else {
      editedStrategies.push({ source: strategyA, target: strategyB });
    }
  });

  portfolioStrategiesB.forEach((strategyB) => {
    const strategyA = portfolioStrategiesA.find(
      (strategy) => strategy.globalStrategyId === strategyB.globalStrategyId
    );

    if (!strategyA) {
      deletedStrategies.push(strategyB);
    }
  });

  return {
    added: addedStrategies,
    deleted: deletedStrategies,
    edited: editedStrategies,
  };
};

const compareStrategiesLists = (
  portfolioStrategiesA: FullPortfolioStrategy[],
  portfolioStrategiesB: FullPortfolioStrategy[],
  portfolioGroupsA: StrategyGroup[],
  portfolioGroupsB: StrategyGroup[]
): StrategiesDifferences[] => {
  const strategiesDiffs: StrategiesDifferences[] = [];

  portfolioStrategiesA.forEach((strategyValueA) => {
    const strategyValueB = portfolioStrategiesB.find(
      (strategy) =>
        strategy.globalStrategyId === strategyValueA.globalStrategyId
    );

    if (strategyValueB) {
      strategiesDiffs.push(
        compareStrategies(
          strategyValueA,
          strategyValueB,
          portfolioGroupsA,
          portfolioGroupsB
        )
      );
    }
  });

  return strategiesDiffs;
};

const compareStrategyGroupsLists = (
  portfolioGroupsA: StrategyGroup[],
  portfolioGroupsB: StrategyGroup[]
): GroupDifferences[] => {
  const groupsDiffs: GroupDifferences[] = [];

  portfolioGroupsA.forEach((groupValueA) => {
    const strategyValueB = portfolioGroupsB.find(
      (group) => group.id === groupValueA.id
    );

    if (strategyValueB) {
      groupsDiffs.push(compareGroups(groupValueA, strategyValueB));
    }
  });

  return groupsDiffs;
};

const createFundLevelDiffsConfig = (
  differences: any
): RowTypeWithValue<any>[] => {
  const config: RowTypeWithValue<any>[] = [];

  if (
    differences[fundOverviewWidgetConfig.aumRow.variableName].source !==
    differences[fundOverviewWidgetConfig.aumRow.variableName].target
  ) {
    config.push({
      fieldName: fundOverviewWidgetConfig.aumRow.fieldName,
      type: fundOverviewWidgetConfig.aumRow.type.left,
      variableName: fundOverviewWidgetConfig.aumRow.variableName,
      sourceValue:
        differences[fundOverviewWidgetConfig.aumRow.variableName].source,
      targetValue:
        differences[fundOverviewWidgetConfig.aumRow.variableName].target,
    });
  }

  fundOverviewWidgetConfig.statRows.forEach((statRow) => {
    if (
      differences[statRow.variableName].source !==
      differences[statRow.variableName].target
    ) {
      config.push({
        fieldName: statRow.fieldName,
        type: statRow.type.left,
        variableName: statRow.variableName,
        sourceValue: differences[statRow.variableName].source,
        targetValue: differences[statRow.variableName].target,
      });
    }
  });

  PERFORMANCE_STATS_WIDGET_CONFIG.forEach((statRow) => {
    if (
      differences[statRow.variableName] &&
      differences[statRow.variableName].source !==
        differences[statRow.variableName].target
    ) {
      config.push({
        fieldName: statRow.fieldName,
        type: statRow.type,
        variableName: statRow.variableName,
        sourceValue: differences[statRow.variableName].source,
        targetValue: differences[statRow.variableName].target,
      });
    }
  });

  PARAMETERS_CONFIG.forEach((statRow) => {
    if (
      differences[statRow.variableName] &&
      differences[statRow.variableName].source !==
        differences[statRow.variableName].target
    ) {
      config.push({
        fieldName: statRow.fieldName,
        type: statRow.type,
        variableName: statRow.variableName,
        sourceValue: differences[statRow.variableName].source,
        targetValue: differences[statRow.variableName].target,
      });
    }
  });

  return config;
};

const createEditedStrategyAllocationsDiffsConfig = (
  strategiesMap: EditedStrategy[]
) => {
  const result: AllocationDiffConfig<any>[] = [];

  strategiesMap.forEach((strategyMap) => {
    const { source, target } = strategyMap;
    if (source.notionalAccountValue !== target.notionalAccountValue) {
      result.push({
        id: source.id,
        name: source.strategy.name,
        config: {
          fieldName: i18n.t('strategyChanged'),
          variableName: 'notionalAccountValue',
          type: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
          sourceValue: source.notionalAccountValue,
          targetValue: target.notionalAccountValue,
        },
      });
    }
  });
  return result;
};

const createStrategyAllocationsDiffsConfig = (
  strategies: FullPortfolioStrategy[],
  isStrategyAdded = true
): AllocationDiffConfig<any>[] => {
  const result: AllocationDiffConfig<any>[] = [];

  strategies.forEach((strategy) => {
    const allocationText = isStrategyAdded
      ? i18n.t('strategyAdded')
      : i18n.t('strategyDeleted');
    const sourceValue = isStrategyAdded
      ? strategy.notionalAccountValue
      : ZERO_ALLOCATIONS_VALUE;
    const targetValue = isStrategyAdded
      ? ZERO_ALLOCATIONS_VALUE
      : strategy.notionalAccountValue;

    result.push({
      id: strategy.id,
      name: strategy.strategy.name,
      config: {
        fieldName: allocationText,
        variableName: 'notionalAccountValue',
        type: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
        sourceValue,
        targetValue,
      },
    });
  });

  return result;
};

const createGroupAllocationsDiffsConfig = (
  strategyGroups: StrategyGroup[],
  isAdded = true
): AllocationDiffConfig<any>[] => {
  const result: AllocationDiffConfig<any>[] = [];

  strategyGroups.forEach((group) => {
    const allocationText = isAdded
      ? i18n.t('groupAdded')
      : i18n.t('groupDeleted');
    const sourceValue = isAdded
      ? getFeeValue('Fixed expense budget', group?.fees || [])
      : ZERO_ALLOCATIONS_VALUE;
    const targetValue = isAdded
      ? ZERO_ALLOCATIONS_VALUE
      : getFeeValue('Fixed expense budget', group?.fees || []);

    result.push({
      id: group?.id || '',
      name: group?.name,
      config: {
        fieldName: allocationText,
        variableName: 'minAllocation',
        type: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
        sourceValue,
        targetValue,
      },
    });
  });

  return result;
};

const createDiffsConfig = (
  differences: any[],
  configBase: typeof STRATEGY_LEVEL_CONFIG_BASE | typeof GROUP_LEVEL_CONFIG_BASE
): ConfigurationWithName<any>[] => {
  const result: ConfigurationWithName<any>[] = [];

  differences.forEach((diffs) => {
    const config: RowTypeWithValue<any>[] = [];
    const entityName = diffs.name.target;
    const entityId = diffs.id;

    Object.keys(diffs).forEach((key) => {
      if (diffs[key].source !== diffs[key].target) {
        const transformedKey = key as keyof typeof configBase;
        config.push({
          fieldName: configBase[transformedKey].name,
          type: configBase[transformedKey].type,
          variableName: key,
          sourceValue: diffs[key].source,
          targetValue: diffs[key].target,
        });
      }
    });

    if (config.length) {
      result.push({
        id: entityId,
        name: entityName,
        config,
      });
    }
  });

  return result;
};

export const collectPortfolioChangesToConfigs = (
  sourcePortfolio: GetPortfolioResponse,
  targetPortfolio: GetPortfolioResponse,
  sourceStrategies: FullPortfolioStrategy[],
  targetStrategies: FullPortfolioStrategy[],
  sourcePerformance: PortfolioPerformanceResponse,
  targetPerformance: PortfolioPerformanceResponse
) => {
  const fundLevelDiffs = comparePortfolios(
    sourcePortfolio,
    targetPortfolio,
    sourcePerformance,
    targetPerformance
  );
  const strategyAllocationsDiffs = compareStrategyAllocations(
    sourceStrategies,
    targetStrategies
  );
  const strategyGroupsAllocationsDiffs = compareStrategyGroupsAllocations(
    sourcePortfolio?.strategyGroups || [],
    targetPortfolio?.strategyGroups || []
  );
  const strategyLevelDiffs = compareStrategiesLists(
    sourceStrategies,
    targetStrategies,
    sourcePortfolio.strategyGroups,
    targetPortfolio.strategyGroups
  );
  const groupLevelDiffs = compareStrategyGroupsLists(
    sourcePortfolio?.strategyGroups || [],
    targetPortfolio?.strategyGroups || []
  );
  const {
    added: addedStrategies,
    deleted: deletedStrategies,
    edited: editedStrategies,
  } = strategyAllocationsDiffs;
  const { added: addedGroups, deleted: deletedGroups } =
    strategyGroupsAllocationsDiffs;

  const fundLevelDiffsConfig = createFundLevelDiffsConfig(fundLevelDiffs);
  const strategyLevelDiffsConfig = createDiffsConfig(
    strategyLevelDiffs,
    STRATEGY_LEVEL_CONFIG_BASE
  );
  const strategyGroupDiffsConfig = createDiffsConfig(
    groupLevelDiffs,
    GROUP_LEVEL_CONFIG_BASE
  );
  const addedStrategiesConfig = createStrategyAllocationsDiffsConfig(
    addedStrategies,
    true
  );
  const deletedStrategiesConfig = createStrategyAllocationsDiffsConfig(
    deletedStrategies,
    false
  );
  const editedStrategiesConfig =
    createEditedStrategyAllocationsDiffsConfig(editedStrategies);
  const addedGroupsConfig = createGroupAllocationsDiffsConfig(
    addedGroups,
    true
  );
  const deletedGroupsConfig = createGroupAllocationsDiffsConfig(
    deletedGroups,
    true
  );

  return {
    fundLevelDiffsConfig,
    strategyLevelDiffsConfig,
    strategyGroupDiffsConfig,
    addedStrategiesConfig,
    deletedStrategiesConfig,
    editedStrategiesConfig,
    addedGroupsConfig,
    deletedGroupsConfig,
  };
};
