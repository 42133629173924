export enum FIELD_LENGTH {
  ARCESIUM_ID = 36,
  SHORT_TEXT = 64,
  X2_SHORT_TEXT = 128,
  X3_SHORT_TEXT = 256,
  MID_TEXT = 512,
  LONG_TEXT = 1024,
  X4_LONG_TEXT = 4096,
}

export const ARCESIUM_ID = 'Arcesium ID';
