export interface StrategyListToolBarProps {
  bulkActions: {
    handleLockClick: () => void;
    handleUnlockClick: () => void;
    handleZeroAllocationClick: () => void;
    handleRevertAllocationClick: () => void;
  };
}

export const defaultMenuSxStyles = {
  width: 40,
  display: 'flex',
  alignItems: 'center',
  button: {
    opacity: '1',
  },
};
