import { useSelector } from 'react-redux';
import { StrategyGroupsResponse } from 'services/portfolioCore/portfolioCore.types';
import {
  sourceStrategyGroupsSelector,
  strategyGroupsSelector,
} from 'store/slices/portfolios';
import { globalStrategiesSelector } from 'store/slices/strategies';
import { teamsSelector } from 'store/slices/teams';
import { v4 as uuidv4 } from 'uuid';
import {
  currencyFormatter,
  percentFormatterShorthand,
  MoreVertIcon,
  THEME_COLORS,
  SensitiveDataWrapper,
  TextFieldWrapper,
} from '@clearalpha/common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { CustomListItem } from 'components/List/CustomList.type';
import {
  FormikCurrencyNumbers,
  FormikPercentNumbers,
  FormikText,
} from 'components/formik/fields';
import { createHashMap } from 'helpers';
import { getFeeValue } from 'services/portfolioCore/portfolioCore.helper';
import { GroupDetailsFields, GroupFields } from './ManageGroupDetails.type';
import {
  BaseText,
  IconsWrapper,
  ItemWrapper,
  ListItemContent,
} from './ManageGroupDetails.styled';

export const createListItems = (
  groupData: StrategyGroupsResponse | undefined,
  pmTeamName: string,
  isSensitiveInfoVisible: boolean,
  isEditModeEnabled: boolean
) => {
  const list = {
    [GroupFields.GROUP_NAME]: {
      value: groupData?.name || '',
      isSensitiveInfo: false,
    },
    [GroupFields.MANAGEMENT_TEAM]: {
      value: pmTeamName || '',
      isSensitiveInfo: true,
    },
    [GroupFields.GROUP_FIXED_EXPENSES]: {
      value:
        currencyFormatter(
          getFeeValue('Fixed expense budget', groupData?.fees || [])
        ) || '0$',
      isSensitiveInfo: false,
    },
    [GroupFields.MANAGMENT_FEE]: {
      value:
        percentFormatterShorthand(
          getFeeValue('Management fee', groupData?.fees || [])
        ) || '0%',
      isSensitiveInfo: false,
    },
    [GroupFields.PERFORMANCE_FEE]: {
      value:
        percentFormatterShorthand(
          getFeeValue('Performance fee', groupData?.fees || [])
        ) || '0%',
      isSensitiveInfo: false,
    },
  };

  const groupDetailsItemsEditMode: CustomListItem[] = [
    {
      item: (
        <ListItemContent>
          <BaseText>{GroupFields.GROUP_NAME}</BaseText>
          <ItemWrapper>
            <FormikText name='groupName' />
          </ItemWrapper>
        </ListItemContent>
      ),
      isSensitiveInfo: false,
      key: GroupFields.GROUP_NAME,
    },
    {
      item: (
        <ListItemContent>
          <BaseText>{GroupFields.MANAGEMENT_TEAM}</BaseText>
          <ItemWrapper>
            <BaseText>{pmTeamName}</BaseText>
          </ItemWrapper>
        </ListItemContent>
      ),
      isSensitiveInfo: false,
      key: GroupFields.MANAGEMENT_TEAM,
    },
    {
      item: (
        <ListItemContent>
          <BaseText>{GroupFields.GROUP_FIXED_EXPENSES}</BaseText>
          <ItemWrapper>
            <FormikCurrencyNumbers
              customInput={TextFieldWrapper}
              minLimit={0}
              name='fixedExp'
            />
          </ItemWrapper>
        </ListItemContent>
      ),
      isSensitiveInfo: false,
      key: GroupFields.GROUP_FIXED_EXPENSES,
    },
    {
      item: (
        <ListItemContent>
          <BaseText>{GroupFields.MANAGMENT_FEE}</BaseText>
          <ItemWrapper>
            <FormikPercentNumbers
              minLimit={0}
              maxLimit={100}
              customInput={TextFieldWrapper}
              name='mgmtFee'
            />
          </ItemWrapper>
        </ListItemContent>
      ),
      isSensitiveInfo: false,
      key: GroupFields.MANAGMENT_FEE,
    },
    {
      item: (
        <ListItemContent>
          <BaseText>{GroupFields.PERFORMANCE_FEE}</BaseText>
          <ItemWrapper>
            <FormikPercentNumbers
              minLimit={0}
              maxLimit={100}
              customInput={TextFieldWrapper}
              name='perfFee'
            />
          </ItemWrapper>
        </ListItemContent>
      ),
      isSensitiveInfo: false,
      key: GroupFields.PERFORMANCE_FEE,
    },
  ];

  const groupDetailsItems: CustomListItem[] = isEditModeEnabled
    ? groupDetailsItemsEditMode
    : Object.entries(list).map(([key, { value, isSensitiveInfo }]) => ({
        item: (
          <ListItemContent key={uuidv4()}>
            <BaseText>{key}</BaseText>
            {isSensitiveInfo ? (
              <SensitiveDataWrapper
                sx={{
                  display: 'flex',
                  flexDirection: 'row !important',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  padding: '0 6px',
                  height: '52px',
                }}
                isVisible={isSensitiveInfoVisible}
              >
                <BaseText>{value}</BaseText>
              </SensitiveDataWrapper>
            ) : (
              <BaseText>{value}</BaseText>
            )}
          </ListItemContent>
        ),
        isSensitiveInfo,
        key: uuidv4(),
      }));
  return groupDetailsItems;
};

export const useManageGroupsDetails = (
  groupId: string,
  isSensitiveInfoVisible: boolean,
  isEditModeEnabled: boolean
) => {
  const sourceStrategyGroups = useSelector(sourceStrategyGroupsSelector);
  const portfolioStrategyGroups: StrategyGroupsResponse[] = useSelector(
    strategyGroupsSelector
  );
  const strategyGroups = sourceStrategyGroups.length
    ? sourceStrategyGroups
    : portfolioStrategyGroups;
  const teams = useSelector(teamsSelector);
  const globalStrategies = useSelector(globalStrategiesSelector);

  const group = strategyGroups.find((current) => current.id === groupId);
  const teamName =
    teams.find((current) => current.id === group?.portfolioManagerTeamId)
      ?.name || '';
  const groupListData = createListItems(
    group,
    teamName,
    isSensitiveInfoVisible,
    isEditModeEnabled
  );
  const groupName = group?.name;
  const strategiesHashMap = createHashMap('id', globalStrategies);
  const listOfGroupStrategiesNames = (group?.strategies || []).map(
    (current) => {
      if (strategiesHashMap[current.globalStrategyId]) {
        return strategiesHashMap[current.globalStrategyId].name;
      }
      return 'Unknown strategy'; // TODO: REMOVE AFTER REAL GROUPS WILL BE ADDED
    }
  );

  const strategiesListData = listOfGroupStrategiesNames.map((name) => ({
    item: (
      <ListItemContent key={uuidv4()}>
        <BaseText>{name}</BaseText>
        <IconsWrapper>
          <ExpandMoreIcon sx={{ color: THEME_COLORS.white }} />
          <MoreVertIcon />
        </IconsWrapper>
      </ListItemContent>
    ),
    isSensitiveInfo: false,
    key: uuidv4(),
  }));

  return {
    groupListData,
    strategiesListData,
    groupName,
  };
};

export const createInitialValues = (
  values: StrategyGroupsResponse
): GroupDetailsFields => {
  return {
    groupName: values.name,
    fixedExp: getFeeValue('Fixed expense budget', values.fees),
    mgmtFee: getFeeValue('Management fee', values.fees),
    perfFee: getFeeValue('Performance fee', values.fees),
  };
};
