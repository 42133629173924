import {
  Fund,
  FundWarnings,
  PortfolioPerformanceWarnings,
} from 'services/fundCalculator/fundCalculator.types';
import {
  Portfolio,
  GetPortfolioResponse,
} from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';

export const getFundWidgetData = (
  portfolio: Portfolio,
  portfolioCore: GetPortfolioResponse,
  warnings?: PortfolioPerformanceWarnings
): {
  data: Fund;
  warnings: FundWarnings;
} => {
  return {
    data: {
      aum: portfolio?.aum,
      lmv: portfolio?.lmv,
      smv: portfolio?.smv,
      leverage: portfolio?.leverage,
      sumOfMarginReqs: portfolio?.sumOfMarginReqs,
      crossMarginBenefitPct: portfolio?.crossMarginBenefitPct,
      marginReqs: portfolio?.marginReqs,
      freeCash: portfolio?.freeCash,
      freeCashPct: portfolio?.freeCashPct,
      concentrationRatio: portfolio?.concentrationRatio,
      stressedFreeCashPct: portfolio?.stressedFreeCashPct,
      targetFreeCashFromAum: portfolioCore?.constraints.targetFreeCashFromAum,
      targetMaxPLRiskCR: portfolioCore?.constraints.targetMaxPLRiskCR,
    },
    warnings: {
      aum: warnings?.portfolio?.aum,
      lmv: warnings?.portfolio?.lmv,
      smv: warnings?.portfolio?.smv,
      leverage: warnings?.portfolio?.leverage,
      sumOfMarginReqs: warnings?.portfolio?.sumOfMarginReqs,
      crossMarginBenefitPct: warnings?.portfolio?.crossMarginBenefitPct,
      marginReqs: warnings?.portfolio?.marginReqs,
      freeCash: warnings?.portfolio?.freeCash,
      freeCashPct: warnings?.portfolio?.freeCashPct,
      concentrationRatio: warnings?.portfolio?.concentrationRatio,
      stressedFreeCashPct: warnings?.portfolio?.stressedFreeCashPct,
      targetFreeCashFromAum: warnings?.portfolio?.targetFreeCashFromAum,
      targetMaxPLRiskCR: warnings?.portfolio?.targetMaxPLRiskCR,
    },
  };
};
