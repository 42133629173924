import { PieChartData } from 'services/fundCalculator/fundCalculator.types';
import { StrategyAllocationInfo } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { FullPortfolioStrategy } from 'services/portfolioCore/portfolioCore.types';
import { THEME_COLORS } from '@clearalpha/common/src/constants';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import {
  setStrategyColor,
  strategiesColorsSelector,
} from 'store/slices/strategies';
import { StrategyDictionary } from './StrategyAllocationWidget.type';

const calculatePercentage = (volatility: number, totalVolatility: number) => {
  try {
    return volatility / totalVolatility;
  } catch {
    return 0;
  }
};

const matchOrder = (
  strategyAllocations: StrategyAllocationInfo[],
  strategyDictionary: StrategyDictionary
) => {
  return [...strategyAllocations].sort((a, b) => {
    if (
      !strategyDictionary[a.strategyId]?.sequence ||
      !strategyDictionary[b.strategyId]?.sequence
    ) {
      return 0;
    }

    return (
      strategyDictionary[a.strategyId].sequence -
      strategyDictionary[b.strategyId].sequence
    );
  });
};

const strategiesToChartData = (
  dictionary: StrategyDictionary,
  strategies: StrategyAllocationInfo[]
) => {
  const totalVolatility = strategies.reduce((acc, { vol }) => {
    return acc + vol ?? 0;
  }, 0);

  const orderedStrategies = matchOrder(strategies, dictionary);

  return orderedStrategies.reduce((acc, { strategyId, vol }) => {
    const strategy = dictionary[strategyId];

    if (!strategy) {
      return acc;
    }

    return [
      ...acc,
      {
        key: strategy.name,
        percentage: calculatePercentage(vol, totalVolatility),
      },
    ];
  }, [] as PieChartData[]);
};

const createStrategiesDictionary = (strategies: FullPortfolioStrategy[]) =>
  strategies.reduce((acc, current, index) => {
    const {
      id,
      strategy: { name },
    } = current;

    const result = {
      name,
      sequence: index + 1,
    };

    return {
      ...acc,
      [id]: result,
    };
  }, {} as StrategyDictionary);

export const prepareVolatilityChartData = (
  prodStrategyAllocations: StrategyAllocationInfo[],
  targetStrategyAllocations: StrategyAllocationInfo[],
  strategies: FullPortfolioStrategy[],
  prodStrategies: FullPortfolioStrategy[]
): { prodPie: PieChartData[]; workPie: PieChartData[] } => {
  const workStrategyDictionary = createStrategiesDictionary(strategies);
  const prodStrategyDictionary = createStrategiesDictionary(prodStrategies);

  const hasWorkStrategiesDictionary = !!Object.keys(workStrategyDictionary)
    .length;
  const hasProdStrategiesDictionary = !!Object.keys(prodStrategyDictionary)
    .length;

  return {
    prodPie:
      hasProdStrategiesDictionary && prodStrategyAllocations.length
        ? strategiesToChartData(prodStrategyDictionary, prodStrategyAllocations)
        : [],
    workPie:
      hasWorkStrategiesDictionary && targetStrategyAllocations.length
        ? strategiesToChartData(
            workStrategyDictionary,
            targetStrategyAllocations
          )
        : [],
  };
};

export const useMapAndSaveStrategyColors = (
  data: PieChartData[],
  colors: THEME_COLORS[]
) => {
  const dispatch = useAppDispatch();
  const strategiesColors = useSelector(strategiesColorsSelector);

  return data
    .sort((a, b) => b.percentage - a.percentage)
    .map((entry, index) => {
      const colorFromState = strategiesColors[entry.key];
      if (colorFromState) {
        entry.color = colorFromState;
      } else {
        const newColor = colors[index % colors.length];
        entry.color = newColor;
        dispatch(setStrategyColor({ [entry.key]: newColor }));
      }
      return entry;
    })
    .filter(({ percentage }) => !!percentage);
};
