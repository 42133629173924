import {
  GetPortfolioManager,
  GetPortfolioManagerRole,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';

export const PORTFOLIO_MANAGERS_SLICE_NAME = 'portfolioManagers';

export const PORTFOLIO_MANAGERS_SLICE_INITIAL_STATE = {
  isPMEditMode: false,
  isLoadingPMs: false,
  portfolioManagers: [] as GetPortfolioManager[],
  roles: [] as GetPortfolioManagerRole[],
  isLoadingRoles: false,
};
