import React, { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  isReviewModePortfolioSelector,
  productionPortfolioSelector,
  workingPortfolioSelector,
} from 'store/slices/portfolios';
import { hasSaraFullPermissionSelector } from 'store/slices/permissions';
import {
  currencyFormatter,
  currencyFormatterShorthand,
  StringValueType,
  MenuItem,
  Tooltip,
  SaveIcon,
  HistoryIcon,
  RefreshIcon,
  PlayArrowIcon,
} from '@clearalpha/common';
import {
  Button,
  ButtonGroup,
  GroupTitle,
  TitleItem,
  LeftSection,
  RightSection,
  Title,
  Container,
  FundMenu,
} from './styled';
import { WorkspaceHeaderProps } from './WorkspaceHeader.type';
import PromotePortfolio from './PromotePortfolio/PromotePortfolio';

const WorkspaceHeader: FC<WorkspaceHeaderProps> = ({
  fundName,
  tenantName,
  totalAUM,
  onRunOptimization,
  onOpenResetToProduction,
  onSave,
  onOpenViewHistory,
  onOpenCorrelations,
  onChangeFundName,
  onOpenParameters,
  fundOptions,
}) => {
  const { t } = useTranslation();
  const isButtonsDisabled = useSelector(isReviewModePortfolioSelector);
  const hasSaraFullPermissions = useSelector(hasSaraFullPermissionSelector);
  const workingPortfolio = useSelector(workingPortfolioSelector);
  const productionPortfolio = useSelector(productionPortfolioSelector);

  return (
    <Container>
      <LeftSection>
        <Title>{t('sara')}</Title>
        <FundMenu onChange={onChangeFundName} value={fundName}>
          {fundOptions?.map((current) => (
            <MenuItem key={uuidv4()} value={current as StringValueType}>
              {current}
            </MenuItem>
          ))}
        </FundMenu>
        <TitleItem>{tenantName}</TitleItem>
        <TitleItem>
          {t('totalAUM')}{' '}
          <Tooltip title={currencyFormatter(totalAUM)} placement='top' arrow>
            <span>{currencyFormatterShorthand(totalAUM)}</span>
          </Tooltip>
        </TitleItem>
      </LeftSection>
      <RightSection>
        <div>
          <GroupTitle>{t('versions')}</GroupTitle>
          <ButtonGroup>
            <Button
              variant='tonal'
              onClick={onSave}
              disabled={isButtonsDisabled}
            >
              <SaveIcon />
            </Button>
            <Button
              variant='tonal'
              onClick={onOpenViewHistory}
              disabled={
                isButtonsDisabled ||
                workingPortfolio?.id === productionPortfolio?.id
              }
            >
              <HistoryIcon />
            </Button>
          </ButtonGroup>
        </div>
        <div>
          <GroupTitle>{t('dataTools')}</GroupTitle>
          <ButtonGroup>
            <Button
              variant='tonal'
              onClick={onOpenResetToProduction}
              startIcon={<RefreshIcon />}
              disabled={isButtonsDisabled}
            >
              {t('actions.resetToProduction')}
            </Button>
            <Button
              variant='tonal'
              onClick={onRunOptimization}
              startIcon={<PlayArrowIcon />}
              disabled={isButtonsDisabled}
            >
              {t('actions.runOptimization')}
            </Button>
            <Button variant='outlined' onClick={onOpenParameters}>
              {t('dialogs.parameters.title')}
            </Button>
            {hasSaraFullPermissions && <PromotePortfolio />}
          </ButtonGroup>
        </div>
        <div>
          <GroupTitle>{t('correlationMatrix')}</GroupTitle>
          <Button variant='tonal' onClick={onOpenCorrelations}>
            {t('actions.correlations')}
          </Button>
        </div>
      </RightSection>
    </Container>
  );
};

export default WorkspaceHeader;
