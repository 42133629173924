import { PermissionsState } from './permissions.types';

export const PERMISSIONS_SLICE_NAME = 'permissions';

export const PERMISSIONS_SLICE_INITIAL_STATE: PermissionsState = {
  arePermissionsDefined: false,
  roles: [],
  isStrategyLibraryPermission: false,
  isSaraUserPermission: false,
  isSaraFullPermission: false,
};
