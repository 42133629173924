import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { GetPortfolioManager } from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { portfolioManagersSelector } from 'store/slices/portfolioManagers';
import {
  ManagerRow,
  PortfolioManagerListConfigParams,
} from './PortfolioManagerList.types';
import StrategyLibraryCell from '../StrategyLibraryCell/StrategyLibraryCell';

export const getManagerFullName = (manager?: GetPortfolioManager) => {
  if (!manager) return '';

  return `${manager.name} ${manager.surname}`;
};

export const sortManagers = (
  managers: GetPortfolioManager[]
): GetPortfolioManager[] => {
  return [...managers].sort((a, b) =>
    getManagerFullName(a).localeCompare(getManagerFullName(b))
  );
};

export const getManagerTeamNames = (
  portfolioManager: GetPortfolioManager | null
): string => {
  return portfolioManager && Array.isArray(portfolioManager.teams)
    ? portfolioManager.teams.map(({ name }) => name).join(', ')
    : '';
};

export const usePortfolioManagerListConfig = (
  handleOpenManagerDetails: (managerId: string) => void
): PortfolioManagerListConfigParams => {
  const { t } = useTranslation();
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);
  const managers = useSelector(portfolioManagersSelector);

  const rows: ManagerRow[] = useMemo(() => {
    return sortManagers(managers).map((entity) => {
      const { id, status } = entity;

      return {
        id,
        name: getManagerFullName(entity),
        teams: getManagerTeamNames(entity),
        status: status.name,
      };
    });
  }, [managers]);

  const columns: GridColDef<ManagerRow>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('name'),
        width: 250,
        sortable: true,
        renderCell: ({ row: { name, id } }) => (
          <StrategyLibraryCell
            handleClick={() => handleOpenManagerDetails(id)}
            isClickableCell
            isVisible={isVisibleSensitiveData}
            content={name}
          />
        ),
      },
      {
        field: 'teams',
        headerName: t('teams'),
        width: 300,
        sortable: true,
        renderCell: ({ row: { teams } }) => (
          <StrategyLibraryCell
            isClickableCell={false}
            isVisible={isVisibleSensitiveData}
            content={teams}
          />
        ),
      },
      {
        field: 'status',
        headerName: t('status'),
        width: 135,
        sortable: true,
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'status-tag',
      },
    ],
    [t, isVisibleSensitiveData, handleOpenManagerDetails]
  );

  return {
    rows,
    columns,
  };
};
