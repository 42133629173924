import { SelectChangeEvent } from '@mui/material';

type WorkspaceHeaderProps = {
  fundName: string;
  tenantName: string;
  totalAUM: number;
  onRunOptimization: () => void;
  onOpenResetToProduction: () => void;
  onSave: () => void;
  onOpenViewHistory: () => void;
  onOpenCorrelations: () => void;
  onOpenParameters: () => void;
  fundOptions: string[];
  onChangeFundName: (event: SelectChangeEvent<unknown>) => void;
};

export const BreakpointToChangeDirection = 2200;

export type { WorkspaceHeaderProps };
