import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 20%;
  margin-bottom: 20px;
`;

const StyledStatus = styled.span`
  padding: 4px 16px;
  border: 1px solid ${({ theme: { colors } }) => colors.outline};
  border-radius: 8px;

  &.highlighted {
    background-color: ${({ theme: { colors } }) => colors.highlightColor};
  }
`;

export { Wrapper, StyledStatus };
