import styled from '@emotion/styled';

export const FieldWrapper = styled.div`
  margin-bottom: 16px;

  @media (max-width: 1200px) {
    order: 1;
  }
`;

export const Title = styled.h2`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 52px;
  margin: 0;
  word-break: break-word;
  max-width: 696px;

  @media (max-width: 1200px) {
    order: 1;
  }
`;
