import React from 'react';
import {
  SectionContainer,
  SectionContent,
  SectionTitle,
} from './Section.styled';
import { SectionProps } from './Section.types';

const Section = ({ children, title, withBackground = false }: SectionProps) => (
  <SectionContainer>
    <SectionTitle>{title}</SectionTitle>
    {withBackground ? (
      <SectionContent data-testid='section-content'>{children}</SectionContent>
    ) : (
      children
    )}
  </SectionContainer>
);

export default Section;
