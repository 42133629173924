import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const LayoutWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  background-color: ${({ theme: { colors } }) => colors.surface};
`;

export const TitleAndContent = styled.div`
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  font-family: Public Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 123.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.white};
`;

export const Description = styled.div``;

export const Text = styled.p`
  font-family: Public Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
  color: ${({ theme }) => theme.colors.whiteOpacity70};
`;
