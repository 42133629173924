import { GridColDef } from '@mui/x-data-grid';
import i18n from 'translations/i18n';
import { StrategiesResponse } from '../../../layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';

export type StrategyRow = {
  id: string;
  name: string;
  category: string;
};

export type StrategyListConfigParams = {
  columns: GridColDef[];
  rows: StrategyRow[];
};

export const STRATEGIES_LIST_COLUMNS = {
  STRATEGY: {
    fieldName: 'name',
    headerName: i18n.t('strategy'),
  },
  CATEGORY: {
    fieldName: 'category',
    headerName: i18n.t('category'),
  },
};

export type StrategyWithFirm = StrategiesResponse & {
  firmName: string;
};
