import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { SensitiveDataBlock } from '@clearalpha/common';
import { FormikText } from 'components/formik/fields';
import { ManagerFormFields } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.constants';
import { PortfolioManagerFormFields } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.type';
import { useSelector } from 'react-redux';
import { isPMEditModeSelector } from 'store/slices/portfolioManagers';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { Title } from '../../styled';
import { Container } from './NameField.styled';

const NameField = () => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isPMEditModeSelector);
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);

  const {
    values: { name, surname },
  } = useFormikContext<PortfolioManagerFormFields>();

  if (isEditMode) {
    return (
      <Container>
        <FormikText
          name={ManagerFormFields.NAME}
          label={t('firstName')}
          placeholder={t('firstName')}
          fullWidth
        />

        <FormikText
          name={ManagerFormFields.SURNAME}
          label={t('lastName')}
          placeholder={t('lastName')}
          fullWidth
        />
      </Container>
    );
  }

  return (
    <Title>
      <SensitiveDataBlock isVisible={isVisibleSensitiveData} maxWidth='272px'>
        {`${name} ${surname}`}
      </SensitiveDataBlock>
    </Title>
  );
};

export default NameField;
