import React from 'react';
import { NumericInputInteger, TextField } from '@clearalpha/common';
import { useField } from 'formik';

type FormikNumbersProps = {
  name: string;
  disabled?: boolean;
  minLimit?: number;
  maxLimit?: number;
};

const FormikNumbers = ({
  name,
  minLimit,
  maxLimit,
  disabled,
}: FormikNumbersProps) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] =
    useField(name);

  const handleChange = (_: string, newValue: number) => {
    setValue(newValue);
    setTouched(true);
  };

  return (
    <NumericInputInteger
      value={value}
      customInput={TextField}
      variableName={name}
      maxLimit={maxLimit}
      minLimit={minLimit}
      setFieldValue={handleChange}
      error={error && touched ? error : undefined}
      testId={name}
      disabled={disabled}
    />
  );
};

export default FormikNumbers;
