import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PieChartsWidget, SortingOrder } from '@clearalpha/common';
import {
  productionPortfolioPerformanceSelector,
  workingPortfolioPerformanceSelector,
  sourcePortfolioPerformanceSelector,
  targetPortfolioPerformanceSelector,
  isReviewModePortfolioSelector,
} from 'store/slices/portfolios';
import { getAlphaArchetypeWidgetData } from './AlphaArchetypeWidget.helper';

const AlphaArchetypeWidget = () => {
  const { t } = useTranslation();

  const isReviewMode = useSelector(isReviewModePortfolioSelector);
  const productionPerformance = useSelector(
    productionPortfolioPerformanceSelector
  );
  const workingPerformance = useSelector(workingPortfolioPerformanceSelector);
  const sourcePerformance = useSelector(sourcePortfolioPerformanceSelector);
  const targetPerformance = useSelector(targetPortfolioPerformanceSelector);

  const currentWorkingPerformance = isReviewMode
    ? sourcePerformance
    : workingPerformance;
  const currentProdPerformance = isReviewMode
    ? targetPerformance
    : productionPerformance;

  const prodPie = getAlphaArchetypeWidgetData(
    currentProdPerformance.portfolio.alphaCategoriesPct
  );

  const scenarioPie = getAlphaArchetypeWidgetData(
    currentWorkingPerformance.portfolio.alphaCategoriesPct
  );

  return (
    <PieChartsWidget
      title={t('alphaArchetype')}
      prodPie={prodPie}
      workPie={scenarioPie}
      sortingOrder={SortingOrder.DESC}
    />
  );
};

export default AlphaArchetypeWidget;
