import styled from '@emotion/styled';
import { MenuItem } from '@clearalpha/common';

const NotificationItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: normal;
  min-width: 265px;
  width: 265px;
  height: max-content !important;
`;

const Title = styled.h3`
  font-size: 13px;
  font-family: 'Work Sans', sans-serif;
  padding: 0;
  margin: 0;
  overflow-wrap: break-word;
`;

const Message = styled.p`
  font-size: 11px;
  font-family: 'Work Sans', sans-serif;
  padding: 0;
  margin: 0;
  overflow-wrap: break-word;
`;

const ChangeRequestInfo = styled.p`
  font-size: 9px;
  font-family: 'Work Sans', sans-serif;
  padding: 0;
  margin: 0;
  color: ${({ theme: { colors } }) => colors.whiteOpacity70};
  overflow-wrap: break-word;
`;

export { Title, Message, ChangeRequestInfo, NotificationItem };
