import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ActionMenu } from '@clearalpha/common';
import { routes } from 'constants/routes';
import { useSelector } from 'react-redux';
import { ActionsContainer } from './Actions.styles';
import { ActionsProps } from './Actions.type';
import FormButtons from './FormButtons';

const Actions = ({
  isDirty,
  isCreateForm,
  handleCancel,
  handleCloseDrawer,
  isEditModeSelector,
  menuItems,
}: ActionsProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const isSaRA = pathname.includes(routes.MONITOR.path);

  const shouldShowActionButton = !isSaRA && !isCreateForm;

  const isEditMode = useSelector(isEditModeSelector);

  return (
    <ActionsContainer>
      <FormButtons
        isDirty={isDirty}
        isEditMode={isEditMode}
        handleCancel={handleCancel}
        handleCloseDrawer={handleCloseDrawer}
      />

      {shouldShowActionButton && (
        <Box>
          <ActionMenu label={t('actions.action')} menuItems={menuItems} />
        </Box>
      )}
    </ActionsContainer>
  );
};

export default Actions;
