import { Amplify, Auth } from 'aws-amplify';
import { config } from 'config';
import { routes } from 'constants/routes';
import { getCookie, removeCookies } from '../../browser/cookies';
import { UserStateStored } from './type';

Amplify.configure({
  Auth: {
    region: config.auth.cognito.region,
    userPoolId: config.auth.cognito.poolId,
    userPoolWebClientId: config.auth.cognito.poolClientId,
    oauth: {
      domain: config.auth.cognito.oauthDomain,
      scope: ['openid', 'profile'],
      redirectSignIn: `${config.url}${routes.ROOT.path}`,
      redirectSignOut: `${config.url}${routes.ROOT.path}`,
      responseType: 'code',
    },
    cookieStorage: {
      path: '/',
      expires: config.auth.cookie_ttl_days,
      sameSite: 'strict',
      secure: true,
    },
  },
});

const getUserStored = (): UserStateStored => {
  const idToken = getCookie(/^CognitoIdentityServiceProvider\.(.*)\.idToken=/);
  const isRedirected =
    getCookie('amplify-redirected-from-hosted-ui') === 'true';

  return { idToken, isRedirected };
};

const clearUserStored = () => {
  removeCookies(/^amplify-/);
};

const getSession = async () => Auth.currentSession();

const signIn = async () => Auth.federatedSignIn();

const signOut = async () => Auth.signOut();

export { getUserStored, clearUserStored, getSession, signIn, signOut };
