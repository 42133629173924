import { InternalAxiosRequestConfig } from 'axios';
import { config } from 'config';
import { refreshSession } from 'services/auth/auth.service';

const FIRST_PARTY_ORIGINS = [
  config.api.portfolioCore,
  config.api.portfolioManagerCore,
  config.api.fundCalculator,
  config.api.strategyCatalog,
];

const isFirstPartyRequest = (request: InternalAxiosRequestConfig): boolean => {
  if (!request.url) return false;

  const url = new URL(request.url);

  return FIRST_PARTY_ORIGINS.includes(url.origin);
};

const addAuthorizationHeader = async (request: InternalAxiosRequestConfig) => {
  if (!isFirstPartyRequest(request)) return request;

  // TODO UX-1514: remove 'isUserAction = false' by making http requests __only__ after user's action, in the first place
  const user = await refreshSession(false);

  if (!user) throw Error('unauthorized');

  const { idToken } = user;

  if (!idToken) throw Error('unauthorized');

  request.headers.Authorization = `Bearer ${idToken}`;

  return request;
};

export { addAuthorizationHeader };
