import { useMemo } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import {
  StrategyParams,
  StrategyListEntity,
} from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { useAppDispatch } from 'store';
import { setStrategiesAllocations } from 'store/slices/portfolios';
import { strategyValidationSchema } from 'validation/strategyValidationSchema';
import { StrategyStatuses } from 'constants/strategy';
import { StrategyGroupsResponse } from 'services/portfolioCore/portfolioCore.types';
import { updateStrategyDetails } from 'store/slices/strategies';
import { createStrategyBase } from 'store/thunks/createStrategyBase';
import { prepareBaseStategyFromDetailsPayload } from 'components/StrategyList/AddNewStrategyDialog/AddNewStrategyDialog.helpers';
import { assignStrategyToAnotherGroup } from 'store/slices/portfolios/portfolios.slice';
import { changeStrategyGroupInWorkingStrategy } from 'store/slices/strategies/strategies.slice';
import {
  RemainingCapacityHelperProps,
  StrategyDetailsEntity,
  VolatilityCapacityHelperProps,
  StrategyDetailsFormFields,
  GetStrategyToViewProps,
  GetGlobalStrategyToViewProps,
  StrategyGroupOption,
} from './StrategyDetails.type';
import { StrategyFormFields } from './StrategyDetails.constants';
import { AlphaCategoryWithValue } from '../../services/strategyCatalog/strategyCatalog.type';

export const getStrategyToView = ({
  strategyId,
  strategies,
}: GetStrategyToViewProps) =>
  strategies.find(({ id }) => id === strategyId) || null;

export const getGlobalStrategyToView = ({
  strategyId,
  strategies,
}: GetGlobalStrategyToViewProps) =>
  strategies.find(({ id }) => id === strategyId) || null;

export const createStrategyGroupOptions = (
  strategyGroups: StrategyGroupsResponse[],
  commonPmTeamId: string
): StrategyGroupOption[] =>
  strategyGroups
    .filter(
      ({ portfolioManagerTeamId }) => portfolioManagerTeamId === commonPmTeamId
    )
    .map((strategyGroup) => ({
      label: strategyGroup.name,
      id: strategyGroup.id,
      strategyGroup,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

const getStrategyGroupOptionById = (
  strategyGroupOptions: StrategyGroupOption[],
  strategyGroupId?: string
) => strategyGroupOptions.find(({ id }) => id === strategyGroupId) || null;

export const calculateVolatilityCapacity = ({
  aumCapacity,
  volatilityTarget,
}: VolatilityCapacityHelperProps) => {
  if (!aumCapacity || !volatilityTarget) {
    return 0;
  }

  return aumCapacity * volatilityTarget;
};

export const calculateRemainingCapacity = ({
  aumCapacity = 0,
  notionalAccountValue = 0,
}: RemainingCapacityHelperProps) => {
  return aumCapacity - notionalAccountValue;
};

export const getAlphaCategoryValue = (
  alphaCategoryFieldName: string,
  alphaCategories: AlphaCategoryWithValue[]
): AlphaCategoryWithValue | null => {
  return (
    alphaCategories?.find(({ id }) => alphaCategoryFieldName === id) || null
  );
};

const transformToDetails = (
  // TODO: Replace StrategyDetailsEntity & StrategyListEntity with StrategyEntity https://linear.app/clearalpha/issue/UX-818/replace-strategydetailsentity-and-strategylistentity-with
  strategy: StrategyListEntity
): StrategyDetailsEntity => {
  return {
    id: strategy?.id,
    name: strategy?.strategy.name,
    arcesiumId: strategy?.arcesiumId,
    description: strategy?.strategy?.description,
    strategy_edge: strategy?.strategy?.strategyEdge,
    strategyGroup: strategy?.strategyGroup,
    gross_sharpe: strategy?.strategy?.grossSharpe,
    gross_sharpe_historic: strategy?.strategy?.gross_sharpe_historic,
    gross_sharpe_historic_unique:
      strategy?.strategy?.gross_sharpe_historic_unique,
    margin_type: strategy?.strategy?.marginType?.id,
    assetCategory: strategy?.strategy?.assetCategory?.id,
    groupCharacteristic: strategy?.strategy?.groupCharacteristic?.id,
    averageHoldingPeriod: strategy?.strategy?.averageHoldingPeriod?.id,
    strategy_volatility_target: strategy?.strategy?.volatilityTarget,
    allocation_min: strategy?.strategy.minAllocation,
    allocation_terms: strategy?.strategy.capacity,
    account_value: strategy?.workingNotionalAccountValue,
    capacity_used: strategy?.strategy.capacity_used,
    remaining_capacity: calculateRemainingCapacity({
      aumCapacity: strategy?.strategy?.capacity,
      notionalAccountValue: strategy?.workingNotionalAccountValue,
    }),
    historicSharpeRatio: strategy?.strategy.historicSharpeRatio,
    fee_management:
      strategy?.strategyGroup?.fees?.find((item) => item.fee.name === 'mgmtFee')
        ?.value || 0,
    fee_performance:
      strategy?.strategyGroup?.fees?.find(
        (item) => item.fee.name === 'performanceFee'
      )?.value || 0,
    expenses:
      strategy?.strategyGroup?.fees?.find(
        (item) => item.fee.name === 'fixedExpenses'
      )?.value || 0,
    accelerator_threshold: strategy?.strategy.accelerator_threshold,
    accelerator_percentage: strategy?.strategy.accelerator_percentage,
    stepup_threshold: strategy?.strategy.stepup_threshold,
    stepup_percentage: strategy?.strategy.stepup_percentage,
    scientist: getAlphaCategoryValue(
      'SCIENTIST',
      strategy?.strategy?.alphaCategories
    ),
    engineer: getAlphaCategoryValue(
      'ENGINEER',
      strategy?.strategy?.alphaCategories
    ),
    actuary: getAlphaCategoryValue(
      'ACTUARY',
      strategy?.strategy?.alphaCategories
    ),
    proportionSystematic: strategy?.strategy.proportionSystematic,
    volatility_capacity: calculateVolatilityCapacity({
      aumCapacity: strategy?.strategy.capacity,
      volatilityTarget: strategy?.strategy.volatilityTarget,
    }),
    status: strategy?.status?.id,
    productionValues: strategy?.productionValues,
    capitalAtRiskWindowBdays: strategy?.strategy.capitalAtRiskWindowBdays,
  };
};

export const transformToPortfolioStrategy = (
  strategy: StrategyDetailsEntity
): StrategyParams => {
  return {
    id: strategy.id,
    arcesiumId: strategy.arcesiumId,
    assetCategory: strategy.assetCategory,
    proportionSystematic: strategy?.proportionSystematic || 0,
    name: strategy.name,
    description: strategy.description,
    strategyEdge: strategy.strategy_edge,
    groupCharacteristic: strategy.groupCharacteristic,
    alphaCategoriesIds: {
      SCIENTIST: strategy.scientist?.value || 0,
      ENGINEER: strategy.engineer?.value || 0,
      ACTUARY: strategy.actuary?.value || 0,
    },
    historicSharpeRatio: strategy?.historicSharpeRatio,
    grossSharpe: strategy.gross_sharpe,
    capacity: strategy.allocation_terms,
    minAllocation: strategy.allocation_min,
    marginType: strategy.margin_type,
    averageHoldingPeriod: strategy.averageHoldingPeriod,
    volatilityTarget: strategy.strategy_volatility_target,
    notionalAccountValue: strategy.account_value,
    capitalAtRiskWindowBdays: strategy.capitalAtRiskWindowBdays,
  };
};

export const strategyToFormFields = (
  strategyDetails: StrategyDetailsEntity | null,
  currentStrategyGroupOption: StrategyGroupOption | null
): StrategyDetailsFormFields => {
  return {
    [StrategyFormFields.STATUS]:
      strategyDetails?.status || StrategyStatuses.NOT_APPROVED,
    [StrategyFormFields.NAME]: strategyDetails?.name || '',
    [StrategyFormFields.ARCESIUM_ID]: strategyDetails?.arcesiumId || '',
    [StrategyFormFields.STRATEGY_GROUP]: currentStrategyGroupOption,
    [StrategyFormFields.DESCRIPTION]: strategyDetails?.description || '',
    [StrategyFormFields.STRATEGY_EDGE]: strategyDetails?.strategy_edge || '',
    [StrategyFormFields.SCIENTIST]: strategyDetails?.scientist || null,
    [StrategyFormFields.ENGINEER]: strategyDetails?.engineer || null,
    [StrategyFormFields.ACTUARY]: strategyDetails?.actuary || null,
    [StrategyFormFields.GROSS_SHARPE]: strategyDetails?.gross_sharpe || 0,
    [StrategyFormFields.GROSS_SHARPE_HISTORIC]:
      strategyDetails?.gross_sharpe_historic || 0,
    [StrategyFormFields.MARGIN_TYPE]: strategyDetails?.margin_type || '',
    [StrategyFormFields.ASSET_CATEGORY]: strategyDetails?.assetCategory || '',
    [StrategyFormFields.PROPORTION_SYSTEMATIC]:
      strategyDetails?.proportionSystematic || 0,
    [StrategyFormFields.GROUP_CHARACTERISTIC]:
      strategyDetails?.groupCharacteristic || '',
    [StrategyFormFields.AVERAGE_HOLD_PERIOD]:
      strategyDetails?.averageHoldingPeriod || '',
    [StrategyFormFields.ALLOCATION_MIN]: strategyDetails?.allocation_min || 0,
    [StrategyFormFields.ALLOCATION_TERMS]:
      strategyDetails?.allocation_terms || 0,
    [StrategyFormFields.STRATEGY_VOLATILITY_TARGET]:
      strategyDetails?.strategy_volatility_target || 0,
    [StrategyFormFields.VOLATILITY_CAPACITY]: calculateVolatilityCapacity({
      aumCapacity: strategyDetails?.allocation_terms,
      volatilityTarget: strategyDetails?.strategy_volatility_target,
    }),
    [StrategyFormFields.HISTORIC_SHARPE_RATIO]:
      strategyDetails?.historicSharpeRatio || 0,
    [StrategyFormFields.ACCOUNT_VALUE]: strategyDetails?.account_value || 0,
    [StrategyFormFields.REMAINING_CAPACITY]: calculateRemainingCapacity({
      aumCapacity: strategyDetails?.allocation_terms,
      notionalAccountValue: strategyDetails?.account_value,
    }),
    [StrategyFormFields.EXPENSES]: strategyDetails?.expenses || 0,
    [StrategyFormFields.FEE_MANAGEMENT]: strategyDetails?.fee_management || 0,
    [StrategyFormFields.FEE_PERFORMANCE]: strategyDetails?.fee_performance || 0,
    [StrategyFormFields.ACCELERATOR_THRESHOLD]:
      strategyDetails?.accelerator_threshold || 0,
    [StrategyFormFields.ACCELERATOR_PERCENTAGE]:
      strategyDetails?.accelerator_percentage || 0,
    [StrategyFormFields.STEPUP_THRESHOLD]:
      strategyDetails?.stepup_threshold || 0,
    [StrategyFormFields.STEPUP_PERCENTAGE]:
      strategyDetails?.stepup_percentage || 0,
    [StrategyFormFields.PRODUCTION_VALUES]:
      strategyDetails?.productionValues || {
        status: StrategyStatuses.NOT_APPROVED,
        arcesiumId: '',
        description: '',
        strategyEdge: '',
        scientist: null,
        engineer: null,
        actuary: null,
        averageHoldingPeriod: '',
        assetCategory: '',
        groupCharacteristic: '',
        marginType: '',
        managementFee: 0,
        performanceFee: 0,
        fixedExpenses: 0,
        acceleratorThreshold: 0,
        acceleratorPercentage: 0,
        stepUpThreshold: 0,
        stepUpPercentage: 0,
        capacity: 0,
        grossSharpe: 0,
        historicSharpeRatio: 0,
        minAllocation: 0,
        volatilityTarget: 0,
        workingNotionalAccountValue: 0,
        strategyGroup: null,
        name: '',
      },
    capitalAtRiskWindowBdays: strategyDetails?.capitalAtRiskWindowBdays || 0,
  };
};

type StrategyDetailsFormProps = {
  strategy: StrategyListEntity | null;
  handleCloseDrawer: () => void;
  strategyGroupOptions: StrategyGroupOption[];
};

export const useStrategyDetailsForm = ({
  strategy,
  handleCloseDrawer,
  strategyGroupOptions,
}: StrategyDetailsFormProps) => {
  const dispatch = useAppDispatch();

  const strategyDetails = useMemo(
    () => transformToDetails(strategy as StrategyListEntity),
    [strategy]
  );

  const currentStrategyGroupOption = useMemo(
    () =>
      getStrategyGroupOptionById(
        strategyGroupOptions,
        strategyDetails?.strategyGroup?.id
      ),
    [strategyGroupOptions, strategyDetails]
  );

  const initialValues: StrategyDetailsFormFields = useMemo(
    () => strategyToFormFields(strategyDetails, currentStrategyGroupOption),
    [strategyDetails, currentStrategyGroupOption]
  );
  const handleSubmit = async (
    { status, ...values }: StrategyDetailsFormFields,
    { resetForm }: FormikHelpers<StrategyDetailsFormFields>
  ) => {
    const strategyId = strategyDetails?.id;

    const transformedPortfolioStrategy = transformToPortfolioStrategy({
      ...strategyDetails,
      ...values,
      strategyGroup: values.strategyGroup?.strategyGroup || null,
    });

    const reqPayload = prepareBaseStategyFromDetailsPayload(
      transformedPortfolioStrategy
    );
    const { payload }: any = await dispatch(
      createStrategyBase({ strategyData: reqPayload })
    );

    if (strategy?.strategyGroup.id !== values.strategyGroup?.id) {
      dispatch(
        assignStrategyToAnotherGroup({
          oldStrategyGroupId: strategy?.strategyGroup.id || '',
          newStrategyGroupId: values.strategyGroup?.id || '',
          strategyId,
        })
      );

      if (values.strategyGroup?.strategyGroup) {
        const { strategies, ...strategyGroup } =
          values.strategyGroup.strategyGroup;
        dispatch(
          changeStrategyGroupInWorkingStrategy({
            strategyId,
            newStrategyGroup: strategyGroup,
          })
        );
      }
    }

    const changedAllocation = {
      strategyId: strategyId as string,
      notionalAccountValue: values.account_value,
      newStrategyId: payload?.id,
    };

    dispatch(setStrategiesAllocations([changedAllocation]));
    dispatch(
      updateStrategyDetails({
        id: strategyId as string,
        updatedStrategy: transformedPortfolioStrategy,
        newStrategyId: payload?.id,
      })
    );
    resetForm({ values: { ...values, status } });
    handleCloseDrawer();
  };

  const formik = useFormik<StrategyDetailsFormFields>({
    initialValues,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validationSchema: strategyValidationSchema,
  });

  return {
    formik,
    initialValues,
  };
};
