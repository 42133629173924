import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { NumericTextDecimal } from '@clearalpha/common';
import { FieldLabel } from 'components/StrategyDetails/blocks/styled';
import { GlobalStrategyDetailsFormFields } from '../../../GlobalStrategyDetails.type';

const HistoricalSharpe = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<GlobalStrategyDetailsFormFields>();

  return (
    <>
      <FieldLabel>{t('historicalSharpeRatio')}</FieldLabel>
      <NumericTextDecimal value={values.gross_sharpe_historic} />
    </>
  );
};

export default HistoricalSharpe;
