import styled from '@emotion/styled';
import { Dialog, FooterButton, TextFieldWrapper } from '@clearalpha/common';

const ContentWrapper = styled.div`
  font-family: 'Work Sans', sans-serif;
  width: 850px;
  display: flex;
  background: ${({ theme: { colors } }) => colors.systemSurfaceAtPlusThree};
  border-radius: 16px;
  flex-direction: column;
  padding: 16px 32px 16px 32px;
`;

const ParametersDialogWrapper = styled(Dialog)`
  overflow-x: hidden;
  overflow-y: scroll;
  & ::-webkit-scrollbar,
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .MuiDialog-paper {
    max-width: 1000px;
    width: 850px;
    border-radius: 16px;
  }
`;

const Label = styled.div`
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 16px;
  color: ${({ theme: { colors } }) => colors.onSurface};
`;

const Description = styled(Label)`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 32px;
`;

const InputBlock = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  color: ${({ theme: { colors } }) => colors.onSurface};
  opacity: 0.87;
  padding-right: 20px;
  padding-bottom: 33px;
  span {
    display: flex;
    justify-content: end;
    text-align: right;
  }
`;

const UnderlinedRow = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 140px 140px;
  grid-gap: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.outline};
  .MuiTextField-root .MuiFormHelperText-root {
    width: 500px;
  }
`;

const ColumnHeader = styled(Description)`
  display: flex;
  justify-content: end;
  :first-child {
    padding-right: 16px;
  }
  .MuiTypography-root {
    font-family: 'Work Sans', sans-serif;
    font-size: 16px;
  }
  svg {
    margin-left: 9px;
  }
`;

const Button = styled(FooterButton)`
  :disabled {
    color: ${({ theme: { colors } }) => colors.outline};
  }
`;

const BlockHeader = styled(Description)`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 11px;
`;

const FieldLabel = styled.p`
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 26px;
  font-family: 'Work Sans', sans-serif;
  margin: 0;
`;

const HighlightedWrapper = styled(TextFieldWrapper)`
  background-color: ${({ theme: { colors } }) => colors.highlightColor};
  width: fit-content;
`;

const IdWrapper = styled.div`
  margin-bottom: 20px;

  &.highlighted {
    .MuiInputBase-root {
      background-color: ${({ theme: { colors } }) => colors.highlightColor};
    }
  }
`;

export {
  ContentWrapper,
  Description,
  Label,
  ParametersDialogWrapper,
  InputBlock,
  Button,
  ColumnHeader,
  BlockHeader,
  FieldLabel,
  InputSection,
  UnderlinedRow,
  HighlightedWrapper,
  IdWrapper,
};
