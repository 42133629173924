import React, { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Notification as NotificationBase } from '@clearalpha/common';
import { useAppDispatch } from 'store';
import {
  changeRequestsSelector,
  setChangeRequests,
} from 'store/slices/changeRequests';
import { routes } from 'constants/routes';
import { getChangeRequests } from 'services/portfolioCore/portfolioCore.service';
import { ApprovalStatus } from 'services/portfolioCore/portfolioCore.types';
import { MINUTE_INTERVAL } from 'constants/timeIntervals';
import { usePortfolioDifferences } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.helper';
import { NotificationMenu } from '../Navigation/Navigation.styled';
import ChangeRequestNotification from '../ChangeRequests/ChangeRequestNotification/ChangeRequestNotification';

const Notification = () => {
  const navigate = useNavigate();
  const match = useMatch(routes.MONITOR);
  const changeRequests = useSelector(changeRequestsSelector);
  const { showReview } = usePortfolioDifferences();
  const dispatch = useAppDispatch();

  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleOpenNotifications = (event: React.MouseEvent<HTMLElement>) => {
    if (changeRequests.length) {
      setNotificationAnchorEl(event.currentTarget);
    }
  };

  const onCloseNotifications = () => {
    setNotificationAnchorEl(null);
  };

  const onChangeRequestView = async (event: React.MouseEvent<HTMLElement>) => {
    const selectedRequestId = event.currentTarget.getAttribute('data-key');
    const selectedRequest = changeRequests.find(
      (element) => element.id === selectedRequestId
    );
    setNotificationAnchorEl(null);

    if (!selectedRequest) return;

    if (!match) {
      navigate(`/${routes.MONITOR.path}`);
    }

    await showReview(selectedRequest);
  };

  useEffect(() => {
    const fetchNewNotifications = async () => {
      const result = await getChangeRequests();
      const filteredResult = result.filter(
        (cr) => cr.changeRequestStatus.id === ApprovalStatus.OPEN
      );

      dispatch(setChangeRequests(filteredResult));
    };

    fetchNewNotifications();
    const notificationTimer = setInterval(
      fetchNewNotifications,
      MINUTE_INTERVAL
    );

    return () => {
      clearInterval(notificationTimer);
    };
  }, [dispatch]);

  return (
    <>
      <NotificationBase
        handleClick={handleOpenNotifications}
        badgeContent={changeRequests.length}
      />
      <NotificationMenu
        onClose={onCloseNotifications}
        onClick={handleOpenNotifications}
        anchorEl={notificationAnchorEl}
        sx={{
          button: {
            display: 'none',
          },
        }}
        setAnchorEl={setNotificationAnchorEl}
      >
        {changeRequests.map((item) => (
          <ChangeRequestNotification
            handleViewClick={onChangeRequestView}
            changeRequest={item}
            key={item.id}
          />
        ))}
      </NotificationMenu>
    </>
  );
};

export { Notification };
