import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PieChartsWidget, SortingOrder } from '@clearalpha/common';
import {
  productionPortfolioPerformanceSelector,
  workingPortfolioPerformanceSelector,
  sourcePortfolioPerformanceSelector,
  targetPortfolioPerformanceSelector,
  isReviewModePortfolioSelector,
} from 'store/slices/portfolios';
import {
  saraProductionStrategiesSelector,
  strategiesSelector,
  sourceStrategiesSelector,
  targetStrategiesSelector,
} from 'store/slices/strategies/strategies.selectors';
import {
  STRATEGY_ALLOCATION_WIDGET_COLORS,
  STRATEGY_ALLOCATION_WIDGET_MIN_ANGLE,
} from './StrategyAllocationWidget.const';
import {
  prepareVolatilityChartData,
  useMapAndSaveStrategyColors,
} from './StrategyAllocationWidget.helper';

const StrategyAllocationWidget = () => {
  const { t } = useTranslation();

  const isReviewMode = useSelector(isReviewModePortfolioSelector);
  const productionPerformance = useSelector(
    productionPortfolioPerformanceSelector
  );
  const workingPerformance = useSelector(workingPortfolioPerformanceSelector);
  const sourcePerformance = useSelector(sourcePortfolioPerformanceSelector);
  const targetPerformance = useSelector(targetPortfolioPerformanceSelector);
  const strategies = useSelector(strategiesSelector);
  const prodStrategies = useSelector(saraProductionStrategiesSelector);
  const sourceStrategies = useSelector(sourceStrategiesSelector);
  const targetStrategies = useSelector(targetStrategiesSelector);

  const currentWorkingPerformance = isReviewMode
    ? sourcePerformance
    : workingPerformance;
  const currentProdPerformance = isReviewMode
    ? targetPerformance
    : productionPerformance;
  const currentStrategies = isReviewMode ? sourceStrategies : strategies;
  const currentProdStrategies = isReviewMode
    ? targetStrategies
    : prodStrategies;

  const { prodPie, workPie } = prepareVolatilityChartData(
    currentProdPerformance.strategyAllocation,
    currentWorkingPerformance.strategyAllocation,
    currentStrategies,
    currentProdStrategies
  );

  const prodPieColored = useMapAndSaveStrategyColors(
    prodPie,
    STRATEGY_ALLOCATION_WIDGET_COLORS
  );
  const workPieColored = useMapAndSaveStrategyColors(
    workPie,
    STRATEGY_ALLOCATION_WIDGET_COLORS
  );

  return (
    <PieChartsWidget
      title={t('strategyAllocation')}
      prodPie={prodPieColored}
      workPie={workPieColored}
      minAngle={STRATEGY_ALLOCATION_WIDGET_MIN_ANGLE}
      sortingOrder={SortingOrder.DESC}
      shouldShowLegendTooltip
    />
  );
};

export default StrategyAllocationWidget;
