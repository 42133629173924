import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const CardWrapper = styled.div`
  min-width: 300px;
  min-height: 300px;
  background: ${({ theme }) => theme.colors.systemSurfaceAtPlusOne};
  border-radius: 4px;
  display: inline-block;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.whiteOpacity12};
`;

export const CardTitle = styled(Typography)`
  font-family: Public Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.4%;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.colors.white};
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px 22px;
`;

export const AdditionalFeatures = styled.div``;
