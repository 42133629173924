import { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { DialogContent, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  FieldValue,
  currencyFormatter,
  currencyFormatterShorthand,
} from '@clearalpha/common';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { allocationValidationSchema } from 'validation/allocationValidationSchema';
import { AllocationDialogProps, allocationRow } from './AllocationDialog.type';
import {
  Dialog,
  DialogTitle,
  StrategyName,
  DialogActions,
  Button,
  Row,
  TitleProduction,
  TitleWorking,
  Value,
} from './styled';

const AllocationDialog: FC<AllocationDialogProps> = ({
  isOpen,
  onUpdate,
  handleClose,
  strategy,
  ...props
}) => {
  const { t } = useTranslation();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);

  const getValues = () => ({
    strategyName: strategy.strategy.name,
    minAllocation: strategy.strategy.minAllocation,
    capacity: strategy.strategy.capacity,
    workingNotionalAccountValue: strategy.workingNotionalAccountValue,
    recommendedNotionalAccountValue: strategy.recommendedNotionalAccountValue,
    productionNotionalAccountValue: strategy.productionNotionalAccountValue,
  });
  const { values, setValues, setFieldValue, errors, isValid, handleSubmit } =
    useFormik({
      initialValues: getValues(),
      validationSchema: allocationValidationSchema,
      onSubmit: ({ workingNotionalAccountValue }) => {
        onUpdate(workingNotionalAccountValue);
        handleClose();
      },
    });

  useEffect(() => {
    setValues(getValues());
  }, [isOpen, strategy]);

  const handeClickCancel = () => {
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={isOpen} {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t('actions.editAllocation')}</DialogTitle>
        <StrategyName>{values.strategyName}</StrategyName>
        <DialogContent>
          <Row>
            <TitleProduction>{t('production')}</TitleProduction>
            <Value>
              <Tooltip
                title={currencyFormatter(values.productionNotionalAccountValue)}
                placement='top'
                arrow
              >
                <span>
                  {currencyFormatterShorthand(
                    values.productionNotionalAccountValue
                  )}
                </span>
              </Tooltip>
            </Value>
          </Row>
          <Row>
            <TitleWorking>{t('scenario')}</TitleWorking>
            <FieldValue
              value={values.workingNotionalAccountValue}
              row={allocationRow}
              setFieldValue={setFieldValue}
              error={errors.workingNotionalAccountValue}
              disabled={isPortfolioReviewMode}
            />
          </Row>
        </DialogContent>
        <DialogActions>
          <Button variant='filled' onClick={handeClickCancel}>
            {t('actions.cancel')}
          </Button>
          <Button
            type='submit'
            variant='filled'
            disabled={!isValid || isPortfolioReviewMode}
          >
            {t('actions.update')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AllocationDialog;
