import React from 'react';
import { useFormikContext } from 'formik';
import TeamMemberListItem from './TeamMemberListItem';
import { TeamDetailsFormFields } from '../../TeamDetails.types';
import { TeamDetailsFormFieldName } from '../../TeamDetails.constants';
import { TeamMembers } from './TeamMemberList.styled';
import { formatMembers } from './TeamMemberList.helpers';
import { TeamMembersProps } from '../TeamMembers.types';

const TeamMemberList = ({ handleOpenTeamMember }: TeamMembersProps) => {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<TeamDetailsFormFields>();

  const { teamLeadId: currentTeamLeadId, members: currentTeamMembers } = values;

  const assignTeamLead = (uuid: string) => {
    setFieldValue(TeamDetailsFormFieldName.TEAM_LEAD_ID, uuid);
    setFieldTouched(TeamDetailsFormFieldName.TEAM_LEAD_ID, true);
  };

  const removeTeamMember = (uuid: string) => {
    const updatedTeamMembers = currentTeamMembers.filter(
      ({ id }) => id !== uuid
    );

    if (uuid === currentTeamLeadId) {
      setFieldValue(TeamDetailsFormFieldName.TEAM_LEAD_ID, '');
      setFieldTouched(TeamDetailsFormFieldName.TEAM_LEAD_ID, true);
    }

    setFieldValue(TeamDetailsFormFieldName.MEMBERS, updatedTeamMembers);
    setFieldTouched(TeamDetailsFormFieldName.MEMBERS, true);
  };

  const teamMembers = formatMembers(currentTeamMembers);

  return (
    <TeamMembers>
      {teamMembers.map((member) => (
        <TeamMemberListItem
          key={member.uuid}
          member={member}
          teamLeadId={currentTeamLeadId}
          assignTeamLead={assignTeamLead}
          removeTeamMember={removeTeamMember}
          handleOpenTeamMember={handleOpenTeamMember}
        />
      ))}
    </TeamMembers>
  );
};

export default TeamMemberList;
