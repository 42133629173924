import {
  PortfolioFee,
  Strategy,
} from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';

export enum ApprovalStatus {
  OPEN = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  COMMENT = 'COMMENT',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export enum ReviewStatus {
  APPROVED = 'APPROVED',
  COMMENTED = 'COMMENTED',
}

export type Portfolio = {
  id: string;
  version: number;
};

type ChangeRequestStatus = {
  id: string;
  name: string;
  deletedAt: null;
};

export type ChangeRequestResponse = {
  id: string;
  ownerId: string;
  createdAt: string;
  changeRequestStatus: ChangeRequestStatus;
  sourceId: string;
  targetId: string;
  sourceVersion: number;
  targetVersion: number;
  reviewerId: string;
  description: string;
  deletedAt: null;
};

export type ChangeRequestBody = {
  targetId: string;
  targetVersion: number;
  sourceId: string;
  sourceVersion: number;
  reviewerId: string;
  changeRequestStatus: string;
  description: string;
};

export interface StrategyGroup {
  id: string;
  name: string;
  description: string;
  portfolioManagerTeamId: string;
  strategyGroupOriginId: string;
  parentGroupId: string;
  deletedAt: string | null;
  version: number;
  fees: PortfolioFee[];
}

export interface GetPortfolioStrategies {
  id: string;
  status: EntityIdInfo;
  globalStrategyId: string;
  globalStrategyVersion: number;
  strategyId: string;
  strategyType: StrategyType;
  strategyGroupId: string;
  strategyGroup: StrategyGroup;
  axiomaId: string;
  arcesiumId: string;
  ownerId: string;
  portfolioId: string;
  notionalAccountValue: number;
  isLocked: boolean;
  version: number;
  deletedAt: string | null;
}

export type StrategyStatus = {
  id: string;
  name: string;
  deletedAt: string | null;
};

export interface EntityIdInfo {
  id: string;
  name: string;
  deletedAt: string | null;
}

export type StrategyType = {
  id: string;
  name: string;
  deletedAt: string | null;
};

export type FullPortfolioStrategy = GetPortfolioStrategies & {
  strategy: Strategy;
};

export type EditedStrategy = {
  source: FullPortfolioStrategy;
  target: FullPortfolioStrategy;
};
export interface Fee {
  fee: {
    id: string;
    name: string;
    deletedAt: string | null;
  };
  value: number;
}

export interface StrategyPortfolioGroupResponse {
  id: string;
  status: {
    id: string;
    name: string;
    deletedAt: string | null;
  };
  globalStrategyId: string;
  globalStrategyVersion: number;
  strategyId: string;
  strategyType: {
    id: string;
    name: string;
    deletedAt: string | null;
  };
  axiomaId: string;
  arcesiumId: string;
  ownerId: string;
  portfolioId: string;
  notionalAccountValue: number;
  isLocked: boolean;
  version: number;
  deletedAt: string | null;
}

export interface StrategyGroupsResponse {
  id: string;
  name: string;
  description: string;
  portfolioId: string;
  portfolioManagerTeamId: string;
  parentGroupId: string;
  deletedAt: string | null;
  version: number;
  fees: Fee[];
  strategies: StrategyPortfolioGroupResponse[];
  strategyGroupOriginId: string;
}

export type PortfolioFeeTypes = {
  id: string;
  name: string;
};

export const initialPortfolioValues = {
  id: '',
  updatedAt: '',
  portfolioOriginId: '',
  ownerId: '',
  portfolioType: {
    id: '',
    name: '',
    deletedAt: null,
  },
  portfolioStatus: {
    id: '',
    name: '',
    deletedAt: null,
  },
  changeRequest: {
    id: '',
    ownerId: '',
    targetId: '',
    targetVersion: '',
    sourceId: '',
    sourceVersion: '',
    reviewerId: '',
    changeRequestStatus: {
      id: '',
      name: '',
      deletedAt: null,
    },
    description: '',
    createdAt: '',
    deletedAt: null,
  },
  axiomaId: '',
  arcesiumId: '',
  name: '',
  version: 1,
  assetsUnderManagement: 0,
  crossMarginBenefit: 0,
  minFreeCashFromAum: 0,
  freeCashFromVolMultiplier: 0,
  averageCorrelation: 0,
  constraints: {
    targetMaxCapitalAtRisk: 0,
    targetMaxLiquidityCR: 0,
    targetMaxPLRiskCR: 0,
    targetMinNicheRisk: 0,
    targetMaxEquityLeverage: 0,
    targetMaxGrossExposure: 0,
    minProbReturnThreshold: 0,
    targetFreeCashFromAum: 0,
    targetNetExcessReturn: 0,
  },
  correlationSets: [],
  crossCapitalAtRiskBenefit: 0,
  fees: [],
  strategyAllocations: [],
  strategyGroups: [],
  deletedAt: null,
  tbillYield: {
    id: '',
    value: 0,
    deletedAt: null,
  },
};
