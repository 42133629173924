import React from 'react';
import { useTranslation } from 'react-i18next';
import { localizeDatetime, formatToCommonDate } from '@clearalpha/common';
import {
  ButtonText,
  ColumnName,
  DateContainer,
  DateAndStatusPart,
  RestoreVersionButton,
  TimeWrapper,
  VersionItem,
} from './VersionListDialog.styled';
import { VersionItemProps } from './VersionListDialog.type';

const VersionListItem = ({
  id,
  handleRestoreVersion,
  asOf,
  version,
  isCurrent,
}: VersionItemProps) => {
  const { t } = useTranslation();
  // TODO: Update with "updatedAt" when possible
  const localizedTime = localizeDatetime(asOf);
  const { time, date } = formatToCommonDate(localizedTime);

  return (
    <VersionItem>
      <DateContainer>
        {isCurrent ? (
          <ColumnName>{t('dialogs.versionHistory.currentVersion')}</ColumnName>
        ) : (
          <TimeWrapper>
            <DateAndStatusPart>{date}</DateAndStatusPart>
            <DateAndStatusPart>{time}</DateAndStatusPart>
          </TimeWrapper>
        )}
      </DateContainer>

      <RestoreVersionButton
        onClick={() => handleRestoreVersion(id, version)}
        variant='filled'
      >
        <ButtonText>{t('actions.restoreVersion')}</ButtonText>
      </RestoreVersionButton>
    </VersionItem>
  );
};

export default VersionListItem;
