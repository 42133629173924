import { FieldValueEnum } from '@clearalpha/common';
import i18n from 'translations/i18n';
import { Fund } from 'services/fundCalculator/fundCalculator.types';

export type FundVariableNameType = keyof Fund;

export interface StatRowFundOverview {
  fieldName: string;
  variableName: FundVariableNameType;
  type: {
    left: FieldValueEnum;
    right: FieldValueEnum;
  };
}

export interface StatRows {
  aumRow: StatRowFundOverview;
  statRows: StatRowFundOverview[];
}

export const fundOverviewWidgetConfig: StatRows = {
  aumRow: {
    fieldName: i18n.t('widgets.fundOverview.fundNAV'),
    variableName: 'aum',
    type: {
      left: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
      right: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
    },
  },
  statRows: [
    {
      fieldName: i18n.t('widgets.fundOverview.leverage'),
      variableName: 'leverage',
      type: {
        left: FieldValueEnum.NUMERIC_TEXT_X_TIMES,
        right: FieldValueEnum.NUMERIC_TEXT_X_TIMES,
      },
    },
    {
      fieldName: i18n.t('widgets.fundOverview.marginReq'),
      variableName: 'marginReqs',
      type: {
        left: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
        right: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
      },
    },
    {
      fieldName: i18n.t('widgets.fundOverview.freeCashDollar'),
      variableName: 'freeCash',
      type: {
        left: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
        right: FieldValueEnum.NUMERIC_TEXT_CURRENCY,
      },
    },
    {
      fieldName: i18n.t('widgets.fundOverview.freeCashPercent'),
      variableName: 'freeCashPct',
      type: {
        left: FieldValueEnum.NUMERIC_TEXT_PERCENT,
        right: FieldValueEnum.NUMERIC_TEXT_PERCENT,
      },
    },
    {
      fieldName: i18n.t('widgets.fundOverview.stressedFreeCash'),
      variableName: 'stressedFreeCashPct',
      type: {
        left: FieldValueEnum.NUMERIC_TEXT_PERCENT,
        right: FieldValueEnum.NUMERIC_TEXT_PERCENT,
      },
    },
    {
      fieldName: i18n.t('widgets.fundOverview.minStressedFreeCash'),
      variableName: 'targetFreeCashFromAum',
      type: {
        left: FieldValueEnum.NUMERIC_TEXT_PERCENT,
        right: FieldValueEnum.NUMERIC_TEXT_PERCENT,
      },
    },
    {
      fieldName: i18n.t('widgets.fundOverview.riskConcentration'),
      variableName: 'concentrationRatio',
      type: {
        left: FieldValueEnum.NUMERIC_TEXT_DECIMAL,
        right: FieldValueEnum.NUMERIC_TEXT_DECIMAL,
      },
    },
    {
      fieldName: i18n.t('widgets.fundOverview.maxRiskConcentrations'),
      variableName: 'targetMaxPLRiskCR',
      type: {
        left: FieldValueEnum.NUMERIC_TEXT_DECIMAL,
        right: FieldValueEnum.NUMERIC_TEXT_DECIMAL,
      },
    },
  ],
};
