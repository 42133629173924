import { object, number } from 'yup';
import i18n from 'translations/i18n';
import { ZERO, ONE_HUNDRED_BILLION } from './validationNumbers';

export const allocationValidationSchema = object().shape({
  workingNotionalAccountValue: number()
    .test({
      name: 'Test notional account value',
      skipAbsent: true,
      test(value, ctx) {
        if (value === ZERO) {
          return true;
        }
        if (value && value < this.parent.minAllocation) {
          return ctx.createError({
            message: i18n.t('errors.notLessThanOrEqualTo', {
              name: i18n.t('notionalAccountValueShort'),
              lessThan: i18n.t('minAllocation'),
              equal: '$0',
            }),
          });
        }
        if (value && value > ONE_HUNDRED_BILLION) {
          return ctx.createError({
            message: i18n.t('errors.notMoreThan', {
              name: i18n.t('notionalAccountValueShort'),
              value: '$100B',
            }),
          });
        }
        if (value && value > this.parent.capacity) {
          return ctx.createError({
            message: i18n.t('errors.notMoreThanOrEqualTo', {
              name: i18n.t('notionalAccountValueShort'),
              largerThan: i18n.t('aumCapacity'),
              equal: '$0',
            }),
          });
        }
        return true;
      },
    })
    .required(
      i18n.t('errors.required', { name: i18n.t('notionalAccountValueShort') })
    ),
});
