import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme: { colors } }) => colors.surface};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 76px;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LogoContainer = styled.div`
  width: 34px;
  height: 24px;
  margin-left: 24px;
  margin-top: 26px;
`;

export const SignInOption = styled.a`
  display: flex;
  gap: 24px;
  border: 0.5px solid ${({ theme: { colors } }) => colors.outline};
  border-radius: 4px;
  padding: 16px 24px;
  width: 384px;
  height: 56px;
  cursor: pointer;
  text-decoration: none;
`;

export const OptionText = styled.p`
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;

  color: ${({ theme: { colors } }) => colors.primary};
`;

export const OtherSsoOption = styled.a`
  margin: 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0.15px;
  color: ${({ theme: { colors } }) => colors.onSurface};
  opacity: 0.6;
  text-decoration: none;
`;

export const OptionLogo = styled.div``;
