import { routes } from 'constants/routes';
import i18n from 'translations/i18n';

export const menuItems = [
  {
    key: 'saraPage',
    path: routes.MONITOR.path,
    name: i18n.t('sara'),
  },
  {
    key: 'strategyLibraryPage',
    path: routes.FEATURES.path,
    name: i18n.t('strategyLibrary'),
  },
];
