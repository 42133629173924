import {
  Expenses,
  ExpensesWarnings,
  PortfolioPerformanceWarnings,
} from 'services/fundCalculator/fundCalculator.types';
import { Portfolio } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';

export const getExpensesWidgetData = (
  portfolio: Portfolio,
  warnings?: PortfolioPerformanceWarnings
): {
  data: Expenses;
  warnings: ExpensesWarnings;
} => {
  return {
    data: {
      platformFixedExpensesPct: portfolio?.platformFixedExpensesPct,
      pmFixedExpensesPct: portfolio?.pmFixedExpensesPct,
      pmPerformanceFeesPct: portfolio?.pmPerformanceFeesPct,
      expensesForRunningFundPct: portfolio?.expensesForRunningFundPct,
      caPerfFeePct: portfolio?.caPerfFeePct,
      totalInvestorExpensesPct: portfolio?.totalInvestorExpensesPct,
      fixedTotalFeesPct: portfolio?.fixedTotalFeesPct,
      variableTotalFeesPct: portfolio?.variableTotalFeesPct,
    },
    warnings: {
      platformFixedExpensesPct: warnings?.portfolio?.platformFixedExpensesPct,
      pmFixedExpensesPct: warnings?.portfolio?.pmFixedExpensesPct,
      pmPerformanceFeesPct: warnings?.portfolio?.pmPerformanceFeesPct,
      expensesForRunningFundPct: warnings?.portfolio?.expensesForRunningFundPct,
      caPerfFeePct: warnings?.portfolio?.caPerfFeePct,
      totalInvestorExpensesPct: warnings?.portfolio?.totalInvestorExpensesPct,
      fixedTotalFeesPct: warnings?.portfolio?.fixedTotalFeesPct,
      variableTotalFeesPct: warnings?.portfolio?.variableTotalFeesPct,
    },
  };
};
