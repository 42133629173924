import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReadonlyData, SensitiveDataWrapper } from '@clearalpha/common';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { ManagerFormFields } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.constants';
import {
  StatName,
  StatRow,
} from 'components/PortfolioManagerDetails/PortfolioManagerDetails.styled';
import { FormikSelectEntityAttributes } from 'components/formik/fields';
import { isPMEditModeSelector } from 'store/slices/portfolioManagers';
import { getPortfolioManagerFirms } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import { GetPortfolioManagerFirm } from 'services/portfolioManagerCore/portfolioManagerCore.type';

type CurrentFirmProps = {
  currentFirm?: GetPortfolioManagerFirm | null;
};

const CurrentFirm = ({ currentFirm }: CurrentFirmProps) => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isPMEditModeSelector);
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);

  return (
    <StatRow>
      <StatName>{t('currentFirm')}</StatName>
      {isEditMode ? (
        <FormikSelectEntityAttributes
          name={ManagerFormFields.CURRENT_FIRM}
          getData={getPortfolioManagerFirms}
        />
      ) : (
        <SensitiveDataWrapper isVisible={isVisibleSensitiveData}>
          <ReadonlyData>{currentFirm?.name}</ReadonlyData>
        </SensitiveDataWrapper>
      )}
    </StatRow>
  );
};

export default CurrentFirm;
