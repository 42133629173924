import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextFieldWrapper, NumericTextCurrency } from '@clearalpha/common';
import FormikCurrencyNumbers from 'components/formik/fields/FormikCurrencyNumbers';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { FieldLabel, FieldWrapper } from '../../styled';
import { StrategyFormFields } from '../../../StrategyDetails.constants';
import { StrategyDetailsFormFields } from '../../../StrategyDetails.type';

const NotionalAccountValue = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.productionValues.workingNotionalAccountValue !==
      values.account_value;

  return (
    <>
      <FieldLabel>{t('notionalAccountValueShort')}</FieldLabel>
      <NumericTextCurrency
        value={values.productionValues.workingNotionalAccountValue}
      />
      {!isPortfolioReviewMode ? (
        <FormikCurrencyNumbers
          name={StrategyFormFields.ACCOUNT_VALUE}
          customInput={TextFieldWrapper}
        />
      ) : (
        <FieldWrapper className={isValueHighlighted ? 'highlighted' : ''}>
          <NumericTextCurrency value={values.account_value} />
        </FieldWrapper>
      )}
    </>
  );
};

export default NotionalAccountValue;
