import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { ReadonlyData } from '@clearalpha/common';
import { assetCategoriesSelector } from 'store/slices/strategies';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { fetchAssetCategories } from 'store/thunks/fetchAssetCategories';
import { FormikSelect } from 'components/formik/fields';
import { FieldLabel, FieldWrapper } from '../../styled';
import { StrategyFormFields } from '../../../StrategyDetails.constants';
import { StrategyDetailsFormFields } from '../../../StrategyDetails.type';

const StrategyCategory = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const assetCategories = useSelector(assetCategoriesSelector);
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.productionValues.assetCategory !== values.assetCategory;

  useEffect(() => {
    if (!assetCategories.length) {
      dispatch(fetchAssetCategories());
    }
  }, [dispatch, assetCategories.length]);

  const options = useMemo(
    () =>
      assetCategories.reduce((acc: { [key: string]: string }, current) => {
        acc[current.id] = current.name;
        return acc;
      }, {}),
    [assetCategories]
  );

  const productionValue = assetCategories.find(
    ({ id }) => id === values.productionValues.assetCategory
  );

  return (
    <>
      <FieldLabel>{t('strategyCategory')}</FieldLabel>
      <ReadonlyData>{productionValue?.name || t('empty')}</ReadonlyData>
      {!isPortfolioReviewMode ? (
        <FormikSelect
          name={StrategyFormFields.ASSET_CATEGORY}
          options={options}
        />
      ) : (
        <FieldWrapper className={isValueHighlighted ? 'highlighted' : ''}>
          <ReadonlyData>
            {
              assetCategories.find(
                (assetCategory) => assetCategory.id === values.assetCategory
              )?.name
            }
          </ReadonlyData>
        </FieldWrapper>
      )}
    </>
  );
};

export default StrategyCategory;
