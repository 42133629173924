import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Tooltip } from '@clearalpha/common';
import {
  isLoadingPMsSelector,
  portfolioManagersSelector,
} from 'store/slices/portfolioManagers';
import { GetPortfolioManager } from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { GlobalStrategiesResponse } from 'services/strategyCatalog/strategyCatalog.type';
import { globalStrategiesSelector } from 'store/slices/strategies';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import {
  STRATEGIES_LIST_COLUMNS,
  StrategyListConfigParams,
  StrategyRow,
} from './GlobalStrategiesList.type';
import { ClickableContainer } from '../StrategyLibrary.styled';

export const sortStrategies = (strategies: GlobalStrategiesResponse[]) => {
  return [...strategies].sort((a, b) => {
    const strategyA = a.name.toLowerCase();
    const strategyB = b.name.toLowerCase();

    if (strategyA > strategyB) return 1;

    if (strategyA < strategyB) return -1;

    return 0;
  });
};

export const useStrategiesListConfig = (
  handleOpenGlobalStrategyDrawer: (strategyId: string) => void
): StrategyListConfigParams => {
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);
  const isLoadingPMs = useSelector(isLoadingPMsSelector);

  const strategies: GlobalStrategiesResponse[] = useSelector(
    globalStrategiesSelector
  );
  const managers: GetPortfolioManager[] = useSelector(
    portfolioManagersSelector
  );

  const rows: StrategyRow[] = useMemo(() => {
    if (isLoadingPMs) return [];

    return sortStrategies(strategies).map((entity) => {
      const { id, name, assetCategory } = entity;

      return {
        id,
        name,
        category: assetCategory.name,
      };
    });
  }, [strategies, managers, isLoadingPMs]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: STRATEGIES_LIST_COLUMNS.STRATEGY.fieldName,
        headerName: STRATEGIES_LIST_COLUMNS.STRATEGY.headerName,
        width: 400,
        sortable: true,
        renderCell: ({ row: { name, id } }) => (
          <ClickableContainer
            onClick={() => handleOpenGlobalStrategyDrawer(id)}
          >
            <Tooltip title={name} placement='top' arrow>
              <div>{name}</div>
            </Tooltip>
          </ClickableContainer>
        ),
      },
      {
        field: STRATEGIES_LIST_COLUMNS.CATEGORY.fieldName,
        headerName: STRATEGIES_LIST_COLUMNS.CATEGORY.headerName,
        width: 210,
        sortable: true,
      },
    ],
    [isVisibleSensitiveData, handleOpenGlobalStrategyDrawer]
  );

  return {
    rows,
    columns,
  };
};
