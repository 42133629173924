import {
  AnyAction,
  combineReducers,
  configureStore,
  PreloadedState,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
// import logger from 'redux-logger';
import { listenerMiddleware } from './middleware';
import { settingsReducer, SETTINGS_SLICE_NAME } from './slices/settings';
import { teamsReducer, TEAMS_SLICE_NAME } from './slices/teams';
import { strategiesReducer, STRATEGIES_SLICE_NAME } from './slices/strategies';
import { portfoliosReducer, PORTFOLIOS_SLICE_NAME } from './slices/portfolios';
import { userReducer, USER_SLICE_NAME } from './slices/user';
import {
  portfolioManagersReducer,
  PORTFOLIO_MANAGERS_SLICE_NAME,
} from './slices/portfolioManagers';
import {
  changeRequestsReducer,
  CHANGE_REQUESTS_SLICE_NAME,
} from './slices/changeRequests';
import {
  PERMISSIONS_SLICE_NAME,
  permissionsReducer,
} from './slices/permissions';

const rootReducer = combineReducers({
  [TEAMS_SLICE_NAME]: teamsReducer,
  [SETTINGS_SLICE_NAME]: settingsReducer,
  [PORTFOLIOS_SLICE_NAME]: portfoliosReducer,
  [STRATEGIES_SLICE_NAME]: strategiesReducer,
  [PORTFOLIO_MANAGERS_SLICE_NAME]: portfolioManagersReducer,
  [CHANGE_REQUESTS_SLICE_NAME]: changeRequestsReducer,
  [USER_SLICE_NAME]: userReducer,
  [PERMISSIONS_SLICE_NAME]: permissionsReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        // logger, // TODO: Use for local development only when needed
        listenerMiddleware.middleware,
      ]),
    preloadedState,
  });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
