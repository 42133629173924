import { List } from '@clearalpha/common';
import { ListContainer } from 'components/StrategyLibrary/StrategyLibrary.styled';
import { useManageGroupsListConfig } from './ManageGroups.helper';

const ManageGroupsList = () => {
  const { rows, columns } = useManageGroupsListConfig();

  return (
    <ListContainer>
      <List
        columns={columns}
        rows={rows}
        checkboxSelection={false}
        columnBuffer={columns.length}
      />
    </ListContainer>
  );
};

export default ManageGroupsList;
