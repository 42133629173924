import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReadonlyData } from '@clearalpha/common';
import { ManagerFormFields } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.constants';
import {
  StatName,
  StatRow,
} from 'components/PortfolioManagerDetails/PortfolioManagerDetails.styled';
import { FormikSelectEntityAttributes } from 'components/formik/fields';
import { getPortfolioManagerStrategyExpertises } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import { GetPortfolioManagerStrategyExpertise } from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { isPMEditModeSelector } from 'store/slices/portfolioManagers';

type StrategyExpertiseProps = {
  expertises?: GetPortfolioManagerStrategyExpertise[] | null;
};

const StrategyExpertise = ({ expertises }: StrategyExpertiseProps) => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isPMEditModeSelector);

  return (
    <StatRow>
      <StatName>{t('strategyExpertise')}</StatName>
      {isEditMode ? (
        <FormikSelectEntityAttributes
          name={ManagerFormFields.STRATEGY_EXPERTISE}
          getData={getPortfolioManagerStrategyExpertises}
          isMultiple
        />
      ) : (
        <ReadonlyData>
          {expertises?.map((exp) => exp.name).join(', ')}
        </ReadonlyData>
      )}
    </StatRow>
  );
};

export default StrategyExpertise;
