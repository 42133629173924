const EST_OFFSET = -300;
const MINUTES = 60;

const getTimestampSeconds = () => Math.floor(Date.now() / 1000);

const getEstDateString = () => {
  const today = new Date();
  const todayEst = new Date(today.getTime() + EST_OFFSET * MINUTES * 1000);
  const month = (todayEst.getMonth() + 1).toString();
  const formattedMonth = month.length === 1 ? `0${month}` : month;

  return `${formattedMonth}${todayEst.getDate()}${todayEst.getFullYear()}`;
};

export { getTimestampSeconds, getEstDateString };
