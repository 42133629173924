import { BasicBreadcrumbs } from '@clearalpha/common';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export const BreadcrumbsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 16px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.whiteOpacity70};
  text-decoration: none;
`;

export const CurrentPage = styled(Typography)`
  color: ${({ theme }) => theme.colors.whiteOpacity70};
  font-size: 24px;
`;

export const StyledBreadcrumbs = styled(BasicBreadcrumbs)`
  .MuiBreadcrumbs-separator {
    color: ${({ theme }) => theme.colors.whiteOpacity70};
    font-size: 24px;
  }
`;

export const Back = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BreadcrumbsText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.greyOne};
  font-size: 24px;
`;
