import {
  GetPortfolioManager,
  GetPortfolioManagerRole,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import {
  getManagerFullName,
  sortManagers,
} from 'components/StrategyLibrary/PortfolioManagerList/PortfolioManagerList.helpers';
import { TeamMemberOption } from '../../TeamDetails.types';

export const membersToOptions = (
  managers: GetPortfolioManager[],
  roles: GetPortfolioManagerRole[]
): TeamMemberOption[] => {
  if (!managers?.length || !roles?.length) return [];
  // TODO: Update other roles than PMs will be implemented
  const DEFAULT_ROLE_NAME = 'Portfolio Manager';
  const defaultRole = roles.find(({ name }) => name === DEFAULT_ROLE_NAME);

  return sortManagers(managers).map((manager) => ({
    id: manager.id,
    label: getManagerFullName(manager),
    roleName: DEFAULT_ROLE_NAME,
    roleId: defaultRole?.id as string,
  }));
};
