import { get, post, put, remove } from 'services/http';
import { config } from 'config';
import {
  GetPortfolioManager,
  GetPortfolioManagerFirm,
  GetPortfolioManagerRole,
  GetPortfolioManagerSchool,
  GetPortfolioManagerStatus,
  GetPortfolioManagerStrategyExpertise,
  GetTeam,
  GetTeamStatus,
  GetTeamType,
  ManagerEntityRequest,
  TeamEntityRequest,
} from './portfolioManagerCore.type';

const rootUrl = `${config.api.portfolioManagerCore}/portfolio-manager-core/api/v3`;

const PortfolioManagerCoreApi = {
  PORTFOLIO_MANAGER: `${rootUrl}/portfolio-manager`,
  PORTFOLIO_MANAGERS: `${rootUrl}/portfolio-managers`,
  TEAM: `${rootUrl}/portfolio-manager-team`,
  TEAMS: `${rootUrl}/portfolio-manager-teams`,
};

export const getPortfolioManagerFirms = async (): Promise<
  GetPortfolioManagerFirm[]
> => get(`${PortfolioManagerCoreApi.PORTFOLIO_MANAGER}/firms`);

export const getPortfolioManagerRoles = async (): Promise<
  GetPortfolioManagerRole[]
> => get(`${PortfolioManagerCoreApi.PORTFOLIO_MANAGER}/roles`);

export const getPortfolioManagerSchools = async (): Promise<
  GetPortfolioManagerSchool[]
> => get(`${PortfolioManagerCoreApi.PORTFOLIO_MANAGER}/schools`);

export const getPortfolioManagerStrategyExpertises = async (): Promise<
  GetPortfolioManagerStrategyExpertise[]
> => get(`${PortfolioManagerCoreApi.PORTFOLIO_MANAGER}/strategy-expertises`);

export const getPortfolioManagerStatuses = async (): Promise<
  GetPortfolioManagerStatus[]
> => get(`${PortfolioManagerCoreApi.PORTFOLIO_MANAGER}/statuses`);

export const getPortfolioManagers = async (): Promise<GetPortfolioManager[]> =>
  get(PortfolioManagerCoreApi.PORTFOLIO_MANAGERS);

export const getPortfolioManagerById = async (
  id: string
): Promise<GetPortfolioManager> =>
  get(`${PortfolioManagerCoreApi.PORTFOLIO_MANAGERS}/${id}`);

export const createPortfolioManager = async (
  data: ManagerEntityRequest
): Promise<GetPortfolioManager> =>
  post(PortfolioManagerCoreApi.PORTFOLIO_MANAGERS, data);

export const updatePortfolioManager = async (
  id: string,
  data: ManagerEntityRequest
): Promise<GetPortfolioManager> =>
  put(`${PortfolioManagerCoreApi.PORTFOLIO_MANAGERS}/${id}`, data);

export const deletePortfolioManager = async (id: string): Promise<string> =>
  remove(`${PortfolioManagerCoreApi.PORTFOLIO_MANAGERS}/${id}`);

export const getTeamStatuses = async (): Promise<GetTeamStatus[]> =>
  get(`${PortfolioManagerCoreApi.TEAM}/statuses`);

export const getTeamTypes = async (): Promise<GetTeamType[]> =>
  get(`${PortfolioManagerCoreApi.TEAM}/types`);

export const createTeam = async (data: TeamEntityRequest): Promise<GetTeam> =>
  post(PortfolioManagerCoreApi.TEAMS, data);

export const updateTeam = async (
  id: string,
  data: TeamEntityRequest
): Promise<GetTeam> => put(`${PortfolioManagerCoreApi.TEAMS}/${id}`, data);

export const deleteTeam = async (id: string): Promise<string> =>
  remove(`${PortfolioManagerCoreApi.TEAMS}/${id}`);

export const getTeams = async (): Promise<GetTeam[]> =>
  get(PortfolioManagerCoreApi.TEAMS);
