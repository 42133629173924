import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { routes } from 'constants/routes';
import { useAppDispatch } from 'store';
import { setRedirectTo } from 'store/slices/user';
import { useSelector } from 'react-redux';
import { isUserSignedInSelector } from 'store/slices/user/user.selectors';
import { ProtectedRouteProps } from './ProtectedRoute.type';

const ProtectedRoute = ({ isAllowed, children }: ProtectedRouteProps) => {
  const isSignedIn = useSelector(isUserSignedInSelector);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setRedirectTo(pathname));
  }, [dispatch, pathname]);

  if (!isSignedIn) {
    return <Navigate to={`/${routes.WELCOME.path}`} />;
  }

  if (!isAllowed) {
    return <Navigate to={`/${routes.NO_ACCESS.path}`} />;
  }

  return children || <div />;
};

export default ProtectedRoute;
