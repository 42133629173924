export enum StrategyFormFields {
  TEAM = 'team',
  NAME = 'name',
  DESCRIPTION = 'description',
  STRATEGY_EDGE = 'strategy_edge',
  SCIENTIST = 'scientist',
  ENGINEER = 'engineer',
  ACTUARY = 'actuary',
  GROSS_SHARPE = 'gross_sharpe',
  GROSS_SHARPE_HISTORIC = 'gross_sharpe_historic',
  MARGIN_TYPE = 'margin_type',
  DESCRIPTION_SHORT = 'description_short',
  CATEGORY = 'category',
  AVERAGE_HOLD_PERIOD = 'average_hold_period',
  ALLOCATION_MIN = 'allocation_min',
  ALLOCATION_TERMS = 'allocation_terms',
  STRATEGY_VOLATILITY_TARGET = 'strategy_volatility_target',
  VOLATILITY_CAPACITY = 'volatility_capacity',
  EXPENSES = 'expenses',
  STATUS = 'status',
  COPY_OF_ID = 'copyOfId',
  COPY_OF_VERSION = 'copyOfVersion',
}
