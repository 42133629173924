import { createSlice } from '@reduxjs/toolkit';
import {
  SETTINGS_SLICE_NAME,
  SETTINGS_SLICE_INITIAL_STATE,
} from './settings.constants';
import { SettingsState } from './settings.types';

const settings = createSlice({
  name: SETTINGS_SLICE_NAME,
  initialState: SETTINGS_SLICE_INITIAL_STATE,
  reducers: {
    toggleSensitiveDataVisibility: (state: SettingsState) => {
      state.isVisibleSensitiveData = !state.isVisibleSensitiveData;
    },
  },
});

export const { toggleSensitiveDataVisibility } = settings.actions;

export default settings.reducer;
