import { AbstractLayout, SaraWidgets } from '@clearalpha/common';
import StrategyList from './StrategyList';
import ExpensesWidget from './ExpensesWidget/ExpensesWidget';
import AverageHoldPeriodWidget from './AverageHoldPeriodWidget/AverageHoldPeriodWidget';
import FundOverviewWidget from './FundOverviewWidget/FundOverviewWidget';
import PnLBreakdownWidget from './PnLBreakdownWidget/PnLBreakdownWidget';
import StrategyCategoryWidget from './StrategyCategoryWidget/StrategyCategoryWidget';
import RiskAllocationWidget from './RiskAllocationWidget/RiskAllocationWidget';
import AlphaArchetypeWidget from './AlphaArchetypeWidget/AlphaArchetypeWidget';
import ProjectedPerformanceStatsWidget from './ProjectedPerformanceStatsWidget/ProjectedPerformanceStatsWidget';
import StrategyAllocationWidget from './StrategyAllocationWidget/StrategyAllocationWidget';

export const createWidgetsWithData = (widgetsConfig: AbstractLayout[]) => {
  const getWidgetId = (type: SaraWidgets) =>
    widgetsConfig.find((current) => current.type === type)?.id;

  const result = {
    [SaraWidgets.FUND_OVERVIEW]: {
      key: getWidgetId(SaraWidgets.FUND_OVERVIEW),
      component: <FundOverviewWidget />,
    },
    [SaraWidgets.PROJECTED_PERFORMANCE_STATISTICS]: {
      key: getWidgetId(SaraWidgets.PROJECTED_PERFORMANCE_STATISTICS),
      component: <ProjectedPerformanceStatsWidget />,
    },
    [SaraWidgets.ALPHA_ARCHETYPE]: {
      key: getWidgetId(SaraWidgets.ALPHA_ARCHETYPE),
      component: <AlphaArchetypeWidget />,
    },
    [SaraWidgets.ASSET_CATEGORY]: {
      key: getWidgetId(SaraWidgets.ASSET_CATEGORY),
      component: <StrategyCategoryWidget />,
    },
    [SaraWidgets.RISK_ALLOCATION]: {
      key: getWidgetId(SaraWidgets.RISK_ALLOCATION),
      component: <RiskAllocationWidget />,
    },
    [SaraWidgets.PNL_BREAKDOWN]: {
      key: getWidgetId(SaraWidgets.PNL_BREAKDOWN),
      component: <PnLBreakdownWidget />,
    },
    [SaraWidgets.AVERAGE_HOLD_PERIOD]: {
      key: getWidgetId(SaraWidgets.AVERAGE_HOLD_PERIOD),
      component: <AverageHoldPeriodWidget />,
    },
    [SaraWidgets.EXPENSES]: {
      key: getWidgetId(SaraWidgets.EXPENSES),
      component: <ExpensesWidget />,
    },
    [SaraWidgets.VOLATILITY]: {
      key: getWidgetId(SaraWidgets.VOLATILITY),
      component: <StrategyAllocationWidget />,
    },
    [SaraWidgets.STRATEGY_LIST]: {
      key: getWidgetId(SaraWidgets.STRATEGY_LIST),
      component: <StrategyList />,
    },
  };

  return widgetsConfig.map(({ type }) => result[type as SaraWidgets]);
};
