import styled from '@emotion/styled';

const ColumnHeader = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
  display: flex;
  justify-content: end;
  :first-child {
    padding-right: 16px;
  }
  svg {
    margin-left: 9px;
  }
`;

const ColumnTitle = styled.p`
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  margin: 0;
`;

export { ColumnHeader, ColumnTitle };
