import { useEffect } from 'react';
import i18n from 'translations/i18n';
import { useAppDispatch } from 'store';
import { fetchGlobalStrategies, fetchTeams } from 'store/thunks';
import ManageGroupsList from 'components/ManageGroupsList/ManageGroupsList';
import SaraBreadcrumbs from 'components/SaraBreadcrumbs/SaraBreadcrumbs';
import {
  Back,
  BreadcrumbsText,
  StyledLink,
} from 'components/SaraBreadcrumbs/SaraBreadcrumbs.styled';
import { routes } from 'constants/routes';
import { useSelector } from 'react-redux';
import { globalStrategiesSelector } from 'store/slices/strategies';
import { LayoutWrapper, Title, TitleAndContent } from './ManageGroups.styled';

const ManageGroups = () => {
  const { t } = i18n;
  const dispatch = useAppDispatch();

  const globalStrategies = useSelector(globalStrategiesSelector);

  useEffect(() => {
    if (!globalStrategies.length) {
      dispatch(fetchGlobalStrategies());
    }
  }, [globalStrategies.length, dispatch]);

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);

  const items = [
    <StyledLink key='home' color='inherit' to={`/${routes.MONITOR.path}`}>
      <Back>
        <BreadcrumbsText>{t('sara')}</BreadcrumbsText>
      </Back>
    </StyledLink>,
  ];

  return (
    <LayoutWrapper>
      <TitleAndContent>
        <SaraBreadcrumbs
          backButtonUrl={`/${routes.MONITOR.path}`}
          items={items}
        />
        <Title variant='h2'>{t('manageStrategyGroups')}</Title>
      </TitleAndContent>
      <ManageGroupsList />
    </LayoutWrapper>
  );
};

export default ManageGroups;
