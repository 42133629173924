import React from 'react';
import { List } from '@clearalpha/common';
import { ListContainer } from 'components/StrategyLibrary/StrategyLibrary.styled';
import useTeamsList from './TeamsList.helpers';
import StrategyLibraryContent from '../../StrategyLibrary/StrategyLibraryContent';

const TeamsList = () => {
  const { rows, columns } = useTeamsList();

  return (
    <StrategyLibraryContent>
      <ListContainer>
        <List
          columns={columns}
          rows={rows}
          checkboxSelection={false}
          columnBuffer={columns.length}
        />
      </ListContainer>
    </StrategyLibraryContent>
  );
};

export default TeamsList;
