import { SensitiveDataWrapper, Tooltip } from '@clearalpha/common';
import React from 'react';
import { ClickableContainer } from '../StrategyLibrary.styled';
import { StrategyLibraryCellProps } from './StrategyLibraryCell.type';

const StrategyLibraryCell = ({
  isClickableCell,
  isVisible,
  content,
  handleClick,
}: StrategyLibraryCellProps) => {
  return isClickableCell && handleClick ? (
    <ClickableContainer onClick={handleClick}>
      <SensitiveDataWrapper isVisible={isVisible}>
        <Tooltip title={content} placement='top' arrow>
          <div>{content}</div>
        </Tooltip>
      </SensitiveDataWrapper>
    </ClickableContainer>
  ) : (
    <SensitiveDataWrapper isVisible={isVisible}>
      <Tooltip title={content} placement='top' arrow>
        <div>{content}</div>
      </Tooltip>
    </SensitiveDataWrapper>
  );
};

export default StrategyLibraryCell;
