import { View, Text } from '@react-pdf/renderer';
import { styles } from '../PdfReportTable/PdfReportTable.styled';
import { formatSourceAndTargetValues } from '../PdfReportTable/PdfReportTable.helpers';
import { PdfReportStrategiesTableProps } from './PdfReportStrategiesTable.type';

const PdfReportStrategiesTable = ({
  config,
  name,
}: PdfReportStrategiesTableProps) => {
  const { formattedSourceValue, formattedTargetValue } =
    formatSourceAndTargetValues(config);

  return (
    <View style={styles.tableContainer}>
      <View style={styles.row}>
        <Text style={styles.description}>{name}</Text>
        <Text style={styles.value}>{formattedSourceValue}</Text>
        <Text style={styles.value}>{formattedTargetValue}</Text>
      </View>
    </View>
  );
};

export { PdfReportStrategiesTable };
