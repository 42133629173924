import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { ReadonlyData } from '@clearalpha/common';
import { marginTypesSelector } from 'store/slices/strategies';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { fetchMarginTypes } from 'store/thunks/fetchMarginTypes';
import { FormikSelect } from 'components/formik/fields';
import { FieldLabel, FieldWrapper } from '../../styled';
import { StrategyFormFields } from '../../../StrategyDetails.constants';
import { StrategyDetailsFormFields } from '../../../StrategyDetails.type';

const MarginType = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<StrategyDetailsFormFields>();
  const marginTypes = useSelector(marginTypesSelector);
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);
  const isValueHighlighted =
    isPortfolioReviewMode &&
    values.productionValues.marginType !== values.margin_type;

  useEffect(() => {
    if (!marginTypes.length) {
      dispatch(fetchMarginTypes());
    }
  }, [dispatch, marginTypes.length]);

  const options = useMemo(
    () =>
      marginTypes.reduce((acc: { [key: string]: string }, current) => {
        acc[current.id] = current.name;
        return acc;
      }, {}),
    [marginTypes]
  );

  const productionValue = marginTypes.find(
    ({ id }) => id === values.productionValues.marginType
  );

  return (
    <>
      <FieldLabel>{t('marginType')}</FieldLabel>
      <ReadonlyData>{productionValue?.name || t('empty')}</ReadonlyData>
      {!isPortfolioReviewMode ? (
        <FormikSelect name={StrategyFormFields.MARGIN_TYPE} options={options} />
      ) : (
        <FieldWrapper className={isValueHighlighted ? 'highlighted' : ''}>
          <ReadonlyData>
            {
              marginTypes.find(
                (marginType) => marginType.id === values.margin_type
              )?.name
            }
          </ReadonlyData>
        </FieldWrapper>
      )}
    </>
  );
};

export default MarginType;
