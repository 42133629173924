import { TestContext } from 'yup';
import Decimal from 'decimal.js';
import { ONE } from './validationNumbers';
import i18n from '../translations/i18n';

export const testArchetypesSum = (
  scientistPct: number,
  engineerPct: number,
  actuaryPct: number,
  ctx: TestContext
) => {
  return !new Decimal(scientistPct)
    .plus(engineerPct)
    .plus(actuaryPct)
    .equals(ONE)
    ? ctx.createError({ message: i18n.t('errors.archetypesSum') })
    : true;
};
