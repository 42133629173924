import {
  GetPortfolioResponse,
  SavePortfolioRequest,
} from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { config } from 'config';
import { get, post, put } from 'services/http';
import { VersionItem } from 'components/Dialogs/VersionListDialog/VersionListDialog.type';
import {
  StrategyGroupsResponse,
  ChangeRequestBody,
  ChangeRequestResponse,
  PortfolioFeeTypes,
} from './portfolioCore.types';

const portfolioCoreUrlV3 = `${config.api.portfolioCore}/portfolio-core/api/v3`;

const ROUTES = {
  PORTFOLIOS: `${portfolioCoreUrlV3}/portfolios`,
  PORTFOLIO_STRATEGIES: `${portfolioCoreUrlV3}/portfolio-strategies`,
  PORTFOLIO_FEE_TYPES: `${portfolioCoreUrlV3}/portfolio-fee-types`,
  PORTFOLIO_HISTORY: (uuid: string) =>
    `${portfolioCoreUrlV3}/portfolios/${uuid}/history-list`,
  GET_PORTFOLIO_VERSION: (uuid: string, version: number) =>
    `${portfolioCoreUrlV3}/portfolios/${uuid}/history/${version}`,
  CHANGE_REQUEST: `${portfolioCoreUrlV3}/change-requests`,
  APPLY_REQUEST: (uuid: string) =>
    `${portfolioCoreUrlV3}/change-requests/${uuid}/apply`,
  DECLINE_REQUEST: (uuid: string) =>
    `${portfolioCoreUrlV3}/change-requests/${uuid}/decline`,
  FILTERED_BY_TYPE_PORTFOLIOS: (type: 'SCENARIO' | 'PRODUCTION') =>
    `${portfolioCoreUrlV3}/portfolios?portfolio_type=${type}`,
  STRATEGY_GROUPS: `${portfolioCoreUrlV3}/strategy-groups`,
  STRATEGY_GROUP_FEE_TYPES: `${portfolioCoreUrlV3}/strategy-groups-fee-types`,
};

export const getPortfoliosProduction = async (): Promise<
  GetPortfolioResponse[]
> => get(ROUTES.FILTERED_BY_TYPE_PORTFOLIOS('PRODUCTION'));

export const getPortfoliosWorking = async (): Promise<GetPortfolioResponse[]> =>
  get(ROUTES.FILTERED_BY_TYPE_PORTFOLIOS('SCENARIO'));

export const getPortfolioHistory = async (id: string): Promise<VersionItem[]> =>
  get(`${ROUTES.PORTFOLIO_HISTORY(id)}`);

export const getPortfolioVersion = async (
  id: string,
  version: number
): Promise<GetPortfolioResponse> =>
  get(`${ROUTES.GET_PORTFOLIO_VERSION(id, version)}`);

export const getPortfolioById = async (
  uuid: string
): Promise<GetPortfolioResponse> => get(`${ROUTES.PORTFOLIOS}/${uuid}`);

export const createWorkingPortfolio = async (
  payload: GetPortfolioResponse
): Promise<GetPortfolioResponse> => {
  return post(ROUTES.PORTFOLIOS, payload);
};

export const updateWorkingPortfolio = async (
  payload: SavePortfolioRequest,
  uuid: string
): Promise<GetPortfolioResponse> => {
  return put(`${ROUTES.PORTFOLIOS}/${uuid}`, payload);
};

export const getChangeRequests = async (): Promise<ChangeRequestResponse[]> =>
  get(ROUTES.CHANGE_REQUEST);

export const requestApproval = async (
  data: ChangeRequestBody
): Promise<ChangeRequestResponse> => {
  return post(ROUTES.CHANGE_REQUEST, data);
};

export const applyChangeRequest = async (id: string): Promise<void> =>
  post(ROUTES.APPLY_REQUEST(id), {});

export const declineChangeRequest = async (id: string): Promise<void> =>
  post(ROUTES.DECLINE_REQUEST(id), {});

export const getAllPortfolioGroups = async (
  portfolioId: string
): Promise<StrategyGroupsResponse[]> =>
  get(`${ROUTES.STRATEGY_GROUPS}/portfolio/${portfolioId}`);

export const getPortfolioFeeTypes = async (): Promise<PortfolioFeeTypes[]> =>
  get(ROUTES.PORTFOLIO_FEE_TYPES);

export const getPortfolioStrategyGroupsFeeTypes = async (): Promise<
  PortfolioFeeTypes[]
> => get(ROUTES.STRATEGY_GROUP_FEE_TYPES);
