import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WidgetBackbone from '@clearalpha/common/dist/components/Dashboard/Widgets/WidgetBackbone/WidgetBackbone';
import TreeChart from '@clearalpha/common/dist/components/TreemapChart/TreemapChart';
import LabelIndicator from '@clearalpha/common/dist/components/LabelIndicator/LabelIndicator';
import { THEME_COLORS } from '@clearalpha/common';
import {
  productionPortfolioPerformanceSelector,
  workingPortfolioPerformanceSelector,
  sourcePortfolioPerformanceSelector,
  targetPortfolioPerformanceSelector,
  isReviewModePortfolioSelector,
} from 'store/slices/portfolios';
import {
  TreemapChartContainer,
  Wrapper,
} from './StrategyCategoryWidget.styled';
import { getStrategyCategoriesWidgetData } from './StrategyCategoryWidget.helper';

const StrategyCategoryWidget = () => {
  const { t } = useTranslation();

  const isReviewMode = useSelector(isReviewModePortfolioSelector);
  const productionPerformance = useSelector(
    productionPortfolioPerformanceSelector
  );
  const workingPerformance = useSelector(workingPortfolioPerformanceSelector);
  const sourcePerformance = useSelector(sourcePortfolioPerformanceSelector);
  const targetPerformance = useSelector(targetPortfolioPerformanceSelector);

  const currentWorkingPerformance = isReviewMode
    ? sourcePerformance
    : workingPerformance;
  const currentProdPerformance = isReviewMode
    ? targetPerformance
    : productionPerformance;

  const { data: production } = getStrategyCategoriesWidgetData(
    currentProdPerformance.portfolio.assetCategoryPct
  );

  const { data: scenario } = getStrategyCategoriesWidgetData(
    currentWorkingPerformance.portfolio.assetCategoryPct
  );

  return (
    <WidgetBackbone
      titleStyles={{ marginLeft: 16 }}
      title={t('strategyCategory')}
    >
      <Wrapper>
        <TreemapChartContainer>
          <LabelIndicator
            label={t('production')}
            indicatorColor={THEME_COLORS.brandColor}
          />
          <TreeChart data={production} />
        </TreemapChartContainer>
        <TreemapChartContainer>
          <LabelIndicator
            label={t('scenario')}
            indicatorColor={THEME_COLORS.dataEight}
          />
          <TreeChart data={scenario} />
        </TreemapChartContainer>
      </Wrapper>
    </WidgetBackbone>
  );
};

export default StrategyCategoryWidget;
