import React from 'react';
import { Svg, Path } from '@react-pdf/renderer';
import { styles } from './Logo.styled';

const Logo = () => (
  <Svg width='38' height='25' viewBox='0 0 38 25' style={styles.logo}>
    <Path
      d='M33.8103 0.8256H30.1316L27.5492 8.73598C27.2383 6.55337 25.1544 1.47526 21.0816 0.402047C17.0089 -0.671166 14.2155 1.56423 14.2155 1.56423C13.4094 0.698015 8.60219 -1.60182 4.49399 2.06615C0.306626 5.8047 -0.0294187 10.3667 0.254731 13.9231C0.538882 17.4794 2.98014 22.4253 7.14703 23.5757C9.08078 24.1139 11.1329 24.0473 13.0277 23.3849C12.4024 22.9947 11.8383 22.5141 11.3537 21.9587C10.6179 22.1859 9.83518 22.2153 9.08436 22.0439C6.96068 21.5735 4.34042 18.6634 4.11262 13.9137C3.88481 9.16407 5.13038 6.28606 6.71288 4.09292C8.29537 1.89978 11.9279 0.126154 15.3148 4.73173C15.3148 4.73173 17.335 1.35148 20.4254 1.88505C23.5159 2.41862 27.1718 7.00946 26.1651 12.9729C25.1583 18.9363 21.8969 21.9719 19.3655 22.1556C19.3655 22.1556 17.2318 22.391 15.5468 20.3206C13.4999 17.805 11.7938 12.3397 14.5738 6.01721C14.5738 6.01721 13.4102 4.43358 12.1069 3.58453C12.1069 3.58453 10.3421 6.07347 9.6023 8.9769C8.90091 11.7294 8.54422 16.6942 12.6138 21.3176C13.1445 21.8788 13.752 22.362 14.4183 22.7528C19.3383 25.4201 23.3699 23.3913 25.6573 21.1058C27.7185 19.0463 29.4968 13.9916 29.4968 13.9916L33.8103 0.8256Z'
      fill='#63D771'
    />
    <Path
      d='M36.3855 17.9432C36.3855 17.9432 35.5338 21.3492 32.9989 21.1021C30.7602 20.8835 29.6784 16.9941 29.6784 16.9941L28.5732 19.3856C28.5732 19.3856 29.2533 23.6013 32.5313 23.9902C35.8094 24.3791 37.0773 19.1593 37.2633 17.9429L36.3855 17.9432Z'
      fill='#63D771'
    />
  </Svg>
);

export { Logo };
