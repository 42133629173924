import React from 'react';
import { ActionMenu, MoreVertIcon } from '@clearalpha/common';
import { useSelector } from 'react-redux';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import {
  AdditionalFeatures,
  CardContent,
  CardHeader,
  CardTitle,
  CardWrapper,
  Divider,
} from './Card.styled';
import { CardProps } from './Card.type';

const Card = ({ menuItems, title, children, width }: CardProps) => {
  const isPortfolioReviewMode = useSelector(isReviewModePortfolioSelector);

  return (
    <CardWrapper style={{ width, height: '100%' }}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <AdditionalFeatures>
          <ActionMenu
            menuItems={menuItems}
            menuIcon={<MoreVertIcon />}
            disabled={isPortfolioReviewMode}
          />
        </AdditionalFeatures>
      </CardHeader>
      <Divider />
      <CardContent>{children}</CardContent>
    </CardWrapper>
  );
};

export default Card;
