import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog, InfoIcon } from '@clearalpha/common';

type UnsavedChangesDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  confirmAction: () => void;
  cancelAction: () => void;
};

const UnsavedChangesDialog = ({
  isOpen,
  handleClose,
  confirmAction,
  cancelAction,
}: UnsavedChangesDialogProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      handleClose={handleClose}
      icon={<InfoIcon />}
      title={t('dialogs.unsavedChanges.title')}
      message={t('dialogs.unsavedChanges.message')}
      confirmButton={{ label: t('actions.confirm'), action: confirmAction }}
      cancelButton={{ label: t('actions.cancel'), action: cancelAction }}
    />
  );
};

export default UnsavedChangesDialog;
