import { array, number, object, string } from 'yup';
import { ARCESIUM_ID, FIELD_LENGTH } from 'constants/fields';
import { TeamDetailsFormFieldName } from 'components/Teams/TeamDetails/TeamDetails.constants';
import i18n from 'translations/i18n';
import { requiredText, optionalText } from './defaultValidationRules';
import { ONE } from './validationNumbers';

export const teamValidationSchema = object().shape({
  [TeamDetailsFormFieldName.STATUS]: requiredText({
    fieldName: i18n.t('status'),
  }),
  [TeamDetailsFormFieldName.NAME]: requiredText({
    fieldName: i18n.t('teamName'),
  }),
  [TeamDetailsFormFieldName.DESCRIPTION]: optionalText({
    fieldName: i18n.t('teamDescription'),
    maxLength: FIELD_LENGTH.X4_LONG_TEXT,
  }),
  [TeamDetailsFormFieldName.ARCESIUM_ID]: optionalText({
    fieldName: ARCESIUM_ID,
    maxLength: FIELD_LENGTH.ARCESIUM_ID,
  }),
  [TeamDetailsFormFieldName.TYPE]: requiredText({
    fieldName: i18n.t('type'),
  }),
  [TeamDetailsFormFieldName.TOTAL_PERSONNEL]: number(),
  [TeamDetailsFormFieldName.INVESTMENT_PROFESSIONALS]: requiredText({
    fieldName: i18n.t('numberOfInvestments'),
  }),
  [TeamDetailsFormFieldName.MEMBERS]: array().min(
    ONE,
    i18n.t('errors.atLeastOneTeamMember')
  ),
  [TeamDetailsFormFieldName.TEAM_LEAD_ID]: string().required(
    i18n.t('errors.teamLeadRequired')
  ),
});
