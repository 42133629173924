import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon, Button, MinusIcon, TextField } from '@clearalpha/common';
import {
  FormikCurrencyNumbers,
  FormikPercentNumbers,
  FormikSelect,
  FormikText,
} from 'components/formik/fields';
import { ONE_HUNDRED, TEN_BILLION, ZERO } from 'validation/validationNumbers';
import {
  ButtonCreate,
  Content,
  CreateGroupWrapper,
  GroupDescription,
  TextWrapper,
  Title,
  Wrapper,
} from './SelectGroup.styled';
import { ButtonWrapper } from '../SelectStrategy/SelectStrategy.styled';
import { SelectGroupProps } from './SelectGroup.type';
import { StrategyFieldNames } from '../../AddNewStrategyDialog.type';

const SelectGroup = ({
  handleCancel,
  setFieldValue,
  isCreateFormVisible,
  setIsCreateFormVisible,
  options,
}: SelectGroupProps) => {
  const { t } = useTranslation();

  const toggleFormVisibility = () => {
    if (isCreateFormVisible) {
      setFieldValue(StrategyFieldNames.STRATEGY_GROUP_NAME, '');
      setFieldValue(StrategyFieldNames.STRATEGY_GROUP_MGMT_FEE, '');
      setFieldValue(StrategyFieldNames.STRATEGY_GROUP_FIXED_EXPENSES, '');
      setFieldValue(StrategyFieldNames.STRATEGY_GROUP_PERFORMANCE_FEE, '');
    } else {
      setFieldValue(StrategyFieldNames.STRATEGY_GROUP, '');
    }
    setIsCreateFormVisible(!isCreateFormVisible);
  };

  useEffect(() => {
    setIsCreateFormVisible(false);
  }, [setIsCreateFormVisible]);

  return (
    <Wrapper>
      <TextWrapper>
        <Title data-testid='strategyTitle'>{t('linkStrategyGroup')}</Title>
        <GroupDescription>
          {t('linkStrategyGroupDescription', { name: 'Example' })}
        </GroupDescription>
      </TextWrapper>
      <Content>
        {!isCreateFormVisible && (
          <FormikSelect
            label={t('strategyGroup')}
            name={StrategyFieldNames.STRATEGY_GROUP}
            options={options}
          />
        )}
        <ButtonCreate
          onClick={toggleFormVisibility}
          variant='text'
          startIcon={isCreateFormVisible ? <MinusIcon /> : <AddIcon />}
        >
          {t('createStrategyGroup')}
        </ButtonCreate>
        {isCreateFormVisible && (
          <CreateGroupWrapper>
            <FormikText
              label={t('createGroupFields.name')}
              name={StrategyFieldNames.STRATEGY_GROUP_NAME}
            />
            <FormikPercentNumbers
              placeholder={t('createGroupFields.mgmtFee')}
              name={StrategyFieldNames.STRATEGY_GROUP_MGMT_FEE}
              customInput={TextField}
              maxLimit={ONE_HUNDRED}
              minLimit={ZERO}
            />
            <FormikPercentNumbers
              name={StrategyFieldNames.STRATEGY_GROUP_PERFORMANCE_FEE}
              placeholder={t('createGroupFields.perfFee')}
              customInput={TextField}
              maxLimit={ONE_HUNDRED}
              minLimit={ZERO}
            />
            <FormikCurrencyNumbers
              placeholder={t('createGroupFields.fixedExp')}
              name={StrategyFieldNames.STRATEGY_GROUP_FIXED_EXPENSES}
              customInput={TextField}
              maxLimit={TEN_BILLION}
              minLimit={ZERO}
            />
          </CreateGroupWrapper>
        )}
      </Content>
      <ButtonWrapper>
        <Button onClick={handleCancel} variant='text'>
          {t('actions.cancel')}
        </Button>
        <Button data-testid='assignGroup' type='submit' variant='filled'>
          {t('assign')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default SelectGroup;
