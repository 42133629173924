import React from 'react';
import { useSelector } from 'react-redux';
import { isReviewModePortfolioSelector } from 'store/slices/portfolios';
import { Field } from 'formik';
import { CorrelationCellProps } from './CorrelationsDialog.type';
import { MainDiagonalField } from './CorrelationsDialog.styled';
import CorrelationField from './CorrelationField';

const CorrelationCell = ({
  yIndex,
  rowsLength,
  xIndex,
  columnsLength,
}: CorrelationCellProps) => {
  const isMainDiagonal = yIndex === xIndex;
  const isLastCell = yIndex === rowsLength - 1 && xIndex === columnsLength - 1;
  const isInputDisabled = useSelector(isReviewModePortfolioSelector);

  return (
    <>
      {isMainDiagonal && <MainDiagonalField>1</MainDiagonalField>}
      <Field
        name={`correlations.${yIndex}.correlations.${xIndex}.correlation`}
        xIndex={xIndex}
        yIndex={yIndex}
        disabled={yIndex > xIndex || isInputDisabled}
        component={CorrelationField}
      />
      {isLastCell && <MainDiagonalField>1</MainDiagonalField>}
    </>
  );
};

export default CorrelationCell;
