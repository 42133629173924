import React from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { Box } from '@mui/material';
import { SensitiveDataButton } from '@clearalpha/common';
import { useTranslation } from 'react-i18next';
import { isTeamsLoadingSelector } from 'store/slices/teams';
import { FormikAutocomplete, FormikValue } from 'components/formik/fields';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import { isGlobalStrategyEditModeSelector } from 'store/slices/strategies';
import { PmTeamProps } from './PmTeam.type';
import { TeamOption } from '../../GlobalStrategyDetails.type';
import { StrategyFormFields } from '../../GlobalStrategyDetails.constants';
import { Button } from './PmTeam.styled';

const PmTeam = ({ handleOpenTeam, teamOptions }: PmTeamProps) => {
  const { t } = useTranslation();
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);
  const isTeamsLoading = useSelector(isTeamsLoadingSelector);
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);
  const [{ value: team }] = useField(StrategyFormFields.TEAM);

  return (
    <Box mb='20px'>
      {isEditMode ? (
        <FormikAutocomplete<TeamOption>
          options={teamOptions}
          label={t('team')}
          name={StrategyFormFields.TEAM}
          disabled={isTeamsLoading}
        />
      ) : (
        <SensitiveDataButton
          isVisible={isVisibleSensitiveData}
          onClick={() => handleOpenTeam(team.id)}
          alignItems='flex-start'
        >
          <Button variant='text' onClick={() => handleOpenTeam(team.id)}>
            <FormikValue
              name={StrategyFormFields.TEAM}
              format={(value) => value?.label}
            />
          </Button>
        </SensitiveDataButton>
      )}
    </Box>
  );
};

export default PmTeam;
