import { TeamMemberOption } from '../../TeamDetails.types';
import { TeamMember } from './TeamMemberList.types';

export const formatMembers = (members: TeamMemberOption[]): TeamMember[] => {
  const sortedMembers = [...members].sort((memberA, memberB) => {
    const managerA = memberA.label.toLowerCase();
    const managerB = memberB.label.toLowerCase();

    if (managerA > managerB) return 1;

    if (managerA < managerB) return -1;

    return 0;
  });

  return sortedMembers.map(({ label, id }) => ({
    name: label,
    uuid: id,
    // TODO: Update with correct member role when roles will be implemented
    role: 'Portfolio manager',
  }));
};
