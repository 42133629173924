import { createAsyncThunk } from '@reduxjs/toolkit';
import { Strategy } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import { createStrategyBaseEntity } from 'services/strategyCatalog/strategyCatalog.service';
import { BaseStrategyEntityRequest } from 'services/strategyCatalog/strategyCatalog.type';
import { RootState } from 'store';
import { setIsLoading } from 'store/slices/portfolios/portfolios.slice';

export const createStrategyBase = createAsyncThunk<
  Strategy | null,
  { strategyData: BaseStrategyEntityRequest },
  { state: RootState }
>('createStrategyBase', async ({ strategyData }, { dispatch }) => {
  dispatch(setIsLoading(true));

  try {
    const strategyBase = await createStrategyBaseEntity(strategyData);
    return strategyBase;
  } catch {
    return null;
  } finally {
    dispatch(setIsLoading(false));
  }
});
