import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { createGlobalStrategy } from 'services/strategyCatalog/strategyCatalog.service';
import { fetchGlobalStrategies } from 'store/thunks';
import {
  GlobalStrategiesRequest,
  GlobalStrategiesResponse,
} from 'services/strategyCatalog/strategyCatalog.type';
import {
  addNewGlobalStrategy,
  setIsGlobalStrategiesLoading,
} from '../slices/strategies';

export const createNewGlobalStrategy = createAsyncThunk<
  GlobalStrategiesResponse | any,
  {
    newStrategy: GlobalStrategiesRequest;
  },
  { state: RootState }
>('createNewGlobalStrategy', async ({ newStrategy }, { dispatch }) => {
  dispatch(setIsGlobalStrategiesLoading(true));

  try {
    const newGlobalStrategy = await createGlobalStrategy(newStrategy);

    dispatch(addNewGlobalStrategy(newGlobalStrategy));

    dispatch(fetchGlobalStrategies());

    return newGlobalStrategy;
  } catch (error) {
    return error;
  } finally {
    dispatch(setIsGlobalStrategiesLoading(false));
  }
});
