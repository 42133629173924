import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { Navigation } from 'components/Navigation/Navigation';
import StrategyLibrary from 'components/StrategyLibrary/StrategyLibrary';
import { GlobalStrategiesList } from 'components/StrategyLibrary/GlobalStrategiesList';
import { PortfolioManagerList } from 'components/StrategyLibrary/PortfolioManagerList';
import TeamsList from 'components/Teams/TeamsList/TeamsList';
import { isUserSignedInSelector } from 'store/slices/user/user.selectors';
import { routes } from 'constants/routes';
import StrategyRiskAllocator from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator';
import ManageGroups from 'layouts/ManageGroups/ManageGroups';
import ManageGroupDetails from 'components/ManageGroupDetails/ManageGroupDetails';
import Root from 'components/Root/Root';
import {
  hasSaraUserPermissionSelector,
  hasStrategyLibraryPermissionSelector,
  hasSaraFullPermissionSelector,
} from 'store/slices/permissions';
import { init } from 'store/thunks';
import { useAppDispatch } from 'store';
import ProtectedRoute from './ProtectedRoute';

const AppRouter = () => {
  const isSignedIn = useSelector(isUserSignedInSelector);
  const isStrategyLibrary = useMatch(`/${routes.FEATURES.path}/:path`);
  const hasSaraUserPermission =
    useSelector(hasSaraUserPermissionSelector) && isSignedIn;
  const hasSaraFullPermission =
    useSelector(hasSaraFullPermissionSelector) && isSignedIn;
  const hasStrategyLibraryPermission =
    useSelector(hasStrategyLibraryPermissionSelector) && isSignedIn;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !isStrategyLibrary &&
      (hasSaraUserPermission || hasSaraFullPermission)
    ) {
      dispatch(init());
    }
  }, [dispatch, isStrategyLibrary]);

  return (
    <>
      {isSignedIn && <Navigation />}

      <Routes>
        <Route
          index
          element={
            <ProtectedRoute isAllowed>
              {/* TODO UX-1514: remove ignore and fix */}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <Root />
            </ProtectedRoute>
          }
        />

        <Route
          path={routes.MONITOR.path}
          element={
            <ProtectedRoute
              isAllowed={hasSaraUserPermission || hasSaraFullPermission}
            >
              <StrategyRiskAllocator dashboardContainerHeight='100vh - 63px' />
            </ProtectedRoute>
          }
        />

        <Route
          path={`${routes.MONITOR.path}/${routes.MANAGE_STRATEGY_GROUPS.path}`}
          element={
            <ProtectedRoute
              isAllowed={hasSaraUserPermission || hasSaraFullPermission}
            >
              <ManageGroups />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${routes.MONITOR.path}/${routes.MANAGE_STRATEGY_GROUPS.path}/:id`}
          element={
            <ProtectedRoute
              isAllowed={hasSaraUserPermission || hasSaraFullPermission}
            >
              <ManageGroupDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path={routes.FEATURES.path}
          element={
            <ProtectedRoute isAllowed={hasStrategyLibraryPermission}>
              <StrategyLibrary />
            </ProtectedRoute>
          }
        >
          <Route
            index
            element={
              <ProtectedRoute isAllowed={hasStrategyLibraryPermission}>
                <Navigate to={routes.STRATEGIES.path} replace />
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.STRATEGIES.path}
            element={
              <ProtectedRoute isAllowed={hasStrategyLibraryPermission}>
                <GlobalStrategiesList />
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.TEAM_MEMBERS.path}
            element={
              <ProtectedRoute isAllowed={hasStrategyLibraryPermission}>
                <PortfolioManagerList />
              </ProtectedRoute>
            }
          />

          <Route
            path={routes.TEAMS.path}
            element={
              <ProtectedRoute isAllowed={hasStrategyLibraryPermission}>
                <TeamsList />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path='*'
          element={
            <ProtectedRoute isAllowed>
              <Navigate to={routes.ROOT.path} replace />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

export default AppRouter;
