import { FieldValueEnum, RowType } from '@clearalpha/common';
import { Expenses } from 'services/fundCalculator/fundCalculator.types';
import i18n from 'translations/i18n';

export const EXPENSES_WIDGET_CONFIG: RowType<keyof Expenses>[] = [
  {
    fieldName: i18n.t('widgets.expenses.platformFixedExpense'),
    variableName: 'platformFixedExpensesPct',
    type: FieldValueEnum.READONLY,
  },
  {
    fieldName: i18n.t('widgets.expenses.pmFixedExpense'),
    variableName: 'pmFixedExpensesPct',
    type: FieldValueEnum.READONLY,
  },
  {
    fieldName: i18n.t('widgets.expenses.pmPerformanceFee'),
    variableName: 'pmPerformanceFeesPct',
    type: FieldValueEnum.READONLY,
  },
  {
    fieldName: i18n.t('widgets.expenses.expensesForRunningFund'),
    variableName: 'expensesForRunningFundPct',
    type: FieldValueEnum.READONLY,
  },
  {
    fieldName: i18n.t('widgets.expenses.clearAlphaPerfFee'),
    variableName: 'caPerfFeePct',
    type: FieldValueEnum.READONLY,
  },
  {
    fieldName: i18n.t('widgets.expenses.totalInvestorExpenses'),
    variableName: 'totalInvestorExpensesPct',
    type: FieldValueEnum.READONLY,
  },
  {
    fieldName: i18n.t('widgets.expenses.fixedTotal'),
    variableName: 'fixedTotalFeesPct',
    type: FieldValueEnum.READONLY,
  },
  {
    fieldName: i18n.t('widgets.expenses.variableTotal'),
    variableName: 'variableTotalFeesPct',
    type: FieldValueEnum.READONLY,
  },
];
