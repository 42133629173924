import { useField } from 'formik';
import { Box } from '@mui/material';

type FormikValueProps = {
  name: string;
  locale?: Record<string, string>;
  format?: (v: any) => string;
};

const FormikValue = ({ name, locale, format }: FormikValueProps) => {
  const [{ value }] = useField(name);

  const valueToShow = () => {
    if (locale) {
      return locale[value];
    }

    if (format) {
      return format(value);
    }

    return value;
  };

  return <Box data-testid={name}>{valueToShow()}</Box>;
};

export default FormikValue;
