import { array, object, string } from 'yup';
import { ManagerFormFields } from 'components/PortfolioManagerDetails/PortfolioManagerDetails.constants';
import { FIELD_LENGTH } from 'constants/fields';
import i18n from 'translations/i18n';
import {
  requiredText,
  optionalText,
  requiredPastDate,
} from './defaultValidationRules';

export const portfolioManagerValidationSchema = object().shape({
  [ManagerFormFields.NAME]: requiredText({
    fieldName: i18n.t('firstName'),
  }),
  [ManagerFormFields.SURNAME]: requiredText({
    fieldName: i18n.t('lastName'),
  }),
  [ManagerFormFields.STATUS]: string().required(
    i18n.t('errors.required', { name: i18n.t('status') })
  ),
  [ManagerFormFields.LOCATION]: optionalText({
    fieldName: i18n.t('location'),
    maxLength: FIELD_LENGTH.X2_SHORT_TEXT,
  }),
  [ManagerFormFields.DESCRIPTION]: optionalText({
    fieldName: i18n.t('about'),
    maxLength: FIELD_LENGTH.X4_LONG_TEXT,
  }),
  [ManagerFormFields.STRATEGY_EXPERTISE]: array(),
  [ManagerFormFields.CURRENT_FIRM]: requiredText({
    fieldName: i18n.t('currentFirm'),
  }),
  [ManagerFormFields.FORMER_FIRMS]: array(),
  [ManagerFormFields.INVESTING_EXPERIENCE]: requiredPastDate({
    fieldName: i18n.t('investingExperience'),
  }),
  [ManagerFormFields.HIGHEST_DEGREE]: optionalText({
    fieldName: i18n.t('highestDegree'),
    maxLength: FIELD_LENGTH.X2_SHORT_TEXT,
  }),
  [ManagerFormFields.SCHOOLS]: array(),
  [ManagerFormFields.CERTIFICATION]: string(),
  // TODO: TBD
  // [ManagerFormFields.CRM_SYSTEM]: string()
  //   .url(i18n.t('errors.urlFormat', { name: i18n.t('crmSystem') }))
  //   .max(
  //     FIELD_LENGTH.X3_SHORT_TEXT,
  //     i18n.t('errors.maxLength', {
  //       name: i18n.t('crmSystem'),
  //       length: FIELD_LENGTH.X3_SHORT_TEXT,
  //     })
  //   ),
});
