import { StyleSheet } from '@react-pdf/renderer';
import { THEME_COLORS } from '@clearalpha/common';

export const styles = StyleSheet.create({
  page: { padding: 30 },
  row: { flexDirection: 'row', justifyContent: 'center' },
  rowNotCentered: { flexDirection: 'row' },
  column: { flexDirection: 'column' },
  header: { fontSize: 18, marginLeft: 15, marginBottom: 25 },
  subheader: {
    fontSize: 13,
    marginTop: 12,
    marginBottom: 12,
    color: THEME_COLORS.systemSurface2,
  },
  tableHeader: {
    backgroundColor: THEME_COLORS.veryLightGrey,
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10pt 0',
    fontSize: 10,
    padding: '10pt 0',
  },
  tableHeaderEmpty: { width: '60%' },
  tableHeaderData: { width: '20%' },
  separator: {
    margin: '15pt 0',
    borderBottom: '1pt',
  },
  title: {
    fontSize: 13,
    margin: '10pt 0 12pt',
    textAlign: 'center',
    width: '100%',
  },
  subtitle: {
    color: THEME_COLORS.systemSurface2,
    fontSize: 10,
    margin: '15pt 0 8pt',
  },
});
