import { get, post, put, remove } from 'services/http';
import { config } from 'config';
import { Strategy } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';
import {
  AllStrategiesRequest,
  GlobalStrategiesResponse,
  GlobalStrategiesRequest,
  StrategyRelatedAttributesResponse,
  BaseStrategyEntityRequest,
  PortfolioStrategyRequest,
} from './strategyCatalog.type';

const strategyCatalogUrl = `${config.api.strategyCatalog}/strategy-catalog/api/v2`;
const strategyCatalogUrlV3 = `${config.api.strategyCatalog}/strategy-catalog/api/v3`;

const ROUTES = {
  PRODUCTION_STRATEGIES: `${strategyCatalogUrl}/production-strategies`,
  PERSONAL_STRATEGIES: `${strategyCatalogUrl}/personal-strategies`,
  PERSONAL_STRATEGIES_BY_ID: `${strategyCatalogUrl}/personal-strategies/list`,
  GLOBAL_STRATEGIES: `${strategyCatalogUrlV3}/global-strategies`,
  ALL_STRATEGIES: `${strategyCatalogUrlV3}/strategies`,
  ALL_STRATEGIES_BY_ID: `${strategyCatalogUrlV3}/strategies/list`,
  MARGIN_TYPES: `${strategyCatalogUrlV3}/margin-types`,
  GROUP_CHARACTERISTIC: `${strategyCatalogUrlV3}/group-characteristic`,
  AVERAGE_HOLD_PERIODS: `${strategyCatalogUrlV3}/average-holding-periods`,
  ASSET_CATEGORIES: `${strategyCatalogUrlV3}/asset-categories`,
  STRATEGY_RELATED_ATTRIBUTES: `${strategyCatalogUrlV3}/strategy-related-attributes`,
  COPY_LIST_OF_STRATEGIES_BY_ID: `${strategyCatalogUrlV3}/strategies/copy/list`,
};

export const getAllStrategies = async (
  strategyIds: AllStrategiesRequest
): Promise<Strategy[]> =>
  get(ROUTES.ALL_STRATEGIES, () => null, { params: strategyIds });

export const createStrategyBaseEntity = async (
  payload: BaseStrategyEntityRequest
): Promise<Strategy> => post(ROUTES.ALL_STRATEGIES, payload);

export const getAllStrategiesByIds = async (
  strategyIds: string[]
): Promise<Strategy[]> => post(ROUTES.ALL_STRATEGIES_BY_ID, { strategyIds });

export const getMarginTypes = async (): Promise<any> =>
  get(ROUTES.MARGIN_TYPES);

export const getGroupCharacteristics = async (): Promise<any> =>
  get(ROUTES.GROUP_CHARACTERISTIC);

export const getAverageHoldPeriods = async (): Promise<any> =>
  get(ROUTES.AVERAGE_HOLD_PERIODS);

export const getAssetCategories = async (): Promise<any> =>
  get(ROUTES.ASSET_CATEGORIES);

export const getGlobalStrategies = async (): Promise<
  GlobalStrategiesResponse[]
> => get(ROUTES.GLOBAL_STRATEGIES);

export const updateGlobalStrategy = async (
  uuid: string,
  strategy: GlobalStrategiesRequest
): Promise<GlobalStrategiesResponse> => {
  const response = await put(`${ROUTES.GLOBAL_STRATEGIES}/${uuid}`, strategy);

  if (!response) {
    throw Error();
  }

  return response;
};

export const createGlobalStrategy = async (
  strategy: GlobalStrategiesRequest
): Promise<GlobalStrategiesResponse> => {
  const response = await post(ROUTES.GLOBAL_STRATEGIES, strategy);

  if (!response) {
    throw Error();
  }

  return response;
};

export const deleteGlobalStrategy = async (uuid: string) => {
  await remove(`${ROUTES.GLOBAL_STRATEGIES}/${uuid}`);
};

export const updatePortfolioStrategy = async (
  strategy: PortfolioStrategyRequest
): Promise<Strategy> => post(ROUTES.ALL_STRATEGIES, strategy);

export const getStrategyRelatedAttributes =
  async (): Promise<StrategyRelatedAttributesResponse> =>
    get(ROUTES.STRATEGY_RELATED_ATTRIBUTES);

export const copyListOfStrategies = async (
  strategyIds: string[]
): Promise<Record<string, string>> => {
  const response = await post(ROUTES.COPY_LIST_OF_STRATEGIES_BY_ID, {
    strategyIds,
  });

  if (!response) {
    throw Error();
  }

  return response;
};
