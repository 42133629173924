import styled from '@emotion/styled';
import {
  DialogTitle as MUIDialogTitle,
  DialogActions as MUIDialogActions,
} from '@mui/material';
import {
  Dialog as BaseDialog,
  Button as BaseButton,
  TextField as BaseTextField,
} from '@clearalpha/common';

import { TotalAumRowProps } from './UpdateAumDialog.type';

const Dialog = styled(BaseDialog)`
  background-color: ${({ theme: { colors } }) => colors.blackOverlay25};

  .MuiPaper-root {
    background: ${({ theme: { colors } }) => colors.surfacePlusFive};
    border-radius: 16px;
    color: ${({ theme: { colors } }) => colors.onSurface};
    padding: 24px;
    max-width: 700px;
    width: 700px;
  }
`;

const DialogTitle = styled(MUIDialogTitle)`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-family: Work Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 44px;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
`;

const ProductionAumRow = styled(Row)`
  span {
    letter-spacing: 1px;
    line-height: 21px;
    opacity: 1;
  }
`;

const TotalAumRow = styled(Row)`
  border-top: 1px solid
    ${({ theme, isValid }: TotalAumRowProps) =>
      isValid ? theme?.colors.onSurface : theme?.colors.sysColorDarkError};
  border-bottom: 1px solid
    ${({ theme, isValid }: TotalAumRowProps) =>
      isValid ? theme?.colors.onSurface : theme?.colors.sysColorDarkError};
  flex-wrap: wrap;
`;

const TotalAumValue = styled.span`
  font-family: 'Droid Sans Mono';
  font-size: 28px;
  line-height: 33px;
`;

const Error = styled.span`
  color: ${({ theme: { colors } }) => colors.sysColorDarkError};
  font-family: Work Sans;
  font-size: 14px;
  text-align: right;
  height: 1px;
  width: 100%;
`;

const FlowRow = styled(Row)`
  flex-wrap: wrap;
  padding: 24px 0;
`;

const Note = styled.span`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  flex-basis: 100%;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
  line-height: 24px;
  opacity: 0.6;
  text-align: right;
`;

const FieldLabel = styled.p`
  color: ${({ theme: { colors } }) => colors.onSurface};
  font-size: 18px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  opacity: 0.87;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin: 0;
  white-space: pre-line;
`;

const FlowFieldLabel = styled(FieldLabel)`
  text-transform: uppercase;
`;

const TextField = styled(BaseTextField)`
  .MuiInputBase-root {
    width: 240px;
    min-height: 46px;
    padding-right: 14px;

    & .MuiInputAdornment-positionEnd {
      position: relative;
      top: 0;
      right: 0;
      padding-top: 22px;
    }

    .MuiOutlinedInput-input {
      font-family: Droid Sans Mono;
      font-size: 18px;
      padding: 0 0 0 16px;
      text-align: right;
    }
  }
`;

const DialogActions = styled(MUIDialogActions)`
  display: flex;
  justify-content: center;
  gap: 24px;
`;

const Button = styled(BaseButton)`
  height: 40px;
  width: 105px;

  span {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
`;

export {
  Dialog,
  DialogTitle,
  Row,
  ProductionAumRow,
  TotalAumRow,
  TotalAumValue,
  Error,
  FlowRow,
  TextField,
  Note,
  FieldLabel,
  FlowFieldLabel,
  DialogActions,
  Button,
};
