import i18n from 'translations/i18n';

export interface StrategyGroupRow {
  id: string;
  name: string;
  team: string; // hardcoded value
  strategies: string; // hardcoded value
}

export const StrategyGroupsListLocales = {
  name: {
    field: 'name',
    headerName: i18n.t('strategyGroup'),
  },
  team: {
    field: 'team',
    headerName: i18n.t('team'),
  },
  strategies: {
    field: 'strategies',
    headerName: i18n.t('strategies'),
  },
};
