import { ChangeEvent, Fragment, useState, useEffect } from 'react';
import { Table } from '@mui/material';
import { createHashMap } from 'helpers';
import { useSelector } from 'react-redux';
import {
  changeStrategiesAllocationsAndRunCalculation,
  removeStrategyFromPortfolio,
  toggleLockStrategiesAllocations,
} from 'store/slices/portfolios';
import TeamDetails from 'components/Teams/TeamDetails/TeamDetails';
import { useAppDispatch } from 'store';
import DeleteConfirmationDialog from 'components/Dialogs/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { fetchPerformanceWork, fetchTeams } from 'store/thunks';
import { useTranslation } from 'react-i18next';
import { teamsSelector } from 'store/slices/teams';
import { updateStrategiesAllocation } from 'store/slices/strategies';
import {
  deleteStrategy,
  updateStrategiesLock,
} from 'store/slices/strategies/strategies.slice';
import { deleteStrategyFromStrategyGroup } from 'store/slices/portfolios/portfolios.slice';
import StrategyListHeader from './StrategyListTableHeader/StrategyListTableHeader';
import StrategyListItem from './StrategyListItem/StrategyListItem';
import StrategyListToolBar from './StrategyListToolBar/StrategyListToolBar';
import { StrategyListProps, DeleteDialogState } from './StrategyList.type';
import { Container, TableBody, TableContainer } from './styled';
import { useStrategyListDrawer } from './useStrategyListDrawer';
import PortfolioManagerDetails from '../PortfolioManagerDetails/PortfolioManagerDetails';
import StrategyDetails from '../StrategyDetails/StrategyDetails';

const StrategyList = ({
  strategiesData: strategies,
  getStrategy,
  strategiesDiffs,
}: StrategyListProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const teams = useSelector(teamsSelector);

  const [selectedStrategies, setSelectedStrategies] = useState<string[]>([]);
  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogState>({
    isDeleteDialogOpened: false,
    strategyId: '',
    strategyGroupId: '',
  });
  const highlightedStrategies: string[] = strategiesDiffs.map(
    (strategy) => strategy.id
  );

  const hasSelectedStrategies = !!selectedStrategies.length;

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = strategies.map((strategy) => strategy.id);
      setSelectedStrategies(newSelected);
      return;
    }
    setSelectedStrategies([]);
  };

  const handleSelect = (strategyId: string) => {
    const selectedIndex = selectedStrategies.indexOf(strategyId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedStrategies, strategyId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedStrategies.slice(1));
    } else if (selectedIndex === selectedStrategies.length - 1) {
      newSelected = newSelected.concat(selectedStrategies.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedStrategies.slice(0, selectedIndex),
        selectedStrategies.slice(selectedIndex + 1)
      );
    }

    setSelectedStrategies(newSelected);
  };

  const handleLockClick = () => {
    if (!hasSelectedStrategies) return;

    const changedStrategies = selectedStrategies.map((strategyId) => ({
      strategyId,
      isLocked: true,
    }));

    setSelectedStrategies([]);
    dispatch(toggleLockStrategiesAllocations(changedStrategies));
    dispatch(updateStrategiesLock(changedStrategies));
  };

  const handleUnlockClick = () => {
    if (!hasSelectedStrategies) return;

    const changedStrategies = selectedStrategies.map((strategyId) => ({
      strategyId,
      isLocked: false,
    }));

    setSelectedStrategies([]);
    dispatch(toggleLockStrategiesAllocations(changedStrategies));
    dispatch(updateStrategiesLock(changedStrategies));
  };

  const handleZeroAllocationClick = () => {
    if (!hasSelectedStrategies) return;

    const changedStrategies = selectedStrategies.map((strategyId) => ({
      strategyId,
      notionalAccountValue: 0,
    }));

    setSelectedStrategies([]);

    dispatch(updateStrategiesAllocation(changedStrategies));
    dispatch(changeStrategiesAllocationsAndRunCalculation(changedStrategies));
  };

  const handleRevertAllocationClick = () => {
    if (!hasSelectedStrategies) return;

    const strategiesHashMap = createHashMap('id', strategies);

    const changedStrategies = selectedStrategies
      .filter(
        (strategyId) =>
          strategiesHashMap[strategyId].productionNotionalAccountValue !== null
      )
      .map((strategyId) => {
        return {
          strategyId,
          notionalAccountValue:
            strategiesHashMap[strategyId].productionNotionalAccountValue,
        };
      });

    setSelectedStrategies([]);
    dispatch(updateStrategiesAllocation(changedStrategies));
    dispatch(changeStrategiesAllocationsAndRunCalculation(changedStrategies));
  };

  const handleOpenDeleteDialog = (id: string, strategyGroupId: string) => {
    setDeleteDialog({
      ...deleteDialog,
      isDeleteDialogOpened: true,
      strategyId: id,
      strategyGroupId,
    });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog({
      ...deleteDialog,
      isDeleteDialogOpened: false,
      strategyId: '',
    });
  };

  const handleDeleteStrategyFromPortfolio = () => {
    dispatch(
      removeStrategyFromPortfolio({ strategyId: deleteDialog.strategyId })
    );
    dispatch(
      deleteStrategyFromStrategyGroup({
        strategyId: deleteDialog.strategyId,
        strategyGroupId: deleteDialog.strategyGroupId,
      })
    );

    dispatch(deleteStrategy(deleteDialog.strategyId));
    dispatch(fetchPerformanceWork());

    setDeleteDialog({
      ...deleteDialog,
      isDeleteDialogOpened: false,
      strategyId: '',
    });
  };

  const {
    drawer,
    handleOpenStrategyDrawer,
    handleCloseDrawer,
    updateDrawer,
    handleOpenTeamDrawer,
    handleOpenPMDrawer,
  } = useStrategyListDrawer({
    getStrategy,
    teams,
  });

  useEffect(() => {
    dispatch(fetchTeams());
  }, [dispatch]);
  return (
    <Container>
      <StrategyListToolBar
        bulkActions={{
          handleLockClick,
          handleUnlockClick,
          handleZeroAllocationClick,
          handleRevertAllocationClick,
        }}
      />
      <TableContainer>
        <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
          <StrategyListHeader
            handleSelectAll={handleSelectAll}
            strategiesCount={strategies.length}
            selectedStrategiesCount={selectedStrategies.length}
          />
          <TableBody>
            {strategies.map((strategy, index) => {
              const isSelected = selectedStrategies.indexOf(strategy.id) !== -1;
              const labelId = `strategy-list-table-checkbox-${index}`;
              const isHighlighted =
                highlightedStrategies.indexOf(strategy.id) !== -1;
              return (
                <Fragment key={strategy.id}>
                  <StrategyListItem
                    strategy={strategy}
                    handleSelect={handleSelect}
                    isSelected={isSelected}
                    isHighlighted={isHighlighted}
                    labelId={labelId}
                    handleOpenStrategyDrawer={handleOpenStrategyDrawer}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                  />
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <PortfolioManagerDetails
        isOpen={drawer.isOpenPMDrawer}
        portfolioManager={drawer.managerDetails}
        handleOpenTeam={handleOpenTeamDrawer}
        handleCloseDrawer={handleCloseDrawer}
      />

      <TeamDetails
        isOpen={drawer.isOpenTeamDrawer}
        handleClose={handleCloseDrawer}
        teamDetails={drawer.teamDetails}
        updateDrawer={updateDrawer}
        handleOpenTeamMember={handleOpenPMDrawer}
      />

      <StrategyDetails
        handleCloseDrawer={handleCloseDrawer}
        strategy={drawer.strategyDetails}
        isOpen={drawer.isOpenStrategiesDrawer}
        handleOpenTeam={handleOpenTeamDrawer}
      />

      <DeleteConfirmationDialog
        isOpen={deleteDialog.isDeleteDialogOpened}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handleDeleteStrategyFromPortfolio}
        title={t('dialogs.deleteStrategy.title')}
        description={t('dialogs.deleteStrategy.message')}
      />
    </Container>
  );
};

export default StrategyList;
