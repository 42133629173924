import { RootState } from 'store';
import { USER_SLICE_NAME } from './user.constants';
import { UserState } from './user.types';

export const isUserSignedInSelector = (state: RootState) =>
  state[USER_SLICE_NAME].isSignedIn;

export const isUserSigningInSelector = (state: RootState) =>
  state[USER_SLICE_NAME].isSignInInitiated;

export const isUserSigningOutSelector = (state: RootState) =>
  state[USER_SLICE_NAME].isSignOutInitiated;

export const redirectToSelector = (state: RootState) =>
  state[USER_SLICE_NAME].redirectTo;

export const getUser = (state: RootState): UserState => state[USER_SLICE_NAME];
