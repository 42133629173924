import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';

export enum Language {
  EN = 'en',
}

i18n.use(initReactI18next).init({
  lng: Language.EN,
  resources: {
    en: {
      translation: en,
    },
  },
  returnNull: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
