import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  arePermissionsDefinedSelector,
  hasSaraFullPermissionSelector,
  hasSaraUserPermissionSelector,
  hasStrategyLibraryPermissionSelector,
} from 'store/slices/permissions';
import { routes } from 'constants/routes';

const Root = () => {
  const arePermissionsDefined = useSelector(arePermissionsDefinedSelector);

  const hasSaraUserPermission = useSelector(hasSaraUserPermissionSelector);
  const hasSaraFullPermission = useSelector(hasSaraFullPermissionSelector);
  const hasStrategyLibraryPermission = useSelector(
    hasStrategyLibraryPermissionSelector
  );

  if (!arePermissionsDefined)
    return styled.div`
      color: ${({ theme: { colors } }) => colors.surface};
    `;

  const path =
    ((hasSaraUserPermission || hasSaraFullPermission) && routes.MONITOR.path) ||
    (hasStrategyLibraryPermission && routes.FEATURES.path) ||
    routes.NO_ACCESS.path;

  return <Navigate to={path} replace />;
};

export default Root;
