import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  PORTFOLIOS_SLICE_NAME,
  setIsLoading,
  setStrategyGroups,
} from 'store/slices/portfolios';
import { getAllPortfolioGroups } from 'services/portfolioCore/portfolioCore.service';

export const fetchStrategyGroups = createAsyncThunk<
  void,
  void,
  { state: RootState }
>('fetchStrategyGroups', async (_, { dispatch, getState }) => {
  const {
    [PORTFOLIOS_SLICE_NAME]: { workingPortfolio },
  } = getState();

  setIsLoading(true);

  try {
    const strategyPortfolioGroups = await getAllPortfolioGroups(
      workingPortfolio.id
    );

    dispatch(setStrategyGroups(strategyPortfolioGroups));
  } finally {
    setIsLoading(false);
  }
});
