import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { NumericTextCurrency } from '@clearalpha/common';
import { FieldLabel } from 'components/StrategyDetails/blocks/styled';
import { GlobalStrategyDetailsFormFields } from '../../../GlobalStrategyDetails.type';
import { calculateVolatilityCapacity } from '../../../GlobalStrategyDetails.helper';

const VolatilityCapacity = () => {
  const { t } = useTranslation();
  const {
    values: {
      allocation_terms: aumCapacity,
      strategy_volatility_target: volatilityTarget,
    },
  } = useFormikContext<GlobalStrategyDetailsFormFields>();

  return (
    <>
      <FieldLabel>{t('volatilityCapacity')}</FieldLabel>
      <NumericTextCurrency
        value={calculateVolatilityCapacity({
          aumCapacity,
          volatilityTarget,
        })}
      />
    </>
  );
};

export default VolatilityCapacity;
