import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TableBody, TableRow } from '@mui/material';
import { FieldValueWarning, NumericTextPercent } from '@clearalpha/common';
import {
  TableWidgetBackbone,
  TitleCell,
  ValueCell,
} from '@clearalpha/common/dist/components/Dashboard/Widgets/TableWidgetBackbone';
import { SensitiveDataWrapper } from '@clearalpha/common/dist/components/SensitiveDataWrapper';
import { sensitiveDataVisibilitySelector } from 'store/slices/settings';
import {
  productionPortfolioPerformanceSelector,
  workingPortfolioPerformanceSelector,
  sourcePortfolioPerformanceSelector,
  targetPortfolioPerformanceSelector,
  isReviewModePortfolioSelector,
} from 'store/slices/portfolios';
import { EXPENSES_WIDGET_CONFIG } from './ExpensesWidget.const';
import { getExpensesWidgetData } from './ExpensesWidget.helper';

const ExpensesWidget = () => {
  const { t } = useTranslation();
  const isVisibleSensitiveData = useSelector(sensitiveDataVisibilitySelector);
  const isReviewMode = useSelector(isReviewModePortfolioSelector);
  const productionPerformance = useSelector(
    productionPortfolioPerformanceSelector
  );
  const workingPerformance = useSelector(workingPortfolioPerformanceSelector);
  const sourcePerformance = useSelector(sourcePortfolioPerformanceSelector);
  const targetPerformance = useSelector(targetPortfolioPerformanceSelector);

  const currentWorkingPerformance = isReviewMode
    ? sourcePerformance
    : workingPerformance;
  const currentProdPerformance = isReviewMode
    ? targetPerformance
    : productionPerformance;

  const { data: production } = getExpensesWidgetData(
    currentProdPerformance.portfolio
  );
  const { data: scenario, warnings: scenarioWarnings } = getExpensesWidgetData(
    currentWorkingPerformance.portfolio,
    currentWorkingPerformance.warnings
  );

  return (
    <TableWidgetBackbone title={t('expenses')}>
      <TableBody>
        {EXPENSES_WIDGET_CONFIG.map(({ fieldName, variableName }) => (
          <TableRow key={fieldName}>
            <TitleCell>{fieldName}</TitleCell>
            <ValueCell align='right'>
              <SensitiveDataWrapper
                isVisible={isVisibleSensitiveData}
                sx={{
                  alignItems: 'flex-end',
                  paddingRight: '13px',
                }}
              >
                <NumericTextPercent value={production[variableName]} />
              </SensitiveDataWrapper>
            </ValueCell>
            <ValueCell align='right'>
              <SensitiveDataWrapper
                isVisible={isVisibleSensitiveData}
                sx={{
                  alignItems: 'flex-end',
                  paddingRight: '13px',
                }}
              >
                <NumericTextPercent value={scenario[variableName]} />
              </SensitiveDataWrapper>

              {scenarioWarnings[variableName] && (
                <FieldValueWarning>
                  {scenarioWarnings[variableName]?.join(' ')}
                </FieldValueWarning>
              )}
            </ValueCell>
          </TableRow>
        ))}
      </TableBody>
    </TableWidgetBackbone>
  );
};

export default ExpensesWidget;
