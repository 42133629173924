import React, { FC } from 'react';
import { useField } from 'formik';
import { NumericInputDecimal, TextFieldProps } from '@clearalpha/common';

type FormikDecimalNumbersProps = {
  name: string;
  customInput: FC<TextFieldProps>;
  disabled?: boolean;
  minLimit?: number;
  maxLimit?: number;
  decimalScale?: number;
};

const FormikDecimalNumbers = ({
  name,
  minLimit,
  maxLimit,
  disabled,
  customInput: CustomInput,
  decimalScale = 2,
}: FormikDecimalNumbersProps) => {
  const [{ value, onBlur }, { error, touched }, { setValue, setTouched }] =
    useField(name);

  const handleChange = (_: string, newValue: number) => {
    setValue(newValue);
    setTouched(true);
  };

  return (
    <NumericInputDecimal
      value={value}
      customInput={CustomInput}
      variableName={name}
      maxLimit={maxLimit}
      minLimit={minLimit}
      handleBlur={onBlur}
      setFieldValue={handleChange}
      error={error && touched ? error : undefined}
      testId={name}
      disabled={disabled}
      decimalScale={decimalScale}
    />
  );
};

export default FormikDecimalNumbers;
