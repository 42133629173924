import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  GetPortfolioManager,
  ManagerEntityRequest,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { createPortfolioManager } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import {
  setIsPortfolioMangersLoading,
  setIsPMEditMode,
} from 'store/slices/portfolioManagers';
import { fetchPortfolioManagers, fetchTeams } from 'store/thunks';

export const createNewPortfolioManager = createAsyncThunk<
  GetPortfolioManager | null,
  ManagerEntityRequest,
  { state: RootState }
>('createNewPortfolioManager', async (manager, { dispatch }) => {
  dispatch(setIsPortfolioMangersLoading(true));

  try {
    const newManager = await createPortfolioManager(manager);

    dispatch(setIsPMEditMode(false));

    dispatch(fetchTeams());
    dispatch(fetchPortfolioManagers());

    return newManager;
  } catch {
    return null;
  } finally {
    dispatch(setIsPortfolioMangersLoading(false));
  }
});
