import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  createWorkingPortfolio,
  getAllPortfolioGroups,
  updateWorkingPortfolio,
} from 'services/portfolioCore/portfolioCore.service';
import {
  PORTFOLIOS_SLICE_NAME,
  setIsLoading,
  setLastWorkingPortfolio,
  setInitialWorkingPortfolio,
  setWorkingPortfolioAndFetchStrategies,
  setStrategyGroups,
} from 'store/slices/portfolios';
import { STRATEGIES_SLICE_NAME } from 'store/slices/strategies';
import { transformToPortfolioRequest } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.helper';
import { GetPortfolioResponse } from 'layouts/StrategyRiskAllocator/StrategyRiskAllocator.type';

// TODO: UX-1530 Check portfolio save logic with real data
export const saveWorkingPortfolio = createAsyncThunk<
  GetPortfolioResponse | null,
  void,
  { state: RootState }
>('saveWorkingPortfolio', async (_, { getState, dispatch }) => {
  const {
    [PORTFOLIOS_SLICE_NAME]: {
      workingPortfolio,
      productionPortfolio,
      lastWorkingPortfolio,
      portfolioFeeTypes,
      strategyGroups,
    },
    [STRATEGIES_SLICE_NAME]: { strategies },
  } = getState();

  dispatch(setIsLoading(true));

  try {
    const shouldUpdatePortfolioVersion =
      lastWorkingPortfolio.id !== productionPortfolio.id;
    const actualPortfolio = shouldUpdatePortfolioVersion
      ? await updateWorkingPortfolio(
          transformToPortfolioRequest(
            workingPortfolio,
            strategies,
            portfolioFeeTypes,
            strategyGroups
          ),
          workingPortfolio.id
        )
      : await createWorkingPortfolio(
          // TODO: remove ts-ignore
          // eslint-disable-next-line
          // @ts-ignore
          transformToPortfolioRequest(
            workingPortfolio,
            strategies,
            portfolioFeeTypes,
            strategyGroups
          )
        );

    dispatch(setLastWorkingPortfolio(actualPortfolio));
    dispatch(setWorkingPortfolioAndFetchStrategies(actualPortfolio));
    dispatch(setInitialWorkingPortfolio(actualPortfolio));
    const groups = await getAllPortfolioGroups(actualPortfolio.id);
    dispatch(setStrategyGroups(groups));

    return actualPortfolio;
  } catch {
    return null;
  } finally {
    dispatch(setIsLoading(false));
  }
});
