import React from 'react';
import { useTranslation } from 'react-i18next';
import { Block, BlockTitle, FieldsWithProduction } from '../styled';
import {
  MinAllocation,
  AUMCapacity,
  VolatilityTarget,
  VolatilityCapacity,
  NotionalAccountValue,
  RemainingCapacity,
} from './fields';
import ColumnsHeader from '../ColumnsHeader/ColumnsHeader';

const Capacity = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <BlockTitle>{t('capacity')}</BlockTitle>
      <FieldsWithProduction>
        <ColumnsHeader />
        <MinAllocation />
        <AUMCapacity />
        <VolatilityTarget />
        <VolatilityCapacity />
        <NotionalAccountValue />
        <RemainingCapacity />
      </FieldsWithProduction>
    </Block>
  );
};

export default Capacity;
