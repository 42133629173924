import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  GetPortfolioManager,
  ManagerEntityRequest,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { updatePortfolioManager } from 'services/portfolioManagerCore/portfolioManagerCore.service';
import {
  setIsPMEditMode,
  setIsPortfolioMangersLoading,
  updateSinglePortfolioManager,
} from 'store/slices/portfolioManagers';
import {
  fetchTeams,
  fetchPortfolioManagers,
  fetchGlobalStrategies,
} from 'store/thunks';

export const updatePortfolioManagerDetails = createAsyncThunk<
  GetPortfolioManager | null,
  {
    uuid: string;
    manager: ManagerEntityRequest;
  },
  { state: RootState }
>('updatePortfolioManagerDetails', async ({ uuid, manager }, { dispatch }) => {
  dispatch(setIsPortfolioMangersLoading(true));

  try {
    const updatedManager = await updatePortfolioManager(uuid, manager);

    dispatch(updateSinglePortfolioManager(updatedManager));

    dispatch(fetchTeams());
    dispatch(fetchPortfolioManagers());
    dispatch(fetchGlobalStrategies()); // TODO: Remove in UX-1245

    return updatedManager;
  } catch {
    return null;
  } finally {
    dispatch(setIsPortfolioMangersLoading(false));
    dispatch(setIsPMEditMode(false));
  }
});
