import { RootState } from 'store';
import { GetTeam } from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { TEAMS_SLICE_NAME } from './teams.constants';

export const isTeamEditModeSelector = (state: RootState): boolean =>
  state[TEAMS_SLICE_NAME].isTeamEditMode;

export const isTeamsLoadingSelector = (state: RootState): boolean =>
  state[TEAMS_SLICE_NAME].isTeamsLoading;

export const teamsSelector = (state: RootState): GetTeam[] =>
  state[TEAMS_SLICE_NAME].teams;
