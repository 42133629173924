import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReadonlyData } from '@clearalpha/common';
import { FormikSelect } from 'components/formik/fields';
import {
  averageHoldPeriodsSelector,
  isGlobalStrategyEditModeSelector,
} from 'store/slices/strategies';
import { useAppDispatch } from 'store';
import { fetchAverageHoldPeriods } from 'store/thunks/fetchAverageHoldPeriods';
import { FieldLabel } from 'components/StrategyDetails/blocks/styled';
import { GlobalStrategyDetailsFormFields } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.type';
import { StrategyFormFields } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.constants';
import { convertValuesToOptions } from 'components/GlobalStrategyDetails/GlobalStrategyDetails.helper';

const AverageHoldPeriod = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<GlobalStrategyDetailsFormFields>();
  const isEditMode = useSelector(isGlobalStrategyEditModeSelector);
  const averageHoldPeriods = useSelector(averageHoldPeriodsSelector);

  useEffect(() => {
    if (!averageHoldPeriods.length) {
      dispatch(fetchAverageHoldPeriods());
    }
  }, [dispatch, averageHoldPeriods.length]);

  const options = useMemo(
    () => convertValuesToOptions(averageHoldPeriods),
    [averageHoldPeriods]
  );

  return (
    <>
      <FieldLabel>{t('averageHoldPeriod')}</FieldLabel>
      {isEditMode ? (
        <FormikSelect
          name={StrategyFormFields.AVERAGE_HOLD_PERIOD}
          options={options}
        />
      ) : (
        <ReadonlyData>
          {
            averageHoldPeriods.find(
              (averageHoldPeriod) =>
                averageHoldPeriod.id === values.average_hold_period
            )?.name
          }
        </ReadonlyData>
      )}
    </>
  );
};

export default AverageHoldPeriod;
