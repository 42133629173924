import { useMemo } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import {
  createNewPortfolioManager,
  updatePortfolioManagerDetails,
} from 'store/thunks';
import { portfolioManagerValidationSchema } from 'validation/portfolioManagerValidationSchema';
import {
  GetPortfolioManager,
  ManagerEntityRequest,
} from 'services/portfolioManagerCore/portfolioManagerCore.type';
import { useAppDispatch } from 'store';
import { dateToDayjsInstance, formatDate } from 'helpers/dateUtils';
import { ManagerFormFields } from './PortfolioManagerDetails.constants';
import { PortfolioManagerFormFields } from './PortfolioManagerDetails.type';

export const managerToFormFields = (
  manager: GetPortfolioManager | null
): PortfolioManagerFormFields => {
  return {
    [ManagerFormFields.NAME]: manager?.name || '',
    [ManagerFormFields.SURNAME]: manager?.surname || '',
    [ManagerFormFields.STATUS]: manager?.status?.id || '',
    [ManagerFormFields.TEAMS]: manager?.teams?.map(({ id }) => id) || [],
    [ManagerFormFields.DESCRIPTION]: manager?.description || '',
    [ManagerFormFields.STRATEGY_EXPERTISE]:
      manager?.strategyExpertises?.map(({ id }) => id) || [],
    [ManagerFormFields.CURRENT_FIRM]: manager?.currentFirm?.id || '',
    [ManagerFormFields.FORMER_FIRMS]:
      manager?.formerFirms?.map(({ id }) => id) || [],
    [ManagerFormFields.INVESTING_EXPERIENCE]: dateToDayjsInstance(
      manager?.investingExperienceStartDate
    ),
    [ManagerFormFields.LOCATION]: manager?.location || '',
    [ManagerFormFields.HIGHEST_DEGREE]: manager?.highestDegree || '',
    [ManagerFormFields.SCHOOLS]: manager?.schools?.map(({ id }) => id) || [],
    [ManagerFormFields.CERTIFICATION]: manager?.certification || '',
    // [ManagerFormFields.CRM_SYSTEM]: manager?.crmSystem || '', // TODO TBD
  };
};

export const managerFormFieldsToManager = ({
  investingExperienceStartDate,
  ...values
}: PortfolioManagerFormFields): ManagerEntityRequest => {
  return {
    ...values,
    investingExperienceStartDate: formatDate(investingExperienceStartDate),
  };
};

type PortfolioManagerDetailsFormProps = {
  isCreateForm: boolean;
  portfolioManager: GetPortfolioManager | null;
  updateManager?: (manager: GetPortfolioManager) => void;
};

export const usePortfolioManagerDetailsForm = ({
  isCreateForm,
  portfolioManager,
  updateManager,
}: PortfolioManagerDetailsFormProps) => {
  const dispatch = useAppDispatch();

  const managerId = portfolioManager?.id;

  const initialValues: PortfolioManagerFormFields = useMemo(
    () => managerToFormFields(portfolioManager),
    [portfolioManager]
  );

  const handleSubmit = async (
    values: PortfolioManagerFormFields,
    { resetForm }: FormikHelpers<PortfolioManagerFormFields>
  ) => {
    const managerToSave = managerFormFieldsToManager(values);
    let updatedManager;

    if (isCreateForm) {
      const { payload } = await dispatch(
        createNewPortfolioManager(managerToSave)
      );

      updatedManager = payload as GetPortfolioManager;
    } else {
      const { payload } = await dispatch(
        updatePortfolioManagerDetails({
          uuid: managerId as string,
          manager: managerToSave,
        })
      );

      updatedManager = payload as GetPortfolioManager;
    }

    if (updatedManager && updateManager) {
      updateManager(updatedManager);
    }

    resetForm({ values });
  };

  const formik = useFormik<PortfolioManagerFormFields>({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: portfolioManagerValidationSchema,
  });

  return {
    formik,
    initialValues,
  };
};
