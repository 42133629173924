import React, { ChangeEvent, FocusEvent } from 'react';
import { getIn } from 'formik';
import { CorrelationFieldProps } from './CorrelationsDialog.type';
import { Input } from './CorrelationsDialog.styled';

const CorrelationField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  yIndex,
  xIndex,
  ...props
}: CorrelationFieldProps) => {
  const { name, onBlur, onChange } = field;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
    const value = event.target.value === '' ? '' : Number(event.target.value);
    setFieldValue(
      `correlations.${xIndex + 1}.correlations.${yIndex}.correlation`,
      value
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur(event);
    setFieldTouched(
      `correlations.${xIndex + 1}.correlations.${yIndex}.correlation`,
      true
    );
  };

  return (
    <div>
      <Input
        className={getIn(touched, name) && getIn(errors, name) ? 'error' : ''}
        type='number'
        {...field}
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default CorrelationField;
