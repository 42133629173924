import { FormHelperText } from '@mui/material';
import styled from '@emotion/styled';

export const TeamMemberFormContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr minmax(142px, 1fr);
  grid-column-gap: 20px;
  align-items: center;
`;

export const ErrorMessage = styled(FormHelperText)`
  &.Mui-error {
    color: ${({ theme: { colors } }) => colors.sysColorDarkError};
  }
`;
